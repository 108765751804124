import { all, takeEvery } from 'redux-saga/effects';

import {
  ACTIVATE_INFINITY_CODE_FAIL,
  ACTIVATE_INFINITY_CODE_SUCCESS,
} from 'bubble-reducers/src/reducers/user';

function handleInfinityCodeFlow(action) {
  const codeResult = action.payload.codeResult;
  alert(`${codeResult.title} - ${codeResult.message}`);
}

export default function* interfaceWatcher() {
  yield all([
    takeEvery(ACTIVATE_INFINITY_CODE_SUCCESS, handleInfinityCodeFlow),
    takeEvery(ACTIVATE_INFINITY_CODE_FAIL, handleInfinityCodeFlow),
  ]);
}

import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { loadPaymentMethodsApi, updatePaymentMethodApi } from '../services/api';

import { LOAD_PAYMENT_METHODS, UPDATE_PAYMENT_METHOD } from '../reducers/payment-methods';
import {
  loadPaymentMethodsFail,
  loadPaymentMethodsSuccess,
  updatePaymentMethodFail,
  updatePaymentMethodSuccess,
} from '../reducers/payment-methods';
import { getClientToken } from '../selectors';

export function* loadPaymentMethodsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadPaymentMethodsApi, clientToken, action.payload.userObjectId);
    yield put(loadPaymentMethodsSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadPaymentMethodsFlow)', error);
    yield put(loadPaymentMethodsFail(error));
  }
}

export function* updatePaymentMethodFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      updatePaymentMethodApi,
      clientToken,
      action.payload.userObjectId,
      action.payload.paymentMethod,
    );

    if (response && response.error) {
      throw response.error;
    }
    yield put(updatePaymentMethodSuccess(response));
  } catch (error) {
    console.log('saga middleware error (updatePaymentMethodFlow)', error);
    yield put(updatePaymentMethodFail(error));
    // we return the error for other flows calling this flow
    return error;
  }
}

export default function* paymentMethodsWatcher() {
  yield all([
    takeLatest(LOAD_PAYMENT_METHODS, loadPaymentMethodsFlow),
    takeLatest(UPDATE_PAYMENT_METHOD, updatePaymentMethodFlow),
  ]);
}

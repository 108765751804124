export const getAvailabilities = (print) => {
  let availabilities = {
    online: false,
    clickAndCollect: false,
    clickAndCollectSellersCount: 0,
    message: '',
    color: '#ea0717',
    atLeastOneChannel: false,
  };
  if (!print || (print && Object.keys(print).length === 0)) {
    return availabilities;
  }
  const sellingInfo = print.sellingInfo || {};
  const onlineSellingInfo = sellingInfo.online || {};
  availabilities.online = onlineSellingInfo.numberOfSellers > 0;
  availabilities.clickAndCollect = (sellingInfo.clickAndCollect || {}).numberOfSellers > 0;
  availabilities.clickAndCollectSellersCount = (sellingInfo.clickAndCollect || {}).numberOfSellers;
  availabilities.atLeastOneChannel = availabilities.online || availabilities.clickAndCollect;

  availabilities.color = availabilities.atLeastOneChannel ? '#7aca22' : '#ea0717';

  if (availabilities.online) {
    if (availabilities.clickAndCollect) {
      availabilities.message = 'Disponible';
    } else {
      availabilities.message = 'Disponible';
    }
  } else {
    if (availabilities.clickAndCollect) {
      availabilities.message = `${
        ((sellingInfo.clickAndCollect || {}).availability || []).message
      }`;
    } else {
      // availabilities.message = 'Non disponible (via Bubble)';
      availabilities.message = (onlineSellingInfo.availability || {}).message;
      availabilities.color = (onlineSellingInfo.availability || {}).color;
    }
  }
  return availabilities;
};

export const getDefaultSellingPrint = (album) => {
  if (!album || album === 'undefined') return {};

  if (album.selectedPrint) {
    return album.selectedPrint;
  }
  return album.prints
    ? album.prints.find((print) => print?.objectId === album?.defaultSellingPrintObjectId) || {}
    : {};
};

export const isPrintPublished = (print) => {
  if (!print) {
    return false;
  }
  // allow users to add the album if the publication date is missing
  if (!print.publicationDate) {
    return true;
  }
  return new Date(print.publicationDate) < new Date();
};

export const isPrintAvailableOnline = (print) => {
  const code = print?.sellingInfo?.online?.availability?.code;
  return code === 100 || code === 104 || code === 103;
};

export const isPrintAvailableClickAndCollect = (print) => {
  const code = print?.sellingInfo?.clickAndCollect?.availability?.code;
  return code === 100;
};

export const isPrintAvailable = (print) => {
  return isPrintAvailableClickAndCollect(print) || isPrintAvailableOnline(print);
};

export const formatAlbum = (album) => {
  let newAlbum = Object.assign({}, album, {
    isPublished: !album.publicationDate || new Date(album.publicationDate) < new Date(),
  });

  // format dates
  newAlbum.publicationDateFormattedAll = null;
  newAlbum.publicationDateFormattedShort = null;
  if (album.publicationDate && new Date(album.publicationDate)) {
    const publicationDate = new Date(album.publicationDate);
    let day = publicationDate.getUTCDate();
    day = day < 10 ? '0' + day : day;

    var month = publicationDate.getUTCMonth() + 1;
    month = month < 10 ? '0' + month : month;

    newAlbum.publicationDateFormattedAll = [day, month, publicationDate.getUTCFullYear()].join('/');
    newAlbum.publicationDateFormattedShort = [day, month].join('/');
  }
  return newAlbum;
};

export const getFormattedTitle = (serie = {}, album = {}) =>
  [
    `${serie.title || ''}${album.tome ? ` tome ${album.tome}` : ''}`,
    !!album.title && album.title !== serie.title ? album.title : null,
  ]
    .filter(Boolean)
    .join(' - ');

export const STORE_PRINTS = 'STORE_PRINTS';

export const RESET_PRINTS_REDUCER = 'RESET_PRINTS_REDUCER';

export const LOAD_PRINT = 'LOAD_PRINT';
export const LOAD_PRINT_SUCCESS = 'LOAD_PRINT_SUCCESS';
export const LOAD_PRINT_FAIL = 'LOAD_PRINT_FAIL';

const initialState = {
  prints: {},
  loading: false,
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case STORE_PRINTS: {
      const newPrints = {};
      action.payload.prints.forEach((print) => (newPrints[print.objectId] = print));
      // erasing old values with new values may cause issues if new values come from 'light' library load
      const prints =
        action.payload.source === 'loadLibraryFlow>storeSeriesFlow>storeAlbumsFlow' ||
        action.payload.source === 'loadFollowedFlow>storeSeriesFlow>storeAlbumsFlow'
          ? Object.assign({}, newPrints, state.prints)
          : Object.assign({}, state.prints, newPrints);
      return {
        ...state,
        prints: prints,
        loading: false,
        errors: [],
      };
    }

    case LOAD_PRINT:
      return {
        ...state,
        requesting: true,
        successful: false,
        errors: [],
      };

    case LOAD_PRINT_SUCCESS: {
      const newPrint = { [action.payload.print.objectId]: action.payload.print };
      const prints = Object.assign({}, state.prints, newPrint);
      return {
        ...state,
        prints,
        requesting: false,
        successful: true,
        errors: [],
      };
    }

    case LOAD_PRINT_FAIL:
      return {
        ...state,
        requesting: false,
        successful: false,
        errors: state.errors.concat([action.payload.error]),
      };

    case RESET_PRINTS_REDUCER: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}

// storePrints ====================================================

export function storePrints(prints, source) {
  return {
    type: STORE_PRINTS,
    payload: { prints, source },
  };
}

// loadPrint ====================================================

export const loadPrint = (printObjectId, options) => {
  return {
    type: LOAD_PRINT,
    payload: { printObjectId, options },
  };
};

export const loadPrintSuccess = (print) => {
  return {
    type: LOAD_PRINT_SUCCESS,
    payload: { print },
  };
};

export const loadPrintFail = (error) => {
  return {
    type: LOAD_PRINT_FAIL,
    payload: { error },
  };
};

// resetPrintsReducer ====================================================

export function resetPrintsReducer() {
  return {
    type: RESET_PRINTS_REDUCER,
  };
}

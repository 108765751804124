import React from 'react';

import imageUtils from '@/services/image-utils';

export default function KeySellingClickAndCollect() {
  return (
    <div className="row">
      <div className="col-md-4 py-1 d-flex flex-column align-items-center">
        <img
          className="pb-2 store-page-keypoint"
          alt="store"
          src={imageUtils.getAssetImage('icon_bookseller_green.svg')}
        />
        <div className="bb-medium-text-size">Bandes dessinées, Comics et Mangas</div>
      </div>
      <div className="col-md-4 py-1 d-flex flex-column align-items-center">
        <img
          className="pb-2 store-page-keypoint"
          alt="store"
          src={imageUtils.getAssetImage('icon_cb_green.svg')}
        />
        <div className="bb-medium-text-size pt-2">Paiement en magasin</div>
      </div>
      <div className="col-md-4 py-1 d-flex flex-column align-items-center">
        <img
          className="pb-2 store-page-keypoint"
          alt="store"
          src={imageUtils.getAssetImage('icon_chrono_green.svg')}
        />
        <div className="bb-medium-text-size">Retrait gratuit au comptoir sous 2h</div>
      </div>
    </div>
  );
}

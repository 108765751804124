import bubbleUtils from 'bubble-utils';

export const SAVE_JWT_TOKEN = 'SAVE_JWT_TOKEN';
export const DELETE_JWT_TOKEN = 'DELETE_JWT_TOKEN';

export const SAVE_SESSION_ID = 'SAVE_SESSION_ID';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGIN_GUEST_REQUEST = 'LOGIN_GUEST_REQUEST';
export const LOGIN_GUEST_SUCCESS = 'LOGIN_GUEST_SUCCESS';
export const LOGIN_GUEST_FAIL = 'LOGIN_GUEST_FAIL';

export const LOGIN_WITH_FACEBOOK = 'LOGIN_WITH_FACEBOOK';
export const LOGIN_WITH_FACEBOOK_JWT_TOKEN = 'LOGIN_WITH_FACEBOOK_JWT_TOKEN';
export const LOGIN_WITH_FACEBOOK_SUCCESS = 'LOGIN_WITH_FACEBOOK_SUCCESS';
export const LOGIN_WITH_FACEBOOK_ERROR = 'LOGIN_WITH_FACEBOOK_ERROR';

export const LOGIN_WITH_APPLE = 'LOGIN_WITH_APPLE';
export const LOGIN_WITH_APPLE_SUCCESS = 'LOGIN_WITH_APPLE_SUCCESS';
export const LOGIN_WITH_APPLE_ERROR = 'LOGIN_WITH_APPLE_ERROR';

export const LOGOUT = 'LOGOUT';

export const CHECK_JWT_TOKEN_VALIDITY = 'CHECK_JWT_TOKEN_VALIDITY';
export const CHECK_JWT_TOKEN_VALIDITY_SUCCESS = 'CHECK_JWT_TOKEN_VALIDITY_SUCCESS';
export const CHECK_JWT_TOKEN_VALIDITY_FAIL = 'CHECK_JWT_TOKEN_VALIDITY_FAIL';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const UPDATE_PASSWORD_VERIFY_TOKEN = 'UPDATE_PASSWORD_VERIFY_TOKEN';
export const UPDATE_PASSWORD_VERIFY_TOKEN_SUCCESS = 'UPDATE_PASSWORD_VERIFY_TOKEN_SUCCESS';
export const UPDATE_PASSWORD_VERIFY_TOKEN_FAIL = 'UPDATE_PASSWORD_VERIFY_TOKEN_FAIL';

export const UPDATE_PASSWORD_FROM_EMAIL = 'UPDATE_PASSWORD_FROM_EMAIL';
export const UPDATE_PASSWORD_FROM_EMAIL_SUCCESS = 'UPDATE_PASSWORD_FROM_EMAIL_SUCCESS';
export const UPDATE_PASSWORD_FROM_EMAIL_FAIL = 'UPDATE_PASSWORD_FROM_EMAIL_FAIL';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';

const initialState = {
  jwtToken: null,
  sessionId: null,
  loading: false,
  passwordUpdateVerified: false,
  errors: [],
  signupErrors: [],
  resetPasswordErrors: [],
  passwordUpdateErrors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_JWT_TOKEN: {
      return {
        ...state,
        jwtToken: action.payload.jwtToken,
      };
    }
    case DELETE_JWT_TOKEN: {
      return {
        ...state,
        jwtToken: null,
      };
    }

    case SAVE_SESSION_ID: {
      return {
        ...state,
        sessionId: action.payload.sessionId,
      };
    }

    case SIGNUP: {
      return {
        ...state,
        loading: true,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        signupErrors: [],
      };
    }
    case SIGNUP_FAIL: {
      return {
        ...state,
        signupErrors: [...state.signupErrors, action.payload],
        loading: false,
      };
    }

    case RESET_PASSWORD: {
      return {
        ...state,
        loading: true,
        resetPasswordErrors: initialState.resetPasswordErrors,
        resetPasswordMessage: null,
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      const resetStatus = action.payload.resetStatus;
      return {
        ...state,
        loading: false,
        resetPasswordErrors: initialState.resetPasswordErrors,
        resetPasswordMessage: resetStatus.message,
      };
    }
    case RESET_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
        resetPasswordErrors: [...state.resetPasswordErrors, action.payload],
        resetPasswordMessage: null,
      };
    }

    case LOGIN: {
      return {
        ...state,
        loading: true,
        errors: initialState.errors,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: initialState.errors,
      };
    }
    case LOGIN_ERROR: {
      return {
        ...state,
        errors: [...state.errors, action.payload],
        loading: false,
      };
    }

    case LOGIN_WITH_FACEBOOK: {
      return {
        ...state,
        loading: true,
        errors: initialState.errors,
      };
    }
    case LOGIN_WITH_FACEBOOK_JWT_TOKEN: {
      return {
        ...state,
        loading: true,
        errors: initialState.errors,
      };
    }
    case LOGIN_WITH_FACEBOOK_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: initialState.errors,
      };
    }
    case LOGIN_WITH_FACEBOOK_ERROR: {
      return {
        ...state,
        errors: [...state.errors, action.payload],
        loading: false,
      };
    }

    case LOGIN_WITH_APPLE: {
      return {
        ...state,
        loading: true,
        errors: initialState.errors,
      };
    }
    case LOGIN_WITH_APPLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: initialState.errors,
      };
    }
    case LOGIN_WITH_APPLE_ERROR: {
      return {
        ...state,
        errors: [...state.errors, action.payload],
        loading: false,
      };
    }

    case LOGOUT: {
      return {
        ...state,
        jwtToken: null,
        errors: initialState.errors,
        loading: false,
      };
    }

    case LOGIN_GUEST_REQUEST: {
      return {
        ...state,
        loading: true,
        errors: initialState.errors,
      };
    }
    case LOGIN_GUEST_SUCCESS: {
      return {
        ...state,
        loading: true,
        errors: initialState.errors,
      };
    }
    case LOGIN_GUEST_FAIL: {
      return {
        ...state,
        errors: [...state.errors, action.payload],
        loading: false,
      };
    }

    case UPDATE_PASSWORD_VERIFY_TOKEN: {
      return {
        ...state,
        loading: true,
        passwordUpdated: false,
        passwordUpdateErrors: initialState.errors,
      };
    }
    case UPDATE_PASSWORD_VERIFY_TOKEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        passwordUpdateErrors: initialState.errors,
      };
    }
    case UPDATE_PASSWORD_VERIFY_TOKEN_FAIL: {
      let errors = (state.errors || []).slice();
      errors.push(action.payload);
      return {
        ...state,
        loading: false,
        passwordUpdateErrors: errors,
      };
    }

    case UPDATE_PASSWORD_FROM_EMAIL: {
      return {
        ...state,
        loading: true,
        passwordUpdated: false,
        passwordUpdateErrors: initialState.errors,
      };
    }
    case UPDATE_PASSWORD_FROM_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        passwordUpdated: true,
        passwordUpdateErrors: initialState.errors,
      };
    }
    case UPDATE_PASSWORD_FROM_EMAIL_FAIL: {
      let errors = (state.errors || []).slice();
      errors.push(action.payload);
      return {
        ...state,
        loading: false,
        passwordUpdated: false,
        passwordUpdateErrors: errors,
      };
    }

    case UPDATE_PASSWORD: {
      return {
        ...state,
        loading: true,
        passwordUpdated: false,
        passwordUpdateErrors: initialState.errors,
      };
    }
    case UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        passwordUpdated: true,
        passwordUpdateErrors: initialState.errors,
      };
    }
    case UPDATE_PASSWORD_FAIL: {
      let errors = (state.errors || []).slice();
      errors.push(action.payload);
      return {
        ...state,
        loading: false,
        passwordUpdated: false,
        passwordUpdateErrors: errors,
      };
    }

    case RESET_ERRORS: {
      return {
        ...state,
        errors: initialState.errors,
        signupErrors: initialState.errors,
      };
    }

    default: {
      return state;
    }
  }
}

// ====================================================================

export function saveJwtToken(jwtToken) {
  return {
    type: SAVE_JWT_TOKEN,
    payload: {
      jwtToken,
    },
  };
}

export function saveSessionId(sessionId) {
  return {
    type: SAVE_SESSION_ID,
    payload: {
      sessionId,
    },
  };
}

export function deleteJwtToken() {
  return {
    type: DELETE_JWT_TOKEN,
  };
}

// ====================================================================

export function signup(
  email,
  password,
  hasAcceptedNewsletter,
  isGdprCompliant,
  token = null,
  cookie,
  localStorage,
  referralCode = null,
  platform = null,
) {
  return {
    type: SIGNUP,
    payload: {
      email,
      password,
      token,
      hasAcceptedNewsletter,
      isGdprCompliant,
      cookie,
      localStorage,
      referralCode,
      platform,
    },
  };
}
export function signupSuccess(initialPayload, authData) {
  return {
    type: SIGNUP_SUCCESS,
    payload: { initialPayload, authData },
  };
}
export function signupFail(error) {
  return {
    type: SIGNUP_FAIL,
    payload: bubbleUtils.string.isString(error) ? new Error(error) : error,
    error: true,
  };
}

// login ====================================

export function login(email, password, stayLogged = false, cookie, localStorage) {
  return {
    type: LOGIN,
    payload: {
      email,
      password,
      stayLogged,
      cookie,
      localStorage,
    },
  };
}
export function loginSuccess(initialPayload, authData) {
  return {
    type: LOGIN_SUCCESS,
    payload: { initialPayload, authData },
  };
}
export function loginFail(error) {
  return {
    type: LOGIN_ERROR,
    payload: error,
    error: true,
  };
}

// loginGuest ====================================

export function loginGuestRequest(email, cookie, localStorage) {
  return {
    type: LOGIN_GUEST_REQUEST,
    payload: {
      email,
      cookie,
      localStorage,
    },
  };
}
export function loginGuestSuccess(initialPayload, authData) {
  return {
    type: LOGIN_GUEST_SUCCESS,
    payload: { initialPayload, authData },
  };
}
export function loginGuestFail(error) {
  return {
    type: LOGIN_GUEST_FAIL,
    payload: error,
    error: true,
  };
}

// loginWithFacebook and loginWithFacebookJwtToken ====================================

export function loginWithFacebook(
  accessToken,
  expirationTime,
  platform,
  method,
  cookie,
  localStorage,
  referralCode,
) {
  return {
    type: LOGIN_WITH_FACEBOOK,
    payload: { accessToken, expirationTime, platform, method, cookie, localStorage, referralCode },
  };
}
export function loginWithFacebookJwtToken(
  authenticationToken,
  platform,
  method,
  nonce,
  referralCode,
) {
  return {
    type: LOGIN_WITH_FACEBOOK_JWT_TOKEN,
    payload: { authenticationToken, platform, method, nonce, referralCode },
  };
}
export function loginWithFacebookSuccess(initialPayload, authData) {
  return {
    type: LOGIN_WITH_FACEBOOK_SUCCESS,
    payload: { initialPayload, authData },
  };
}
export function loginWithFacebookFail(error) {
  return {
    type: LOGIN_WITH_FACEBOOK_ERROR,
    payload: error,
    error: true,
  };
}

// loginWithApple ====================================

export function loginWithApple(authentificationData, platform, referralCode) {
  return {
    type: LOGIN_WITH_APPLE,
    payload: { authentificationData, platform, referralCode },
  };
}
export function loginWithAppleSuccess(initialPayload, authData) {
  return {
    type: LOGIN_WITH_APPLE_SUCCESS,
    payload: { initialPayload, authData },
  };
}
export function loginWithAppleFail(error) {
  return {
    type: LOGIN_WITH_APPLE_ERROR,
    payload: error,
    error: true,
  };
}

// logout ====================================

export function logout(cookie, localStorage) {
  return {
    type: LOGOUT,
    payload: { cookie, localStorage },
  };
}

// checkJwtTokenValidity ====================================

export function checkJwtTokenValidity() {
  return {
    type: CHECK_JWT_TOKEN_VALIDITY,
  };
}
export function checkJwtTokenValiditySuccess(token) {
  return {
    type: CHECK_JWT_TOKEN_VALIDITY_SUCCESS,
    payload: { token },
  };
}
export function checkJwtTokenValidityFail(error) {
  return {
    type: CHECK_JWT_TOKEN_VALIDITY_FAIL,
    payload: error,
    error: true,
  };
}

// resetPassword ====================================

export function resetPassword(email) {
  return {
    type: RESET_PASSWORD,
    payload: { email },
  };
}
export function resetPasswordSuccess(resetStatus) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: { resetStatus },
  };
}
export function resetPasswordFail(error) {
  return {
    type: RESET_PASSWORD_FAIL,
    payload: error,
    error: true,
  };
}

// updatePasswordVerify ====================================

export function updatePasswordVerify(email, token) {
  return {
    type: UPDATE_PASSWORD_VERIFY_TOKEN,
    payload: { email, token },
  };
}
export function updatePasswordVerifySuccess() {
  return {
    type: UPDATE_PASSWORD_VERIFY_TOKEN_SUCCESS,
  };
}
export function updatePasswordVerifyFail(error) {
  return {
    type: UPDATE_PASSWORD_VERIFY_TOKEN_FAIL,
    payload: error,
    error: true,
  };
}

// updatePasswordFromEmail ====================================

export function updatePasswordFromEmail(email, token, newPassword) {
  return {
    type: UPDATE_PASSWORD_FROM_EMAIL,
    payload: { email, token, newPassword },
  };
}
export function updatePasswordFromEmailSuccess() {
  return {
    type: UPDATE_PASSWORD_FROM_EMAIL_SUCCESS,
  };
}
export function updatePasswordFromEmailFail(error) {
  return {
    type: UPDATE_PASSWORD_FROM_EMAIL_FAIL,
    payload: error,
    error: true,
  };
}

// passwordUpdate ====================================

export function updatePassword(userObjectId, newPassword) {
  return {
    type: UPDATE_PASSWORD,
    payload: { userObjectId, newPassword },
  };
}
export function updatePasswordSuccess() {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
  };
}
export function updatePasswordFail(error) {
  return {
    type: UPDATE_PASSWORD_FAIL,
    payload: error,
    error: true,
  };
}

// resetErrors ====================================

export function resetErrors() {
  return {
    type: RESET_ERRORS,
  };
}

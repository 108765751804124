import PropTypes from 'prop-types';
import React from 'react';

import config from '@/project-config';

import Toaster from '../Toaster';

const Toast = (props) => {
  const onClickRemove = () => Toaster.removeToast(props.toastId);

  return (
    <div
      id={props.toastId}
      data-delay="5000"
      style={{ position: 'relative', right: '0rem', minWidth: '300px' }}
      className="toast"
      role="alert"
    >
      <div className="toast-header">
        <img
          src={`${config.resources.domain}/favicon-48x48.png`}
          className="rounded me-2"
          style={{ width: 20, height: 20 }}
          alt="bubble"
        />
        <strong className="me-auto">{props.title}</strong>
        <small className="text-muted">{props.small ? props.small : ''}</small>
        <button
          type="button"
          className="ms-2 mb-1 close"
          onClick={onClickRemove}
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="toast-body">{props.body}</div>
    </div>
  );
};

Toast.propTypes = {
  toastId: PropTypes.string.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  small: PropTypes.any,
};

export default Toast;

import React from 'react';

import { connect } from 'react-redux';

import { loadProducts } from 'bubble-reducers/src/reducers/products';
import { getProductType } from 'bubble-reducers/src/selectors';

import { getSeoForGiftCard } from '@/services/seo-utils';

import BreadCrumbs from '@/components/Breadcrumbs/BreadCrumbs';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import ShareZone from '@/components/ShareZone/ShareZone';
import withRouter from '@/components/withRouter';

import GiftCardChooser from './components/GiftCardChooser';

class GiftCard extends React.PureComponent {
  constructor(props) {
    super(props);
    props.loadProducts({ type: 'gift-card' });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const { loadProducts } = this.props;
      loadProducts({ type: 'gift-card' });
    }
  }

  render() {
    const { products, product } = this.props;
    return (
      <>
        <BubbleHelmet seo={getSeoForGiftCard(product)} />
        <div className="bb-background-light-grey">
          <div className="container pb-6">
            <div className="d-flex justify-content-end">
              <BreadCrumbs currentObject={product} />
              <ShareZone title={`Partager`} />
            </div>
            <div className="row px-sm-3">
              <div className="col-md-6">
                <img
                  alt="cheque cadeau"
                  className="d-flex flex-fill w-100 pb-5 pb-md-0 border"
                  src={((product.images || {}).front || {}).large}
                />
              </div>
              <div className="col-md-6">
                <GiftCardChooser selected={product} products={products} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  products: getProductType(state, 'gift-card') || [],
  product: state.products.products[props.router?.params?.productObjectId] || {},
});

export default withRouter(connect(mapStateToProps, { loadProducts })(GiftCard));

import React from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import burgerIcon from '@/assets/header/burger_bubblebd.svg';
import cartIcon from '@/assets/header/icon_cart.svg';
import userIcon from '@/assets/header/icon_profile.svg';
import logo from '@/assets/logo/full_horizontal.svg';

import HomeSearchBar from '../HomeSearchBar/HomeSearchBar';
import BurgerMenu from './components/BurgerMenu/BurgerMenu';
import CartSection from './components/CartSection/CartSection';
import DropdownContainer from './components/DropdownContainer/DropdownContainer';
import ProfileTab from './components/ProfileTab/ProfileTab';
import SubNavbar from './components/SubNavbar/SubNavbar';

import bubbleUtils from 'bubble-utils';

import './Header.scss';

const MIN_Y_POS_FOR_SCROLL = 100;

class Header extends React.PureComponent {
  state = { isSearching: false, showBurgerMenu: false };
  headerRef = React.createRef();
  inputRef = React.createRef();
  prevScrollPos = 0;

  hideOnScroll = () => {
    const currentScrollPos = window.pageYOffset;
    if (this.headerRef && this.prevScrollpos > currentScrollPos) {
      this.headerRef.classList.remove('header-hidden');
    } else if (currentScrollPos > MIN_Y_POS_FOR_SCROLL && this.headerRef) {
      this.headerRef.classList.add('header-hidden');
    }
    this.prevScrollpos = currentScrollPos;
  };

  componentDidMount() {
    if (window) {
      this.prevScrollPos = window.pageYOffset;
      /* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
      window.addEventListener('scroll', bubbleUtils.code.throttle(this.hideOnScroll, 200));
    }

    if (window && window.IntersectionObserver) {
      const observer = new IntersectionObserver(
        (entries, observer) => {
          const trigger = entries[0];
          if (this.headerRef) {
            if (trigger.isIntersecting) {
              this.headerRef.classList.remove('bb-s-shadow-hover');
            } else {
              this.headerRef.classList.add('bb-s-shadow-hover');
            }
          }
        },
        { root: null },
      );
      observer.observe(this.trigger);
    }
  }

  handleSearchClick = () =>
    this.setState({ isSearching: true }, () => {
      setTimeout(() => {
        if (this.inputRef.current) this.inputRef.current.focus();
      }, 0);
    });
  handleSearchClose = () => this.setState({ isSearching: false });

  onClickBurgerMenu = () => this.setState({ showBurgerMenu: !this.state.showBurgerMenu });

  render() {
    return (
      <>
        <div ref={(ref) => (this.trigger = ref)} />
        <header
          ref={(ref) => (this.headerRef = ref)}
          className={`website-header header-container position-sticky sticky-top transition bg-blacker text-white`}
        >
          <div className="px-0 px-lg-5">
            <nav className="transition navbar navbar-expand-lg d-flex justify-content-between justify-content-lg-start align-items-center">
              <div className="d-flex align-items-center">
                <div className="d-lg-none bg-grey burger-menu-button d-flex align-items-center justify-content-center">
                  <button
                    onClick={this.onClickBurgerMenu}
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <img alt="menu burger" src={burgerIcon} className="header-burger-icon" />
                  </button>
                </div>

                {!this.state.isSearching && (
                  <div className="d-flex d-lg-none px-3">
                    <Link to={'/'}>
                      <img className="bubble-logo" alt="img" src={logo} />
                    </Link>
                  </div>
                )}
              </div>
              <div className={`d-flex d-lg-none ${this.state.isSearching && 'flex-fill px-3'}`}>
                {!!this.state.isSearching && (
                  <HomeSearchBar forceExitButton exitAndClearCallback={this.handleSearchClose} />
                )}
                {!this.state.isSearching && (
                  <>
                    <WithClickHandler
                      onClick={this.handleSearchClick}
                      className="d-flex no-decoration p-2"
                    >
                      <Icon name="search" className="text-white bb-l-text-size" />
                    </WithClickHandler>
                    <Link className="d-flex no-decoration p-2" to={'/cart'}>
                      <img alt="shopping-bag" src={cartIcon} className="text-white header-icon" />
                    </Link>
                    <Link
                      className="d-flex no-decoration p-2 px-3"
                      to={this.props.userObjectId ? '/my-account/home' : '/login'}
                    >
                      <img alt="user" src={userIcon} className="text-white header-icon" />
                    </Link>
                  </>
                )}
              </div>
              <div className="collapse navbar-collapse">
                <div className="d-flex flex-fill align-items-center">
                  <Link className="navbar-brand me-2" to={'/'}>
                    <img className="bubble-logo" alt="img" src={logo} />
                  </Link>
                  <div className="d-flex flex-fill px-4">
                    <HomeSearchBar inputRef={this.inputRef} />
                  </div>
                </div>

                <ul className="navbar-nav d-flex">
                  <li className="nav-item d-flex align-items-center pe-1 pe-xl-5">
                    <Link className="no-decoration text-white" to="/9emeart">
                      Trouver de l'inspiration
                    </Link>
                  </li>

                  <li className="nav-item d-flex align-items-center ps-1">
                    <CartSection />
                  </li>
                  <li className="nav-item d-flex align-items-center px-1">
                    <ProfileTab />
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>

        <SubNavbar />
        <DropdownContainer />
        <div className="position-absolute d-lg-none">
          <BurgerMenu show={this.state.showBurgerMenu} onClose={this.onClickBurgerMenu} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userObjectId: state.user.user?.objectId,
});

export default connect(mapStateToProps)(Header);

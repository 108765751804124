import React, { useCallback, useMemo, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { createPrintRevision } from 'bubble-reducers/src/reducers/catalogs';
import {
  searchAuthors,
  searchCollections,
  searchPublishers,
  searchTypes,
} from 'bubble-reducers/src/reducers/search';
import { makeGetAlbumPrints } from 'bubble-reducers/src/selectors';

import { Icon } from '@/components/Icon/Icon';
import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';
import SearchAuthorCard from '@/components/SearchResults/components/SearchAuthorCard';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import ElementCell from './components/ElementCell';
import PrintSelectionCell from './components/PrintSelectionCell';

import BubbleUtils from 'bubble-utils';

import './Modals.css';

const PrintRevisionModal = (props) => {
  const dispatch = useDispatch();
  const { albumObjectId, serieObjectId, printObjectId, allowGoBack } = props;

  const [editedFields, setEditedFields] = useState({});

  const [isSelectingPrint, setIsSelectingPrint] = useState(false);
  const [authorFocused, setAuthorFocused] = useState(false);
  const [collectionFocused, setCollectionFocused] = useState(false);
  const [publisherFocused, setPublisherFocused] = useState(false);
  const [typeFocused, setTypeFocused] = useState(false);

  const [searchAuthorText, setSearchAuthorText] = useState('');
  const [searchCollectionsText, setSearchCollectionsText] = useState('');
  const [searchPublishersText, setSearchPublishersText] = useState('');
  const [searchTypesText, setSearchTypesText] = useState('');
  const [selectedPrint, setSelectedPrint] = useState(null);

  const print = useSelector((state) => state.prints.prints[printObjectId]);

  const getAlbumPrints = useMemo(() => makeGetAlbumPrints(albumObjectId), [albumObjectId]);
  const prints = useSelector((state) => getAlbumPrints(state, albumObjectId));
  const userObjectId = useSelector((state) => state.user.user.objectId);
  const album = useSelector((state) => state.albums.albums[albumObjectId]);
  const serie = useSelector((state) => state.series.series[serieObjectId]);
  const hitsAuthors = useSelector((state) => state.search.hitsAuthors);
  const hitsCollections = useSelector((state) => state.search.hitsCollections);
  const hitsPublishers = useSelector((state) => state.search.hitsPublishers);
  const hitsTypes = useSelector((state) => state.search.hitsTypes);

  const clearState = useCallback(() => {
    setIsSelectingPrint(false);
    setEditedFields({});
    setSearchAuthorText('');
    setSearchCollectionsText('');
    setSearchPublishersText('');
    setSearchTypesText('');
    setAuthorFocused(false);
    setCollectionFocused(false);
    setPublisherFocused(false);
    setTypeFocused(false);
    setSelectedPrint(null);
  }, [
    setEditedFields,
    setSearchAuthorText,
    setSearchCollectionsText,
    setSearchPublishersText,
    setSearchTypesText,
    setAuthorFocused,
    setCollectionFocused,
    setPublisherFocused,
    setTypeFocused,
    setIsSelectingPrint,
    setSelectedPrint,
  ]);

  const dismiss = () => {
    clearState();
    props.callback();
  };

  const handleGoBack = () => {
    dismiss();
    props.handleGoBack();
  };

  const isRevisionEmpty = useCallback((newValue, oldValue) => {
    const convertedOldValue =
      oldValue && typeof oldValue !== 'string' ? oldValue.toString() : oldValue;
    if (newValue === convertedOldValue) {
      return true;
      //This is to prevent revisions like null => null or null => ''
    } else if (
      (convertedOldValue === null || convertedOldValue === '' || convertedOldValue === undefined) &&
      (newValue === null || newValue === '' || newValue === undefined)
    ) {
      return true;
    } else {
      return false;
    }
  }, []);

  const sendRevision = () => {
    const revisions = [];
    const revisedPrint = selectedPrint || print;

    Object.keys(editedFields).forEach((field) => {
      if (field !== 'authors' && !isRevisionEmpty(editedFields[field], revisedPrint[field])) {
        revisions.push({
          userObjectId,
          oldValue: revisedPrint[field],
          newValue: editedFields[field],
          fieldRevised: field,
        });
      } else if (field === 'authors') {
        const newHash = editedFields[field]
          .sort((a, b) => a.objectId - b.objectId)
          .reduce((hash, auth) => {
            hash += auth.objectId;
            return hash;
          }, '');
        const oldHash = (revisedPrint[field] || [])
          .sort((a, b) => a.objectId - b.objectId)
          .reduce((hash, auth) => {
            hash += auth.objectId;
            return hash;
          }, '');
        if (newHash !== oldHash) {
          revisions.push({
            userObjectId,
            oldValue: revisedPrint[field],
            newValue: editedFields[field],
            fieldRevised: field,
          });
        }
      }
    });
    if (revisions.length) {
      dispatch(createPrintRevision(revisedPrint.objectId, revisions));
      dismiss();
      props.openThankYourModal();
    } else {
      alert("Aucun champ n'a l'air d'avoir été modifié, êtes vous bien sûr de vos modifications ?");
    }
  };

  const displayPublisher = () => {
    if ('publisher' in editedFields) return editedFields.publisher?.name || '';
    else if (selectedPrint) return selectedPrint?.publisher || '';
    return print?.publisher || '';
  };
  const displayCollection = () => {
    if ('collection' in editedFields) return editedFields.collection?.nameFrench || '';
    else if (selectedPrint) return selectedPrint?.collection || '';
    return print?.collection || '';
  };
  const displayType = () => {
    if ('type' in editedFields) return editedFields.type?.nameFrench || '';
    else if (selectedPrint) return selectedPrint?.type || '';
    return print?.type || '';
  };
  const displayPublicationDate = () => {
    if ('publicationDate' in editedFields)
      return BubbleUtils.date.extractDate(editedFields.publicationDate);
    else if (selectedPrint) return selectedPrint?.publicationDate?.split('T')[0] || '';
    return print?.publicationDate?.split('T')[0] || '';
  };
  const displayLegalDepositDate = () => {
    if ('legalDepositDate' in editedFields)
      return BubbleUtils.date.extractDate(editedFields.legalDepositDate);
    else if (selectedPrint) return selectedPrint?.legalDepositDate?.split('T')[0] || '';
    return print?.legalDepositDate?.split('T')[0] || '';
  };

  const getAuthorsArray = () => {
    if ('authors' in editedFields) return editedFields.authors || [];
    else if (selectedPrint) return selectedPrint?.authors ? [...selectedPrint.authors] : [];
    return print?.authors ? [...print.authors] : [];
  };

  const removePublisher = () => {
    setEditedFields({ ...editedFields, publisher: '' });
  };
  const removeCollection = () => {
    setEditedFields({ ...editedFields, collection: '' });
  };
  const removeType = () => {
    setEditedFields({ ...editedFields, type: '' });
  };

  const addAuthor = (author) => {
    let authorList = getAuthorsArray();

    const potentialAldreadyThereIndex = authorList.indexOf(
      authorList.find((authorToFind) => {
        return authorToFind.objectId === author.objectId;
      }),
    );
    if (potentialAldreadyThereIndex !== -1) {
      authorList.splice(potentialAldreadyThereIndex, 1);
    } else {
      authorList.push(author);
    }
    setEditedFields({ ...editedFields, authors: authorList });
  };

  const openAuthorIntercom = useCallback(
    () =>
      window &&
      window.Intercom &&
      window.Intercom(
        'showNewMessage',
        `Bonjour ! J'aimerais que cet auteur soit ajouté dans Bubble: ${searchAuthorText} , merci :)`,
      ),
    [searchAuthorText],
  );

  const renderPublisherSection = () => {
    return (
      <div className="col-12">
        <div className="p-2">
          <div className="row d-flex align-items-center">
            <div className="col-9">
              <LabeledTextInput
                white
                noRightLabel
                title="Editeur"
                placeholder="Rechercher"
                value={searchPublishersText}
                onChange={(e) => {
                  setSearchPublishersText(e.target.value);
                  dispatch(searchPublishers(e.target.value, { hitsPerPage: 10 }));
                }}
                onFocus={() => setPublisherFocused(true)}
                onBlur={() =>
                  setTimeout(() => {
                    setPublisherFocused(false);
                  }, 200)
                }
              />

              <div className={`dropdown ${publisherFocused && searchPublishersText ? 'show' : ''}`}>
                <div
                  className={`mt-n3 dropdown-menu ${
                    publisherFocused && searchPublishersText ? 'show' : ''
                  }`}
                  aria-labelledby="dropdownMenuButton"
                >
                  {searchPublishersText &&
                    hitsPublishers.data.slice(0, 10).map((publisher) => (
                      <WithClickHandler
                        key={'search_' + publisher.objectId}
                        onMouseDown={() => setEditedFields({ ...editedFields, publisher })}
                      >
                        <div className="ms-3 me-5">{publisher.name}</div>
                      </WithClickHandler>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <ElementCell
                text={displayPublisher()}
                iconName="times"
                onClick={() => removePublisher()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCollectionSection = () => {
    return (
      <div className="col-12">
        <div className="p-2">
          <div className="row d-flex align-items-center">
            <div className="col-9">
              <LabeledTextInput
                white
                noRightLabel
                placeholder="Rechercher"
                title="Collection"
                value={searchCollectionsText}
                onChange={(e) => {
                  setSearchCollectionsText(e.target.value);
                  dispatch(searchCollections(e.target.value, { hitsPerPage: 5 }));
                }}
                onFocus={() => setCollectionFocused(true)}
                onBlur={() =>
                  setTimeout(() => {
                    setCollectionFocused(false);
                  }, 200)
                }
              />

              <div
                className={`dropdown ${collectionFocused && searchCollectionsText ? 'show' : ''}`}
              >
                <div
                  className={`mt-n3 dropdown-menu ${
                    collectionFocused && searchCollectionsText ? 'show' : ''
                  }`}
                  aria-labelledby="dropdownMenuButton"
                >
                  {searchCollectionsText &&
                    hitsCollections.data.slice(0, 5).map((collection) => (
                      <WithClickHandler
                        key={'search_' + collection.objectId}
                        onMouseDown={() => setEditedFields({ ...editedFields, collection })}
                      >
                        <div className="ms-3 me-5">{collection.nameFrench}</div>
                      </WithClickHandler>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <ElementCell
                text={displayCollection()}
                iconName="times"
                onClick={() => removeCollection()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTypeSection = () => {
    return (
      <div className="col-12">
        <div className="p-2">
          <div className="row d-flex align-items-center">
            <div className="col-9">
              <LabeledTextInput
                white
                noRightLabel
                placeholder="Rechercher"
                title="Type"
                value={searchTypesText}
                onChange={(e) => {
                  setSearchTypesText(e.target.value);
                  dispatch(searchTypes(e.target.value, { hitsPerPage: 5 }));
                }}
                onFocus={() => setTypeFocused(true)}
                onBlur={() =>
                  setTimeout(() => {
                    setTypeFocused(false);
                  }, 200)
                }
              />

              <div className={`dropdown ${typeFocused && searchTypesText ? 'show' : ''}`}>
                <div
                  className={`mt-n3 dropdown-menu ${typeFocused && searchTypesText ? 'show' : ''}`}
                  aria-labelledby="dropdownMenuButton"
                >
                  {searchTypesText &&
                    hitsTypes.data.slice(0, 5).map((type) => (
                      <WithClickHandler
                        key={'search_' + type.objectId}
                        onMouseDown={() => setEditedFields({ ...editedFields, type })}
                      >
                        <div className="ms-3 me-5">{type.nameFrench}</div>
                      </WithClickHandler>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <ElementCell text={displayType()} iconName="times" onClick={() => removeType()} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onSearch = useCallback(
    (e) => {
      setSearchAuthorText(e.target.value);
      dispatch(searchAuthors(e.target.value));
    },
    [setSearchAuthorText, dispatch],
  );

  const renderAuthorSection = () => {
    return (
      <div className="col-12 p-4">
        <div className="row align-items-lg-center">
          <div className="col-lg-6">
            <LabeledTextInput
              white
              noRightLabel
              title="Auteurs"
              value={searchAuthorText}
              onChange={onSearch}
              onFocus={() => setAuthorFocused(true)}
              onBlur={() =>
                setTimeout(() => {
                  setAuthorFocused(false);
                }, 200)
              }
            />
            <div className={`dropdown ${authorFocused && searchAuthorText ? 'show' : ''}`}>
              <div
                className={`mt-n3 dropdown-menu ${authorFocused && searchAuthorText ? 'show' : ''}`}
                aria-labelledby="dropdownMenuButton"
              >
                {authorFocused &&
                  searchAuthorText &&
                  hitsAuthors.data.slice(0, 5).map((author) => (
                    <WithClickHandler
                      consumeEvent={true}
                      key={'search_' + author.objectId}
                      onMouseDown={(e) => addAuthor(author)}
                    >
                      <div className="ms-3 me-5 py-1">
                        <SearchAuthorCard author={author} noLink />
                      </div>
                    </WithClickHandler>
                  ))}
                <button onClick={openAuthorIntercom} className="btn btn-link m-0 p-0 ms-3">
                  Vous n'avez pas trouvé ?
                </button>
              </div>
            </div>
          </div>
          {getAuthorsArray().map((author) => (
            <div className="col-lg-3 col-md-6" key={author.objectId}>
              <ElementCell
                text={author.displayName}
                iconName="times"
                onClick={() => addAuthor(author)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handlePrintSelectionClick = useCallback(
    (print) => {
      setSelectedPrint(print);
      clearState();
    },
    [setSelectedPrint, clearState],
  );

  const renderPrintSelectionSection = () => {
    return (
      <>
        <div className="px-4 py-2">
          <div className="text-secondary text-center">
            Selectionnez l'édition dont vous souhaitez modifier les informations
          </div>
          {prints &&
            prints.length &&
            prints.map((printToSelect) => (
              <WithClickHandler
                onClick={() => handlePrintSelectionClick(printToSelect)}
                key={printToSelect.objectId}
              >
                <PrintSelectionCell album={album} print={printToSelect} />
              </WithClickHandler>
            ))}
        </div>
      </>
    );
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="lg"
      centered
      contentClassName="bb-background-light-grey"
    >
      {(album && serie && (
        <>
          <div className="modal-header header-no-separator">
            {allowGoBack && (
              <WithClickHandler onClick={handleGoBack}>
                <Icon name="chevron-left" />
              </WithClickHandler>
            )}
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={dismiss}
            ></button>
          </div>
          <div className="modal-body mx-sm-3">
            <div className="row">
              <div className="col py-1">
                <div className="bb-l-text-size d-flex flex-column justify-content-center align-items-center">
                  <span>Signalement</span>
                  <span className="fw-bold">
                    {serie.title ? serie.title + ' - ' : ''}
                    {album.title} Tome {album.tome}
                  </span>
                </div>
                <div className="text-secondary text-center">Information sur l'édition</div>
              </div>
            </div>
          </div>
          {isSelectingPrint && <>{renderPrintSelectionSection()}</>}

          {!isSelectingPrint && (
            <>
              <WithClickHandler
                onClick={() => setIsSelectingPrint(true)}
                className="p-4 pt-0 mt-n4"
              >
                <PrintSelectionCell album={album} print={selectedPrint || print} />
              </WithClickHandler>
              {renderPublisherSection()}
              {renderCollectionSection()}
              {renderTypeSection()}
              <div className="row p-4 mt-n2">
                <div className="col-6 text-secondary">
                  <label htmlFor="publicationDate">Date de publication:</label>
                  <input
                    name="publicationDate"
                    className="form-control labeled-text-input-white"
                    type="date"
                    value={displayPublicationDate()}
                    onChange={(e) =>
                      setEditedFields({
                        ...editedFields,
                        publicationDate: new Date(e.target.value).toISOString(),
                      })
                    }
                  />
                </div>
                <div className="col-6 text-secondary">
                  <label htmlFor="legalDepositDate">Date de dépot légal:</label>
                  <input
                    name="legalDepositDate"
                    className="form-control labeled-text-input-white"
                    type="date"
                    value={displayLegalDepositDate()}
                    onChange={(e) =>
                      setEditedFields({
                        ...editedFields,
                        legalDepositDate: new Date(e.target.value).toISOString(),
                      })
                    }
                  />
                </div>
              </div>
              {renderAuthorSection()}
              <div className="h-separator mt-2" />
              <div className="d-flex flex-row justify-content-between p-4 align-items-center">
                {props.allowGoBack && (
                  <WithClickHandler onClick={handleGoBack}>
                    <div className="text-start bb-medium-text-size">
                      <u>Retour</u>
                    </div>
                  </WithClickHandler>
                )}
                <div className="text-end">
                  <button onClick={sendRevision} className="btn btn-success btn-lg text-white">
                    Soumettre
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )) ||
        null}
    </Modal>
  );
};

export default PrintRevisionModal;

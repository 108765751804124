import menuAuthors from '../services/menu-authors.json';

export const LOAD_AUTHOR = 'LOAD_AUTHOR';
export const LOAD_AUTHOR_SUCCESS = 'LOAD_AUTHOR_SUCCESS';
export const LOAD_AUTHOR_FAIL = 'LOAD_AUTHOR_FAIL';

export const LOAD_RELATED_AUTHORS = 'LOAD_RELATED_AUTHORS';
export const LOAD_RELATED_AUTHORS_SUCCESS = 'LOAD_RELATED_AUTHORS_SUCCESS';
export const LOAD_RELATED_AUTHORS_FAIL = 'LOAD_RELATED_AUTHORS_FAIL';

export const CREATE_AUTHOR_REVISION = 'CREATE_AUTHOR_REVISION';
export const CREATE_AUTHOR_REVISION_SUCCESS = 'CREATE_AUTHOR_REVISION_SUCCESS';
export const CREATE_AUTHOR_REVISION_FAIL = 'CREATE_AUTHOR_REVISION_FAIL';

export const LOAD_AUTHORS_ALPHANUMERIC_INDEX = 'LOAD_AUTHORS_ALPHANUMERIC_INDEX';
export const LOAD_AUTHORS_ALPHANUMERIC_INDEX_SUCCESS = 'LOAD_AUTHORS_ALPHANUMERIC_INDEX_SUCCESS';
export const LOAD_AUTHORS_ALPHANUMERIC_INDEX_FAIL = 'LOAD_AUTHORS_ALPHANUMERIC_INDEX_FAIL';

export const LOAD_AUTHOR_TAGS = 'LOAD_AUTHOR_TAGS';
export const LOAD_AUTHOR_TAGS_SUCCESS = 'LOAD_AUTHOR_TAGS_SUCCESS';
export const LOAD_AUTHOR_TAGS_FAIL = 'LOAD_AUTHOR_TAGS_FAIL';

export const STORE_AUTHORS = 'STORE_AUTHORS';

const initialState = {
  authors: menuAuthors,
  menuAuthorsObjectIds: {
    bd: [
      '79uE6tav93',
      'E3us165g2U',
      'AGW8AOfuaP',
      'i0NHUrcqGi',
      'Tr23NgmAMt',
      'cSkvfqdiUV',
      'uB3AMrxu9L',
      'Vjj9Io8APS',
      'PJhjZ3Mb4C',
      'Mxh28n62Q3',
      '9A3ECKZQjX',
      'tJYFZyfhwU',
      'tfeI0cwG2Y',
      'WBxFwdDKhf',
      'YrmQvTb5Y2',
    ],
    comics: [
      'xaNPdlnqHC',
      'CoZy96h4H6',
      'RK81jtkR9X',
      '3wSaSWOZXh',
      '79P6Keo2PK',
      '1Uvt74IE4M',
      'vLK2RCbvm8',
      'dUNJWl2Msu',
      'bbcZVAygPT',
      'ftVxxGshYq',
      'OzGbmrtDgZ',
      'U3LPrEc0JS',
      'HbjiLG9IRM',
      'UzLwanTyN1',
      'j6rcZ69NYq',
    ],
    mangas: [
      'RQ5tK5OEQA',
      'rmCqAg0XuF',
      'Ggq8twUs57',
      'CrQIiyaPyI',
      'weBPi1v2kT',
      'I5yUIAmh8n',
      'OHr9xughQf',
      'HE8pBBtKpB',
      'xMDFveqoMW',
      'BsSnl5T6Qp',
      '3Zq3DrXUYl',
      'Y2IWO0Wbk3',
      'wG9iX2nUkT',
      'nVR4oWJjYO',
      'A6wbVIVtwg',
    ],
    jeunesse: ['0l7NNoc3km', 'dNFI4g5XCY', 'BzyGofOFbM', 'rl9EjsdOhb'],
  },
  relatedAuthorsMap: {},
  relatedAuthorsRelatedSeriesObjectIdsMap: {},
  authorSeriesObjectIdsMap: {},
  authorSeries: {}, // trash series not conforming to "series" reducer
  authorTagsMap: {},
  loading: {},
  authorsAlphanumericIndexMap: {},
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case STORE_AUTHORS: {
      let authorSeries = state.authorSeries;
      const authorSeriesObjectIdsMap = { ...state.authorSeriesObjectIdsMap };
      let authors = state.authors;
      if (action.payload.authors.length) {
        authors = action.payload.authors.reduce(
          (map, author) => {
            authorSeriesObjectIdsMap[author.objectId] = new Set(
              authorSeriesObjectIdsMap[author.objectId],
            );
            authorSeries = (author.series || []).reduce(
              (serieMap, serie) => {
                serieMap[serie.objectId] = serie;
                authorSeriesObjectIdsMap[author.objectId].add(serie.objectId);
                return serieMap;
              },
              { ...state.authorSeries },
            );
            authorSeriesObjectIdsMap[author.objectId] = Array.from(
              authorSeriesObjectIdsMap[author.objectId],
            );

            author.series = (author.series || []).map((serie) => serie.objectId);
            map[author.objectId] = author;
            return map;
          },
          { ...state.authors },
        );
      }
      return {
        ...state,
        authorSeries,
        authorSeriesObjectIdsMap,
        authors,
      };
    }

    case LOAD_AUTHOR: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_AUTHOR]: true },
      };
    }
    case LOAD_AUTHOR_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_AUTHOR]: false },
        errors: [],
      };
    }
    case LOAD_AUTHOR_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_AUTHOR]: false },
      };
    }

    case LOAD_RELATED_AUTHORS: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_RELATED_AUTHORS]: true },
      };
    }

    case LOAD_RELATED_AUTHORS_SUCCESS: {
      const authorObjectId = action.payload.authorObjectId;
      const authors = action.payload.authors;
      const relatedAuthorsRelatedSeriesObjectIdsMap = state.relatedAuthorsRelatedSeriesObjectIdsMap;
      relatedAuthorsRelatedSeriesObjectIdsMap[authorObjectId] =
        relatedAuthorsRelatedSeriesObjectIdsMap[authorObjectId] || {};
      const relatedAuthorsMap = {
        ...state.relatedAuthorsMap,
        [authorObjectId]: authors.map((author) => {
          relatedAuthorsRelatedSeriesObjectIdsMap[authorObjectId][author.objectId] = author.series;
          return author.objectId;
        }),
      };

      return {
        ...state,
        loading: { ...state.loading, [LOAD_RELATED_AUTHORS]: false },
        relatedAuthorsMap,
        relatedAuthorsRelatedSeriesObjectIdsMap,
        errors: [],
      };
    }
    case LOAD_RELATED_AUTHORS_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_RELATED_AUTHORS]: false },
      };
    }

    case CREATE_AUTHOR_REVISION: {
      return {
        ...state,
        loading: { ...state.loading, [CREATE_AUTHOR_REVISION]: true },
      };
    }
    case CREATE_AUTHOR_REVISION_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, [CREATE_AUTHOR_REVISION]: false },
      };
    }
    case CREATE_AUTHOR_REVISION_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [CREATE_AUTHOR_REVISION]: false },
      };
    }
    case LOAD_AUTHOR_TAGS: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_AUTHOR_TAGS]: true },
      };
    }
    case LOAD_AUTHOR_TAGS_SUCCESS: {
      return {
        ...state,
        authorTagsMap: {
          ...state.authorTagsMap,
          [action.payload.authorObjectId]: action.payload.tags,
        },
        loading: { ...state.loading, [LOAD_AUTHOR_TAGS]: false },
      };
    }
    case LOAD_AUTHOR_TAGS_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_AUTHOR_TAGS]: false },
      };
    }
    case LOAD_AUTHORS_ALPHANUMERIC_INDEX: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_AUTHORS_ALPHANUMERIC_INDEX]: true },
      };
    }
    case LOAD_AUTHORS_ALPHANUMERIC_INDEX_SUCCESS: {
      return {
        ...state,
        authorsAlphanumericIndexMap: {
          ...state.authorsAlphanumericIndexMap,
          [action.payload.index]: action.payload.authors,
        },
        loading: { ...state.loading, [LOAD_AUTHORS_ALPHANUMERIC_INDEX]: false },
      };
    }
    case LOAD_AUTHORS_ALPHANUMERIC_INDEX_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_AUTHORS_ALPHANUMERIC_INDEX]: false },
      };
    }

    default: {
      return state;
    }
  }
}

// loadAuthor ====================================================

export function loadAuthor(authorObjectId) {
  return {
    type: LOAD_AUTHOR,
    payload: { authorObjectId },
  };
}
export function loadAuthorSuccess() {
  return {
    type: LOAD_AUTHOR_SUCCESS,
  };
}
export function loadAuthorFail(error) {
  return {
    type: LOAD_AUTHOR_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadRelatedAuthors ====================================================

export function loadRelatedAuthors(authorObjectId) {
  return {
    type: LOAD_RELATED_AUTHORS,
    payload: { authorObjectId },
  };
}
export function loadRelatedAuthorsSuccess(authors, authorObjectId) {
  return {
    type: LOAD_RELATED_AUTHORS_SUCCESS,
    payload: {
      authors,
      authorObjectId,
    },
  };
}
export function loadRelatedAuthorsFail(error) {
  return {
    type: LOAD_RELATED_AUTHORS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// createAuthorRevision ====================================================

export function createAuthorRevision(authorObjectId, revisions) {
  return {
    type: CREATE_AUTHOR_REVISION,
    payload: {
      authorObjectId,
      revisions,
    },
  };
}
export function createAuthorRevisionSuccess() {
  return {
    type: CREATE_AUTHOR_REVISION_SUCCESS,
    payload: {},
  };
}
export function createAuthorRevisionFail(error) {
  return {
    type: CREATE_AUTHOR_REVISION_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// storeAuthors ====================================================

export function storeAuthors(authors, source = null) {
  return {
    type: STORE_AUTHORS,
    payload: { authors, source },
  };
}

// loadAuthorsAtAlphanumericIndex ====================================================

export function loadAuthorsAtAlphanumericIndex(index) {
  return {
    type: LOAD_AUTHORS_ALPHANUMERIC_INDEX,
    payload: {
      index,
    },
  };
}
export function loadAuthorsAtAlphanumericIndexSuccess(index, authors) {
  return {
    type: LOAD_AUTHORS_ALPHANUMERIC_INDEX_SUCCESS,
    payload: {
      index,
      authors,
    },
  };
}
export function loadAuthorsAtAlphanumericIndexFail(error) {
  return {
    type: LOAD_AUTHORS_ALPHANUMERIC_INDEX_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadAuthorTags ====================================================

export function loadAuthorTags(authorObjectId) {
  return {
    type: LOAD_AUTHOR_TAGS,
    payload: {
      authorObjectId,
    },
  };
}
export function loadAuthorTagsSuccess(authorObjectId, tags) {
  return {
    type: LOAD_AUTHOR_TAGS_SUCCESS,
    payload: {
      authorObjectId,
      tags,
    },
  };
}
export function loadAuthorTagsFail(error) {
  return {
    type: LOAD_AUTHOR_TAGS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

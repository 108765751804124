export const GUEST_CHECKOUT_REDIRECT = 'website/GUEST_CHECKOUT_REDIRECT';
export const GUEST_CREATE_AVAILABILITY_ALERT = 'website/GUEST_CREATE_AVAILABILITY_ALERT';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

// guestCheckoutRedirect ====================================================

export function guestCheckoutRedirect(email, cookie, localStorage) {
  return {
    type: GUEST_CHECKOUT_REDIRECT,
    payload: { email, cookie, localStorage },
  };
}

// guestCreateAvailabilityAlert ====================================================

export function guestCreateAvailabilityAlert(printObjectId, email, cookie, localStorage) {
  return {
    type: GUEST_CREATE_AVAILABILITY_ALERT,
    payload: { printObjectId, email, cookie, localStorage },
  };
}

import React from 'react';

import { Link } from 'react-router-dom';

import { formatDateWithFormat } from 'bubble-utils/src/date-utils';

import ImageCover from '../ImageCover/ImageCover';

import './ArticleCard.css';

export default function ArticleCard(props) {
  const article = props.article || {};
  article.section = article.section || {};
  article.title = article.title || '';

  const category = article.category;

  return (
    <div className={`d-flex ${props.className || 'col-md-6 col-lg-3 pt-3'}`}>
      {article.path && (
        <Link
          className="d-flex flex-fill rounded-medium cover-zoom nart-shadow transition-short no-decoration"
          to={article.path}
        >
          <div
            className={`rounded-medium d-flex flex-column h-100 w-100 overflow-hidden ${
              props.grey ? 'bb-background-light-grey' : 'bg-white'
            }`}
          >
            <ImageCover
              hoverable
              alt={`Illustration de l'article ${article.title}`}
              height="200px"
              imageUrl={article.imageSmallUrl || article.imageUrl}
            />
            <div className="p-3 d-flex flex-column flex-fill bb-text-gray-dark">
              <div>
                <span className={`bb-s-text-size fw-bold text-9eart-${category} text-uppercase`}>
                  {article.section.title}
                </span>

                <span className="ps-2 bb-s-text-size text-secondary">
                  {formatDateWithFormat(article.publicationDate, {
                    month: 'short',
                    day: 'numeric',
                  })}
                </span>
              </div>
              <div className="overflow-hidden-2-lines fw-bold ">{article.title}</div>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
}

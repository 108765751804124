import React from 'react';

import { connect } from 'react-redux';

import { loadArticle, loadArticles } from 'bubble-reducers/src/reducers/articles';

import ArticleHorizontalBigCard from '@/components/ArticleHorizontalBigCard/ArticleHorizontalBigCard';
import ArticleHorizontalCardWhite from '@/components/ArticleHorizontalCardWhite/ArticleHorizontalCardWhite';

class NArtHomeHeader extends React.Component {
  constructor(props) {
    super(props);

    const { loadArticles } = this.props;
    loadArticles({
      highlighted: 1,
    });
  }

  render() {
    const highlightedArticleLeft = this.props.articles[this.props.highlightedArticleObjectIds[0]];
    return (
      <>
        <div className="d-none d-lg-block">
          <div className="px-paper bb-background-light-grey">
            <div className="row py-paper">
              <div className="col-8">
                <ArticleHorizontalBigCard article={highlightedArticleLeft} />
              </div>
              <div className="col-4">
                <div className="d-flex h-100 flex-column justify-content-between">
                  {this.props.highlightedArticleObjectIds.slice(1).map((articleObjectId) => (
                    <ArticleHorizontalCardWhite
                      key={`article-${articleObjectId}`}
                      article={this.props.articles[articleObjectId]}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-block d-lg-none">
          <div className="bb-background-light-grey">
            <ArticleHorizontalBigCard article={highlightedArticleLeft} />
            <div className="d-flex h-100 px-4 py-4 flex-column justify-content-between">
              {this.props.highlightedArticleObjectIds.slice(1).map((articleObjectId) => (
                <div key={`article-${articleObjectId}`} className="py-2">
                  <ArticleHorizontalCardWhite article={this.props.articles[articleObjectId]} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  highlightedArticleObjectIds: state.articles.highlightedArticleObjectIds,
  articles: state.articles.articlesMapByObjectId,
});

export default connect(mapStateToProps, { loadArticles, loadArticle })(NArtHomeHeader);

import React from 'react';

import { Route, Routes } from 'react-router-dom';

import AppBubble from './AppBubble';
import AppNart from './AppNart';
import Layout from './Layout';

const Root = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/9emeart/*" element={<AppNart />} />
        <Route path="/*" element={<AppBubble />} />
      </Route>
    </Routes>
  );
};

export default Root;

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { updateAlbumTags } from 'bubble-reducers/src/reducers/albums';
import { searchTags } from 'bubble-reducers/src/reducers/search';
import { loadUserTags } from 'bubble-reducers/src/reducers/tags';

import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';

import ModalTagRow from './components/ModalTagRow';

import '../Modals.css';

const AddTagModal = (props) => {
  const dispatch = useDispatch();

  const itemType = props.itemType;
  const itemObjectId = props.itemObjectId;
  const user = useSelector((state) => state.user.user);
  const hitsTags = useSelector((state) => state.search.hitsTags);
  const item = useSelector((state) => (itemType ? state[itemType][itemType][itemObjectId] : null));
  const userTags = Object.values(useSelector((state) => state.tags.userTags)).filter(
    (tag) => tag.albumObjectId === itemObjectId,
  );

  const [text, setText] = useState('');

  const userObjectId = user?.objectId;

  useEffect(() => {
    dispatch(loadUserTags(userObjectId));
  }, []);

  const dismiss = () => {
    setText('');
    props.callback();
  };

  const onAddTag = (tagName = null) => {
    if (!tagName) tagName = text;
    dispatch(updateAlbumTags(userObjectId, itemObjectId, [tagName]));
    setText('');
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="md"
      centered
      contentClassName="bb-background-light-grey"
    >
      <div className="modal-header header-no-separator">
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={dismiss}
        ></button>
      </div>
      <div className="modal-body mx-sm-3">
        <div className="pb-5">
          <div className="bb-l-text-size fw-bold text-center">Nouveaux thèmes</div>
          <div className="text-secondary text-center px-sm-5">
            Ajoutez des thèmes pertinents pour faciliter la recherche de cet album
          </div>
        </div>

        <LabeledTextInput
          onChange={(e) => {
            setText(e.target.value);
            dispatch(
              searchTags(e.target.value, {
                restrictSearchableAttributes: ['name'],
              }),
            );
          }}
          value={text || ''}
          white
          noRightLabel
          placeholder="Ajouter un thème"
          buttonCallback={text ? onAddTag : null}
          buttonLabel="Ajouter"
        />

        <div className="row">
          <div className="col">
            {!text &&
              (item.tags || []).map((tag) => (
                <div className="pb-2" key={`tag_row_${tag.name}`}>
                  <ModalTagRow
                    tag={tag}
                    onClickPlus={onAddTag}
                    userAdded={!!userTags.find((userTag) => userTag.objectId === tag.objectId)}
                  />
                </div>
              ))}
            {!!text &&
              !!hitsTags?.data?.length &&
              (hitsTags.data || []).map((tag) => {
                return (
                  <div className="pb-2" key={`tag_row2_${tag.name}`}>
                    <ModalTagRow
                      tag={tag}
                      onClickPlus={onAddTag}
                      userAdded={!!userTags.find((userTag) => userTag.objectId === tag.objectId)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button onClick={dismiss} className="btn btn-success btn-lg text-white">
          Valider
        </button>
      </div>
    </Modal>
  );
};

AddTagModal.propTypes = {
  itemObjectId: PropTypes.string,
  itemType: PropTypes.string,
};

export default AddTagModal;

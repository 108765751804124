import React, { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { LOAD_RELATED_AUTHORS, loadRelatedAuthors } from 'bubble-reducers/src/reducers/authors';
import { getAuthor } from 'bubble-reducers/src/selectors';

import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

import RelatedAuthorCard from './RelatedAuthorCard';

const defautObject = {};

export default function AuthorRelatedAuthors({ authorObjectId }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.authors.loading[LOAD_RELATED_AUTHORS]);

  const relatedAuthorsObjectIds =
    useSelector((state) => state.authors.relatedAuthorsMap[authorObjectId]) || null;
  const author = useSelector((state) => getAuthor(state, authorObjectId)) || defautObject;

  useEffect(() => {
    if (authorObjectId) {
      dispatch(loadRelatedAuthors(authorObjectId));
    }
  }, [dispatch, authorObjectId]);

  const rootPath = '/' + [author?.permalink, 'author', authorObjectId].join('/');

  const relatedAuthorsObjectIdsLimitedSet = useMemo(
    () => (relatedAuthorsObjectIds?.length ? relatedAuthorsObjectIds.slice(0, 4) : null),
    [relatedAuthorsObjectIds],
  );

  const authorComponents = useMemo(
    () =>
      relatedAuthorsObjectIdsLimitedSet?.map((relatedAuthorObjectId, index) => {
        return (
          <div
            className={`col-12 col-md-6 col-lg-4 col-xl-3 mb-3 d-flex${
              index >= 3 ? ' d-lg-none d-xl-flex' : ''
            }`}
            key={`author_${relatedAuthorObjectId}`}
          >
            <RelatedAuthorCard
              authorObjectId={authorObjectId}
              relatedAuthorObjectId={relatedAuthorObjectId}
            />
          </div>
        );
      }),
    [relatedAuthorsObjectIdsLimitedSet, authorObjectId],
  );

  return (
    <TitledListContainer
      title={`Auteurs liés à ${author.displayName || "l'auteur"}`}
      className="py-5"
      topRightLink={<Link to={rootPath + '/authors#ct1'}>Voir tous les auteurs</Link>}
      rightLinkWrapToBottomThreshold="md"
      list={
        !isLoading && relatedAuthorsObjectIdsLimitedSet?.length
          ? authorComponents
          : [
              <div className="col-12" key="no-author">
                Nous n'avons pas encore trouvé d'autres auteurs
              </div>,
            ]
      }
    />
  );
}

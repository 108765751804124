import React from 'react';

import { connect } from 'react-redux';

import { loadAlbum, loadAlbumSerie } from 'bubble-reducers/src/reducers/albums';
import { loadPrint } from 'bubble-reducers/src/reducers/prints';
import { getUserPrintForAlbum } from 'bubble-reducers/src/selectors';

import { getSeoForAlbum } from '@/services/seo-utils';

import BreadCrumbs from '@/components/Breadcrumbs/BreadCrumbs';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import CategoryRelatedArticles from '@/components/CategoryRelatedArticles/CategoryRelatedArticles';
import ItemReviews from '@/components/ItemReviews/ItemReviews';
import PromotionSectionWrapper from '@/components/PromotionSectionWrapper/PromotionSectionWrapper';
import RelatedArticles from '@/components/RelatedArticles/RelatedArticles';
import SerieSameGenre from '@/components/SerieSameGenre/SerieSameGenre';
import ShareZone from '@/components/ShareZone/ShareZone';
import withRouter from '@/components/withRouter';

import AlbumDetails from './components/AlbumDetails';
import AlbumHeader from './components/AlbumHeader/AlbumHeader';
import OtherAlbumsInSerie from './components/OtherAlbumsInSerie';

class Album extends React.Component {
  constructor(props) {
    super(props);
    const { loadAlbum, loadAlbumSerie, loadPrint, albumObjectId, printObjectId } = props;
    loadAlbum(albumObjectId);
    loadAlbumSerie(albumObjectId);
    if (printObjectId) {
      loadPrint(printObjectId);
    }
  }

  componentDidUpdate(prevProps) {
    const { loadAlbum, loadAlbumSerie, loadPrint } = this.props;

    if (prevProps.albumObjectId !== this.props.albumObjectId) {
      loadAlbum(this.props.albumObjectId);
      loadAlbumSerie(this.props.albumObjectId);
    }
    if (prevProps.printObjectId !== this.props.printObjectId) {
      loadPrint(this.props.printObjectId);
    }
  }

  render() {
    const { album, serie, print, albumObjectId, serieObjectId } = this.props;
    const albumsInSerieCount = serie.albums?.length;
    return (
      <div className="bb-background-light-grey">
        {!!album.objectId && !!serie.objectId && !!print.objectId && (
          <BubbleHelmet seo={getSeoForAlbum(album, serie, print)} />
        )}
        <div className="nart-background-grey">
          <div className="container">
            <div className="d-flex align-items-center">
              <BreadCrumbs currentObject={album} />
              <ShareZone title="Faire découvrir cet album à des amis" />
            </div>
            <AlbumHeader album={album} serie={serie} print={print} />
          </div>
        </div>
        <div className="container">
          <AlbumDetails album={album} serie={serie} print={print} />
        </div>

        <div className="container">
          <div className="px-sm-3">
            <PromotionSectionWrapper albumObjectId={albumObjectId} />
          </div>
        </div>

        {/* ARTICLES TALKING ABOUT IT */}
        <RelatedArticles grey resourceObjectId={album.objectId} />

        {/* REVIEWS */}
        <div className="nart-background-grey">
          <div className="container">
            <div className="px-sm-3">
              <ItemReviews albumObjectId={albumObjectId} serieObjectId={serieObjectId} />
            </div>
          </div>
        </div>

        {/* OTHER ALBUMS IN SERIE */}
        {!!serie && albumsInSerieCount > 1 && (
          <div className="container">
            <div className="px-sm-3">
              <OtherAlbumsInSerie album={album} serie={serie} />
            </div>
          </div>
        )}

        <div className="nart-background-grey">
          <div className="container">
            <div className="px-sm-3">
              <SerieSameGenre serie={serie} />
            </div>
          </div>
        </div>

        {/* OTHER ARTICLES TALKING ABOUT SAME CATEGORY */}
        <div className="container">
          <div className="px-sm-3">
            <CategoryRelatedArticles title={serie?.title} category={serie?.category} />
          </div>
        </div>
      </div>
    );
  }
}

const defaultObject = {};

const mapStateToProps = (state, props) => {
  const { albumObjectId, printObjectId } = props.router?.params || defaultObject;
  const userObjectId = state.user.user?.objectId;
  const album = state.albums.albums[albumObjectId] || defaultObject;
  const serieObjectId = album.serie?.objectId;
  const serie = state.series.series[album.serie?.objectId] || defaultObject;

  const print =
    state.prints.prints[printObjectId] ||
    getUserPrintForAlbum(state, userObjectId, album?.defaultPrintObjectId, album?.objectId) ||
    defaultObject;

  return {
    albumObjectId,
    printObjectId: printObjectId || print.objectId,
    serieObjectId,
    serie,
    album,
    print,
  };
};

export default withRouter(
  connect(mapStateToProps, { loadAlbum, loadAlbumSerie, loadPrint })(Album),
);

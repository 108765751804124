import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import { useAddPrintsToWishlist, useRemoveFromCart } from '@/services/hooks/useLibraryUtils';
import imageUtils from '@/services/image-utils';

import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import bubbleUtils from 'bubble-utils';

const PrintContent = (props) => {
  const addPrintsToWishlist = useAddPrintsToWishlist();
  const removeFromCart = useRemoveFromCart();

  const { serie, album, item, extra, sellingInfo, user } = props;
  const estimatedDeliveryDate = ((sellingInfo || {}).online || {}).estimatedDeliveryDate;

  const moveToWishlist = () => {
    addPrintsToWishlist(user, [
      { ...item, album: { objectId: album.objectId }, objectId: item.itemObjectId },
    ]);
    removeFromCart([{ ...item, album: { objectId: album.objectId }, objectId: item.itemObjectId }]);
  };

  return (
    <div>
      <div>
        <span>
          {!!serie.objectId && (
            <Link
              className="no-decoration link-to-orange text-black fw-bold bb-medium-small-text-size"
              to={`/${album.permalink}/album/${album.objectId}`}
            >
              {serie.title || item.title}
              {extra.tome !== null ? ' - Tome ' + extra.tome : ''}
            </Link>
          )}
        </span>
      </div>
      <Link
        className="no-decoration link-to-orange text-black bb-medium-text-size"
        to={`/${album.permalink}/album/${album.objectId}`}
      >
        {item.title || serie.title}
      </Link>
      {!('noDate' in props) && estimatedDeliveryDate && (
        <div>
          Commandez maintenant pour le recevoir le{' '}
          {bubbleUtils.date.formatDateWithFormat(estimatedDeliveryDate, { year: null })} !
        </div>
      )}
      <div className="mb-2" style={{ color: item?.sellingInfo?.online?.availability?.color }}>
        {item?.sellingInfo?.online?.availability?.message}
      </div>
      {!props.noWishlist && (
        <WithClickHandler onClick={moveToWishlist}>
          <div className="mt-1 text-muted d-flex bb-s-text-size">
            <img
              className="me-1 wishlist-heart"
              style={{ width: 13, height: 13 }}
              alt="wishlist"
              src={imageUtils.getAssetImage('icon_wishlist_off.svg')}
            />
            Déplacer vers ma liste d'envies
          </div>
        </WithClickHandler>
      )}
    </div>
  );
};

PrintContent.propTypes = {
  serie: PropTypes.shape({
    objectId: PropTypes.string,
    title: PropTypes.string,
    permalink: PropTypes.string,
  }),
  album: PropTypes.shape({
    note: PropTypes.number,
  }),
  extra: PropTypes.shape({
    tome: PropTypes.number,
  }),
  item: PropTypes.shape({
    title: PropTypes.string,
  }),
  noDate: PropTypes.bool,
  noWishlist: PropTypes.bool,
  sellingInfo: PropTypes.shape(),
};

export default PrintContent;

import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  deleteItemTagApi,
  deleteTagApi,
  loadTagApi,
  loadTagsApi,
  loadUserTagsApi,
  updateItemTagApi,
  updateTagApi,
} from '../services/api';

import {
  DELETE_ITEM_TAG,
  DELETE_TAG,
  LOAD_TAG,
  LOAD_TAGS,
  LOAD_USER_TAGS,
  UPDATE_ITEM_TAG,
  UPDATE_TAG,
} from '../reducers/tags';
import {
  deleteItemTagFail,
  deleteItemTagSuccess,
  deleteTagFail,
  deleteTagSuccess,
  loadTagFail,
  loadTagSuccess,
  loadTagsFail,
  loadTagsSuccess,
  loadUserTagsFail,
  loadUserTagsSuccess,
  updateItemTagFail,
  updateItemTagSuccess,
  updateTagFail,
  updateTagSuccess,
} from '../reducers/tags';
import { getClientToken, getClientTokenBackOffice } from '../selectors';

export function* loadUserTagsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);

    const userTags = yield call(loadUserTagsApi, clientToken, action.payload.userObjectId);
    yield put(loadUserTagsSuccess(userTags));
  } catch (error) {
    console.log('saga middleware error (loadUserTagsFlow)', error);
    yield put(loadUserTagsFail(error));
  }
}

export function* loadTagsFlow(action) {
  try {
    const clientToken = yield select(getClientTokenBackOffice);

    const tags = yield call(loadTagsApi, clientToken);
    yield put(loadTagsSuccess(tags));
  } catch (error) {
    console.log('saga middleware error (loadTagsFlow)', error);
    yield put(loadTagsFail(error));
  }
}

export function* loadTagFlow(action) {
  try {
    const clientToken = yield select(getClientTokenBackOffice);

    const tag = yield call(loadTagApi, clientToken, action.payload.tagObjectId);
    yield put(loadTagSuccess(tag));
  } catch (error) {
    console.log('saga middleware error (loadTagFlow)', error);
    yield put(loadTagFail(error));
  }
}

export function* updateTagFlow(action) {
  try {
    const clientToken = yield select(getClientToken);

    const tag = yield call(
      updateTagApi,
      clientToken,
      action.payload.tagObjectId,
      action.payload.tag,
    );
    yield put(updateTagSuccess(tag));
  } catch (error) {
    console.log('saga middleware error (updateTagFlow)', error);
    yield put(updateTagFail(error));
  }
}

// only used in BO
export function* deleteTagFlow(action) {
  try {
    const clientToken = yield select(getClientTokenBackOffice);
    yield call(deleteTagApi, clientToken, action.payload.tagObjectId);
    yield put(deleteTagSuccess(action.payload.tagObjectId));
  } catch (error) {
    console.log('saga middleware error (deleteTagFlow)', error);
    yield put(deleteTagFail(error));
  }
}

// only used in BO
export function* deleteItemTagFlow(action) {
  try {
    const clientToken = yield select(getClientTokenBackOffice);
    yield call(deleteItemTagApi, clientToken, action.payload.itemTagObjectId);
    // if (action.payload.albumObjectId) {
    //   yield call(loadAlbumFlow, { payload: { albumObjectId: action.payload.albumObjectId } });
    // }
    yield put(deleteItemTagSuccess());
  } catch (error) {
    console.log('saga middleware error (deleteItemTagFlow)', error);
    yield put(deleteItemTagFail(error));
  }
}

// only used in BO
export function* updateItemTagFlow(action) {
  try {
    const clientToken = yield select(getClientTokenBackOffice);
    yield call(
      updateItemTagApi,
      clientToken,
      action.payload.itemTagObjectId,
      action.payload.itemTag,
    );
    // if (action.payload.albumObjectId) {
    //   yield call(loadAlbumFlow, { payload: { albumObjectId: action.payload.albumObjectId } });
    // }
    yield put(updateItemTagSuccess());
  } catch (error) {
    console.log('saga middleware error (updateItemTagFlow)', error);
    yield put(updateItemTagFail(error));
  }
}

export default function* seriesWatcher() {
  yield all([
    takeLatest(LOAD_USER_TAGS, loadUserTagsFlow),
    takeLatest(LOAD_TAGS, loadTagsFlow),
    takeLatest(LOAD_TAG, loadTagFlow),
    takeEvery(UPDATE_TAG, updateTagFlow),
    takeEvery(DELETE_TAG, deleteTagFlow),
    takeEvery(UPDATE_ITEM_TAG, updateItemTagFlow),
    takeEvery(DELETE_ITEM_TAG, deleteItemTagFlow),
  ]);
}

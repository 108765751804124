import React from 'react';

import { connect } from 'react-redux';

import { loadSerie } from 'bubble-reducers/src/reducers/series';

import { getSeoForSerie } from '@/services/seo-utils';

import BreadCrumbs from '@/components/Breadcrumbs/BreadCrumbs';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import CategoryRelatedArticles from '@/components/CategoryRelatedArticles/CategoryRelatedArticles';
import ItemReviews from '@/components/ItemReviews/ItemReviews';
import PromotionSectionWrapper from '@/components/PromotionSectionWrapper/PromotionSectionWrapper';
import RelatedArticles from '@/components/RelatedArticles/RelatedArticles';
import SerieSameGenre from '@/components/SerieSameGenre/SerieSameGenre';
import ShareZone from '@/components/ShareZone/ShareZone';
import withRouter from '@/components/withRouter';

import SerieAlbumsList from './components/SerieAlbumsList/SerieAlbumsList';
import SerieBatchActions from './components/SerieBatchActions/SerieBatchActions';
import SerieHeader from './components/SerieHeader/SerieHeader';
import { MODES } from './components/SerieModeSwitcher/SerieModeSwitcher';

import './Serie.scss';

class Serie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serieMode: MODES.GRID,
    };
    const { loadSerie, serieObjectId } = props;
    loadSerie(serieObjectId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.serieObjectId !== this.props.serieObjectId)
      this.props.loadSerie(this.props.serieObjectId);
  }

  setSerieMode = (mode) => {
    this.setState({ serieMode: mode });
  };

  render() {
    const { serie, serieObjectId } = this.props;
    const serieSeo = getSeoForSerie(serie);

    return (
      <div className="bb-background-light-grey">
        {!!serie.objectId && <BubbleHelmet seo={serieSeo} />}
        <div className="nart-background-grey">
          <div className="container">
            <div className="d-flex align-items-center">
              <BreadCrumbs currentObject={serie} />
              <ShareZone title={`Faire découvrir ${serie.title} à des amis`} />
            </div>
            <SerieHeader serie={serie} />
          </div>
        </div>
        <div className="container">
          <div className="row px-sm-3">
            <div className="col-xl-12">
              <SerieBatchActions
                serie={serie}
                serieMode={this.state.serieMode}
                setSerieMode={this.setSerieMode}
              />
              {/* ALBUMS LIST */}
              <SerieAlbumsList serie={serie} serieMode={this.state.serieMode} />
            </div>
          </div>
        </div>

        <div className="container">
          <PromotionSectionWrapper serieObjectId={serieObjectId} />
        </div>

        <RelatedArticles grey resourceObjectId={serieObjectId} />

        <div className="nart-background-grey">
          <div className="container">
            <div className="px-sm-3">
              <ItemReviews reviewType="serie" serieObjectId={serieObjectId} />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="px-sm-3">
            <SerieSameGenre serie={serie} grey />
          </div>
        </div>

        <div className="nart-background-grey">
          <div className="container">
            <div className="px-sm-3">
              <CategoryRelatedArticles title={serie.title} category={serie.category} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { serieObjectId } = props.router?.params;
  return {
    serieObjectId,
    serie: state.series.series[serieObjectId] || {},
  };
};

export default withRouter(connect(mapStateToProps, { loadSerie })(Serie));

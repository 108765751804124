import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';

import {
  isAlbumInWishlist,
  isAlbumOwned,
  makeGetPreviousAlbumsFromSerie,
  makeIsNoPreviousAlbumsOwned,
} from 'bubble-reducers/src/selectors';

import { isBinaryFlagEnabled } from 'bubble-utils/src/code-utils';

import {
  useAddPrintsToLibrary,
  useRemovePrintsFromLibrary,
} from '@/services/hooks/useLibraryUtils';

import { Icon } from '@/components/Icon/Icon';
import Tooltip from '@/components/Tooltip/Tooltip';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import iconCollectionGreen from '@/assets/icon_library_green.svg';

import { GENERAL } from 'bubble-constants';

import './AddToCollectionZone.scss';

const AddToCollectionZone = (props) => {
  const addPrintsToLibrary = useAddPrintsToLibrary();
  const removePrintsFromLibrary = useRemovePrintsFromLibrary();

  const handleCollectionClick = () => {
    const { isOwned, user, album, albumInLibrary } = props;

    const albumDefaultPrint = {
      album: { objectId: album.objectId },
      objectId: album.defaultPrintObjectId,
    };
    const previousAlbumDefaultPrints = [album, ...props.previousAlbumsFromSerie].map((album) => ({
      album: { objectId: album.objectId },
      objectId: album.defaultPrintObjectId,
    }));
    const ownedAlbumPrints = albumInLibrary
      ? Object.entries(albumInLibrary?.userPrints || {})
          .map(([printObjectId, flag]) => {
            if (isBinaryFlagEnabled(flag, GENERAL.USER_PRINT.OWNED)) {
              return {
                album: { objectId: album.objectId },
                objectId: printObjectId,
              };
            } else return null;
          })
          .filter((row) => row)
      : [];
    if (!isOwned) {
      if (
        props.isNoPreviousAlbumsOwned &&
        window.confirm('Voulez-vous ajouter tous les tomes précédents ?')
      ) {
        addPrintsToLibrary(user, previousAlbumDefaultPrints);
      } else {
        addPrintsToLibrary(user, [albumDefaultPrint]);
      }
    } else {
      removePrintsFromLibrary(
        user,
        ownedAlbumPrints.length ? ownedAlbumPrints : [albumDefaultPrint],
        true,
      );
    }
  };

  return (
    <div
      className={`add-to-collection-zone rounded display-on-parent-hover${
        props.isOwned ? ' force-show' : ''
      }`}
    >
      <Tooltip tip={props.isOwned ? 'Retirer de ma collection' : 'Ajouter à ma collection'}>
        <WithClickHandler consumeEvent onClick={handleCollectionClick}>
          {!props.isOwned ? (
            <img className="p-1" alt="add" src={iconCollectionGreen} />
          ) : (
            <Icon name="check" className="text-success bb-l-text-size pt-1" />
          )}
        </WithClickHandler>
      </Tooltip>
    </div>
  );
};

AddToCollectionZone.propTypes = {
  album: PropTypes.object,
  serie: PropTypes.object,
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.album === nextProps.album &&
    prevProps.serie === nextProps.serie &&
    prevProps.isOwned === nextProps.isOwned &&
    prevProps.noPreviousAlbumsOwned === nextProps.noPreviousAlbumsOwned &&
    prevProps.previousAlbumsFromSerie.map((album) => album.objectId).join('') ===
      nextProps.previousAlbumsFromSerie.map((album) => album.objectId).join('')
  );
};

const makeMapStateToProps = () => {
  const isNoPreviousAlbumsOwned = makeIsNoPreviousAlbumsOwned();
  const previousAlbumsFromSerie = makeGetPreviousAlbumsFromSerie();
  const mapStateToProps = (state, props) => {
    const album = props.album || {};
    const serie = props.serie || {};
    const print = props.print || {};

    return {
      user: state.user.user,
      print,
      albumInLibrary: state.profiles.myAlbums[album.objectId],
      isOwned: isAlbumOwned(state, album.objectId),
      isWishlist: isAlbumInWishlist(state, album.objectId),
      previousAlbumsFromSerie: previousAlbumsFromSerie(state, {
        albumObjectId: album.objectId,
        serieObjectId: serie.objectId,
      }),
      isNoPreviousAlbumsOwned: isNoPreviousAlbumsOwned(state, {
        albumObjectId: album.objectId,
        serieObjectId: serie.objectId,
      }),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps, null)(React.memo(AddToCollectionZone, areEqual));

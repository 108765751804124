export const LOAD_HIGHLIGHTED_RESOURCES = 'LOAD_HIGHLIGHTED_RESOURCES';
export const LOAD_HIGHLIGHTED_RESOURCES_SUCCESS = 'LOAD_HIGHLIGHTED_RESOURCES_SUCCESS';
export const LOAD_HIGHLIGHTED_RESOURCES_FAIL = 'LOAD_HIGHLIGHTED_RESOURCES_FAIL';

const initialState = {
  highlightedResourcesIdMap: { articles: [], series: [], albums: [], authors: [], stores: [] },
  loading: false,
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_HIGHLIGHTED_RESOURCES: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_HIGHLIGHTED_RESOURCES_SUCCESS: {
      return {
        ...state,
        highlightedResourcesIdMap: action.payload.highlightedResourcesIdMap,
        loading: false,
      };
    }
    case LOAD_HIGHLIGHTED_RESOURCES_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}

// loadHighlightedResources ====================================================

export function loadHighlightedResources() {
  return { type: LOAD_HIGHLIGHTED_RESOURCES };
}
export function loadHighlightedResourcesSuccess(highlightedResourcesIdMap) {
  return { type: LOAD_HIGHLIGHTED_RESOURCES_SUCCESS, payload: { highlightedResourcesIdMap } };
}
export function loadHighlightedResourcesFail(error) {
  return { type: LOAD_HIGHLIGHTED_RESOURCES_FAIL, payload: new Error(error), error: true };
}

import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  addAuthorToFollowApi,
  addSerieToFollowApi,
  loadFollowedApi,
  loadLibraryApi,
  loadLibraryAuthorFollowApi,
  loadLibraryPrintsOptionsApi,
  loadLoanedAlbumsApi,
  loadRecommendationApi,
  loadSelectedAlbumPrintsApi,
  loadSignedAlbumsApi,
  loadUserAgendaApi,
  loadUserStatsApi,
  loadWishlistAlbumsApi,
  removeAuthorFromFollowApi,
  removeSerieFromFollowApi,
  setSelectedAlbumPrintApi,
  updateLibraryApi,
  updateWishlistAlbumsApi,
} from '../services/api';

import { loadAlbum } from '../reducers/albums';
import { storeAuthors } from '../reducers/authors';
import {
  ADD_AUTHOR_TO_FOLLOW,
  ADD_PRINTS_TO_LIBRARY,
  ADD_PRINTS_TO_WISHLIST,
  ADD_PRINT_LOAN,
  ADD_SERIE_TO_FOLLOW,
  LOAD_FOLLOWED,
  LOAD_LIBRARY,
  LOAD_LIBRARY_AUTHOR_FOLLOW,
  LOAD_LIBRARY_PRINTS_OPTIONS,
  LOAD_LOANED_ALBUMS,
  LOAD_RECOMMENDATION,
  LOAD_SELECTED_ALBUM_PRINTS,
  LOAD_SIGNED_ALBUMS,
  LOAD_USER_AGENDA,
  LOAD_USER_STATS,
  LOAD_WISHLIST_ALBUMS,
  REMOVE_AUTHOR_FROM_FOLLOW,
  REMOVE_PRINTS_FROM_LIBRARY,
  REMOVE_PRINTS_FROM_WISHLIST,
  REMOVE_PRINT_LOAN,
  REMOVE_SERIE_FROM_FOLLOW,
  SET_BUY_PRICE_FLAG_ON_PRINTS,
  SET_CONDITION_FLAG_ON_PRINTS,
  SET_DELUXE_FLAG_ON_PRINTS,
  SET_DIGITAL_FLAG_ON_PRINTS,
  SET_EO_FLAG_ON_PRINTS,
  SET_EXLIBRIS_FLAG_ON_PRINTS,
  SET_FOR_SALE_FLAG_ON_PRINTS,
  SET_LIMITED_FLAG_ON_PRINTS,
  SET_NUMBERING_FLAG_ON_PRINTS,
  SET_READ_FLAG_ON_PRINTS,
  SET_RESELL_VALUE_FLAG_ON_PRINTS,
  SET_SELECTED_ALBUM_PRINT,
  SET_SIGNED_FLAG_ON_PRINTS,
  UPDATE_LIBRARY,
  UPDATE_MEMO_PRINTS,
  UPDATE_WISHLIST_PRINTS,
} from '../reducers/profiles';
import {
  addAuthorToFollowFail,
  addAuthorToFollowSuccess,
  addSerieToFollowFail,
  addSerieToFollowSuccess,
  loadFollowedFail,
  loadFollowedSuccess,
  loadLibraryAuthorFollowFail,
  loadLibraryAuthorFollowSuccess,
  loadLibraryFail,
  loadLibraryPrintsOptions,
  loadLibraryPrintsOptionsFail,
  loadLibraryPrintsOptionsSuccess,
  loadLibrarySuccess,
  loadLoanedAlbumsFail,
  loadLoanedAlbumsSuccess,
  loadRecommendationFail,
  loadRecommendationSuccess,
  loadSelectedAlbumPrintsFail,
  loadSelectedAlbumPrintsSuccess,
  loadSignedAlbumsFail,
  loadSignedAlbumsSuccess,
  loadUserAgendaFail,
  loadUserAgendaSuccess,
  loadUserStatsFail,
  loadUserStatsSuccess,
  loadWishlistAlbumsFail,
  loadWishlistAlbumsSuccess,
  removeAuthorFromFollowFail,
  removeAuthorFromFollowSuccess,
  removeSerieFromFollowFail,
  removeSerieFromFollowSuccess,
  setSelectedAlbumPrintFail,
  setSelectedAlbumPrintSuccess,
  updateLibraryFail,
  updateLibrarySuccess,
  updateWishlistPrintsFail,
  updateWishlistPrintsSuccess,
} from '../reducers/profiles';
import { loadFollowed, loadLibrary, loadUserAgenda, loadUserStats } from '../reducers/profiles';
import { storeAlbumsFlow } from '../sagas/albums';
import { getClientId, getClientToken } from '../selectors';
import { loadAuthorFlow } from './authors';
import { storePrintsFlow } from './prints';

let loadLibraryForkId = null;

function* loadLibraryDelayer(libraryId) {
  yield delay(3000);
  yield all([
    put(loadUserAgenda(libraryId)),
    put(loadLibrary(libraryId)),
    put(loadLibraryPrintsOptions(libraryId)),
    put(loadUserStats(libraryId)),
  ]);
}

function* loadLibraryHandler(libraryId) {
  if (loadLibraryForkId) {
    yield cancel(loadLibraryForkId);
  }
  loadLibraryForkId = yield fork(loadLibraryDelayer, libraryId);
}

function* loadLibraryFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadLibraryApi, clientToken, action.payload.libraryObjectId);

    yield put(loadLibrarySuccess(action.payload.libraryObjectId, response, action.payload.isGuest));
  } catch (error) {
    console.log('saga middleware error (loadLibraryFlow)', error);
    yield put(loadLibraryFail(error));
  }
}

export function* loadLibraryPrintsOptionsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      loadLibraryPrintsOptionsApi,
      clientToken,
      action.payload.libraryObjectId,
    );
    yield put(
      loadLibraryPrintsOptionsSuccess(
        action.payload.libraryObjectId,
        response,
        action.payload.isGuest,
      ),
    );
  } catch (error) {
    console.log('saga middleware error (loadLibraryPrintsOptionsFlow)', error);
    yield put(loadLibraryPrintsOptionsFail(error));
  }
}

function* loadFollowedFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadFollowedApi, clientToken, action.payload.libraryObjectId);

    yield put(
      loadFollowedSuccess(action.payload.libraryObjectId, response, action.payload.isGuest),
    );
  } catch (error) {
    console.log('saga middleware error (loadFollowedFlow)', error);
    yield put(loadFollowedFail(error));
  }
}

function* addSerieToFollowFlow(action) {
  try {
    const { libraryObjectId, serieObjectId } = action.payload;
    const clientToken = yield select(getClientToken);
    const response = yield call(addSerieToFollowApi, clientToken, libraryObjectId, serieObjectId);

    yield put(addSerieToFollowSuccess(response));
    yield all([put(loadFollowed(libraryObjectId)), put(loadUserAgenda(libraryObjectId))]);
  } catch (error) {
    console.log('saga middleware error (addSerieToFollowFlow)', error);
    yield put(addSerieToFollowFail(error));
  }
}

function* removeSerieFromFollowFlow(action) {
  try {
    const { libraryObjectId, serieObjectId } = action.payload;
    const clientToken = yield select(getClientToken);
    const response = yield call(
      removeSerieFromFollowApi,
      clientToken,
      libraryObjectId,
      serieObjectId,
    );
    yield put(removeSerieFromFollowSuccess(response));
    yield all([put(loadFollowed(libraryObjectId)), put(loadUserAgenda(libraryObjectId))]);
  } catch (error) {
    console.log('saga middleware error (removeSerieFromFollowFlow)', error);
    yield put(removeSerieFromFollowFail(error));
  }
}

function* loadUserAgendaFlow(action) {
  try {
    const { libraryObjectId, months, isGuest } = action.payload;
    const clientToken = yield select(getClientToken);
    const agenda = yield call(loadUserAgendaApi, clientToken, libraryObjectId, months);
    // hack to have the correct print id publication date within the album in the agenda.
    const myAgenda = agenda.map((album) => ({
      printObjectId: album.prints[0].objectId,
      albumObjectId: album.objectId,
      publicationDate: album.prints[0].publicationDate,
      userAgendaExtras: album.userAgendaExtras,
    }));
    yield put(loadUserAgendaSuccess(action.payload.libraryObjectId, myAgenda, isGuest, months));
    yield call(storeAlbumsFlow, { payload: { albums: agenda, source: 'loadUserAgendaFlow' } });
  } catch (error) {
    console.log('saga middleware error (loadUserAgendaFlow)', error);
    yield put(loadUserAgendaFail(error));
  }
}

function* loadUserStatsFlow(action) {
  try {
    const { libraryObjectId } = action.payload;
    const clientToken = yield select(getClientToken);
    const response = yield call(loadUserStatsApi, clientToken, libraryObjectId);
    yield put(loadUserStatsSuccess(libraryObjectId, response, action.payload.isGuest));
  } catch (error) {
    console.log('saga middleware error (loadUserStatsFlow)', error);
    yield put(loadUserStatsFail(error));
  }
}

function* loadSignedAlbumsFlow(action) {
  try {
    const { libraryObjectId, isGuest } = action.payload;
    const clientToken = yield select(getClientToken);
    const albums = yield call(loadSignedAlbumsApi, clientToken, libraryObjectId);
    yield put(loadSignedAlbumsSuccess(libraryObjectId, albums, isGuest));
  } catch (error) {
    console.log('saga middleware error (loadSignedAlbumsFlow)', error);
    yield put(loadSignedAlbumsFail(error));
  }
}

function* loadLoanedAlbumsFlow(action) {
  try {
    const { libraryObjectId, isGuest } = action.payload;
    const clientToken = yield select(getClientToken);
    const albums = yield call(loadLoanedAlbumsApi, clientToken, libraryObjectId);
    yield put(loadLoanedAlbumsSuccess(libraryObjectId, albums, isGuest));
  } catch (error) {
    console.log('saga middleware error (loadLoanedAlbumsFlow)', error);
    yield put(loadLoanedAlbumsFail(error));
  }
}

function* loadWishlistAlbumsFlow(action) {
  try {
    const { libraryObjectId, isGuest } = action.payload;
    const clientToken = yield select(getClientToken);
    const albums = yield call(loadWishlistAlbumsApi, clientToken, libraryObjectId);
    yield call(storeAlbumsFlow, {
      payload: { albums: albums, source: 'loadWishlistAlbumsFlow' },
    });
    yield put(loadWishlistAlbumsSuccess(libraryObjectId, albums, isGuest));
  } catch (error) {
    console.log('saga middleware error (loadWishlistAlbumsFlow)', error);
    yield put(loadWishlistAlbumsFail(error));
  }
}

function* updateWishlistAlbumsFlow(action) {
  try {
    const { libraryObjectId, printIds, wishlistObject } = action.payload;
    const clientToken = yield select(getClientToken);
    yield call(updateWishlistAlbumsApi, clientToken, libraryObjectId, printIds, wishlistObject);
    yield call(loadWishlistAlbumsFlow, { payload: { libraryObjectId, isGuest: true } });
    yield put(updateWishlistPrintsSuccess(libraryObjectId));
  } catch (error) {
    console.log('saga middleware error (updateWishlistAlbumsFlow)', error);
    yield put(updateWishlistPrintsFail(error));
  }
}

function* loadRecommendationFlow(action) {
  try {
    const { libraryObjectId } = action.payload;
    const clientToken = yield select(getClientToken);
    const albums = yield call(loadRecommendationApi, clientToken, libraryObjectId);
    yield call(storeAlbumsFlow, {
      payload: { albums: albums, source: 'loadRecommendationFlow' },
    });
    yield put(loadRecommendationSuccess(albums));
  } catch (error) {
    console.log('saga middleware error (loadRecommendationFlow)', error);
    yield put(loadRecommendationFail(error));
  }
}

function* setSelectedAlbumPrintFlow(action) {
  try {
    const { libraryObjectId, albumObjectId, printObjectId } = action.payload;
    const clientToken = yield select(getClientToken);
    yield call(
      setSelectedAlbumPrintApi,
      clientToken,
      libraryObjectId,
      albumObjectId,
      printObjectId,
    );
    yield put(setSelectedAlbumPrintSuccess());
    yield call(loadLibraryHandler, libraryObjectId);
  } catch (error) {
    console.log('saga middleware error (setSelectedAlbumPrintFlow)', error);
    yield put(setSelectedAlbumPrintFail(error));
  }
}

function* loadSelectedAlbumPrintsFlow(action) {
  try {
    const { libraryObjectId } = action.payload;
    const clientToken = yield select(getClientToken);
    const selectedPrints = yield call(loadSelectedAlbumPrintsApi, clientToken, libraryObjectId);
    yield call(storePrintsFlow, {
      payload: { prints: selectedPrints, source: 'loadSelectedAlbumPrintsFlow' },
    });
    yield put(loadSelectedAlbumPrintsSuccess(selectedPrints));
  } catch (error) {
    console.log('saga middleware error (loadSelectedAlbumPrintsFlow)', error);
    yield put(loadSelectedAlbumPrintsFail(error));
  }
}

export function* updateLibraryFlow(action) {
  try {
    const { prints } = action.payload;
    const userObjectId = action.payload.userObjectId
      ? action.payload.userObjectId
      : yield select(getClientId);
    const clientToken = yield select(getClientToken);
    const response = yield call(updateLibraryApi, clientToken, userObjectId, prints);
    yield put(updateLibrarySuccess(response.payload));
    if (prints.some((print) => print.isWishlist !== undefined)) {
      yield call(loadWishlistAlbumsFlow, { payload: { libraryObjectId: userObjectId } });
      const albumObjectIdsMap = {};
      prints.forEach((print) => {
        if (print?.albumObjectId) albumObjectIdsMap[print.albumObjectId] = true;
      });
      yield all(
        Object.keys(albumObjectIdsMap).map((albumObjectId) => put(loadAlbum(albumObjectId))),
      );
    }

    yield call(loadLibraryHandler, userObjectId);
  } catch (error) {
    console.log('saga middleware error (updateLibraryFlow)', error);
    yield put(updateLibraryFail(error));
  }
}

function* loadLibraryAuthorFollowFlow(action) {
  try {
    const { userObjectId } = action.payload;
    const clientToken = yield select(getClientToken);
    const authors = yield call(loadLibraryAuthorFollowApi, clientToken, userObjectId);
    yield put(storeAuthors(authors, 'loadLibraryAuthorFollow'));
    yield put(loadLibraryAuthorFollowSuccess(authors));
  } catch (error) {
    console.log('saga middleware error (loadLibraryAuthorFollowFlow)', error);
    yield put(loadLibraryAuthorFollowFail(error));
  }
}

function* addAuthorToFollowFlow(action) {
  try {
    const { userObjectId, authorObjectId } = action.payload;
    const clientToken = yield select(getClientToken);
    yield call(addAuthorToFollowApi, clientToken, userObjectId, authorObjectId);
    yield put(addAuthorToFollowSuccess());
    yield all([
      call(loadLibraryAuthorFollowFlow, { payload: { userObjectId } }),
      call(loadUserAgendaFlow, { payload: { libraryObjectId: userObjectId } }),
    ]);
  } catch (error) {
    console.log('saga middleware error (addAuthorToFollowFlow)', error);
    yield put(addAuthorToFollowFail(error));
  }
}
function* removeAuthorFromFollowFlow(action) {
  try {
    const { userObjectId, authorObjectId } = action.payload;
    const clientToken = yield select(getClientToken);
    yield call(removeAuthorFromFollowApi, clientToken, userObjectId, authorObjectId);
    yield all([
      call(loadAuthorFlow, { payload: { authorObjectId } }),
      call(loadLibraryAuthorFollowFlow, { payload: { userObjectId } }),
      call(loadUserAgendaFlow, { payload: { libraryObjectId: userObjectId } }),
    ]);
    yield put(removeAuthorFromFollowSuccess());
  } catch (error) {
    console.log('saga middleware error (removeAuthorFromFollowFlow)', error);
    yield put(removeAuthorFromFollowFail(error));
  }
}

export default function* profilesWatcher() {
  yield all([
    takeEvery(LOAD_LIBRARY, loadLibraryFlow),
    takeEvery(LOAD_LIBRARY_PRINTS_OPTIONS, loadLibraryPrintsOptionsFlow),
    takeEvery(LOAD_FOLLOWED, loadFollowedFlow),
    takeEvery(LOAD_USER_AGENDA, loadUserAgendaFlow),
    takeEvery(LOAD_SIGNED_ALBUMS, loadSignedAlbumsFlow),
    takeEvery(LOAD_USER_STATS, loadUserStatsFlow),
    takeEvery(LOAD_LOANED_ALBUMS, loadLoanedAlbumsFlow),
    takeEvery(LOAD_WISHLIST_ALBUMS, loadWishlistAlbumsFlow),
    takeEvery(LOAD_SELECTED_ALBUM_PRINTS, loadSelectedAlbumPrintsFlow),
    takeLatest(UPDATE_WISHLIST_PRINTS, updateWishlistAlbumsFlow),
    takeLatest(LOAD_RECOMMENDATION, loadRecommendationFlow),
    takeLatest(SET_SELECTED_ALBUM_PRINT, setSelectedAlbumPrintFlow),
    takeLatest(ADD_SERIE_TO_FOLLOW, addSerieToFollowFlow),
    takeLatest(REMOVE_SERIE_FROM_FOLLOW, removeSerieFromFollowFlow),
    takeLatest(SET_EO_FLAG_ON_PRINTS, updateLibraryFlow),
    takeLatest(SET_SIGNED_FLAG_ON_PRINTS, updateLibraryFlow),
    takeLatest(SET_DELUXE_FLAG_ON_PRINTS, updateLibraryFlow),
    takeLatest(SET_LIMITED_FLAG_ON_PRINTS, updateLibraryFlow),
    takeLatest(SET_EXLIBRIS_FLAG_ON_PRINTS, updateLibraryFlow),
    takeLatest(SET_DIGITAL_FLAG_ON_PRINTS, updateLibraryFlow),
    takeLatest(SET_FOR_SALE_FLAG_ON_PRINTS, updateLibraryFlow),
    takeLatest(SET_BUY_PRICE_FLAG_ON_PRINTS, updateLibraryFlow),
    takeLatest(SET_RESELL_VALUE_FLAG_ON_PRINTS, updateLibraryFlow),
    takeLatest(SET_CONDITION_FLAG_ON_PRINTS, updateLibraryFlow),
    takeLatest(SET_NUMBERING_FLAG_ON_PRINTS, updateLibraryFlow),
    takeLatest(ADD_PRINTS_TO_WISHLIST, updateLibraryFlow),
    takeLatest(REMOVE_PRINTS_FROM_WISHLIST, updateLibraryFlow),
    takeLatest(UPDATE_MEMO_PRINTS, updateLibraryFlow),
    takeLatest(ADD_PRINTS_TO_LIBRARY, updateLibraryFlow),
    takeLatest(REMOVE_PRINTS_FROM_LIBRARY, updateLibraryFlow),
    takeLatest(SET_READ_FLAG_ON_PRINTS, updateLibraryFlow),
    takeLatest(ADD_PRINT_LOAN, updateLibraryFlow),
    takeLatest(REMOVE_PRINT_LOAN, updateLibraryFlow),
    takeLatest(UPDATE_LIBRARY, updateLibraryFlow),
    takeLatest(ADD_AUTHOR_TO_FOLLOW, addAuthorToFollowFlow),
    takeLatest(REMOVE_AUTHOR_FROM_FOLLOW, removeAuthorFromFollowFlow),
    takeLatest(LOAD_LIBRARY_AUTHOR_FOLLOW, loadLibraryAuthorFollowFlow),
  ]);
}

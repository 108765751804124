import menuSeries from '../services/menu-series.json';

export const LOAD_SERIE = 'LOAD_SERIE';
export const LOAD_SERIE_SUCCESS = 'LOAD_SERIE_SUCCESS';
export const LOAD_SERIE_FAIL = 'LOAD_SERIE_FAIL';

export const LOAD_SERIES = 'LOAD_SERIES';
export const LOAD_SERIES_SUCCESS = 'LOAD_SERIES_SUCCESS';
export const LOAD_SERIES_FAIL = 'LOAD_SERIES_FAIL';

export const LOAD_SERIES_ALPHANUMERIC_INDEX = 'LOAD_SERIES_ALPHANUMERIC_INDEX';
export const LOAD_SERIES_ALPHANUMERIC_INDEX_SUCCESS = 'LOAD_SERIES_ALPHANUMERIC_INDEX_SUCCESS';
export const LOAD_SERIES_ALPHANUMERIC_INDEX_FAIL = 'LOAD_SERIES_ALPHANUMERIC_INDEX_FAIL';

export const RESET_SERIE_REDUCER = 'RESET_SERIE_REDUCER';

export const STORE_SERIES = 'STORE_SERIES';

const initialState = {
  mapOfSerieIdsClassic: {},
  mapOfSerieIdsTopRated: {},
  mapOfSerieIdsCategoriesAndGenres: {},
  mapOfSeriesIdsTeamFavorite: {},
  menuSeriesObjectIds: {
    bd: ['4eKFLiHZY6', 'aVEuh2T41m'],
    comics: ['YexpqI1CMZ', 'i2GNIZI1CV'],
    mangas: ['qyoCxB0di6', 'JxOzbofIbs'],
    jeunesse: ['Tg3wI2JpTz', 'kXTrOHff6C', 'DNEPUjJkGQ'],
  },
  series: menuSeries,
  seriesAlphanumericIndexMap: {},
  loading: false,
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case STORE_SERIES: {
      const series = action.payload.series.reduce(
        (map, serie) => {
          serie.albums = serie.albums.map((album) =>
            typeof album === 'string' ? album : album.objectId,
          );
          map[serie.objectId] = serie;
          return map;
        },
        { ...state.series },
      );

      return {
        ...state,
        loading: false,
        series: series,
        errors: [],
      };
    }
    case LOAD_SERIES_ALPHANUMERIC_INDEX: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_SERIES_ALPHANUMERIC_INDEX_SUCCESS: {
      return {
        ...state,
        seriesAlphanumericIndexMap: {
          ...state.seriesAlphanumericIndexMap,
          [action.payload.index]: action.payload.series,
        },
        loading: false,
      };
    }
    case LOAD_SERIES_ALPHANUMERIC_INDEX_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case LOAD_SERIE: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_SERIE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case LOAD_SERIE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LOAD_SERIES: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_SERIES_SUCCESS: {
      const params = action.payload.params;
      const serieObjectIds = action.payload.series.map((serie) => serie.objectId);

      let mapOfSerieIdsClassic = state.mapOfSerieIdsClassic;
      let mapOfSerieIdsTopRated = state.mapOfSerieIdsTopRated;
      let mapOfSerieIdsCategoriesAndGenres = state.mapOfSerieIdsCategoriesAndGenres;
      let mapOfSeriesIdsTeamFavorite = state.mapOfSeriesIdsTeamFavorite;

      const key = (params.category || '') + (params.genre || '') + (params.tags || '') || 'all';

      if (params && params.is_classic) {
        mapOfSerieIdsClassic = Object.assign({}, mapOfSerieIdsClassic);
        mapOfSerieIdsClassic[key] = serieObjectIds;
      }
      if (params && params.is_top_rated) {
        mapOfSerieIdsTopRated = Object.assign({}, mapOfSerieIdsTopRated);
        mapOfSerieIdsTopRated[key] = serieObjectIds;
      }
      if (params && params.is_team_favorite) {
        mapOfSeriesIdsTeamFavorite = Object.assign({}, mapOfSeriesIdsTeamFavorite);
        mapOfSeriesIdsTeamFavorite[key] = serieObjectIds;
      }

      if (params && !params.is_classic && !params.is_top_rated && !params.is_team_favorite) {
        mapOfSerieIdsCategoriesAndGenres = Object.assign({}, mapOfSerieIdsCategoriesAndGenres);
        mapOfSerieIdsCategoriesAndGenres[key] = serieObjectIds;
      }

      return {
        ...state,
        mapOfSerieIdsClassic,
        mapOfSerieIdsTopRated,
        mapOfSerieIdsCategoriesAndGenres,
        mapOfSeriesIdsTeamFavorite,
        loading: false,
        errors: [],
      };
    }
    case LOAD_SERIES_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case RESET_SERIE_REDUCER: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
}

// storeSeries ====================================================

export function storeSeries(series) {
  return {
    type: STORE_SERIES,
    payload: {
      series,
    },
  };
}

// loadSerie ====================================================

export function loadSerie(serieObjectId, params) {
  return {
    type: LOAD_SERIE,
    payload: {
      serieObjectId,
      params,
    },
  };
}
export function loadSerieSuccess(serie) {
  return {
    type: LOAD_SERIE_SUCCESS,
    payload: {
      serie,
    },
  };
}
export function loadSerieFail(error) {
  return {
    type: LOAD_SERIE_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadSeries ====================================================

export function loadSeries(params) {
  return {
    type: LOAD_SERIES,
    payload: {
      params,
    },
  };
}
export function loadSeriesSuccess(series, params) {
  return {
    type: LOAD_SERIES_SUCCESS,
    payload: {
      series,
      params,
    },
  };
}
export function loadSeriesFail(error) {
  return {
    type: LOAD_SERIES_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadSeriesAtAlphanumericIndex ====================================================

export function loadSeriesAtAlphanumericIndex(index) {
  return {
    type: LOAD_SERIES_ALPHANUMERIC_INDEX,
    payload: {
      index,
    },
  };
}
export function loadSeriesAtAlphanumericIndexSuccess(index, series) {
  return {
    type: LOAD_SERIES_ALPHANUMERIC_INDEX_SUCCESS,
    payload: {
      index,
      series,
    },
  };
}
export function loadSeriesAtAlphanumericIndexFail(error) {
  return {
    type: LOAD_SERIES_ALPHANUMERIC_INDEX_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// resetSeriesReducer ====================================================

export function resetSerieReducer() {
  return {
    type: RESET_SERIE_REDUCER,
  };
}

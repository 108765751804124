import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  createPromotionApi,
  deletePromotionApi,
  loadPromotionApi,
  loadPromotionsApi,
  updatePromotionApi,
} from '../services/api';

import {
  CREATE_PROMOTION,
  DELETE_PROMOTION,
  LOAD_PROMOTION,
  LOAD_PROMOTIONS,
  UPDATE_PROMOTION,
} from '../reducers/promotions';
import {
  createPromotionFail,
  createPromotionSuccess,
  deletePromotionFail,
  deletePromotionSuccess,
  loadPromotionFail,
  loadPromotionSuccess,
  loadPromotionsFail,
  loadPromotionsSuccess,
  updatePromotionFail,
  updatePromotionSuccess,
} from '../reducers/promotions';
import { getClientToken, getClientTokenBackOffice } from '../selectors';

function* createPromotionFlow(action) {
  try {
    const clientToken = yield select(getClientTokenBackOffice);
    const promotionCreated = yield call(createPromotionApi, clientToken, action.payload.promotion);
    yield put(createPromotionSuccess(promotionCreated));
  } catch (error) {
    console.log('saga middleware error (createPromotionFlow)', error);
    yield put(createPromotionFail(error));
  }
}

function* loadPromotionFlow(action) {
  try {
    let clientToken = yield select(getClientToken);
    if (!clientToken) clientToken = yield select(getClientTokenBackOffice);
    const promotion = yield call(loadPromotionApi, clientToken, action.payload.promotionObjectId);
    yield put(loadPromotionSuccess(promotion));
  } catch (error) {
    console.log('saga middleware error (loadPromotionFlow)', error);
    yield put(loadPromotionFail(error));
  }
}

function* loadPromotionsFlow(action) {
  try {
    let clientToken = yield select(getClientToken);
    if (!clientToken) clientToken = yield select(getClientTokenBackOffice);
    const promotions = yield call(loadPromotionsApi, clientToken, action.payload.paramsString);
    yield put(loadPromotionsSuccess(promotions));
  } catch (error) {
    console.log('saga middleware error (loadPromotionsFlow)', error);
    yield put(loadPromotionsFail(error));
  }
}

function* updatePromotionFlow(action) {
  try {
    const clientToken = yield select(getClientTokenBackOffice);
    const promotionUpdated = yield call(
      updatePromotionApi,
      clientToken,
      action.payload.promotionObjectId,
      action.payload.fieldsAndData,
    );
    yield put(updatePromotionSuccess(promotionUpdated));
  } catch (error) {
    console.log('saga middleware error (updatePromotionFlow)', error);
    yield put(updatePromotionFail(error));
  }
}

function* deletePromotionFlow(action) {
  try {
    const clientToken = yield select(getClientTokenBackOffice);
    yield call(deletePromotionApi, clientToken, action.payload.promotionObjectId);
    yield put(deletePromotionSuccess(action.payload.promotionObjectId));
  } catch (error) {
    console.log('saga middleware error (deletePromotionFlow)', error);
    yield put(deletePromotionFail(error));
  }
}

export default function* productsWatcher() {
  yield all([
    takeEvery(CREATE_PROMOTION, createPromotionFlow),
    takeLatest(LOAD_PROMOTION, loadPromotionFlow),
    takeLatest(LOAD_PROMOTIONS, loadPromotionsFlow),
    takeEvery(UPDATE_PROMOTION, updatePromotionFlow),
    takeEvery(DELETE_PROMOTION, deletePromotionFlow),
  ]);
}

import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { createAddressApi, createOrderApi, loadOrderApi, updateOrderApi } from '../services/api';

import {
  CREATE_ADDRESS_FOR_ORDER,
  CREATE_CARD_FOR_ORDER,
  CREATE_ORDER,
  LOAD_ORDER,
  UPDATE_ORDER,
} from '../reducers/orders';
import {
  createAddressForOrderFail,
  createAddressForOrderSuccess,
  createCardForOrderFail,
  createCardForOrderSuccess,
  createOrderFail,
  createOrderSuccess,
  loadOrderFail,
  loadOrderSuccess,
  updateOrder,
  updateOrderFail,
  updateOrderSuccess,
} from '../reducers/orders';
import { getClientToken, getDefaultPaymentMethod } from '../selectors';
import { updatePaymentMethodFlow } from './payment-methods';

import { GENERAL } from 'bubble-constants';

function* createOrderFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(createOrderApi, clientToken, action.payload.params);

    yield put(createOrderSuccess(response));
  } catch (error) {
    console.log('saga middleware error (createOrderFlow)', error);
    yield put(createOrderFail(error));
  }
}

function* updateOrderFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      updateOrderApi,
      clientToken,
      action.payload.orderObjectId,
      action.payload.params,
    );
    yield put(updateOrderSuccess(response));
  } catch (error) {
    console.log('saga middleware error (updateOrderFlow)', error);
    yield put(updateOrderFail(error));
  }
}

function* loadOrderFlow(action) {
  try {
    const clientToken = yield select(getClientToken);

    const response = yield call(loadOrderApi, clientToken, action.payload.orderObjectId);
    yield put(loadOrderSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadOrderFlow)', error);
    yield put(loadOrderFail(error));
  }
}

function* createAddressForOrderFlow(action) {
  try {
    const clientToken = yield select(getClientToken);

    const addressResponse = yield call(createAddressApi, clientToken, action.payload.address);

    const orderAction = yield put(
      updateOrder(action.payload.orderObjectId, {
        addressObjectId: addressResponse.objectId,
        status: 'address-added',
        page: 'checkout',
      }),
    );
    yield put(createAddressForOrderSuccess(addressResponse));
  } catch (error) {
    console.log('saga middleware error (createAddressForOrderFlow)', error);
    yield put(createAddressForOrderFail(error));
  }
}

function* createCardForOrderFlow(action) {
  try {
    const potentialError = yield call(updatePaymentMethodFlow, {
      payload: {
        rethrow: true,
        userObjectId: action.payload.userObjectId,
        paymentMethod: {
          action: GENERAL.PAYMENT_METHOD_ACTIONS.ADD,
          paymentMethodId: action.payload.paymentMethodId,
        },
      },
    });
    if (potentialError) throw potentialError;

    const paymentMethod = yield select(getDefaultPaymentMethod);
    if (!paymentMethod || !paymentMethod.paymentMethodId) {
      throw new Error("Aucun moyen de paiement n'a été enregistré");
    }

    yield put(
      updateOrder(action.payload.orderObjectId, {
        paymentMethodId: paymentMethod.paymentMethodId,
        status: 'card-added',
        page: 'checkout',
      }),
    );
    yield put(createCardForOrderSuccess());
  } catch (error) {
    console.log('saga middleware error (createCardForOrderFlow)', error);
    yield put(createCardForOrderFail(error));
  }
}

export default function* orderWatcher() {
  yield all([
    takeEvery(CREATE_ORDER, createOrderFlow),
    takeEvery(UPDATE_ORDER, updateOrderFlow),
    takeEvery(LOAD_ORDER, loadOrderFlow),
    takeEvery(CREATE_ADDRESS_FOR_ORDER, createAddressForOrderFlow),
    takeEvery(CREATE_CARD_FOR_ORDER, createCardForOrderFlow),
  ]);
}

import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadAppReviews } from 'bubble-reducers/src/reducers/reviews';

import useEffectOnce from '@/services/hooks/useEffectOnce';

import ReviewCard from '@/components/ReviewCard/ReviewCard';

import './BubbleHomeInfinityAd.scss';

export default function BubbleHomeInfinityAd() {
  const appReviews = useSelector((state) => state.reviews.appReviews) || {};
  const dispatch = useDispatch();

  const reviews = Object.values(appReviews);
  useEffectOnce(() => {
    dispatch(loadAppReviews());
  });

  return (
    <div className="row home-infinity-background py-5 px-3 px-lg-5 text-center">
      <div className="d-none d-lg-block col-3 text-start">
        <div className="pb-4">
          <ReviewCard noLike review={reviews[0]} />
        </div>
        <div>
          <ReviewCard noLike review={reviews[1]} />
        </div>
      </div>
      <div className="col-12 col-lg-6 d-flex flex-column align-items-center">
        <div className="h1">
          <span className="fw-bold">Bubble Infinity :</span>
          <br />
          le meilleur outil pour
          <br />
          gérer votre collection
        </div>
        <p className="px-lg-6 px-4 py-4 h5">
          Passioné de BD, comics ou manga, composez votre collection avec Bubble : ajoutez vos
          albums favoris et ceux qu'il vous manque, programmez des alertes pour les sorties des
          prochains volumes, et bien plus encore !
        </p>

        <Link to="/infinity" className="btn btn-outline-black d-none d-lg-block">
          Découvrez l'offre Bubble Infinity
        </Link>
      </div>
      <div className="d-none d-lg-block col-3 text-start">
        <div className="pb-4">
          <ReviewCard noLike review={reviews[2]} />
        </div>
        <div>
          <ReviewCard noLike review={reviews[3]} />
        </div>
      </div>
      <div className="d-lg-none col-12 pt-4 px-0">
        <div className="row flex-nowrap text-start overflow-auto">
          {reviews.slice(0, 4).map((review) => (
            <div key={review.objectId} className="col-8">
              <ReviewCard noLike review={review} />
            </div>
          ))}
        </div>

        <Link to="/infinity" className="btn btn-outline-black d-block d-lg-none mt-5">
          Découvrez l'offre Bubble Infinity
        </Link>
      </div>
    </div>
  );
}

import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { addToCart } from 'bubble-reducers/src/reducers/cart';

import { useRemoveFromCart } from '@/services/hooks/useLibraryUtils';

import { Icon } from '@/components/Icon/Icon';
import AddedToCartModal from '@/components/Modals/AddedToCartModal';
import ReservationOneClickModal from '@/components/Modals/ReservationOneClickModal';
import Tooltip from '@/components/Tooltip/Tooltip';

import iconShippingGreen from '@/assets/icon_livraison_green.svg';
import iconShippingRed from '@/assets/icon_livraison_red.svg';
import iconShippingYellow from '@/assets/icon_livraison_yellow.svg';
import iconStoreBlue from '@/assets/icon_store_blue.svg';
import iconStoreYellow from '@/assets/icon_store_yellow.svg';

import AlbumBuySectionAvailabilityAlert from './components/AlbumBuySectionAvailabilityAlert';

const defaultObject = {};

const AlbumBuySection = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const removeFromCart = useRemoveFromCart();

  const print = props.print;

  const [showReservationOneClickModal, setShowReservationOneClickModal] = useState(false);
  const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);

  const sellingInfoOnline = props.sellingInfo.online || defaultObject;
  const sellingInfoClickandCollect = props.sellingInfo.clickAndCollect || defaultObject;
  const onlineAvailability = sellingInfoOnline.availability || defaultObject;
  const clickAndCollectAvailability = sellingInfoClickandCollect.availability || defaultObject;
  const truckIcon =
    onlineAvailability.color === '#92D148'
      ? iconShippingGreen
      : onlineAvailability.color === '#ea0717'
        ? iconShippingRed
        : iconShippingYellow;
  const storeIcon =
    clickAndCollectAvailability.color === '#92D148' ? iconStoreBlue : iconStoreYellow;

  const handleAddToCart = useCallback(() => {
    if (props.isOwned) {
      if (
        window.confirm(
          "Cet album fait déjà parti de votre collection, Êtes vous sûr de vouloir l'ajouter à votre panier ?",
        )
      ) {
        dispatch(addToCart(print));
        setShowAddedToCartModal(true);
      }
    } else {
      dispatch(addToCart(print));
      setShowAddedToCartModal(true);
    }
  }, [props.isOwned, print]);

  const onClickRemoveFromCart = useCallback(() => removeFromCart([print]), [print]);

  const onClickReservationOneClick = () => setShowReservationOneClickModal(true);

  return (
    <div className="row px-3">
      {showAddedToCartModal && (
        <AddedToCartModal
          show={showAddedToCartModal}
          itemIds={[print].map((print) => print.objectId)}
          callback={() => setShowAddedToCartModal(false)}
        />
      )}
      {showReservationOneClickModal && (
        <ReservationOneClickModal
          show={showReservationOneClickModal}
          reservationOneClickAlbums={[
            { ...props.album, itemObjectId: print.objectId, extra: { ean: print.ean } },
          ]}
          hasCheckoutRedirect={true}
          callback={() => setShowReservationOneClickModal(false)}
        />
      )}

      {/* LEFT */}
      <div className="col-12 col-md-6 d-flex flex-column bg-white p-4">
        <div className="px-3">
          <div className="fw-bold text-success bb-medium-text-size">
            {!!sellingInfoOnline.numberOfSellers
              ? 'Commandez-le en ligne maintenant'
              : 'Indisponible en ligne'}
          </div>
          <div
            className="d-flex mt-1"
            style={{
              color: onlineAvailability.color,
            }}
          >
            <img alt="truck" style={{ width: 25 }} src={truckIcon} className="me-2" />
            {onlineAvailability.message}
          </div>
          <div className="pt-3">
            {!!sellingInfoOnline.numberOfSellers && (
              <>
                <span className="text-success">✔</span> Livraison à partir de 0,01 €{' '}
                <Tooltip
                  className="ms-1 text-secondary"
                  tip="Pour les commandes d'un montant supérieur à 35€"
                >
                  ( <Icon className="bb-s-text-size" name="info" /> )
                </Tooltip>
              </>
            )}
          </div>
          <div className="pt-1">
            <span className="text-success">✔</span> Emballage super{' '}
            <Link to="/9emeart/bd/edito/bubble-expedie-vos-albums-emballage-avec-ou-sans-bulle">
              soigné
            </Link>
          </div>

          <div className="d-inline-flex mt-3 w-100">
            {(!!sellingInfoOnline.numberOfSellers || props.isInCart) && (
              <button
                onClick={handleAddToCart}
                disabled={props.isInCart}
                className="btn btn-lg text-white btn-success w-100"
              >
                {props.isInCart ? 'Déjà ajouté au panier' : 'Ajouter au panier'}
              </button>
            )}
            {!sellingInfoOnline.numberOfSellers && !props.isInCart && (
              <AlbumBuySectionAvailabilityAlert printObjectId={print?.objectId} />
            )}
            {props.isInCart && (
              <Tooltip tip="Retirer du panier">
                <button className="btn btn-lg btn-danger ms-2" onClick={onClickRemoveFromCart}>
                  <Icon name="times" />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>

      {/* RIGHT */}
      <div className="col-12 col-md-6 d-flex flex-column bg-white p-4 mt-3 mt-md-0">
        <div className="px-3 h-100 d-flex flex-column">
          <div className="fw-bold bb-medium-text-size text-primary">
            Retirez-le gratuitement en librairie
          </div>
          <div className="d-flex align-items-start mt-1">
            <img style={{ width: 22 }} alt="librairie" src={storeIcon} className="me-2" />

            <div
              className={
                sellingInfoClickandCollect.numberOfSellers &&
                sellingInfoClickandCollect.numberOfSellers > 0
                  ? 'text-primary'
                  : 'text-warning'
              }
            >
              Disponible dans{' '}
              {!sellingInfoClickandCollect.numberOfSellers
                ? 0
                : sellingInfoClickandCollect.numberOfSellers}{' '}
              librairie
              {sellingInfoClickandCollect.numberOfSellers > 1 ? 's' : ''} partenaire
              {sellingInfoClickandCollect.numberOfSellers > 1 ? 's' : ''}
            </div>
          </div>
          <div className="pt-3">
            <span className="text-success">✔</span> Retrait et paiement en librairie
          </div>
          <div className="d-grid flex-fill align-items-end">
            <button
              className="btn btn-lg text-white btn-primary"
              disabled={
                !sellingInfoClickandCollect.numberOfSellers ||
                sellingInfoClickandCollect.numberOfSellers === 0
              }
              onClick={onClickReservationOneClick}
            >
              Retirer en librairie
            </button>
          </div>
        </div>
      </div>
      <div
        className="d-none d-md-block v-separator position-relative h-100 overflow-hidden"
        style={{ left: '50%', top: '-100%' }}
      />
    </div>
  );
};

AlbumBuySection.propTypes = {
  isOwned: PropTypes.bool,
  isInCart: PropTypes.bool,
  userObjectId: PropTypes.string,
  print: PropTypes.shape({ objectId: PropTypes.string }),
  sellingInfo: PropTypes.shape({ online: PropTypes.object, clickAndCollect: PropTypes.object }),
};

export default AlbumBuySection;

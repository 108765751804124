import React from 'react';

import config from '@/project-config';

import bubbleUtils from 'bubble-utils';

export default class BubbleAffilae extends React.Component {
  constructor(props) {
    super(props);

    if (!bubbleUtils.env.isServer) {
      this.initialize();
    }
  }

  initialize() {
    if (!config.affilae.programId) {
      console.error('[bubble-affilae] program ID is required.');
      return;
    }

    window._ae = {
      pid: config.affilae.programId,
      advertiserFirst: 21600, // 15 days in mins
    };

    (function () {
      const element = document.createElement('script');
      element.type = 'text/javascript';
      element.async = true;
      element.src = '//static.affilae.com/ae-v3.5.js';
      const body = document.getElementsByTagName('body')[0];
      body.appendChild(element);
    })();
  }

  render() {
    return null;
  }
}

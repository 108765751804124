import React, { useRef, useState } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { isAlbumInWishlist, isAlbumOwned, makeIsAlbumInCart } from 'bubble-reducers/src/selectors';

import { getDefaultSellingPrint } from 'bubble-utils/src/album-utils';
import { formatCurrency } from 'bubble-utils/src/currency-utils';

import {
  useAddPrintsToLibrary,
  useAddPrintsToWishlist,
  useRemovePrintsFromLibrary,
  useRemovePrintsFromWishlist,
} from '@/services/hooks/useLibraryUtils';
import imageUtils from '@/services/image-utils';

import AddToCartZone from '@/components/AlbumCard/components/AddToCartZone';
import Cover from '@/components/Cover/Cover';
import ImageBrowsingModal from '@/components/Modals/ImageBrowsingModal';
import StarLine from '@/components/StarLine/StarLine';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import TopScores from '../TopScores';

import '../../Top.scss';

const TopAlbumCard = (props) => {
  const addPrintsToWishlist = useAddPrintsToWishlist();
  const removePrintsFromWishlist = useRemovePrintsFromWishlist();
  const addPrintsToLibrary = useAddPrintsToLibrary();
  const removePrintsFromLibrary = useRemovePrintsFromLibrary();

  const [showImageBrowsingModal, setShowImageBrowsingModal] = useState(false);

  const activeIndex = useRef(0);

  const topItem = props.topItem || {};
  const album = topItem.album || {};
  const serie = album.serie || {};
  const print = getDefaultSellingPrint(album) || {};

  const smallImagesArray = print.images
    ? Object.values(print.images).map(
        (type) => type.smallFramed || type.medium || type.original || type.large,
      )
    : [];

  const onClickAddCollection = () => {
    if (props.isOwned) {
      removePrintsFromLibrary(props.user, [print]);
    } else {
      addPrintsToLibrary(props.user, [print]);
    }
  };

  const onClickAddWishlist = () => {
    if (props.isWishlist) {
      removePrintsFromWishlist(props.user, [print]);
    } else {
      addPrintsToWishlist(props.user, [print]);
    }
  };

  const handleShowImageBrowsingModal = () => {
    setShowImageBrowsingModal(true);
  };

  return (
    <div className="bg-white rounded p-3 px-4 pt-4 position-relative">
      {showImageBrowsingModal && (
        <ImageBrowsingModal
          show={showImageBrowsingModal}
          startIndex={activeIndex.current}
          images={smallImagesArray}
          callback={() => setShowImageBrowsingModal(false)}
        />
      )}

      <TopScores
        lastPosition={topItem.lastPosition}
        position={topItem.position}
        index={topItem.position + 1}
      />

      <div className="d-flex pt-3">
        <div className="pe-4">
          <Link to={`/${album.permalink}/album/${album.objectId}`}>
            <Cover
              shadow
              rounded
              height={170}
              imageUrl={((print.images || {}).front || {}).smallFramed}
            />
          </Link>
          <div className="d-flex justify-content-between">
            {smallImagesArray.slice(0, 3).map((image, index) => (
              <div key={`carouselimg${image}`} className="my-1">
                <WithClickHandler
                  onClick={() => {
                    activeIndex.current = index;
                    handleShowImageBrowsingModal();
                  }}
                >
                  <Cover height={50} imageUrl={image} />
                </WithClickHandler>
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex flex-fill flex-column">
          <Link
            className="text-black semi-bold bb-medium-large-text-size no-decoration link-to-orange"
            to={`/${album.permalink}/album/${album.objectId}`}
          >
            {album.title || serie.title}
            <span className="d-none d-lg-inline-block ps-1 text-secondary bb-s-text-size fw-normal">
              -{' '}
              {[album.title ? serie.title : null, album.tome ? `Tome ${album.tome}` : null]
                .filter((row) => row)
                .join(' - ')}
            </span>
          </Link>
          <div>{album.price ? formatCurrency(album.price) : ''}</div>

          <div className="d-flex justify-content-around border rounded my-2 py-2 position-relative">
            <Link
              to={`/${album.permalink}/album/${album.objectId}/avis`}
              className="no-decoration link-to-orange text-black bb-s-text-size d-flex flex-column align-items-center"
            >
              <div>Note des lecteurs</div>
              <div>
                <StarLine note={album.note} />
              </div>
              <div className="d-flex pt-1">
                <span className="fw-bold">{album.note ? album.note.toFixed(1) : ''}</span>
                {album.numberOfNotes && (
                  <span className="ps-1">
                    ({album.numberOfNotes} note{album.numberOfNotes > 1 ? 's' : ''})
                  </span>
                )}
              </div>
            </Link>
            <hr className="d-none d-md-block v-separator my-n2 mx-0 position-absolute separator-absolute" />
            <Link
              to={`/${album.permalink}/album/${album.objectId}/avis`}
              className="no-decoration link-to-orange text-black d-none d-md-flex flex-column align-items-center"
            >
              <div>Evaluer cet album</div>
              <div>
                <StarLine note={0} />
              </div>
            </Link>
          </div>

          <AddToCartZone print={print} isOwned={props.isOwned} isInCart={props.isInCart} />

          <div className="d-flex justify-content-md-around pt-2">
            <button
              onClick={onClickAddCollection}
              className="btn btn-link no-decoration d-flex align-items-center"
            >
              <img
                className="mt-n2"
                alt="collection"
                src={
                  props.isOwned
                    ? imageUtils.getAssetImage('icon_add_collection_green.svg')
                    : imageUtils.getAssetImage('icon_add_collection.svg')
                }
              />
              <div className={`d-none d-md-block ms-1 ${props.isOwned ? 'text-success' : ''}`}>
                {!props.isOwned ? 'Ajouter à' : 'Retirer de'} ma collection
              </div>
            </button>
            <button
              onClick={onClickAddWishlist}
              className="btn btn-link no-decoration d-flex align-items-center"
            >
              <img
                alt="wishlist"
                src={
                  props.isWishlist
                    ? imageUtils.getAssetImage('icon_wishlist_on.svg')
                    : imageUtils.getAssetImage('icon_wishlist_off.svg')
                }
              />
              <div className={`d-none d-md-block ms-2 ${props.isWishlist ? 'text-success' : ''}`}>
                {!props.isWishlist ? 'Ajouter à' : 'Retirer de'} ma liste d'envies
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const isAlbumInCart = makeIsAlbumInCart();
  const topItem = props.topItem || {};
  const album = topItem.album || {};
  const print = getDefaultSellingPrint(album);
  return {
    user: state.user.user,
    isOwned: isAlbumOwned(state, album.objectId),
    isInCart: isAlbumInCart(state, print?.objectId),
    isWishlist: isAlbumInWishlist(state, album.objectId),
  };
};

export default connect(mapStateToProps, null)(TopAlbumCard);

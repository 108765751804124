import React, { useCallback } from 'react';

import imageUtils from '@/services/image-utils';

import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

const RevisionMenuCell = ({ id, onClick, imageSource, mainText, subText }) => {
  const _onClick = useCallback(() => onClick(id), [id, onClick]);

  return (
    <WithClickHandler onClick={_onClick}>
      <div className="row rounded p-4 my-2 bb-s-text-size text-secondary bg-white">
        <div className="col-12 d-flex flex-row align-items-center">
          {imageSource && (
            <img
              width="40 rem"
              className="mb-2 collection-array-picto"
              alt="revision_album"
              src={imageUtils.getAssetImage(`${imageSource}`)}
            />
          )}
          <div className="d-flex flex-column px-2">
            <span className="ps-lg-4 ps-2 bb-medium-text-size text-black">{mainText || ''}</span>
            <span className="ps-lg-4 ps-2 bb-small-text-size">{subText || ''}</span>
          </div>
          <div className="flex-fill"></div>
          <Icon className="ps-lg-3" name="chevron-end" />
        </div>
      </div>
    </WithClickHandler>
  );
};

export default RevisionMenuCell;

import React from 'react';

import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import {
  loadFollowed,
  loadLibrary,
  loadLibraryPrintsOptions,
  loadLoanedAlbums,
  loadSelectedAlbumPrints,
  loadSignedAlbums,
  loadUserAgenda,
  loadUserStats,
  loadWishlistAlbums,
} from 'bubble-reducers/src/reducers/profiles';
import { loadUser } from 'bubble-reducers/src/reducers/user';

import { isValidObjectId } from 'bubble-utils/src/validity-utils';

import { getSeoForUser } from '@/services/seo-utils';

import asyncComponent from '@/components/AsyncComponent/AsyncComponent';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import withRouter from '@/components/withRouter';

import PrivateProfileNotice from './components/PrivateProfileNotice';
import UserProfileHeader from './components/UserProfileHeader/UserProfileHeader';

import { GENERAL } from 'bubble-constants';
import bubbleUtils from 'bubble-utils';

import './UserProfile.scss';

const AsyncMyCollection = asyncComponent(() => import('./components/MyCollection/MyCollection'));
const AsyncMyAgenda = asyncComponent(() => import('./components/MyAgenda'));
const AsyncMyMissingAlbums = asyncComponent(
  () => import('./components/MyMissingAlbums/MyMissingAlbums'),
);
const AsyncMyStats = asyncComponent(() => import('./components/MyStats/MyStats'));
const AsyncMyWishlist = asyncComponent(() => import('./components/MyWishlist/MyWishlist'));

export const tabs = {
  MY_LIBRARY: 'ma-collection',
  MY_MISSING_ALBUMS: 'mes-albums-manquants',
  MY_WISHLIST: 'ma-wishlist',
  MY_AGENDA: 'mon-agenda',
  MY_STATS: 'mes-statistiques',
};

class UserProfile extends React.PureComponent {
  constructor(props) {
    super(props);
    if (bubbleUtils.env.isServer) {
      const user = props.user;
      const userObjectId = user.objectId;
      const urlUserObjectId = props.location.pathname.split('/')[2];
      const isGuest = userObjectId !== urlUserObjectId;
      const { loadUser, loadUserStats } = this.props;
      loadUserStats(urlUserObjectId, isGuest);
      loadUser(urlUserObjectId, isGuest);
    }
  }

  componentDidMount() {
    this.loadInfos();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname.split('/')[2] !== this.props.location.pathname.split('/')[2]) {
      this.loadInfos();
    }
  }

  loadInfos() {
    const user = this.props.user;
    const userObjectId = user.objectId;
    const urlUserObjectId = this.props.location.pathname.split('/')[2];
    const isGuest = userObjectId !== urlUserObjectId;
    const {
      loadUser,
      loadUserStats,
      loadUserAgenda,
      loadLibrary,
      loadFollowed,
      loadSignedAlbums,
      loadLoanedAlbums,
      loadWishlistAlbums,
      loadLibraryPrintsOptions,
    } = this.props;
    if (urlUserObjectId && isValidObjectId(urlUserObjectId)) {
      if (urlUserObjectId !== userObjectId) {
        loadLibrary(urlUserObjectId, isGuest);
        loadLibraryPrintsOptions(urlUserObjectId, isGuest);
        loadFollowed(urlUserObjectId, isGuest);
        loadSignedAlbums(urlUserObjectId, isGuest);
        loadLoanedAlbums(urlUserObjectId, isGuest);
        loadSelectedAlbumPrints(urlUserObjectId, isGuest);
      }
      loadUserStats(urlUserObjectId, isGuest);
      loadWishlistAlbums(urlUserObjectId, isGuest);
      loadUserAgenda(urlUserObjectId, isGuest);
      loadUser(urlUserObjectId, isGuest);
    }
  }

  render() {
    const user = this.props.user;
    const userObjectId = user.objectId;
    const pathname = this.props.location.pathname;
    const urlUserObjectId = pathname.split('/')[2];
    const isGuest = userObjectId !== urlUserObjectId;
    let otherUser = null;
    let otherProfile = null;
    let seo = null;
    if (isGuest) {
      otherUser = this.props.otherUsers[urlUserObjectId];
      otherProfile = this.props.otherProfiles[urlUserObjectId];
      seo = getSeoForUser(otherUser || {}, (otherProfile || {}).stats || {}, pathname);
    } else {
      seo = getSeoForUser(user, this.props.stats, pathname);
    }

    return (
      <>
        <BubbleHelmet seo={seo || {}} />
        <UserProfileHeader
          isGuest={isGuest}
          user={isGuest ? this.props.otherUsers[urlUserObjectId] : user}
          userObjectId={isGuest ? urlUserObjectId : null}
        />
        <div className="bb-background-light-grey">
          <Routes>
            <Route
              path={`/:userObjectId/${tabs.MY_LIBRARY}`}
              element={
                <PrivacyRerouter
                  otherUser={isGuest ? otherUser : null}
                  flag={GENERAL.FLAG_PRIVACY_MY_BDS}
                  route={
                    <AsyncMyCollection
                      isGuest={isGuest}
                      userObjectId={isGuest ? urlUserObjectId : null}
                    />
                  }
                />
              }
            />
            <Route
              path={`/:userObjectId/${tabs.MY_MISSING_ALBUMS}`}
              element={
                <PrivacyRerouter
                  otherUser={isGuest ? otherUser : null}
                  flag={GENERAL.FLAG_PRIVACY_MY_MISSING_ALBUMS}
                  route={
                    <AsyncMyMissingAlbums
                      isGuest={isGuest}
                      userObjectId={isGuest ? urlUserObjectId : null}
                    />
                  }
                />
              }
            />
            <Route
              path={`/:userObjectId/${tabs.MY_WISHLIST}`}
              element={
                <PrivacyRerouter
                  otherUser={isGuest ? otherUser : null}
                  flag={GENERAL.FLAG_PRIVACY_MY_WISHLIST}
                  route={
                    <AsyncMyWishlist
                      isGuest={isGuest}
                      userObjectId={isGuest ? urlUserObjectId : null}
                    />
                  }
                />
              }
            />
            <Route
              path={`/:userObjectId/${tabs.MY_AGENDA}`}
              element={
                <PrivacyRerouter
                  otherUser={isGuest ? otherUser : null}
                  flag={GENERAL.FLAG_PRIVACY_MY_AGENDA}
                  route={
                    <AsyncMyAgenda
                      isGuest={isGuest}
                      userObjectId={isGuest ? urlUserObjectId : null}
                    />
                  }
                />
              }
            />
            <Route
              path={`/:userObjectId/${tabs.MY_STATS}`}
              element={
                <PrivacyRerouter
                  otherUser={isGuest ? otherUser : null}
                  flag={GENERAL.FLAG_PRIVACY_MY_STATS}
                  route={
                    <AsyncMyStats
                      user={isGuest ? otherUser : user}
                      isGuest={isGuest}
                      userObjectId={isGuest ? urlUserObjectId : null}
                    />
                  }
                />
              }
            />
          </Routes>
          <div style={{ height: 100 }} />
        </div>
      </>
    );
  }
}

const PrivacyRerouter = (props) => {
  const allowed = props.otherUser
    ? (props.otherUser.profilePrivacyFlags & props.flag) === props.flag
    : true;

  return allowed ? props.route : <PrivateProfileNotice />;
};

const areEqual = (prevProps, nextProps) =>
  prevProps.user === nextProps.user &&
  nextProps.user.infinityExpirationDate === prevProps.user.infinityExpirationDate &&
  prevProps.otherUsers === nextProps.otherUsers &&
  prevProps.otherProfiles === nextProps.otherProfiles &&
  prevProps.stats === nextProps.stats &&
  prevProps.location.pathname === nextProps.location.pathname;

const mapStateToProps = (state) => ({
  otherProfiles: state.profiles.otherProfiles,
  user: state.user.user,
  stats: state.profiles.stats,
  otherUsers: state.user.otherUsers,
});

export default withRouter(
  connect(mapStateToProps, {
    loadUser,
    loadUserStats,
    loadUserAgenda,
    loadLibrary,
    loadFollowed,
    loadSignedAlbums,
    loadLibraryPrintsOptions,
    loadLoanedAlbums,
    loadWishlistAlbums,
    loadSelectedAlbumPrints,
  })(React.memo(UserProfile, areEqual)),
);

import { all, call, put, takeEvery } from 'redux-saga/effects';

import { createAvailabilityAlert } from 'bubble-reducers/src/reducers/availability-alerts';
import { loginGuestFlow } from 'bubble-reducers/src/sagas/auth';

import {
  GUEST_CHECKOUT_REDIRECT,
  GUEST_CREATE_AVAILABILITY_ALERT,
} from '../reducers/website-guest';

function* guestCheckoutRedirecterFlow(payload) {
  yield call(loginGuestFlow, payload);
}

function* guestCreateAvailabilityAlertFlow(action) {
  yield call(loginGuestFlow, action);
  yield put(createAvailabilityAlert(null, action.payload.printObjectId));
}

export default function* guestWatcher() {
  yield all([
    takeEvery(GUEST_CHECKOUT_REDIRECT, guestCheckoutRedirecterFlow),
    takeEvery(GUEST_CREATE_AVAILABILITY_ALERT, guestCreateAvailabilityAlertFlow),
  ]);
}

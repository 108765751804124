import React, { StrictMode } from 'react';

import { createRoot, hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { initEvents } from 'bubble-reducers/src/reducers/events';

import { checkAuthentication } from '@/services/auth-utils';

import SentryServices from './services/sentry';

import BubbleAffilae from '@/components/BubbleAffilae/BubbleAffilae';
import BubbleIntercom from '@/components/BubbleIntercom/BubbleIntercom';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { globalParams, isSent } from './init/events';
import { store } from './init/store';
import reportWebVitals from './report-web-vitals';

import bubbleUtils from 'bubble-utils';

import './custom.scss';

export default store;

SentryServices.init();

// Initial run
store.runSaga();

// Retrieve user token from storage if it exists
checkAuthentication(store);

// init events
store.dispatch(initEvents(isSent, globalParams));

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
const Application = (
  <StrictMode>
    <ErrorBoundary>
      <Provider store={store} serverState={store.getState()}>
        <BrowserRouter>
          <HelmetProvider>
            {bubbleUtils.env.isProductionEnvironment ? (
              <>
                <App />
                <BubbleIntercom />
                <BubbleAffilae />
              </>
            ) : (
              <App />
            )}
          </HelmetProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </StrictMode>
);

const container = document.getElementById('root');

(async () => {
  const support = await bubbleUtils.image.supportsWebp();
  if (!support) {
    container.classList.add('no-webp');
  }
})();

if (container.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  hydrateRoot(container, Application);
} else {
  // If we're not running on the server, just render like normal
  const root = createRoot(container);
  root.render(Application);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

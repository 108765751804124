import menuArticles from '../services/menu-articles.json';

import bubbleUtils from '../../../bubble-utils';

export const LOAD_ARTICLES = 'LOAD_ARTICLES';
export const LOAD_ARTICLES_SUCCESS = 'LOAD_ARTICLES_SUCCESS';
export const LOAD_ARTICLES_FAIL = 'LOAD_ARTICLES_FAIL';

export const LOAD_HOME_ARTICLES = 'LOAD_HOME_ARTICLES';
export const LOAD_HOME_ARTICLES_SUCCESS = 'LOAD_HOME_ARTICLES_SUCCESS';
export const LOAD_HOME_ARTICLES_FAIL = 'LOAD_HOME_ARTICLES_FAIL';

export const LOAD_ARTICLE = 'LOAD_ARTICLE';
export const LOAD_ARTICLE_SUCCESS = 'LOAD_ARTICLE_SUCCESS';
export const LOAD_ARTICLE_FAIL = 'LOAD_ARTICLE_FAIL';

export const LOAD_ARTICLE_RESOURCES = 'LOAD_ARTICLE_RESOURCES';
export const LOAD_ARTICLE_RESOURCES_SUCCESS = 'LOAD_ARTICLE_RESOURCES_SUCCESS';
export const LOAD_ARTICLE_RESOURCES_FAIL = 'LOAD_ARTICLE_RESOURCES_FAIL';

export const LOAD_ARTICLES_FOR_RESOURCES = 'LOAD_ARTICLES_FOR_RESOURCES';
export const LOAD_ARTICLES_FOR_RESOURCES_SUCCESS = 'LOAD_ARTICLES_FOR_RESOURCES_SUCCESS';
export const LOAD_ARTICLES_FOR_RESOURCES_FAIL = 'LOAD_ARTICLES_FOR_RESOURCES_FAIL';

export const LOAD_RELATED_ARTICLES = 'LOAD_RELATED_ARTICLES';
export const LOAD_RELATED_ARTICLES_SUCCESS = 'LOAD_RELATED_ARTICLES_SUCCESS';
export const LOAD_RELATED_ARTICLES_FAIL = 'LOAD_RELATED_ARTICLES_FAIL';

export const STORE_ARTICLES = 'STORE_ARTICLES';

const initialState = {
  articlesMapByObjectId: menuArticles || {},
  articleContent: null, // web : this is used to avoid saving content of every articles
  articleContentFormatted: null, // app : this is used to avoid saving content of every articles
  articlesObjectIdsMapByPermalink: {},
  relatedArticlesMapByPermalink: {},
  homeArticlesMapByCategory: {},
  paginatedArticleMapByQuery: {},
  articlesMapByCategoryForHome: {},
  highlightedArticleObjectIds: [],
  articlesByResourceId: {},
  menuArticlesObjectIds: {
    bd: ['o9fkSHgbHB', 'cUGVgHLAZk'],
    comics: ['d7kA9H9JVl', 'gsWdLLmvIt'],
    mangas: ['wxFyrDQ7K4', 'jKH0n6ZnTj'],
    jeunesse: ['p7LKscseXD', 'lBQMhcpFOW'],
  },
  resourcesObjectIdsMap: {},
  loading: {},
  errors: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case STORE_ARTICLES: {
      const articlesMapByObjectId = { ...state.articlesMapByObjectId };
      const articlesObjectIdsMapByPermalink = { ...state.articlesObjectIdsMapByPermalink };
      let articleContent = state.articleContent;
      let articleContentFormatted = state.articleContentFormatted;
      action.payload.articles.forEach((article) => {
        articlesMapByObjectId[article.objectId] = article;
        articlesObjectIdsMapByPermalink[article.permalink] = article.objectId;
        if (article.contentWithLinks || article.contentFormatted) {
          articleContent = article.contentWithLinks || article.contentFormatted;
        }
        if (article.contentFormatted) articleContentFormatted = article.contentFormatted;
        articlesMapByObjectId[article.objectId].contentWithLinks = undefined;
        articlesMapByObjectId[article.objectId].contentFormatted = undefined;
      });
      return {
        ...state,
        articlesMapByObjectId,
        articleContent,
        articleContentFormatted,
        articlesObjectIdsMapByPermalink,
      };
    }

    case LOAD_ARTICLES: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ARTICLES]: true },
        errors: { ...state.errors, [LOAD_ARTICLES]: null },
      };
    }

    case LOAD_ARTICLES_SUCCESS: {
      const params = action.payload.params || {};
      const paginatedArticles = action.payload.paginatedArticles;
      const paginatedArticleMapByQuery = { ...state.paginatedArticleMapByQuery };
      const articleObjectIds = paginatedArticles.articles.map((article) => {
        return article.objectId;
      });
      let highlightedArticleObjectIds = [...state.highlightedArticleObjectIds];
      if (params.highlighted) {
        highlightedArticleObjectIds = articleObjectIds;
      }
      paginatedArticles.articles = articleObjectIds;

      paginatedArticleMapByQuery[bubbleUtils.string.buildSortedQueryString(params)] = {
        ...paginatedArticles,
      };

      return {
        ...state,
        highlightedArticleObjectIds,
        paginatedArticleMapByQuery,
        loading: { ...state.loading, [LOAD_ARTICLES]: false },
        errors: { ...state.errors, [LOAD_ARTICLES]: null },
      };
    }
    case LOAD_ARTICLES_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ARTICLES]: false },
        errors: { ...state.errors, [LOAD_ARTICLES]: action.payload },
      };
    }

    case LOAD_ARTICLE: {
      return {
        ...state,
        articleContent: null,
        loading: { ...state.loading, [LOAD_ARTICLE]: true },
        errors: { ...state.errors, [LOAD_ARTICLE]: null },
      };
    }

    case LOAD_ARTICLE_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ARTICLE]: false },
        errors: { ...state.errors, [LOAD_ARTICLE]: null },
      };
    }
    case LOAD_ARTICLE_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ARTICLE]: false },
        errors: { ...state.errors, [LOAD_ARTICLE]: action.payload },
      };
    }

    case LOAD_HOME_ARTICLES: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_HOME_ARTICLES]: true },
        errors: { ...state.errors, [LOAD_HOME_ARTICLES]: null },
      };
    }
    case LOAD_HOME_ARTICLES_SUCCESS: {
      const { category = 'all', sections } = action.payload;
      const homeArticlesMapByCategory = { ...state.homeArticlesMapByCategory };
      homeArticlesMapByCategory[category] = {};
      Object.keys(sections).forEach((key) => {
        homeArticlesMapByCategory[category][key] = sections[key].map((article) => article.objectId);
      });
      return {
        ...state,
        homeArticlesMapByCategory,
        loading: { ...state.loading, [LOAD_HOME_ARTICLES]: false },
        errors: { ...state.errors, [LOAD_HOME_ARTICLES]: null },
      };
    }
    case LOAD_HOME_ARTICLES_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_HOME_ARTICLES]: false },
        errors: { ...state.errors, [LOAD_HOME_ARTICLES]: action.payload },
      };
    }

    case LOAD_ARTICLE_RESOURCES: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ARTICLE_RESOURCES]: true },
        errors: { ...state.errors, [LOAD_ARTICLE_RESOURCES]: null },
      };
    }
    case LOAD_ARTICLE_RESOURCES_SUCCESS: {
      const resourcesObjectIdsMap = Object.assign({}, state.resourcesObjectIdsMap);
      resourcesObjectIdsMap[action.payload.articleObjectId] = action.payload.resources;
      return {
        ...state,
        resourcesObjectIdsMap: resourcesObjectIdsMap,
        loading: { ...state.loading, [LOAD_ARTICLE_RESOURCES]: false },
        errors: { ...state.errors, [LOAD_ARTICLE_RESOURCES]: null },
      };
    }
    case LOAD_ARTICLE_RESOURCES_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ARTICLE_RESOURCES]: false },
        errors: { ...state.errors, [LOAD_ARTICLE_RESOURCES]: action.payload },
      };
    }

    case LOAD_ARTICLES_FOR_RESOURCES: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ARTICLES_FOR_RESOURCES]: true },
        errors: { ...state.errors, [LOAD_ARTICLES_FOR_RESOURCES]: null },
      };
    }
    case LOAD_ARTICLES_FOR_RESOURCES_SUCCESS: {
      const newArticleObjectIds = action.payload.articles.map((article) => article.objectId);
      return {
        ...state,
        articlesByResourceId: {
          ...state.articlesByResourceId,
          [action.payload.resourceObjectId]: newArticleObjectIds,
        },
        loading: { ...state.loading, [LOAD_ARTICLES_FOR_RESOURCES]: false },
        errors: { ...state.errors, [LOAD_ARTICLES_FOR_RESOURCES]: null },
      };
    }
    case LOAD_ARTICLES_FOR_RESOURCES_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ARTICLES_FOR_RESOURCES]: false },
        errors: { ...state.errors, [LOAD_ARTICLES_FOR_RESOURCES]: action.payload },
      };
    }

    case LOAD_RELATED_ARTICLES: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_RELATED_ARTICLES]: true },
        errors: { ...state.errors, [LOAD_RELATED_ARTICLES]: null },
      };
    }
    case LOAD_RELATED_ARTICLES_SUCCESS: {
      const relatedArticlesMapByPermalink = {
        ...state.relatedArticlesMapByPermalink,
        [action.payload.articleObjectId]: action.payload.articles.map(
          (article) => article.permalink,
        ),
      };

      return {
        ...state,
        relatedArticlesMapByPermalink,
        loading: { ...state.loading, [LOAD_RELATED_ARTICLES]: false },
        errors: { ...state.errors, [LOAD_RELATED_ARTICLES]: null },
      };
    }
    case LOAD_RELATED_ARTICLES_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_RELATED_ARTICLES]: false },
        errors: { ...state.errors, [LOAD_RELATED_ARTICLES]: action.payload },
      };
    }

    default: {
      return state;
    }
  }
}

// storeArticles ====================================================

export function storeArticles(articles) {
  return {
    type: STORE_ARTICLES,
    payload: {
      articles,
    },
  };
}

// loadArticles ====================================================

export function loadArticles(params) {
  return {
    type: LOAD_ARTICLES,
    payload: {
      params,
    },
  };
}
export function loadArticlesSuccess(paginatedArticles, params) {
  return {
    type: LOAD_ARTICLES_SUCCESS,
    payload: {
      params,
      paginatedArticles,
    },
  };
}
export function loadArticlesFail(error) {
  return {
    type: LOAD_ARTICLES_FAIL,
    payload: new Error(error),
  };
}

// loadArticle ====================================================

export function loadArticle(articleObjectId, params) {
  return {
    type: LOAD_ARTICLE,
    payload: {
      articleObjectId,
      params,
    },
  };
}
export function loadArticleSuccess(article) {
  return {
    type: LOAD_ARTICLE_SUCCESS,
    payload: {
      article,
    },
  };
}
export function loadArticleFail(error) {
  return {
    type: LOAD_ARTICLE_FAIL,
    payload: new Error(error),
  };
}

// loadArticleResources ====================================================

export function loadArticleResources(articleObjectId) {
  return {
    type: LOAD_ARTICLE_RESOURCES,
    payload: {
      articleObjectId,
    },
  };
}
export function loadArticleResourcesSuccess(resources, articleObjectId) {
  return {
    type: LOAD_ARTICLE_RESOURCES_SUCCESS,
    payload: {
      resources,
      articleObjectId,
    },
  };
}
export function loadArticleResourcesFail(error) {
  return {
    type: LOAD_ARTICLE_RESOURCES_FAIL,
    payload: new Error(error),
  };
}

// loadArticlesForResource ====================================================

export function loadArticlesForResource(resourceObjectId) {
  return {
    type: LOAD_ARTICLES_FOR_RESOURCES,
    payload: {
      resourceObjectId,
    },
  };
}
export function loadArticlesForResourceSuccess(articles, resourceObjectId) {
  return {
    type: LOAD_ARTICLES_FOR_RESOURCES_SUCCESS,
    payload: {
      articles,
      resourceObjectId,
    },
  };
}
export function loadArticlesForResourceFail(error) {
  return {
    type: LOAD_ARTICLES_FOR_RESOURCES_FAIL,
    payload: new Error(error),
  };
}

// loadHomeArticles ====================================================

export function loadHomeArticles(category) {
  return {
    type: LOAD_HOME_ARTICLES,
    payload: {
      category,
    },
  };
}
export function loadHomeArticlesSuccess(category, sections) {
  return {
    type: LOAD_HOME_ARTICLES_SUCCESS,
    payload: {
      category,
      sections,
    },
  };
}
export function loadHomeArticlesFail(error) {
  return {
    type: LOAD_HOME_ARTICLES_FAIL,
    payload: new Error(error),
  };
}

// loadRelatedArticles ====================================================

export function loadRelatedArticles(articleObjectId) {
  return {
    type: LOAD_RELATED_ARTICLES,
    payload: {
      articleObjectId,
    },
  };
}
export function loadRelatedArticlesSuccess(articleObjectId, articles) {
  return {
    type: LOAD_RELATED_ARTICLES_SUCCESS,
    payload: {
      articleObjectId,
      articles,
    },
  };
}
export function loadRelatedArticlesFail(error) {
  return {
    type: LOAD_RELATED_ARTICLES_FAIL,
    payload: new Error(error),
  };
}

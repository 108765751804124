import bubbleConfig from 'bubble-config';
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';

import { loadCrowdfundingApi } from '../services/api';

import { LOAD_CROWDFUNDING, STOP_CROWDFUNDING_LOADING } from '../reducers/crowdfundings';
import { loadCrowdfundingFail, loadCrowdfundingSuccess } from '../reducers/crowdfundings';
import { getClientToken } from '../selectors';

let shouldLoadCrowdfunding = false;

function* crowdfundingLoadLoop(crowdfundingId) {
  const clientToken = yield select(getClientToken);
  while (shouldLoadCrowdfunding) {
    const response = yield call(loadCrowdfundingApi, clientToken, crowdfundingId);
    yield put(loadCrowdfundingSuccess(crowdfundingId, response));
    yield delay(bubbleConfig.crowdfunding.loadDelayInMs);
  }
}

function* stopLoadCrowdfundingFlow(action) {
  shouldLoadCrowdfunding = false;
}

function* loadCrowdfundingFlow(action) {
  try {
    if (shouldLoadCrowdfunding) return; // loop is already ongoing
    shouldLoadCrowdfunding = true;
    yield call(crowdfundingLoadLoop, action.payload.crowdfundingId);
  } catch (error) {
    console.log('saga middleware error (loadCrowdfundingFlow)', error);
    yield put(loadCrowdfundingFail(error));
  }
}

export default function* crowdfundingsWatcher() {
  yield all([
    takeEvery(LOAD_CROWDFUNDING, loadCrowdfundingFlow),
    takeEvery(STOP_CROWDFUNDING_LOADING, stopLoadCrowdfundingFlow),
  ]);
}

import React from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Cover from '@/components/Cover/Cover';
import SerieFollowBadge from '@/components/SerieFollowBadge/SerieFollowBadge';
import StarLine from '@/components/StarLine/StarLine';

import TopScores from '../TopScores';

const TopSerieCard = (props) => {
  const topItem = props.topItem || {};
  const serie = topItem.serie || {};

  return (
    <div className="bg-white rounded p-3 px-4 pt-4 position-relative">
      <TopScores
        lastPosition={topItem.lastPosition}
        position={topItem.position}
        index={topItem.position + 1}
      />

      <div className="d-flex pt-3">
        <div className="pe-4 pb-2">
          <Link to={`/${serie.permalink}/serie/${serie.objectId}`}>
            <Cover
              shadow
              rounded
              height={170}
              imageUrl={((topItem.images || {}).front || {}).smallFramed}
            />
          </Link>
        </div>

        <div className="d-flex flex-fill flex-column">
          <div className="d-flex justify-content-between">
            <Link
              className="text-black semi-bold bb-medium-large-text-size no-decoration link-to-orange"
              to={`/${serie.permalink}/serie/${serie.objectId}`}
            >
              {serie.title}
            </Link>
            <span className="d-none d-lg-block">
              <SerieFollowBadge serieObjectId={serie.objectId} />
            </span>
          </div>
          <div className="bb-s-text-size mt-lg-n2 text-secondary d-flex ">
            {(serie.authors || []).map((author, index) => (
              <React.Fragment key={author.objectId}>
                {index !== 0 && <div className="me-1">,</div>}
                <Link
                  className="no-decoration link-to-orange"
                  to={`/${author.permalink}/author/${author.objectId}`}
                >
                  {author.displayName}
                </Link>
              </React.Fragment>
            ))}
          </div>

          <p className="my-2 two-lines bb-s-text-size">{serie.descriptionShort}</p>

          <div className="d-flex justify-content-around border rounded my-2 py-2 position-relative">
            <Link
              to={`/${serie.permalink}/serie/${serie.objectId}/avis`}
              className="no-decoration link-to-orange text-black bb-s-text-size d-flex flex-column align-items-center"
            >
              <div>Note des lecteurs</div>
              <div>
                <StarLine note={serie.note} />
              </div>
              <div className="d-flex">
                <span className="fw-bold">{serie.note ? serie.note.toFixed(1) : ''}</span>
                {serie.numberOfNotes && (
                  <span className="ps-1">
                    ({serie.numberOfNotes} note{serie.numberOfNotes > 1 ? 's' : ''})
                  </span>
                )}
              </div>
            </Link>
            <hr className="d-none d-md-block v-separator my-n2 mx-0 position-absolute separator-absolute" />
            <Link
              to={`/${serie.permalink}/serie/${serie.objectId}/avis`}
              className="no-decoration link-to-orange text-black d-none d-md-flex flex-column align-items-center"
            >
              <div>Evaluer cette série</div>
              <div>
                <StarLine note={0} />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="row pt-2 flex-nowrap overflow-hidden">
        {serie.albums &&
          serie.albums.slice(0, 6).map((album, index) => (
            <Link
              to={`/${album.permalink}/album/${album.objectId}`}
              key={`thumb_${album.objectId}`}
              className="col-4 col-sm-3 col-lg-2"
            >
              <Cover fullWidth rounded shadow imageUrl={album?.images?.front.smallFramed} />
            </Link>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(TopSerieCard);

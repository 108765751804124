import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  createAvailabilityAlertApi,
  deleteAvailabilityAlertApi,
  loadAvailabilityAlertsApi,
} from '../services/api';

import {
  CREATE_AVAILABILITY_ALERT,
  DELETE_AVAILABILITY_ALERT,
  LOAD_AVAILABILITY_ALERTS,
} from '../reducers/availability-alerts';
import {
  createAvailabilityAlertFail,
  createAvailabilityAlertSuccess,
  deleteAvailabilityAlertFail,
  deleteAvailabilityAlertSuccess,
  loadAvailabilityAlertsFail,
  loadAvailabilityAlertsSuccess,
} from '../reducers/availability-alerts';
import { getClientToken, getGuestId } from '../selectors';
import { storeAlbumsFlow } from './albums';

function* loadAvailabilityAlertsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      loadAvailabilityAlertsApi,
      clientToken,
      action.payload.userObjectId,
    );
    yield call(storeAlbumsFlow, {
      payload: {
        albums: response.map((alert) => alert.album),
        source: 'loadAvailabilityAlertsFlow',
      },
    });
    yield put(loadAvailabilityAlertsSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadAvailabilityAlertsFlow)', error);
    yield put(loadAvailabilityAlertsFail(error));
  }
}

function* createAvailabilityAlertFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const guestId = yield select(getGuestId);
    const userObjectId = action.payload.userObjectId || guestId;
    const response = yield call(
      createAvailabilityAlertApi,
      clientToken,
      userObjectId,
      action.payload.printObjectId,
    );
    yield call(storeAlbumsFlow, {
      payload: {
        albums: [response.album],
        source: 'createAvailabilityAlertFlow',
      },
    });
    yield put(createAvailabilityAlertSuccess(response));
  } catch (error) {
    console.log('saga middleware error (createAvailabilityAlertFlow)', error);
    yield put(createAvailabilityAlertFail(error));
  }
}

function* deleteAvailabilityAlertFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const userObjectId = action.payload.userObjectId || select(getGuestId);

    const response = yield call(
      deleteAvailabilityAlertApi,
      clientToken,
      userObjectId,
      action.payload.availabilityAlertObjectId,
    );
    yield call(storeAlbumsFlow, {
      payload: {
        albums: response.map((alert) => alert.album),
        source: 'deleteAvailabilityAlertFlow',
      },
    });
    yield put(loadAvailabilityAlertsSuccess(response));
    yield put(deleteAvailabilityAlertSuccess());
  } catch (error) {
    console.log('saga middleware error (deleteAvailabilityAlertFlow)', error);
    yield put(deleteAvailabilityAlertFail(error));
  }
}

export default function* agendasWatcher() {
  yield all([
    takeLatest(LOAD_AVAILABILITY_ALERTS, loadAvailabilityAlertsFlow),
    takeLatest(CREATE_AVAILABILITY_ALERT, createAvailabilityAlertFlow),
    takeLatest(DELETE_AVAILABILITY_ALERT, deleteAvailabilityAlertFlow),
  ]);
}

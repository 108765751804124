import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { loadAvailabilityAlerts } from 'bubble-reducers/src/reducers/availability-alerts';
import { loadCart } from 'bubble-reducers/src/reducers/cart';
import {
  loadFollowed,
  loadLibrary,
  loadLibraryAuthorFollow,
  loadLibraryPrintsOptions,
  loadRecommendation,
  loadSelectedAlbumPrints,
} from 'bubble-reducers/src/reducers/profiles';
import { loadPromotions } from 'bubble-reducers/src/reducers/promotions';
import { loadSeries } from 'bubble-reducers/src/reducers/series';
import { loadUserLikes } from 'bubble-reducers/src/reducers/user';

import Agenda from '@/scenes/Agenda/Agenda';
import Album from '@/scenes/Album/Album';
import AlbumReviews from '@/scenes/AlbumReviews/AlbumReviews';
import IndexAuthors from '@/scenes/AlphanumericIndexes/IndexAuthors';
import IndexSeries from '@/scenes/AlphanumericIndexes/IndexSeries';
import Author from '@/scenes/Author/Author';
// import Christmas2023 from '@/scenes/Christmas2023/Christmas2023';
import GiftCard from '@/scenes/GiftCard/GiftCard';
import Home from '@/scenes/Home/Home';
import Layout from '@/scenes/Layout/Layout';
import Listing from '@/scenes/Listing/Listing';
import Login from '@/scenes/Login/Login';
import SignupInvite from '@/scenes/Login/SignupInvite';
import Logout from '@/scenes/Logout/Logout';
import Promotions from '@/scenes/Promotions/Promotions';
import PublicOrder from '@/scenes/PublicOrder/PublicOrder';
import Review from '@/scenes/Review/Review';
import SearchSeeAll from '@/scenes/SearchSeeAll/SearchSeeAll';
import Serie from '@/scenes/Serie/Serie';
import SerieReviews from '@/scenes/SerieReviews/SerieReviews';
import Store from '@/scenes/Store/Store';
import Top from '@/scenes/Top/Top';
import TopList from '@/scenes/TopList/TopList';
import UserProfile from '@/scenes/UserProfile/UserProfile';
import ProfileRedirecter from '@/scenes/UserProfile/components/ProfileRedirecter';

import asyncComponent from '@/components/AsyncComponent/AsyncComponent';
import RedirectTo404 from '@/components/RedirectTo404/RedirectTo404';
import RequireAuth from '@/components/RequireAuth/RequireAuth';

import { GENERAL } from 'bubble-constants';
import bubbleUtils from 'bubble-utils';

// import { Dummy } from 'bubble-components';

// only pages very rarely seen are async
const AsyncAboutUs = asyncComponent(() => import('@/scenes/AboutUs/AboutUs'));
const AsyncLegal = asyncComponent(() => import('@/scenes/Legal/Legal'));
const AsyncConditionsOfSale = asyncComponent(
  () => import('@/scenes/ConditionsOfSale/ConditionsOfSale'),
);
const AsyncConditionsOfUse = asyncComponent(
  () => import('@/scenes/ConditionsOfUse/ConditionsOfUse'),
);
const AsyncReviewPublicationGuidelines = asyncComponent(
  () => import('@/scenes/ReviewPublicationGuidelines/ReviewPublicationGuidelines'),
);
const AsyncPrivacyNotice = asyncComponent(() => import('@/scenes/PrivacyNotice/PrivacyNotice'));
const AsyncCookies = asyncComponent(() => import('@/scenes/Cookies/Cookies'));
const AsyncNotFound = asyncComponent(() => import('@/scenes/NotFound/NotFound'));
const AsyncCheckoutLoading = asyncComponent(() => import('@/scenes/Checkout/CheckoutLoading'));
const AsyncCheckout = asyncComponent(() => import('@/scenes/Checkout/Checkout'));
const AsyncCheckoutDone = asyncComponent(() => import('@/scenes/Checkout/CheckoutDone'));
const AsyncCart = asyncComponent(() => import('@/scenes/Cart/Cart'));

const AsyncInfinityLanding = asyncComponent(
  () => import('@/scenes/InfinityLanding/InfinityLanding'),
);
const AsyncMobileAppLanding = asyncComponent(
  () => import('@/scenes/MobileAppLanding/MobileAppLanding'),
);
const AsyncBooksellersMap = asyncComponent(() => import('@/scenes/BooksellersMap/BooksellersMap'));

const AsyncAccount = asyncComponent(() => import('@/scenes/Account/Account'));
const AsyncPasswordUpdate = asyncComponent(
  () => import('@/scenes/PasswordUpdateFromMail/PasswordUpdateFromMail'),
);
const AsyncCategoryLanding = asyncComponent(
  () => import('@/scenes/CategoryLanding/CategoryLanding'),
);
const AsyncReferralLanding = asyncComponent(
  () => import('@/scenes/ReferralLanding/ReferralLanding'),
);
const AsyncRewardProgramLanding = asyncComponent(
  () => import('@/scenes/RewardProgramLanding/RewardProgramLanding'),
);
const AsyncEcommerceHelp = asyncComponent(() => import('@/scenes/EcommerceHelp/EcommerceHelp'));
const AsyncAddAlbum = asyncComponent(() => import('@/scenes/AddAlbum/AddAlbum'));
const AsyncB2bPartnership = asyncComponent(() => import('@/scenes/B2bPartnership/B2bPartnership'));
const AsyncMyAvailabilityAlert = asyncComponent(
  () => import('@/scenes/MyAvailabilityAlert/MyAvailabilityAlert'),
);

const AsyncNewsletterOptionsFromEmail = asyncComponent(
  () => import('@/scenes/NewsletterOptionsFromEmail/NewsletterOptionsFromEmail'),
);

const AppBubble = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const userObjectId = user?.objectId;

  useEffect(() => {
    // Here, like loadWishlist, it might not be necessary to load
    // originalEditions, signed, loaned, memos, selectedAlbumPrints, since everything is already in library.
    // TODO: test and cleanup
    dispatch(loadCart());
    dispatch(loadRecommendation(userObjectId || null));
    dispatch(loadSeries({ is_top_rated: 1 }));
    dispatch(loadPromotions());
    if (userObjectId) {
      dispatch(loadLibrary(userObjectId));
      dispatch(loadLibraryPrintsOptions(userObjectId));
      dispatch(loadFollowed(userObjectId));
      dispatch(loadSelectedAlbumPrints(userObjectId));
      dispatch(loadUserLikes(userObjectId));
      dispatch(loadLibraryAuthorFollow(userObjectId));
      dispatch(loadAvailabilityAlerts(userObjectId));
    }
  }, [userObjectId]);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        {['/serie/:serieObjectId', '/:permalink/serie/:serieObjectId'].map((path) => (
          <Route key={path} path={path} element={<Serie />} />
        ))}
        {['/serie/:serieObjectId/avis', '/:permalink/serie/:serieObjectId/avis'].map((path) => (
          <Route key={path} path={path} element={<SerieReviews />} />
        ))}
        <Route
          path={'/:permalink/serie/:serieObjectId/avis/:reviewObjectId'}
          element={<Review />}
        />
        {['/album/:albumObjectId/avis', '/:permalink/album/:albumObjectId/avis'].map((path) => (
          <Route key={path} path={path} element={<AlbumReviews />} />
        ))}
        <Route
          path={'/:permalink/album/:albumObjectId/avis/:reviewObjectId'}
          element={<Review />}
        />
        {[
          '/album/:albumObjectId',
          '/album/:albumObjectId/:printObjectId',
          '/:permalink/album/:albumObjectId',
          '/:permalink/album/:albumObjectId/:printObjectId',
        ].map((path) => (
          <Route key={path} path={path} element={<Album />} />
        ))}
        {['/author/:authorObjectId/*', '/:permalink/author/:authorObjectId/*'].map((path) => (
          <Route key={path} path={path} element={<Author />} />
        ))}
        {['/store/:storeObjectId/*', '/:permalink/store/:storeObjectId/*'].map((path) => (
          <Route key={path} path={path} element={<Store />} />
        ))}
        <Route path="/:permalink/product/gift-card/:productObjectId" element={<GiftCard />} />
        {['/tops/:topName', '/:permalink/tops/:topName'].map((path) => (
          <Route key={path} path={path} element={<Top />} />
        ))}
        <Route path="/cart" element={<AsyncCart />} />
        <Route path="/login" element={<Login />} />
        <Route path="/invite" element={<SignupInvite />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/password-update" element={<AsyncPasswordUpdate />} />
        <Route path="/tops" element={<TopList />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/list" element={<Listing />} />
        <Route path="/search" element={<SearchSeeAll />} />
        <Route
          path="/my-account/*"
          element={
            <RequireAuth>
              <AsyncAccount />
            </RequireAuth>
          }
        />
        {[
          '/user/ma-collection',
          '/user/mes-albums-manquants',
          '/user/ma-wishlist',
          '/user/ma-wishlist ',
          '/user/ma-wishlist%20',
          '/user/mon-agenda',
          '/user/mes-statistiques',
          '/user/',
        ].map((path) => (
          <Route key={path} path={path} element={<ProfileRedirecter />} />
        ))}
        <Route path="/user/*" element={<UserProfile />} />
        <Route path="/order/:orderObjectId" element={<PublicOrder />} />
        <Route path="/checkout" element={<AsyncCheckoutLoading />} />
        <Route path="/checkout/:orderObjectId" element={<AsyncCheckout />} />
        <Route path="/checkout/:orderObjectId/done" element={<AsyncCheckoutDone />} />
        <Route path="/qui-sommes-nous" element={<AsyncAboutUs />} />
        <Route path="/infinity" element={<AsyncInfinityLanding />} />
        <Route path="/mobile-app" element={<AsyncMobileAppLanding />} />
        <Route path="/cartes-des-librairies-partenaires" element={<AsyncBooksellersMap />} />
        <Route path="/mentions-legales" element={<AsyncLegal />} />
        <Route
          path="/conditions-generales-de-vente-ecommerce"
          element={<AsyncConditionsOfSale />}
        />
        <Route
          path="/conditions-generales-de-vente-et-d-utilisation-marketplace"
          element={<AsyncConditionsOfUse />}
        />
        <Route path="/protection-de-vos-donnees-personnelles" element={<AsyncPrivacyNotice />} />
        <Route
          path="/charte-de-publication-d'avis"
          element={<AsyncReviewPublicationGuidelines />}
        />
        <Route path="/cookies" element={<AsyncCookies />} />
        <Route path="/fan-de-comics" element={<AsyncCategoryLanding category={GENERAL.COMICS} />} />
        <Route path="/fan-de-mangas" element={<AsyncCategoryLanding category={GENERAL.MANGAS} />} />
        <Route path="/fan-de-bd" element={<AsyncCategoryLanding category={GENERAL.BD} />} />
        <Route path="/aide-ecommerce/*" element={<AsyncEcommerceHelp />} />
        <Route path="/invite-landing" element={<AsyncReferralLanding />} />
        <Route path="/ajouter-un-album" element={<AsyncAddAlbum />} />
        <Route path="/programme-de-fidelite" element={<AsyncRewardProgramLanding />} />
        <Route path="/promotions" element={<Promotions />} />
        <Route path="/devenir-partenaire-libraire" element={<AsyncB2bPartnership />} />
        <Route path="/series/:pageIndex" element={<IndexSeries />} />
        <Route path="/auteurs/:pageIndex" element={<IndexAuthors />} />
        <Route path="/gerer-mes-alertes" element={<AsyncNewsletterOptionsFromEmail />} />
        {/* <Route path="/noel/*" element={<Christmas2023 />} /> */}
        <Route
          path="/mes-alertes/:availabilityAlertObjectId"
          element={<AsyncMyAvailabilityAlert />}
        />
        <Route path="/404" element={<AsyncNotFound />} />
        <Route
          path="*"
          element={bubbleUtils.env.isServer ? <AsyncNotFound /> : <RedirectTo404 />}
        />
      </Routes>
    </Layout>
  );
};

export default AppBubble;

import React from 'react';

import { formatDateWithFormat } from 'bubble-utils/src/date-utils';

import ArticleSectionBadge from '@/components/ArticleSectionBadge/ArticleSectionBadge';
import ShareZone from '@/components/ShareZone/ShareZone';

export default function ArticleContentHeader({ article }) {
  return (
    <>
      <div className="pb-4 d-flex flex-column align-items-center align-items-md-start">
        <div className="d-flex w-100 justify-content-center justify-content-md-between">
          <div className="pe-2 d-block d-md-flex">
            <div className="d-flex flex-row flex-wrap">
              <ArticleSectionBadge categories={[article.category]} tag={article.section} />
              {article.isNartBlog ? <ArticleSectionBadge title="Blog" color="blog" /> : null}
              {article.isFromNart ? (
                <ArticleSectionBadge title="Archive 9ᵉArt" color="from-9eart" />
              ) : null}
            </div>
            <div className="d-none d-md-block ps-2 text-nowrap">
              par <span className="fw-bold">{article.author}</span> - le{' '}
              {formatDateWithFormat(article.publicationDate, {
                day: 'numeric',
                month: '2-digit',
                year: 'numeric',
              })}
            </div>
          </div>
          <div className="up-2">
            <ShareZone title="Partager" body={article.title} />
          </div>
        </div>

        <div className="d-block d-md-none">
          par <span className="fw-bold">{article.author}</span> - le{' '}
          {formatDateWithFormat(article.publicationDate, {
            day: 'numeric',
            month: '2-digit',
            year: 'numeric',
          })}
        </div>
      </div>
      <h1 className="h2 fw-bold pb-4">{article.title}</h1>
      {!!article?.excerpt && <h2 className="fw-bold article-headline">{article.excerpt}</h2>}
    </>
  );
}

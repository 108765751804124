import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware, { END } from 'redux-saga';

// Import the index reducer and sagas
import reducers from './reducers';
import sagas from './sagas';

import bubbleUtils from 'bubble-utils';

const createAppStore = (url = '/') => {
  // Do we have preloaded state available? Great, save it.
  const initialState = !bubbleUtils.env.isServer ? window.__PRELOADED_STATE__ : {};

  // Delete the state once we have it stored in a variable and update the location
  if (!bubbleUtils.env.isServer) {
    delete window.__PRELOADED_STATE__;
    if (
      window &&
      window.location &&
      window.location.hash &&
      initialState &&
      initialState.router &&
      initialState.router.location
    ) {
      // Affilae initialization must be done in the index.html because the hash fragment `#ae1` is not
      // sent to the server (this is form the hash fragment spec, it is not meant to be used only on
      // server side) resulting in a route initialized in the store wihtout the hash fragment, then when
      // react re-executes locally the route does not contain the hash fragment anymore.
      initialState.router.location.hash = window.location.hash;
    }
  }

  // Setup the middleware to watch between the Reducers and the Actions
  const sagaMiddleware = createSagaMiddleware();

  // automatically set up the Redux DevTools extension connection https://redux-toolkit.js.org/api/configureStore#devtools
  const store = configureStore({
    reducer: reducers,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      // https://redux-toolkit.js.org/api/getDefaultMiddleware#development
      getDefaultMiddleware({
        // immutableCheck: { warnAfter: 256 },
        immutableCheck: false,
        serializableCheck: false,
      }).concat(sagaMiddleware),
  });

  // Init sagas utils
  store.runSaga = () => {
    // Avoid running twice
    if (store.saga) return;
    store.saga = sagaMiddleware.run(sagas);
  };

  store.stopSaga = async () => {
    // Avoid running twice
    if (!store.saga) return;
    store.dispatch(END);
    await store.saga.done;
    store.saga = null;
  };

  store.execSagaTasks = async (isServer, tasks) => {
    // run saga
    store.runSaga();
    // dispatch saga tasks
    tasks(store.dispatch);
    // Stop running and wait for the tasks to be done
    await store.stopSaga();
    // Re-run on client side
    if (!isServer) {
      store.runSaga();
    }
  };

  return {
    store,
  };
};

export default createAppStore;

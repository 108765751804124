import React from 'react';

import { PROJECT } from '@/project-constants';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadAlbums } from 'bubble-reducers/src/reducers/albums';
import { loadArticles } from 'bubble-reducers/src/reducers/articles';
import { loadBanners } from 'bubble-reducers/src/reducers/banners';
import { loadCategories } from 'bubble-reducers/src/reducers/categories';
import { loadGenres } from 'bubble-reducers/src/reducers/genres';
import { loadSeries } from 'bubble-reducers/src/reducers/series';

import { getSeoForListing } from '@/services/seo-utils';

import AlbumCard from '@/components/AlbumCard/AlbumCard';
import ArticleCard from '@/components/ArticleCard/ArticleCard';
import BreadCrumbs from '@/components/Breadcrumbs/BreadCrumbs';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import InfinityCard from '@/components/InfinityCard/InfinityCard';
import SerieCard from '@/components/SerieCard/SerieCard';
import ShareZone from '@/components/ShareZone/ShareZone';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';
import withRouter from '@/components/withRouter';

import ListHeader from './components/ListHeader';
import ListSelection from './components/ListSelections';

import './Listing.css';

class Listing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.loadInfos();
  }

  loadInfos = () => {
    const { loadBanners, loadAlbums, loadArticles, loadSeries, loadCategories, loadGenres } =
      this.props;
    loadArticles();
    loadBanners();
    const queryStrings = Listing.getQueryStrings(this.props.location.search);
    loadSeries({
      is_classic: 1,
      category: queryStrings.category || null,
      genre: queryStrings.genre ? decodeURIComponent(queryStrings.genre) : null,
      tags: queryStrings.tags ? decodeURIComponent(queryStrings.tags) : null,
    });
    loadSeries({
      is_top_rated: 1,
      category: queryStrings.category || null,
      genre: queryStrings.genre ? decodeURIComponent(queryStrings.genre) : null,
      tags: queryStrings.tags ? decodeURIComponent(queryStrings.tags) : null,
    });
    loadSeries({
      is_team_favorite: 1,
      category: queryStrings.category || null,
      genre: queryStrings.genre ? decodeURIComponent(queryStrings.genre) : null,
      tags: queryStrings.tags ? decodeURIComponent(queryStrings.tags) : null,
    });
    loadAlbums({
      last_published: 1,
      category: queryStrings.category || null,
      genre: queryStrings.genre ? decodeURIComponent(queryStrings.genre) : null,
      tags: queryStrings.tags ? decodeURIComponent(queryStrings.tags) : null,
    });
    loadAlbums({
      best_sellers: 1,
      category: queryStrings.category || null,
      genre: queryStrings.genre ? decodeURIComponent(queryStrings.genre) : null,
      tags: queryStrings.tags ? decodeURIComponent(queryStrings.tags) : null,
    });

    //TODO: load linked tags ?

    if (queryStrings.category && !queryStrings.genre) {
      loadCategories();
    }
    if (queryStrings.genre) {
      loadGenres();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const newQueryStrings = Listing.getQueryStrings(this.props.location.search);
    const oldQueryStrings = Listing.getQueryStrings(prevProps.location.search);
    let shouldReload = false;
    Object.keys(newQueryStrings).forEach(
      (key) => (shouldReload = newQueryStrings[key] !== oldQueryStrings[key] ? true : shouldReload),
    );

    if (shouldReload) {
      this.loadInfos(null);
    }
  }

  static getDerivedStateFromProps(nextProps, lastState) {
    return Listing.getCategoryAndGenre(nextProps);
  }

  static getQueryStrings = (search) => {
    const urlParams = new URLSearchParams(search);
    return {
      category: (urlParams.get('category') || '').toLowerCase() || null,
      genre: (urlParams.get('genre') || '').toLowerCase() || null,
      tags: (urlParams.get('tags') || '').toLowerCase() || null,
    };
  };

  static getKeyForList = (categoryString, genreString, tagsString) => {
    return (categoryString || '') + (genreString || '') + (tagsString || '') || 'all';
  };
  static getCategoryAndGenre = (props) => {
    //TODO: category as id, genre as id ? (/listing?cat=12&genre=4564)
    const queryStrings = Listing.getQueryStrings(props.location.search);
    const categoryName = (queryStrings.category || '').toLowerCase();
    const genreName = queryStrings.genre || null;
    let tags = (queryStrings.tags || '').split(',').filter((row) => row) || null;
    if (!!tags && tags.length === 0) {
      // prevents tags being []
      tags = null;
    }

    const category = {
      name: categoryName,
      text: (
        props.categories.find((category) => category.nameFrench?.toLowerCase() === categoryName) ||
        {}
      ).descriptionShort,
    };

    const genre = {
      name: genreName,
      text: (
        (props.genres.find((genre) => genre.nameFrench?.toLowerCase() === genreName) || {})[
          categoryName
        ] || {}
      ).descriptionShort,
    };
    const authorsMenu = (props.menuAuthorsObjectIds[categoryName] || []).map(
      (objectId) => props.authors[objectId],
    );
    const articles = props.articles.filter((article) =>
      article.tags.some(
        (tag) => tag?.toLowerCase() === categoryName || tag?.toLowerCase() === genreName,
      ),
    );

    const listKey = [
      PROJECT.CATEGORY_NAMES_SHORT[category.name],
      genre.name ? `"${genre.name?.trim()}"` : null,
      tags ? tags.map((tag) => `"${tag}"`).join(', ') : null,
    ]
      .filter(Boolean)
      .join(' ');

    return {
      category,
      genre,
      tags,
      authorsMenu,
      articles,
      listKey,
    };
  };

  renderAgenda = () => {
    const category = this.state.category;
    const genre = this.state.genre;
    const tags = this.state.tags;

    const lastPublishedAlbums = (
      this.props.mapOfAlbumIdsLastPublished[
        Listing.getKeyForList(category.name, genre.name, (tags || []).join(','))
      ] || []
    ).slice(0, 4);

    return !!lastPublishedAlbums.length ? (
      <TitledListContainer
        noWrap
        title={
          <>
            <div>Nouveautés et prochaines sorties {this.state.listKey}</div>
            <Link className="d-md-none fw-normal bb-regular-text-size pt-2" to="/agenda">
              Voir tout
            </Link>
          </>
        }
        topRightLink={
          <Link className="d-none d-md-block" to="/agenda">
            Voir tout
          </Link>
        }
        list={lastPublishedAlbums.map((albumObjectId) => (
          <AlbumCard
            className="col-7 col-xl-3 col-lg-3 col-md-4 pb-4"
            key={`albumcard-${albumObjectId}`}
            forcePublicationDate={true}
            albumObjectId={albumObjectId}
          />
        ))}
      />
    ) : null;
  };

  renderBestSellingAlbums = () => {
    const category = this.state.category;
    const genre = this.state.genre;
    const tags = this.state.tags;
    const bestSellersAlbums = (
      this.props.mapOfAlbumIdsBestSellers[
        Listing.getKeyForList(category.name, genre.name, (tags || []).join(','))
      ] || []
    ).slice(0, 6);

    return !!bestSellersAlbums.length ? (
      <TitledListContainer
        noWrap
        title={<h2 className="h5 m-0 p-0">Meilleures ventes {this.state.listKey}</h2>}
        list={bestSellersAlbums.slice(0, 4).map((albumObjectId) => (
          <AlbumCard
            className="col-7 col-xl-3 col-lg-3 col-md-4"
            key={`albumcard-${albumObjectId}`}
            albumObjectId={albumObjectId}
          />
        ))}
      />
    ) : null;
  };

  renderTeamFavoriteSeries = () => {
    const category = this.state.category;
    const genre = this.state.genre;
    const tags = this.state.tags;
    const teamFavoriteSeries = (
      this.props.mapOfSeriesIdsTeamFavorite[
        Listing.getKeyForList(category.name, genre.name, (tags || []).join(','))
      ] || []
    )
      .map((serieObjectId) => this.props.series[serieObjectId])
      .filter((row) => row)
      .slice(0, 6);

    return !!teamFavoriteSeries.length ? (
      <TitledListContainer
        title={
          <h2 className="h5 m-0 p-0">Coups de coeur {this.state.listKey} de la rédaction Bubble</h2>
        }
        list={teamFavoriteSeries.map((serie) => (
          <div key={`team-favorite-${serie.objectId}`} className="col-md-6 col-lg-4 mt-3">
            <SerieCard white serieObjectId={serie.objectId} />
          </div>
        ))}
      />
    ) : null;
  };

  renderClassicSeries = () => {
    const category = this.state.category;
    const genre = this.state.genre;
    const tags = this.state.tags;
    const classicSeries = (
      this.props.mapOfSerieIdsClassic[
        Listing.getKeyForList(category.name, genre.name, (tags || []).join(','))
      ] || []
    )
      .map((serieObjectId) => this.props.series[serieObjectId])
      .filter((row) => row)
      .slice(0, 6);

    return !!classicSeries.length ? (
      <TitledListContainer
        title={<h2 className="h5 m-0 p-0">Les grands classiques {this.state.listKey}</h2>}
        list={classicSeries.map((serie) => (
          <div key={`classic-${serie.objectId}`} className="col-md-6 col-lg-4 mt-3">
            <SerieCard serieObjectId={serie.objectId} />
          </div>
        ))}
      />
    ) : null;
  };

  renderTopRatedSeries = () => {
    const category = this.state.category;
    const genre = this.state.genre;
    const tags = this.state.tags;
    const topRatedSeries = (
      this.props.mapOfSerieIdsTopRated[
        Listing.getKeyForList(category.name, genre.name, (tags || []).join(','))
      ] || []
    )
      .map((serieObjectId) => this.props.series[serieObjectId])
      .filter((row) => row)
      .slice(0, 6);

    return !!topRatedSeries.length ? (
      <TitledListContainer
        title={
          <>
            <h2 className="h5 m-0 p-0">
              Séries {this.state.listKey} les mieux notées par les membres de Bubble
            </h2>
            <Link
              className="d-md-none fw-normal bb-regular-text-size pt-2"
              to="/tops/topBestNotedSeriesAllTime"
            >
              Voir le top
            </Link>
          </>
        }
        topRightLink={
          <Link className="d-none d-md-block" to="/tops/topBestNotedSeriesAllTime">
            Voir le top
          </Link>
        }
        list={topRatedSeries.map((serie) => (
          <div key={`top_rated_${serie.objectId}`} className="col-md-6 col-lg-4 mt-3">
            <SerieCard serieObjectId={serie.objectId} />
          </div>
        ))}
      />
    ) : null;
  };

  getLinkForGenre(genre) {
    const urlParams = Listing.getQueryStrings(this.props.location.search);
    return encodeURI(`/list?category=${urlParams.category}&genre=${genre.nameFrench}`);
  }

  render() {
    const category = this.state.category;
    const genre = this.state.genre;
    const tags = this.state.tags;
    const banner =
      this.props.selectedListingBannerId && this.props.banners[this.props.selectedListingBannerId]
        ? this.props.banners[this.props.selectedListingBannerId]
        : {};
    const seo = getSeoForListing(category, genre);
    return (
      <div className="bb-background-light-grey">
        <BubbleHelmet seo={seo} />
        <div className="container">
          <div className="d-flex align-items-center">
            <BreadCrumbs
              currentObject={{ listing: true, category: category.name, genre: genre.name }}
            />
            <ShareZone title="Partager cette sélection avec des amis" />
          </div>
          <div className="row px-sm-3">
            <div className="d-none d-lg-block col-md-2">
              <ListSelection
                category={category}
                genre={genre}
                tags={tags}
                authors={this.state.authorsMenu}
              />
              <div className="d-none d-lg-block">
                <InfinityCard />
              </div>
            </div>
            <div className="col-12 col-lg-10">
              <ListHeader genre={genre} tags={tags} category={category} banner={banner} />
              {this.props.genres
                .filter((g) => g[category.name?.toLowerCase()])
                .map((genreFromProps) => (
                  <Link
                    key={`all_genres_${genreFromProps.objectId}`}
                    to={this.getLinkForGenre(genreFromProps)}
                    className="me-2 link-to-orange"
                  >
                    {genreFromProps.nameFrench}
                  </Link>
                ))}

              {this.renderClassicSeries()}
              <div className="h-separator" />
              {this.renderAgenda()}
              <div className="h-separator" />
              {this.renderTopRatedSeries()}
              <div className="h-separator" />
              {this.renderTeamFavoriteSeries()}
              <div className="h-separator" />
              {this.renderBestSellingAlbums()}
            </div>
          </div>
        </div>
        <div className="nart-background-grey">
          <div className="container">
            <div className="px-sm-3">
              <TitledListContainer
                noWrap
                title={
                  <>
                    <div>
                      Suivez toute l'actualité {PROJECT.CATEGORY_NAMES_SHORT[category.name]}
                      {genre.name ? ` "${genre.name}" ` : ' '}sur notre blog
                    </div>
                    <Link className="d-md-none fw-normal bb-regular-text-size pt-2" to="/9emeart">
                      Accéder au blog Bubble
                    </Link>
                  </>
                }
                topRightLink={
                  <Link className="d-none d-md-block" to="/9emeart">
                    Accéder au blog Bubble
                  </Link>
                }
                list={(this.state.articles.length ? this.state.articles : this.props.articles)
                  .slice(0, 4)
                  .map((article) => (
                    <ArticleCard
                      className="col-10 col-md-6 col-lg-3 pt-3"
                      key={article.objectId}
                      article={article}
                    />
                  ))}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const urlParams = new URLSearchParams(props.location.search);
  const category = (urlParams.get('category') || '').toLowerCase() || null;
  return {
    series: state.series.series,
    mapOfSerieIdsClassic: state.series.mapOfSerieIdsClassic,
    mapOfSerieIdsTopRated: state.series.mapOfSerieIdsTopRated,
    mapOfSeriesIdsTeamFavorite: state.series.mapOfSeriesIdsTeamFavorite,
    mapOfAlbumIdsLastPublished: state.albums.mapOfAlbumIdsLastPublished,
    mapOfAlbumIdsBestSellers: state.albums.mapOfAlbumIdsBestSellers,
    articles: Object.values(state.articles.articlesMapByObjectId), //TODO: whole article list for no reason
    authors: state.authors.authors,
    menuAuthorsObjectIds: state.authors.menuAuthorsObjectIds,
    categories: state.categories.categories,
    genres: state.genres.genres,
    banners: state.banners.banners,
    selectedListingBannerId:
      state.banners.selectedListingBannerIdsMap[category] ||
      state.banners.selectedListingBannerIdsMap['promo'] ||
      state.banners.selectedListingBannerIdsMap['bd'],
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadBanners,
    loadAlbums,
    loadArticles,
    loadSeries,
    loadCategories,
    loadGenres,
  })(Listing),
);

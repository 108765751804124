import React from 'react';

import { connect } from 'react-redux';
import { Link, Route, Routes } from 'react-router-dom';

import { loadArticles } from 'bubble-reducers/src/reducers/articles';
import { loadStore } from 'bubble-reducers/src/reducers/stores';
import {
  addStoreToFavorite,
  loadUserFavoriteStores,
  removeStoreFromFavorite,
} from 'bubble-reducers/src/reducers/user';

import { getSeoForStore } from '@/services/seo-utils';

import ArticleCard from '@/components/ArticleCard/ArticleCard';
import asyncComponent from '@/components/AsyncComponent/AsyncComponent';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import RelatedArticles from '@/components/RelatedArticles/RelatedArticles';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';
import withRouter from '@/components/withRouter';

import StoreDetails from './components/StoreDetails';
import StoreHeader from './components/StoreHeader';
import StoreHome from './components/StoreHome';

import './Store.css';

const AsyncStoreStockSearch = asyncComponent(
  () => import('./components/StoreStockSearch/StoreStockSearch'),
);

export const tabs = {
  HOME: '/',
  EVENTS: 'evenements',
  STORE: 'notre-librairie',
};

class Store extends React.Component {
  state = { searchText: null };

  componentDidMount() {
    this.loadInfos(this.props.router?.params?.storeObjectId);

    const { user, loadUserFavoriteStores, loadArticles } = this.props;
    loadArticles({ highlighted: 1 });
    if (user && user.objectId) loadUserFavoriteStores(user.objectId);
  }

  loadInfos(info) {
    const { loadStore } = this.props;
    loadStore(info);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.router?.params?.storeObjectId !== this.props.router?.params?.storeObjectId) {
      this.loadInfos(this.props.router?.params?.storeObjectId);
    }
  }

  setSearchText = (searchText) => {
    this.setState({ searchText, isSearching: !!searchText });
  };

  render() {
    const store = this.props.store;

    return (
      <div className="bb-background-light-grey">
        {!!store.objectId && <BubbleHelmet seo={getSeoForStore(store)} />}

        <StoreHeader
          storeObjectId={store.objectId}
          searchText={this.state.searchText}
          onSearchTextChange={this.setSearchText}
        />
        {!this.state.isSearching && (
          <Routes>
            <Route path="/" element={<StoreHome storeObjectId={store.objectId} />} />
            <Route
              path={`/${tabs.STORE}`}
              element={<StoreDetails storeObjectId={store.objectId} />}
            />
          </Routes>
        )}

        {this.state.isSearching && (
          <AsyncStoreStockSearch
            searchText={this.state.searchText}
            storeObjectId={store.objectId}
          />
        )}

        <RelatedArticles white resourceObjectId={store.objectId} />

        <div className="nart-background-grey">
          <div className="container">
            <div className="px-sm-3">
              <TitledListContainer
                noWrap
                title={
                  <>
                    <div>Suivez toute l'actualité BD, Comics et Mangas sur notre blog</div>
                    <Link className="d-md-none bb-regular-text-size fw-normal y-2" to="/9emeart">
                      Accéder au blog Bubble
                    </Link>
                  </>
                }
                topRightLink={
                  <Link className="d-none d-md-block" to="/9emeart">
                    Accéder au blog Bubble
                  </Link>
                }
                list={this.props.highlightedArticleObjectIds.slice(0, 4).map((articleObjectId) => (
                  <ArticleCard
                    className="col-10 col-md-6 col-lg-3 pt-3"
                    key={`article-${articleObjectId}`}
                    article={this.props.articles[articleObjectId]}
                  />
                ))}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  store: state.stores.stores[props.router?.params?.storeObjectId] || {},
  user: state.user.user,
  isFavorite: state.user.favoriteStoresIds.includes(props.router?.params?.storeObjectId),
  highlightedArticleObjectIds: state.articles.highlightedArticleObjectIds,
  articles: state.articles.articlesMapByObjectId, //TODO: whole article list for no reason
});

export default withRouter(
  connect(mapStateToProps, {
    loadStore,
    loadUserFavoriteStores,
    addStoreToFavorite,
    removeStoreFromFavorite,
    loadArticles,
  })(Store),
);

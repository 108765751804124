export const LOAD_TOPS = 'LOAD_TOPS';
export const LOAD_TOPS_SUCCESS = 'LOAD_TOPS_SUCCESS';
export const LOAD_TOPS_FAIL = 'LOAD_TOPS_FAIL';

export const LOAD_TOP = 'LOAD_TOP';
export const LOAD_TOP_SUCCESS = 'LOAD_TOP_SUCCESS';
export const LOAD_TOP_FAIL = 'LOAD_TOP_FAIL';

const initialState = {
  topNames: [],
  tops: {},
  loadedTops: {},
  loading: false,
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_TOPS: {
      return {
        ...state,
        loading: true,
        topNames: [],
      };
    }
    case LOAD_TOPS_SUCCESS: {
      let loadedTops = Object.assign({}, state.loadedTops);
      const tops = action.payload.tops;
      const topNames = tops.map((top) => top.name);
      let newTops = {};
      topNames.forEach((name) => {
        const associatedTop = tops.find((top) => top.name === name);
        if (action.payload.withData) {
          loadedTops[name] = (associatedTop.albums || associatedTop.series).slice();
          delete associatedTop.series;
          delete associatedTop.albums;
        }
        newTops[name] = associatedTop;
      });

      return {
        ...state,
        loading: false,
        topNames: topNames,
        tops: newTops,
        loadedTops,
        errors: [],
      };
    }
    case LOAD_TOPS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LOAD_TOP: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_TOP_SUCCESS: {
      const top = action.payload.top;
      let loadedTops = Object.assign({}, state.loadedTops);
      loadedTops[action.payload.topName] = top;
      return {
        ...state,
        loading: false,
        loadedTops: loadedTops,
        errors: [],
      };
    }
    case LOAD_TOP_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}

// loadTops ====================================================

export function loadTops(withData = false) {
  return {
    type: LOAD_TOPS,
    payload: {
      withData,
    },
  };
}
export function loadTopsSuccess(tops, withData) {
  return {
    type: LOAD_TOPS_SUCCESS,
    payload: {
      tops,
      withData,
    },
  };
}
export function loadTopsFail(error) {
  return {
    type: LOAD_TOPS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadTop ====================================================

export function loadTop(topName) {
  return {
    type: LOAD_TOP,
    payload: { topName },
  };
}
export function loadTopSuccess(top, topName) {
  return {
    type: LOAD_TOP_SUCCESS,
    payload: {
      top,
      topName,
    },
  };
}
export function loadTopFail(error) {
  return {
    type: LOAD_TOP_FAIL,
    payload: new Error(error),
    error: true,
  };
}

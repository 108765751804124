import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { buyIapApi, loadIapsApi, updateIapPaymentIntentApi } from '../services/api';

import {
  BUY_IAP,
  BUY_IAP_WITH_NEW_CARD,
  LOAD_IAPS,
  UPDATE_IAP_PAYMENT_INTENT,
} from '../reducers/in-app-purchases';
import {
  buyIapFail,
  buyIapSuccess,
  buyIapWithNewCardFail,
  buyIapWithNewCardSuccess,
  loadIapsFail,
  loadIapsSuccess,
  updateIapPaymentIntentFail,
  updateIapPaymentIntentSuccess,
} from '../reducers/in-app-purchases';
import { updatePaymentMethodFlow } from '../sagas/payment-methods';
import { loadUserFlow } from '../sagas/user';
import { getClientToken } from '../selectors';

import { GENERAL } from 'bubble-constants';

function* loadIapsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadIapsApi, clientToken);
    yield put(loadIapsSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadIapsFlow)', error);
    yield put(loadIapsFail(error));
  }
}

function* buyIapFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      buyIapApi,
      clientToken,
      action.payload.userObjectId,
      action.payload.productId,
    );
    yield call(loadUserFlow, { payload: { userObjectId: action.payload.userObjectId } });
    yield put(buyIapSuccess(response));
  } catch (error) {
    console.log('saga middleware error (buyIapFlow)', error);
    yield put(buyIapFail(error));
  }
}

function* buyIapWithNewCardFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const { userObjectId, productId, paymentMethodId } = action.payload;
    yield call(updatePaymentMethodFlow, {
      payload: {
        userObjectId,
        paymentMethod: {
          action: GENERAL.PAYMENT_METHOD_ACTIONS.ADD,
          paymentMethodId: paymentMethodId,
        },
      },
    });

    const response = yield call(buyIapApi, clientToken, userObjectId, productId);
    yield call(loadUserFlow, { payload: { userObjectId } });
    yield put(buyIapSuccess(response));
    yield put(buyIapWithNewCardSuccess(response));
  } catch (error) {
    console.log('saga middleware error (buyIapWithNewCardFlow)', error);
    yield put(buyIapWithNewCardFail(error));
  }
}

function* updateIapPaymentIntentFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const { userObjectId, productId, paymentIntentId } = action.payload;

    const response = yield call(
      updateIapPaymentIntentApi,
      clientToken,
      userObjectId,
      productId,
      paymentIntentId,
    );

    if (response.success || response.paymentIntentStatus === 'succeeded') {
      yield call(loadUserFlow, { payload: { userObjectId } });
    }
    yield put(updateIapPaymentIntentSuccess(response));
  } catch (error) {
    console.log('saga middleware error (updateIapPaymentIntentFlow)', error);
    yield put(updateIapPaymentIntentFail(error));
  }
}

export default function* inAppPurchasesWatcher() {
  yield all([
    takeLatest(LOAD_IAPS, loadIapsFlow),
    takeEvery(BUY_IAP, buyIapFlow),
    takeEvery(BUY_IAP_WITH_NEW_CARD, buyIapWithNewCardFlow),
    takeEvery(UPDATE_IAP_PAYMENT_INTENT, updateIapPaymentIntentFlow),
  ]);
}

export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

export const LOAD_ORDER = 'LOAD_ORDER';
export const LOAD_ORDER_SUCCESS = 'LOAD_ORDER_SUCCESS';
export const LOAD_ORDER_FAIL = 'LOAD_ORDER_FAIL';

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';

export const CREATE_ADDRESS_FOR_ORDER = 'CREATE_ADDRESS_FOR_ORDER';
export const CREATE_ADDRESS_FOR_ORDER_SUCCESS = 'CREATE_ADDRESS_FOR_ORDER_SUCCESS';
export const CREATE_ADDRESS_FOR_ORDER_FAIL = 'CREATE_ADDRESS_FOR_ORDER_FAIL';

export const CREATE_CARD_FOR_ORDER = 'CREATE_CARD_FOR_ORDER';
export const CREATE_CARD_FOR_ORDER_SUCCESS = 'CREATE_CARD_FOR_ORDER_SUCCESS';
export const CREATE_CARD_FOR_ORDER_FAIL = 'CREATE_CARD_FOR_ORDER_FAIL';

export const RESET_ORDERS_REDUCER = 'RESET_ORDERS_REDUCER';

const initialState = {
  order: null,
  loading: {},
  errors: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_ORDER: {
      return {
        ...state,
        order: initialState.order,
        loading: { ...state.loading, [CREATE_ORDER]: true },
        errors: { ...state.errors, [CREATE_ORDER]: null },
      };
    }
    case CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        order: action.payload.order,
        loading: { ...state.loading, [CREATE_ORDER]: false },
        errors: { ...state.errors, [CREATE_ORDER]: null },
      };
    }
    case CREATE_ORDER_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [CREATE_ORDER]: false },
        errors: { ...state.errors, [CREATE_ORDER]: action.payload },
      };
    }

    case CREATE_ADDRESS_FOR_ORDER: {
      return {
        ...state,
        loading: { ...state.loading, [CREATE_ADDRESS_FOR_ORDER]: true },
        errors: { ...state.errors, [CREATE_ADDRESS_FOR_ORDER]: null },
      };
    }
    case CREATE_ADDRESS_FOR_ORDER_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, [CREATE_ADDRESS_FOR_ORDER]: false },
        errors: { ...state.errors, [CREATE_ADDRESS_FOR_ORDER]: null },
      };
    }
    case CREATE_ADDRESS_FOR_ORDER_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [CREATE_ADDRESS_FOR_ORDER]: false },
        errors: { ...state.errors, [CREATE_ADDRESS_FOR_ORDER]: action.payload },
      };
    }

    case CREATE_CARD_FOR_ORDER: {
      return {
        ...state,
        loading: { ...state.loading, [CREATE_CARD_FOR_ORDER]: true },
        errors: { ...state.errors, [CREATE_CARD_FOR_ORDER]: null },
      };
    }
    case CREATE_CARD_FOR_ORDER_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, [CREATE_CARD_FOR_ORDER]: false },
        errors: { ...state.errors, [CREATE_CARD_FOR_ORDER]: null },
      };
    }
    case CREATE_CARD_FOR_ORDER_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [CREATE_CARD_FOR_ORDER]: false },
        errors: { ...state.errors, [CREATE_CARD_FOR_ORDER]: action.payload },
      };
    }

    case LOAD_ORDER: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ORDER]: true },
        errors: { ...state.errors, [LOAD_ORDER]: null },
      };
    }
    case LOAD_ORDER_SUCCESS: {
      return {
        ...state,
        order: action.payload.order,
        loading: { ...state.loading, [LOAD_ORDER]: false },
        errors: { ...state.errors, [LOAD_ORDER]: null },
      };
    }
    case LOAD_ORDER_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ORDER]: false },
        errors: { ...state.errors, [LOAD_ORDER]: action.payload },
      };
    }

    case UPDATE_ORDER: {
      return {
        ...state,
        loading: {
          ...state.loading,
          [UPDATE_ORDER]: action.payload?.options?.shouldNotDisplayLoading ? false : true,
        },
        errors: { ...state.errors, [UPDATE_ORDER]: null },
      };
    }
    case UPDATE_ORDER_SUCCESS: {
      if (action.payload.order.error && action.payload.order.error.code < 500) {
        return {
          ...state,
          loading: { ...state.loading, [UPDATE_ORDER]: false },
          errors: { ...state.errors, [UPDATE_ORDER]: null },
        };
      }

      return {
        ...state,
        order: action.payload.order,
        loading: { ...state.loading, [UPDATE_ORDER]: false },
        errors: { ...state.errors, [UPDATE_ORDER]: null },
      };
    }
    case UPDATE_ORDER_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_ORDER]: false },
        errors: { ...state.errors, [UPDATE_ORDER]: action.payload },
      };
    }

    case RESET_ORDERS_REDUCER: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}

// resetOrdersReducer ====================================================

export function resetOrdersReducer() {
  return {
    type: RESET_ORDERS_REDUCER,
  };
}

// createOrder ====================================================

export function createOrder(params) {
  return {
    type: CREATE_ORDER,
    payload: { params },
  };
}
export function createOrderSuccess(order) {
  return {
    type: CREATE_ORDER_SUCCESS,
    payload: {
      order,
    },
  };
}
export function createOrderFail(error) {
  return {
    type: CREATE_ORDER_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadOrder ====================================================

export function loadOrder(orderObjectId) {
  return {
    type: LOAD_ORDER,
    payload: { orderObjectId },
  };
}
export function loadOrderSuccess(order) {
  return {
    type: LOAD_ORDER_SUCCESS,
    payload: {
      order,
    },
  };
}
export function loadOrderFail(error) {
  return {
    type: LOAD_ORDER_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// updateOrder ====================================================

export function updateOrder(orderObjectId, params, options) {
  return {
    type: UPDATE_ORDER,
    payload: { orderObjectId, params, options },
  };
}
export function updateOrderSuccess(order) {
  return {
    type: UPDATE_ORDER_SUCCESS,
    payload: {
      order,
    },
  };
}
export function updateOrderFail(error) {
  return {
    type: UPDATE_ORDER_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// createAddressForOrder ====================================================

export function createAddressForOrder(userObjectId, address, orderObjectId) {
  return {
    type: CREATE_ADDRESS_FOR_ORDER,
    payload: { userObjectId, address, orderObjectId },
  };
}
export function createAddressForOrderSuccess(address) {
  return {
    type: CREATE_ADDRESS_FOR_ORDER_SUCCESS,
    payload: {
      order: address,
    },
  };
}
export function createAddressForOrderFail(error) {
  return {
    type: CREATE_ADDRESS_FOR_ORDER_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// createCardForOrder ====================================================

export function createCardForOrder(userObjectId, paymentMethodId, orderObjectId) {
  return {
    type: CREATE_CARD_FOR_ORDER,
    payload: { userObjectId, paymentMethodId, orderObjectId },
  };
}
export function createCardForOrderSuccess() {
  return {
    type: CREATE_CARD_FOR_ORDER_SUCCESS,
  };
}
export function createCardForOrderFail(error) {
  return {
    type: CREATE_CARD_FOR_ORDER_FAIL,
    payload: { ...error, where: 'card_creation' },
    error: true,
  };
}

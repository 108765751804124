import React, { useEffect, useRef } from 'react';

import { PROJECT } from '@/project-constants';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadAgendas } from 'bubble-reducers/src/reducers/agendas';
import { loadHomeArticles } from 'bubble-reducers/src/reducers/articles';
import { getAgendaAlbums, getArticlesForHomeCategory } from 'bubble-reducers/src/selectors';

import {
  getSeoForDefaultNArt,
  getSeoForNArtDefault,
  getSeoForPaperBackstage,
  getSeoForPaperCantMiss,
  getSeoForPaperEyeOnReleases,
  getSeoForPaperKnowAll,
  getSeoForWhatsNew,
} from '@/services/seo-utils';

import NArtAlbumCardLight from '@/scenes/NArt/components/NArtAlbumCard/NArtAlbumCardLight';

import ArticleCard from '@/components/ArticleCard/ArticleCard';
import ArticleHorizontalCardWhite from '@/components/ArticleHorizontalCardWhite/ArticleHorizontalCardWhite';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

import ArticleBriefCard from '../../components/ArticleBriefCard/ArticleBriefCard';
import ArticleFocusSection from '../../components/ArticleFocusSection/ArticleFocusSection';
import NArtNewsletterOptin from '../../components/NArtNewsletterOptin/NArtNewsletterOptin';
import NArtHomeHeader from './components/NArtHomeHeader';

import '../../NArt.scss';

const idToSeoFunction = {
  default: getSeoForNArtDefault,
  608491395: getSeoForWhatsNew,
  608487237: getSeoForPaperKnowAll,
  608487239: getSeoForPaperEyeOnReleases,
  174725: getSeoForPaperBackstage,
  1: getSeoForPaperCantMiss,
};

const NArtHome = ({ nArtCategory }) => {
  const dispatch = useDispatch();
  const topRef = useRef(null);
  const seoSpecific = idToSeoFunction[nArtCategory] || getSeoForDefaultNArt();

  const sectionCritiques =
    useSelector((state) =>
      getArticlesForHomeCategory(state, {
        category: nArtCategory,
        section: PROJECT.PAPER_SECTIONS_ID_MAP['critiques'],
      }),
    ) || [];
  const sectionActualites =
    useSelector((state) =>
      getArticlesForHomeCategory(state, {
        category: nArtCategory,
        section: PROJECT.PAPER_SECTIONS_ID_MAP['actualites'],
      }),
    ) || [];
  const sectionEdito =
    useSelector((state) =>
      getArticlesForHomeCategory(state, {
        category: nArtCategory,
        section: PROJECT.PAPER_SECTIONS_ID_MAP['edito'],
      }),
    ) || [];
  const sectionIncontournables =
    useSelector((state) =>
      getArticlesForHomeCategory(state, {
        category: nArtCategory,
        section: PROJECT.PAPER_SECTIONS_ID_MAP['incontournables'],
      }),
    ) || [];
  const sectionEnVideo =
    useSelector((state) =>
      getArticlesForHomeCategory(state, {
        category: nArtCategory,
        section: PROJECT.PAPER_SECTIONS_ID_MAP['en-video'],
      }),
    ) || [];

  const agendaAlbums = useSelector((state) => getAgendaAlbums(state, nArtCategory, true));
  useEffect(() => {
    dispatch(loadAgendas());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadHomeArticles(nArtCategory));
  }, [nArtCategory, dispatch]);

  const nextPathRoot = ['/9emeart', nArtCategory].filter((row) => row).join('/');

  return (
    <>
      <BubbleHelmet seo={seoSpecific.current} />
      <NArtHomeHeader />
      <div className={nArtCategory ? 'nart-bg-highlight' : 'nart-background-grey'}>
        <div className="px-4 px-lg-paper">
          <span ref={topRef}>
            <TitledListContainer
              topRightLink={
                <Link className={nArtCategory ? 'text-white' : ''} to={`${nextPathRoot}/critiques`}>
                  Voir toutes les critiques
                </Link>
              }
              rightLinkWrapToBottomThreshold="md"
              titleClassName="h2 fw-bold pb-2"
              noWrap
              title="Dernières critiques"
              list={sectionCritiques.slice(0, 3).map((article) => (
                <ArticleCard
                  className="col-9 col-md-4 pb-md-5 pb-md-0 d-inline-block d-md-flex"
                  key={`article-${article.objectId}`}
                  article={article}
                />
              ))}
            />
          </span>
        </div>
      </div>
      <div className="bb-background-light-grey px-4 px-lg-paper">
        <div className="row">
          <div className="col-12 col-lg-8">
            <TitledListContainer
              className="py-5 overflow-scroll"
              title="Éditorial"
              noWrap
              rightLinkWrapToBottomThreshold="md"
              topRightLink={<Link to={`${nextPathRoot}/edito`}>Voir tous les éditoriaux</Link>}
              titleClassName="h2 fw-bold pb-2"
              list={sectionEdito.slice(0, 4)?.map((article) => (
                <ArticleCard
                  className="padding-first-two col-9 col-md-6 pb-md-5 pb-md-0 d-inline-block d-md-flex"
                  key={`article-${article.objectId}`}
                  article={article}
                />
              ))}
            />
          </div>
          <div className="col-12 col-lg-4">
            <div className="d-flex pt-5 align-items-center justify-content-between pb-2">
              <div className="d-flex mb-n1">
                <div className="nart-bg-highlight nart-colored-macaron rounded me-3" />
                <div className="h2 fw-bold">Actualités</div>
              </div>
              <Link to={`${nextPathRoot}/actualites`}>Voir tout</Link>
            </div>
            <ArticleBriefCard articleObjectId={sectionActualites[0]?.objectId} />
            <ArticleBriefCard articleObjectId={sectionActualites[1]?.objectId} />
            <ArticleBriefCard articleObjectId={sectionActualites[2]?.objectId} />
            <ArticleBriefCard articleObjectId={sectionActualites[3]?.objectId} />
            <ArticleBriefCard articleObjectId={sectionActualites[4]?.objectId} />
          </div>
        </div>
      </div>
      <div
        className={`${
          nArtCategory ? 'nart-bg-highlight' : 'nart-background-grey'
        } px-4 px-lg-paper`}
      >
        <TitledListContainer
          className="py-5"
          title="Incontournables"
          topRightLink={
            <Link
              className={nArtCategory ? 'text-white' : ''}
              to={`${nextPathRoot}/incontournables`}
            >
              Voir tous les incontournables
            </Link>
          }
          titleClassName="h2 fw-bold pb-2"
          list={sectionIncontournables.slice(0, 6)?.map((article) => (
            <ArticleHorizontalCardWhite
              className="col-12 col-md-6 col-lg-4 pb-4 d-flex flex-fill"
              key={`article-${article.objectId}`}
              article={article}
            />
          ))}
        />
      </div>
      <div className={`bb-background-light-grey px-4 px-lg-paper`}>
        <TitledListContainer
          className="py-5"
          title="Les nouveautés du moment"
          topRightLink={<Link to={`/agenda`}>Voir l'agenda</Link>}
          titleClassName="h2 fw-bold pb-2"
          noWrap
          list={agendaAlbums.slice(0, 4)?.map((album) => (
            <div className="col-8 col-md-3" key={album.objectId}>
              <NArtAlbumCardLight albumObjectId={album.objectId} />
            </div>
          ))}
        />
      </div>
      <div
        className={`${
          nArtCategory ? 'nart-bg-highlight' : 'nart-background-grey'
        } px-4 px-lg-paper`}
      >
        <TitledListContainer
          className="py-5"
          title="Dernières vidéos"
          topRightLink={
            <Link className={nArtCategory ? 'text-white' : ''} to={`${nextPathRoot}/en-video`}>
              Voir toutes les vidéos
            </Link>
          }
          titleClassName="h2 fw-bold pb-2"
          list={sectionEnVideo.slice(0, 6)?.map((article) => (
            <ArticleHorizontalCardWhite
              isVideo
              className="col-12 col-md-6 col-lg-4 pb-4 d-flex flex-fill"
              key={`article-${article.objectId}`}
              article={article}
            />
          ))}
        />
      </div>
      <ArticleFocusSection
        articleObjectId={sectionIncontournables[6]?.objectId || sectionIncontournables[0]?.objectId}
      />
      <NArtNewsletterOptin />
    </>
  );
};

export default NArtHome;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loadGenresApi } from '../services/api';
import initialGenres from '../services/genres.json';

import { getClientToken } from '../selectors';

const LOAD_GENRES = 'genres/loadGenres';

const initialState = {
  genres: initialGenres.slice(),
  loading: false,
  errors: [],
};

export const loadGenres = createAsyncThunk(LOAD_GENRES, async (arg, { getState }) => {
  const clientToken = getClientToken(getState());
  const genres = await loadGenresApi(clientToken);
  return { genres };
});

const genresSlice = createSlice({
  name: 'genres',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadGenres.pending, (state, action) => {
        state.genres = initialState.genres;
        state.loading = true;
      })
      .addCase(loadGenres.fulfilled, (state, action) => {
        state.genres = action.payload.genres;
        state.loading = false;
      })
      .addCase(loadGenres.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default genresSlice.reducer;

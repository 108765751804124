import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { createPrintRevision } from 'bubble-reducers/src/reducers/catalogs';
import { searchAlbums } from 'bubble-reducers/src/reducers/search';

import { Icon } from '@/components/Icon/Icon';
import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import AlbumSelectionCell from './components/AlbumSelectionCell';

import './Modals.css';

const MovePrintToAlbumModal = (props) => {
  const dispatch = useDispatch();
  const { albumObjectId, serieObjectId, printObjectId, allowGoBack } = props;

  const [searchAlbumText, setSearchAlbumText] = useState('');
  const [selectedAlbumObjectId, setSelectedAlbumObjectId] = useState(null);

  const album = useSelector((state) => state.albums.albums[albumObjectId]);
  const serie = useSelector((state) => state.series.series[serieObjectId]);
  const userObjectId = useSelector((state) => state.user.user.objectId);
  const hitsAlbums = useSelector((state) => state.search.hitsAlbums);

  const dismiss = () => {
    setSearchAlbumText('');
    setSelectedAlbumObjectId(null);
    props.callback();
  };

  const handleGoBack = () => {
    dismiss();
    props.handleGoBack();
  };

  const sendRevision = () => {
    if (userObjectId && selectedAlbumObjectId && albumObjectId !== selectedAlbumObjectId) {
      const revision = {
        userObjectId,
        oldValue: albumObjectId,
        newValue: selectedAlbumObjectId,
        fieldRevised: 'albumObjectId',
      };
      dispatch(createPrintRevision(printObjectId, [revision]));
      dismiss();
      props.openThankYourModal();
    } else {
      alert("Aucun champ n'a l'air d'avoir été modifié, êtes vous bien sûr de vos modifications ?");
    }
  };

  const handleSelectAlbumObjectId = (objectId) => {
    if (selectedAlbumObjectId !== objectId) setSelectedAlbumObjectId(objectId);
    else setSelectedAlbumObjectId(null);
  };

  const renderAlbumsSection = () => {
    const albums = hitsAlbums.data.slice(0, 20);
    return albums.map((album) => (
      <AlbumSelectionCell
        key={album.objectId}
        album={album}
        onClick={() => handleSelectAlbumObjectId(album.objectId)}
        selectedAlbumObjectId={selectedAlbumObjectId}
      />
    ));
  };

  const onSearch = (e) => {
    setSearchAlbumText(e.target.value);
    dispatch(searchAlbums(e.target.value));
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="lg"
      centered
      contentClassName="bb-background-light-grey"
    >
      {serie && album && (
        <>
          <div className="modal-header header-no-separator">
            {allowGoBack && (
              <WithClickHandler onClick={handleGoBack}>
                <Icon name="chevron-left" />
              </WithClickHandler>
            )}
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={dismiss}
            ></button>
          </div>
          <div className="modal-body mx-sm-3">
            <div className="row">
              <div className="col">
                <div className="bb-l-text-size d-flex flex-column justify-content-center align-items-center">
                  <span>Signalement</span>
                  <span className="fw-bold">
                    {serie.title ? serie.title + ' - ' : ''}
                    {album.tome && `Tome ${album.tome}`}
                  </span>
                </div>
                <div className="text-secondary d-flex justify-content-center">
                  Rechercher l'album dont celui-ci est une édition
                </div>
              </div>
            </div>
            <div className="py-2">
              <LabeledTextInput
                white
                noRightLabel
                value={searchAlbumText}
                placeholder="Rechercher l'album"
                onChange={onSearch}
              />
            </div>
            <div className="px-2">{renderAlbumsSection()}</div>

            <div className="h-separator mt-2" />
            <div className="d-flex flex-row justify-content-between p-4 align-items-center">
              {allowGoBack && (
                <WithClickHandler onClick={handleGoBack}>
                  <div className="text-start bb-medium-text-size">
                    <u>Retour</u>
                  </div>
                </WithClickHandler>
              )}
              <div className="text-end">
                <button onClick={sendRevision} className="btn btn-success btn-lg text-white">
                  Soumettre
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default MovePrintToAlbumModal;

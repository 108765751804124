import React from 'react';
import { useEffect } from 'react';

import { PROJECT } from '@/project-constants';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { loadArticles, loadHomeArticles } from 'bubble-reducers/src/reducers/articles';
import { getArticlesForHomeCategory } from 'bubble-reducers/src/selectors';

import GenericNewsSection from '@/scenes/NArt/components/GenericNewsSection/GenericNewsSection';
import NArtPagination from '@/scenes/NArt/components/NArtPagination';

import ArticleHorizontalBigCard from '@/components/ArticleHorizontalBigCard/ArticleHorizontalBigCard';
import ArticleHorizontalCardWhite from '@/components/ArticleHorizontalCardWhite/ArticleHorizontalCardWhite';

import ArticleBriefCard from '../../components/ArticleBriefCard/ArticleBriefCard';
import NArtNewsletterOptin from '../../components/NArtNewsletterOptin/NArtNewsletterOptin';

import bubbleUtils from 'bubble-utils';

import '../../NArt.scss';

export default function NArtListing({ category, section }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { nArtCategory, nArtSection, tag } = useParams();
  const listingCategory = category || nArtCategory;
  const listingSection = section || nArtSection;

  const urlParams = new URLSearchParams(location.search);
  const page = Number(urlParams.get('page')) || 1;
  urlParams.set('page', page);
  if (listingCategory) {
    urlParams.set('category', listingCategory);
  }
  if (listingSection && PROJECT.PAPER_SECTIONS_ID_MAP[listingSection]) {
    urlParams.set('section-id', PROJECT.PAPER_SECTIONS_ID_MAP[listingSection]);
  }
  if (tag) {
    urlParams.set('tags', [tag]);
  }
  const queryString = bubbleUtils.string.buildSortedQueryStringFromParams(urlParams);

  const articlesPage =
    useSelector((state) => state.articles.paginatedArticleMapByQuery[queryString]) || {};
  const articleObjectIds = articlesPage.articles || [];
  const sectionActualites =
    useSelector((state) =>
      getArticlesForHomeCategory(state, {
        category: listingCategory,
        section: PROJECT.PAPER_SECTIONS_ID_MAP['actualites'],
      }),
    ) || [];

  useEffect(() => {
    dispatch(loadArticles(Object.fromEntries(new URLSearchParams(queryString).entries())));
    dispatch(loadHomeArticles(listingCategory));
  }, [queryString, listingCategory, dispatch]);

  const title = [
    listingSection ? bubbleUtils.string.capitalizeFirstLetter(listingSection) : null,
    listingCategory ? `: ${bubbleUtils.string.capitalizeFirstLetter(listingCategory)}` : null,
    tag ? `"${tag}"` : null,
  ]
    .filter((row) => row)
    .join(' ');

  const articleObjectIdsForRender = [...articleObjectIds];
  return (
    <>
      <div className="container-fluid p-4 p-lg-paper bb-background-light-grey">
        <div className="row pb-6">
          <div className="col-lg-9">
            <div className="d-flex justify-content-between align-items-center pb-4">
              <h1 className="h2 m-0 fw-bold">
                {title || 'Tous les articles'}
                <span className="ps-2 h5 text-secondary">
                  {!!articlesPage.total && ` (${articlesPage.total} articles)`}
                </span>
              </h1>
              {page !== '1' && <div>Page {page}</div>}
            </div>
            {page === '1' && (
              <ArticleHorizontalBigCard articleObjectId={articleObjectIdsForRender.shift()} />
            )}
            {articleObjectIdsForRender.map((articleObjectId, index) => (
              <React.Fragment key={`listing_article_${articleObjectId}`}>
                <div className="d-none d-lg-block pt-4">
                  <ArticleHorizontalCardWhite big articleObjectId={articleObjectId} />
                </div>
                <div className="d-lg-none pt-4">
                  <ArticleHorizontalCardWhite articleObjectId={articleObjectId} />
                </div>
              </React.Fragment>
            ))}
            <div className="pt-6">
              <NArtPagination page={articlesPage} />
            </div>
          </div>
          <div className="d-none d-lg-block col-lg-3">
            <div className="d-flex align-items-center justify-content-between pb-2">
              <div className="d-flex">
                <div className="nart-bg-highlight nart-colored-macaron rounded me-3" />
                <div className="h2 fw-bold">Actualités</div>
              </div>
              <Link to="/9emeart/actualites">Voir tout</Link>
            </div>
            {sectionActualites.map((article) => (
              <ArticleBriefCard
                key={`brief_${article.objectId}`}
                articleObjectId={article.objectId}
              />
            ))}
            <div className="pt-4">
              <GenericNewsSection title="Les News" maxArticlesCount={6} />
            </div>
          </div>
        </div>
      </div>
      <NArtNewsletterOptin />
    </>
  );
}

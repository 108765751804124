import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';

import { setClickedHeaderMenu, setShowHeaderMenu } from '@/engine/reducers/website-interface';

import { Icon } from '@/components/Icon/Icon';
import withRouter from '@/components/withRouter';

import './HeaderDropdownButton.css';

const typeMap = {
  bd: { title: 'BD' },
  comics: { title: 'Comics' },
  mangas: { title: 'Mangas' },
  jeunesse: { title: 'Jeunesse' },
  tops: {
    title: (
      <>
        Tops<span className="d-none d-xl-inline-block ps-1">& sélections</span>
      </>
    ),
  },
};

class HeaderDropdownButton extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = { hasLeft: false, hasHovered: false };
  }

  toggleClickedMenu = () => {
    const type = this.props.type;
    this.setState({ hasLeft: false, hasHovered: true });
    const { setClickedHeaderMenu, setShowHeaderMenu } = this.props;
    setClickedHeaderMenu(type);
    setTimeout(() => {
      if (this.state.hasHovered && !this.state.hasLeft) {
        setShowHeaderMenu(true);
      }
      this.setState({ hasLeft: false, hasHovered: false });
    }, 150);
  };

  handleOnMouseLeave = () => {
    this.setState({ hasLeft: true });
    const { setShowHeaderMenu } = this.props;
    setShowHeaderMenu(false);
  };

  handleClick = () => {
    const type = this.props.type;
    const { setShowHeaderMenu } = this.props;
    setShowHeaderMenu(false);
    if (type) {
      this.props.router.navigate(type === 'tops' ? '/tops' : `/list?category=${type}`);
    }
  };

  render() {
    const typeObject = typeMap[this.props.type || 'bd'];
    return (
      <div className="dropdown d-flex align-items-center">
        <button
          onMouseEnter={this.toggleClickedMenu}
          onMouseLeave={this.handleOnMouseLeave}
          onClick={this.handleClick}
          className="d-flex button-no-style px-3"
          type="button"
        >
          {typeObject.title}
          <span className="bb-xs-text-size position-relative ms-2 navbar-dropdown-chevron">
            <Icon name="chevron-down" />
          </span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clickedHeaderMenu: state.websiteInterface.clickedHeaderMenu,
});

export default withRouter(
  connect(mapStateToProps, { setClickedHeaderMenu, setShowHeaderMenu })(HeaderDropdownButton),
);

import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { formatDateWithFormat } from 'bubble-utils/src/date-utils';

import { useImageLoaded } from '@/services/hooks/useImageLoaded';

import ArticleSectionBadge from '@/components/ArticleSectionBadge/ArticleSectionBadge';
import { Icon } from '@/components/Icon/Icon';

import './ArticleHorizontalBigCard.scss';

const defaultObject = {};

export default function ArticleHorizontalBigCard({
  article,
  articleObjectId = null,
  noTitle,
  noButton,
  noShadow,
}) {
  const articleFromReducer = useSelector(
    (state) => state.articles.articlesMapByObjectId[articleObjectId],
  );
  article = article || articleFromReducer || defaultObject;
  article.title = article.title || '';
  const articlePath = article.path;
  const section = article.section || defaultObject;

  const [ref, imageLoaded, onImageLoaded] = useImageLoaded();

  return (
    <>
      <div
        className="nart-shadow d-flex flex-fill article-big-card-rounded bg-cover article-big-card"
        style={imageLoaded ? { backgroundImage: `url(${article.imageUrl})` } : {}}
      >
        <div
          className={`d-flex flex-column flex-fill p-3 p-md-paper article-big-card-rounded justify-content-between ${
            !noShadow && 'article-overlay-gradient'
          }`}
        >
          <div>
            <ArticleSectionBadge categories={article.tags} tag={section} />
          </div>
          <div>
            {!noTitle && (
              <div className="text-white">
                {formatDateWithFormat(article.publicationDate, {
                  month: 'long',
                  day: 'numeric',
                })}
              </div>
            )}
            <h1 className="text-white fw-bold mb-3 mt-2 article-big-card-title">
              {articlePath && !noTitle && (
                <Link className="no-decoration text-white" to={articlePath}>
                  {article.title}
                </Link>
              )}
            </h1>
            <div>
              {articlePath && !noButton && (
                <Link
                  className={`no-decoration btn btn-9eart-${article.category} text-white rounded-medium article-big-card-button`}
                  to={articlePath}
                >
                  <span>Lire l'article</span>
                  <Icon className="ms-2 article-big-card-button-caret" name="caret-end" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <img src={article.imageUrl} alt="no" className="d-none" ref={ref} onLoad={onImageLoaded} />
    </>
  );
}

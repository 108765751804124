import React from 'react';

import { connect } from 'react-redux';

import {
  addStoreToFavorite,
  loadUserFavoriteStores,
  removeStoreFromFavorite,
} from 'bubble-reducers/src/reducers/user';

import { useIsUserlogged } from '@/services/hooks/useUserUtils';

import BreadCrumbs from '@/components/Breadcrumbs/BreadCrumbs';
import SearchBar from '@/components/SearchBar/SearchBar';
import ShareZone from '@/components/ShareZone/ShareZone';

import placeholderProfileJpg from '@/assets/placeholders/placeholder-profile.jpg';

import StoreHeaderNavbar from './StoreHeaderNavbar';

const StoreHeader = (props) => {
  const isUserlogged = useIsUserlogged();

  const store = props.store || {};
  const isFavorite = props.isFavorite;
  const isPartnered = store.isClickAndCollectSeller;

  const onClickFavorite = () => {
    if (isUserlogged()) {
      props.isFavorite ? removeFromFavorite(store.objectId) : addToFavorite(store.objectId);
    }
  };

  const addToFavorite = (storeObjectId) => {
    const { addStoreToFavorite, user } = props;
    addStoreToFavorite(user.objectId, storeObjectId);
  };
  const removeFromFavorite = (storeObjectId) => {
    const { removeStoreFromFavorite, user } = props;
    removeStoreFromFavorite(user.objectId, storeObjectId);
  };

  const onTextChange = (e) => props.onSearchTextChange(e.target.value);
  const resetText = () => props.onSearchTextChange(null);

  return (
    <div className="store-background">
      <div className="container ">
        <div className="px-sm-3">
          <div className="d-flex align-items-center mx-sm-n3">
            <BreadCrumbs
              light
              currentObject={{ custom: true, name: store.name }}
              previousObject={{
                custom: true,
                name: 'Librairies partenaires',
                link: '/cartes-des-librairies-partenaires',
              }}
            />
            <ShareZone title={`Faire découvrir ${store.name || 'cette librairie'} à des amis`} />
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-md-8 col-lg-9 py-3">
              <div className="d-flex align-items-center text-white">
                <img
                  className="store-page-logo me-3"
                  alt="logo"
                  src={store.logo || placeholderProfileJpg}
                />
                <div>
                  <h1 className="bb-xl-text-size fw-bold">{store.name}</h1>
                  <div>
                    {store.line1} - {store.zipcode} {store.city}
                  </div>
                  <div>{store.schedule}</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 py-3">
              <button
                disabled={!isPartnered && !store.isClosed}
                onClick={onClickFavorite}
                className={`w-100 btn btn-lg btn-${
                  isFavorite ? 'danger' : 'success'
                } text-white bb-regular-text-size`}
              >
                {isFavorite
                  ? '✖ Retirer de mes librairies préférées'
                  : '✔ Ajouter à mes librairies préférées'}
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-end px-sm-3">
          <StoreHeaderNavbar store={props.store} onChange={resetText} />
          <div className="d-none d-md-flex w-50">
            <SearchBar
              text={props.searchText || ''}
              onChange={onTextChange}
              placeholder="Rechercher un album"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  user: state.user.user,
  store: state.stores.stores[props.storeObjectId],
  isFavorite: state.user.favoriteStoresIds.includes(props.storeObjectId),
});

export default connect(mapStateToProps, {
  loadUserFavoriteStores,
  addStoreToFavorite,
  removeStoreFromFavorite,
})(StoreHeader);

import React, { useState } from 'react';

import bubbleUtils from 'bubble-utils';

const ArticleHeaderImage = ({ article }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const onImageLoaded = () => setImageLoaded(true);

  return (
    <div className={`${!imageLoaded && !bubbleUtils.env.isServer ? 'article-loading' : ''}`}>
      {!!article.imageUrl && (
        <img
          className="article-image"
          onLoad={onImageLoaded}
          src={article.imageUrl}
          alt="Illustration de l'article"
        />
      )}
    </div>
  );
};

export default ArticleHeaderImage;

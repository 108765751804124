import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadArticles } from 'bubble-reducers/src/reducers/articles';
import { getHighlightedArticles } from 'bubble-reducers/src/selectors';

import useEffectOnce from '@/services/hooks/useEffectOnce';

import ArticleHorizontalCard from '@/components/ArticleHorizontalCard/ArticleHorizontalCard';
import SocialNetworkFollow from '@/components/SocialNetworksFollow/SocialNetworkFollow';

import NewsletterOptin from '../NewsletterOptin/NewsletterOptin';

const Sidebar = () => {
  const optedIn = useSelector((state) => state.websiteOther.reOptinNewsletter);
  const hasAcceptedNewsletter = useSelector((state) => state.user.user?.hasAcceptedNewsletter);

  const dispatch = useDispatch();
  const highlightedArticles = useSelector(getHighlightedArticles);
  useEffectOnce(() => {
    dispatch(loadArticles({ highlighted: 1 }));
  });

  return (
    <>
      <div className="mb-3 fw-bold">Toute l'actualité BD, Comics et Manga sur le Blog Bubble :</div>
      {highlightedArticles.map((article) => (
        <ArticleHorizontalCard key={article.objectId} className="mb-3" article={article} />
      ))}
      <div className="d-grid">
        <button className="btn btn-lg btn-primary mb-5">
          <Link className="text-white no-decoration " to="/9emeart">
            Voir tous les articles sur le Blog
          </Link>
        </button>
      </div>
      {!optedIn && hasAcceptedNewsletter ? <SocialNetworkFollow /> : <NewsletterOptin />}
    </>
  );
};

export default Sidebar;

import { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { removeFromCart } from 'bubble-reducers/src/reducers/cart';
import { logEvent } from 'bubble-reducers/src/reducers/events';
import {
  addPrintsToLibrary,
  addPrintsToWishlist,
  removePrintLoan,
  removePrintsFromLibrary,
  removePrintsFromWishlist,
  setDeluxeFlagOnPrints,
  setDigitalFlagOnPrints,
  setEoFlagOnPrints,
  setExlibrisFlagOnPrints,
  setForSaleFlagOnPrints,
  setLimitedFlagOnPrints,
  setReadFlagOnPrints,
  setSignedFlagOnPrints,
} from 'bubble-reducers/src/reducers/profiles';

import { getPropertiesForCart } from 'bubble-utils/src/event-utils';

import Toaster from '@/components/DUPLICATE/Toaster/Toaster';

import { useIsUserInfinity, useIsUserlogged } from './useUserUtils';

import { EVENT } from 'bubble-constants';

export const useRemoveFromCart = () => {
  const dispatch = useDispatch();

  return useCallback((prints, isBatch) => {
    dispatch(removeFromCart(prints, isBatch));
    const plural = prints.length > 1 ? 's' : '';
    Toaster.addToast(
      'Panier',
      `${prints.length} album${plural} retiré${plural} de mon panier`,
      <Link to={`/cart`}>Voir mon panier</Link>,
    );
  }, []);
};

export const useAddPrintsToLibrary = () => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  return useCallback((user, prints) => {
    if (isUserlogged(user)) {
      const plural = prints.length > 1 ? 's' : '';
      Toaster.addToast(
        'Ma Collection',
        `${`${prints.length} album${plural}`} ajouté${plural} à ma collection`,
        <Link to={`/user/${user.objectId}/ma-collection`}>Voir ma collection</Link>,
      );
      dispatch(
        addPrintsToLibrary(
          user.objectId,
          prints.map((print) => ({
            printObjectId: print.objectId,
            albumObjectId: print.album.objectId,
            isInLibrary: true,
          })),
        ),
      );
    }
  }, []);
};

export const useRemovePrintsFromLibrary = () => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  return useCallback((user, prints, isBatch = false) => {
    if (isUserlogged(user)) {
      const plural = prints.length > 1 ? 's' : '';
      Toaster.addToast(
        'Ma Collection',
        `${`${prints.length} album${plural}`} retiré${plural} de ma collection`,
        <Link to={`/user/${user.objectId}/ma-collection`}>Voir ma collection</Link>,
      );

      dispatch(
        removePrintsFromLibrary(
          user.objectId,
          prints.map((print) => ({
            printObjectId: print.objectId,
            albumObjectId: print.album.objectId,
            isInLibrary: false,
          })),
          isBatch,
        ),
      );
    }
  }, []);
};

export const useSetReadFlagOnPrints = () => {
  const dispatch = useDispatch();
  const isUserInfinity = useIsUserInfinity();

  return useCallback((user, prints = [], value, isBatch = false) => {
    const plural = prints.length > 1 ? 's' : '';

    let shouldContinue = true;
    const isInfinity = isUserInfinity(user);

    if (!isInfinity && !value) {
      shouldContinue = window.confirm(
        `Vous n'êtes pas membre infinity\n${
          plural
            ? 'Si vous marquez ces albums comme "non lus", vous ne pourrez plus les remettre en "lus"'
            : 'Si vous marquez cet album comme "non lu", vous ne pourrez plus le remettre en "lu"'
        }`,
      );
    }
    if (shouldContinue) {
      if (!value || (value && isInfinity)) {
        dispatch(
          setReadFlagOnPrints(
            user.objectId,
            prints.map((print) => ({
              albumObjectId: print.album.objectId,
              printObjectId: print.objectId,
              isRead: value,
            })),
            isBatch,
          ),
        );

        Toaster.addToast(
          'Lu / Non lu',
          `${`${prints.length} album${plural}`} marqué${plural} comme ${
            value ? '"Lu"' : '"Non lu"'
          }`,
        );
      }
    }
  }, []);
};

export const useSetEoFlagOnPrints = () => {
  const dispatch = useDispatch();

  return useCallback((user, prints, value, isBatch = false) => {
    dispatch(
      setEoFlagOnPrints(
        user.objectId,
        prints.map((print) => ({
          albumObjectId: print.album.objectId,
          printObjectId: print.objectId,
          isOriginalEdition: value,
        })),
        isBatch,
      ),
    );
    const plural = prints.length > 1 ? 's' : '';
    Toaster.addToast(
      'Editions Originales',
      `${`${prints.length} album${plural}`} ${
        value ? `ajouté${plural} aux` : `retiré${plural} des`
      } éditions originales`,
    );
  }, []);
};

export const useSetSignedFlagOnPrints = () => {
  const dispatch = useDispatch();

  return useCallback((user, prints, value, isBatch = false) => {
    dispatch(
      setSignedFlagOnPrints(
        user.objectId,
        prints.map((print) => ({
          albumObjectId: print.album.objectId,
          printObjectId: print.objectId,
          isSigned: value,
        })),
        isBatch,
      ),
    );
    const plural = prints.length > 1 ? 's' : '';
    Toaster.addToast(
      'Dédicaces',
      `${`${prints.length} album${plural}`} ${
        value ? `marqué${plural} comme dédicacé` : `retiré${plural} des dédicaces`
      }`,
    );
  }, []);
};

export const useSetDeluxeFlagOnPrints = () => {
  const dispatch = useDispatch();

  return useCallback((user, prints, value, isBatch = false) => {
    dispatch(
      setDeluxeFlagOnPrints(
        user.objectId,
        prints.map((print) => ({
          albumObjectId: print.album.objectId,
          printObjectId: print.objectId,
          isDeluxe: value,
        })),
        isBatch,
      ),
    );
    const plural = prints.length > 1 ? 's' : '';
    Toaster.addToast(
      'Tirage de tête',
      `${`${prints.length} album${plural}`} ${
        value ? `marqué${plural} comme tirage de tête` : `retiré${plural} des tirages de tête`
      }`,
    );
  }, []);
};

export const useSetLimitedFlagOnPrints = () => {
  const dispatch = useDispatch();

  return useCallback((user, prints, value, isBatch = false) => {
    dispatch(
      setLimitedFlagOnPrints(
        user.objectId,
        prints.map((print) => ({
          albumObjectId: print.album.objectId,
          printObjectId: print.objectId,
          isLimited: value,
        })),
        isBatch,
      ),
    );
    const plural = prints.length > 1 ? 's' : '';
    Toaster.addToast(
      'Série limitée',
      `${`${prints.length} album${plural}`} ${
        value ? `marqué${plural} comme série limité` : `retiré${plural} des séries limitées`
      }`,
    );
  }, []);
};

export const useSetDigitalFlagOnPrints = () => {
  const dispatch = useDispatch();

  return useCallback((user, prints, value, isBatch = false) => {
    dispatch(
      setDigitalFlagOnPrints(
        user.objectId,
        prints.map((print) => ({
          albumObjectId: print.album.objectId,
          printObjectId: print.objectId,
          isDigital: value,
        })),
        isBatch,
      ),
    );
    const plural = prints.length > 1 ? 's' : '';
    Toaster.addToast(
      'Version numérique',
      `${`${prints.length} album${plural}`} ${
        value
          ? `marqué${plural} comme version numérique`
          : `retiré${plural} des albums en version numérique`
      }`,
    );
  }, []);
};

export const useSetExLibrisFlagOnPrints = () => {
  const dispatch = useDispatch();

  return useCallback((user, prints, value, isBatch = false) => {
    dispatch(
      setExlibrisFlagOnPrints(
        user.objectId,
        prints.map((print) => ({
          albumObjectId: print.album.objectId,
          printObjectId: print.objectId,
          isWithExLibris: value,
        })),
        isBatch,
      ),
    );
    const plural = prints.length > 1 ? 's' : '';
    Toaster.addToast(
      'Ex Libris',
      `${`${prints.length} album${plural}`} ${
        value
          ? `marqué${plural} comme contenant un ex-libris`
          : `retiré${plural} des albums avec ex-libris`
      }`,
    );
  }, []);
};

export const useSetForSaleFlagOnPrints = () => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  return useCallback((user, prints, value, isBatch = false) => {
    if (isUserlogged(user)) {
      dispatch(
        setForSaleFlagOnPrints(
          user.objectId,
          prints.map((print) => ({
            albumObjectId: print.album.objectId,
            printObjectId: print.objectId,
            isForSale: value,
          })),
          isBatch,
        ),
      );
      const plural = prints.length > 1 ? 's' : '';
      Toaster.addToast(
        'A vendre',
        `${`${prints.length} album${plural}`} ${
          value ? `marqué${plural} comme à vendre` : `retiré${plural} des albums à vendre`
        }`,
      );
    }
  }, []);
};

export const useAddPrintsToWishlistv1 = () => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  return useCallback((user, prints, value = { isGifted: null, giftedDate: null }) => {
    if (isUserlogged(user)) {
      dispatch(
        addPrintsToWishlist(
          user.objectId,
          prints.map((print) => ({
            printObjectId: print.objectId,
            albumObjectId: print.album.objectId,
            isWishlist: value,
          })),
        ),
      );
      const plural = prints.length > 1 ? 's' : '';

      Toaster.addToast(
        'Wishlist',
        `${`${prints.length} album${plural}`} ajouté${plural} à votre liste d'envies`,
      );
    }
  }, []);
};

export const useAddPrintsToWishlist = () => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  return useCallback((user, prints, value = { isGifted: null, giftedDate: null }) => {
    if (isUserlogged(user)) {
      dispatch(
        logEvent(
          EVENT.ADD_TO_WISHLIST,
          getPropertiesForCart(prints),
          null,
          EVENT.ALBUMS_IN_WISHLIST,
          prints.length,
        ),
      );
      dispatch(
        addPrintsToWishlist(
          user.objectId,
          prints.map((print) => ({
            printObjectId: print.objectId,
            albumObjectId: print.album.objectId,
            isWishlist: value,
          })),
        ),
      );
      const plural = prints.length > 1 ? 's' : '';

      Toaster.addToast(
        'Wishlist',
        `${`${prints.length} album${plural}`} ajouté${plural} à votre liste d'envies`,
      );
    }
  }, []);
};

export const useRemovePrintsFromWishlist = () => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  return useCallback((user, prints) => {
    if (isUserlogged(user)) {
      dispatch(
        logEvent(
          EVENT.REMOVE_FROM_WISHLIST,
          getPropertiesForCart(prints),
          null,
          EVENT.ALBUMS_IN_WISHLIST,
          prints.length,
        ),
      );
      dispatch(
        removePrintsFromWishlist(
          user.objectId,
          prints.map((print) => ({
            printObjectId: print.objectId,
            albumObjectId: print.album.objectId,
            isWishlist: null,
          })),
        ),
      );
      const plural = prints.length > 1 ? 's' : '';

      Toaster.addToast(
        'Wishlist',
        `${`${prints.length} album${plural}`} retiré${plural} de votre liste d'envies`,
      );
    }
  }, []);
};

export const useRemovePrintLoan = () => {
  const dispatch = useDispatch();

  return useCallback((userObjectId, print, isBatch = false) => {
    dispatch(
      removePrintLoan(
        userObjectId,
        [
          {
            printObjectId: print.objectId,
            albumObjectId: print.album.objectId,
            loan: null,
          },
        ],
        isBatch,
      ),
    );
    Toaster.addToast('Mes prêts', '1 album retiré de ma liste de prêts');
  }, []);
};

export const useRemovePrintsLoan = () => {
  const dispatch = useDispatch();

  return useCallback((userObjectId, prints, isBatch = false) => {
    dispatch(
      removePrintLoan(
        userObjectId,
        prints.map((print) => ({
          printObjectId: print.objectId,
          albumObjectId: print.album.objectId,
          loan: null,
        })),
        isBatch,
      ),
    );
    const plural = prints.length > 1 ? 's' : '';
    Toaster.addToast(
      'Mes prêts',
      `${prints.length} album${plural} retiré${plural} de ma liste de prêts`,
    );
  }, []);
};

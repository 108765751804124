export const LOAD_PAYMENT_METHODS = 'LOAD_PAYMENT_METHODS';
export const LOAD_PAYMENT_METHODS_SUCCESS = 'LOAD_PAYMENT_METHODS_SUCCESS';
export const LOAD_PAYMENT_METHODS_FAIL = 'LOAD_PAYMENT_METHODS_FAIL';

export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD';
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS';
export const UPDATE_PAYMENT_METHOD_FAIL = 'UPDATE_PAYMENT_METHOD_FAIL';

export const RESET_PAYMENT_METHODS_REDUCER = 'RESET_PAYMENT_METHODS_REDUCER';
export const RESET_PAYMENT_METHODS_ERRORS = 'RESET_PAYMENT_METHODS_ERRORS';

const initialState = {
  paymentMethods: [],
  loading: false,
  updating: false,
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_PAYMENT_METHODS: {
      return {
        ...state,
        loading: true,
        paymentMethods: initialState.paymentMethods,
        errors: [],
      };
    }
    case LOAD_PAYMENT_METHODS_SUCCESS: {
      return {
        ...state,
        loading: false,
        paymentMethods: action.payload.paymentMethods,
        errors: [],
      };
    }
    case LOAD_PAYMENT_METHODS_FAIL: {
      return {
        ...state,
        errors: [...state.errors, action.payload],
        loading: false,
      };
    }

    case UPDATE_PAYMENT_METHOD: {
      return {
        ...state,
        updating: true,
        loading: true,
        errors: [],
      };
    }
    case UPDATE_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        updating: false,
        paymentMethods: action.payload.paymentMethods,
        loading: false,
        errors: [],
      };
    }
    case UPDATE_PAYMENT_METHOD_FAIL: {
      return {
        ...state,
        errors: [...state.errors, action.payload],
        updating: false,
        loading: false,
      };
    }

    case RESET_PAYMENT_METHODS_REDUCER: {
      return {
        ...initialState,
      };
    }
    case RESET_PAYMENT_METHODS_ERRORS: {
      return {
        ...state,
        errors: initialState.errors,
      };
    }

    default: {
      return state;
    }
  }
}

// resetPaymentMethodsReducer ====================================================

export function resetPaymentMethodsReducer() {
  return {
    type: RESET_PAYMENT_METHODS_REDUCER,
  };
}

// resetPaymentMethodsErrors ====================================================

export function resetPaymentMethodsErrors() {
  return {
    type: RESET_PAYMENT_METHODS_ERRORS,
  };
}

// loadPaymentMethods ====================================================

export function loadPaymentMethods(userObjectId) {
  return {
    type: LOAD_PAYMENT_METHODS,
    payload: { userObjectId },
  };
}
export function loadPaymentMethodsSuccess(paymentMethods) {
  return {
    type: LOAD_PAYMENT_METHODS_SUCCESS,
    payload: { paymentMethods },
  };
}
export function loadPaymentMethodsFail(error) {
  return {
    type: LOAD_PAYMENT_METHODS_FAIL,
    payload: error,
    error: true,
  };
}

// updatePaymentMethod ====================================================

export function updatePaymentMethod(userObjectId, paymentMethod) {
  return {
    type: UPDATE_PAYMENT_METHOD,
    payload: { userObjectId, paymentMethod },
  };
}
export function updatePaymentMethodSuccess(paymentMethods) {
  return {
    type: UPDATE_PAYMENT_METHOD_SUCCESS,
    payload: { paymentMethods },
  };
}
export function updatePaymentMethodFail(error) {
  return {
    type: UPDATE_PAYMENT_METHOD_FAIL,
    payload: error,
    error: true,
  };
}

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadSeries } from 'bubble-reducers/src/reducers/series';
import { get3RecoSeries } from 'bubble-reducers/src/selectors';

import SerieBigCard from '@/components/SerieBigCard/SerieBigCard';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

const SerieSameGenre = ({ serie = {}, grey }) => {
  const dispatch = useDispatch();
  const key = (serie.category || '') + (serie.tags?.map((t) => t.name).join(',') || '') || 'all';
  const sameGenreSeries = useSelector((state) => get3RecoSeries(state, key));

  const tagsString = serie.tags ? serie.tags.map((tag) => tag.name).join(',') : null;

  useEffect(() => {
    dispatch(
      loadSeries({
        category: serie.category || null,
        tags: tagsString || null,
        random: 1,
      }),
    );
  }, [dispatch, serie.category, serie.genre, tagsString]);

  const genre = serie.tags?.[0]?.name || serie.genre || null;

  return (
    <TitledListContainer
      noWrap
      title={
        <div className="d-md-flex">
          <div>Découvrez d'autres séries du même genre</div>
          <div>
            <Link
              className="d-block d-md-none fw-normal bb-regular-text-size py-2"
              to={`/list?category=${serie.category}${genre ? `&tags=${genre}` : ''}`}
            >
              Voir tous les albums {[serie.category, genre].filter((row) => row).join(' - ')}
            </Link>
          </div>
        </div>
      }
      topRightLink={
        <Link
          className="d-none d-md-block"
          to={`/list?category=${serie.category}${genre ? `&tags=${genre}` : ''}`}
        >
          Voir tous les albums {[serie.category, genre].filter((row) => row).join(' - ')}
        </Link>
      }
      list={sameGenreSeries.map((serie) => (
        <div
          className="col-11 col-md-6 col-lg-4 mt-3 d-inline-block d-md-flex"
          key={serie.objectId}
        >
          <SerieBigCard serieObjectId={serie.objectId} />
        </div>
      ))}
    />
  );
};

export default SerieSameGenre;

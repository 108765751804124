import React, { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { addSerieToFollow, removeSerieFromFollow } from 'bubble-reducers/src/reducers/profiles';
import { getUserId, isSerieFollowed } from 'bubble-reducers/src/selectors';

import { useIsUserlogged } from '@/services/hooks/useUserUtils';

import Toaster from '@/components/DUPLICATE/Toaster/Toaster';
import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import './SerieFollowBadge.scss';

export default function SerieFollowBadge({
  serieObjectId,
  noFlex,
  className: classNameFromProps,
  smallPadding,
}) {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  const userObjectId = useSelector(getUserId);
  const isFollowed = useSelector(
    (state) => !!userObjectId && isSerieFollowed(state, serieObjectId),
  );

  const onClick = useCallback(() => {
    if (isUserlogged()) {
      if (isFollowed) {
        Toaster.addToast(
          'Série non suivie',
          'Cette série a été retirée de vos albums manquants et de votre agenda',
        );
        dispatch(removeSerieFromFollow(userObjectId, serieObjectId));
      } else {
        Toaster.addToast(
          'Série suivie',
          'Retrouvez cette série dans vos albums manquants et ses prochaines sorties dans votre agenda',
          <Link to={`/user/${userObjectId}/mes-albums-manquants`}>Voir mes albums manquants</Link>,
        );
        dispatch(addSerieToFollow(userObjectId, serieObjectId));
      }
    }
  }, [dispatch, isUserlogged, userObjectId, serieObjectId, isFollowed]);

  const containerClassName = useMemo(
    () => [classNameFromProps, noFlex ? null : 'd-flex h-100'].filter(Boolean).join(' '),
    [classNameFromProps, noFlex],
  );

  const badgeClassName = useMemo(
    () =>
      [
        'serie-follow-badge px-2 d-flex align-items-center bb-background-light-grey border rounded',
        isFollowed ? 'text-white border-success bg-success' : 'text-secondary border-muted',
        smallPadding ? 'py-0' : null,
      ]
        .filter(Boolean)
        .join(' '),
    [isFollowed, smallPadding],
  );

  return (
    <WithClickHandler className={containerClassName} consumeEvent onClick={onClick}>
      <div className={badgeClassName}>
        {isFollowed ? <Icon className="pe-1" name="check" size="xs" /> : `+\xa0`}
        {isFollowed ? 'Suivie' : 'Suivre'}
      </div>
    </WithClickHandler>
  );
}

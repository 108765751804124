import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from '@/components/Icon/Icon';

import './SearchBar.css';

export default class SearchBar extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    matches: PropTypes.number,
    placeholder: PropTypes.string,
  };

  handleOnChange = (event, clearText = false) => {
    let newEvent = Object.assign({}, event);
    if (clearText) {
      newEvent.target.value = '';
    }
    this.props.onChange && this.props.onChange(event);
  };

  handleOnChangeWithClear = (event) => this.handleOnChange(event, true);

  render() {
    const text = this.props.text || '';
    return (
      <div className="input-group">
        <span className="input-group-text transparent-icon-container" id="basic-addon1">
          {!Number(this.props.matches) && this.props.matches !== 0 && <Icon name="search" />}
          {this.props.matches && <span>{this.props.matches}</span>}
        </span>
        <input
          onChange={this.handleOnChange}
          value={text}
          type="text"
          className="form-control input-no-start-border input-no-end-border input-medium-height"
          placeholder={this.props.placeholder || 'Rechercher'}
          aria-label="Rechercher"
          aria-describedby="basic-addon1"
        />
        <button
          onClick={this.handleOnChangeWithClear}
          className="input-group-text transparent-icon-container input-no-start-border"
          id="basic-addon1"
        >
          <Icon name="times" />
        </button>
      </div>
    );
  }
}

import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';

import AlbumCard from '@/components/AlbumCard/AlbumCard';
import AlbumHorizontalCard from '@/components/AlbumHorizontalCard/AlbumHorizontalCard';

import { MODES } from '../SerieModeSwitcher/SerieModeSwitcher';

const SerieAlbumsList = (props) => {
  const serie = props.serie;

  const albums = (serie.albums || []).slice().reverse();
  return (
    <div
      className={`row ${
        props.serieMode === MODES.GRID ? 'row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5' : ''
      }`}
    >
      {albums.map((objectId, index) => {
        return props.serieMode === MODES.GRID ? (
          <AlbumCard key={`albumcard${objectId}`} className="col pb-3" albumObjectId={objectId} />
        ) : (
          <div
            className="col-12 transition hoverable"
            style={{ zIndex: albums.length - index }}
            key={`albumcard${objectId}`}
          >
            <AlbumHorizontalCard
              //TODO: add forcePublicationDate
              albumObjectId={objectId}
            />
          </div>
        );
      })}
    </div>
  );
};

SerieAlbumsList.propTypes = {
  serie: PropTypes.object,
  serieMode: PropTypes.any,
};

const mapStateToProps = (state) => ({
  prints: state.prints.prints,
  albums: state.albums.albums,
});

export default connect(mapStateToProps, null)(SerieAlbumsList);

import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { loadHighlightedResourcesApi } from '../services/api';

import { storeArticles } from '../reducers/articles';
import { storeAuthors } from '../reducers/authors';
import { LOAD_HIGHLIGHTED_RESOURCES } from '../reducers/highlighted-resources';
import {
  loadHighlightedResourcesFail,
  loadHighlightedResourcesSuccess,
} from '../reducers/highlighted-resources';
import { storeStores } from '../reducers/stores';
import { getClientToken } from '../selectors';
import { storeAlbumsFlow } from './albums';
import { storeSeriesFlow } from './series';

function* loadHighlightedResourcesFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadHighlightedResourcesApi, clientToken);

    yield all([
      call(storeSeriesFlow, {
        payload: { series: response.series, source: 'loadHighlightedResourcesFlow' },
      }),
      call(storeAlbumsFlow, {
        payload: { albums: response.albums, source: 'loadHighlightedResourcesFlow' },
      }),

      put(storeArticles(response.articles)),
      put(storeStores(response.stores)),
      put(storeAuthors(response.authors, 'loadHighlightedResources')),
    ]);
    const objectIds = {};
    Object.keys(response).forEach((key) => {
      objectIds[key] = response[key].map((object) => object.objectId);
    });
    yield put(loadHighlightedResourcesSuccess(objectIds));
  } catch (error) {
    console.log('saga middleware error (loadHighlightedResourcesFlow)', error);
    yield put(loadHighlightedResourcesFail(error));
  }
}

export default function* otherWatcher() {
  yield all([takeLatest(LOAD_HIGHLIGHTED_RESOURCES, loadHighlightedResourcesFlow)]);
}

import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';

import {
  isAlbumOwned,
  makeGetPreviousAlbumsFromSerie,
  makeIsNoPreviousAlbumsOwned,
} from 'bubble-reducers/src/selectors';

import {
  useAddPrintsToLibrary,
  useRemovePrintsFromLibrary,
} from '@/services/hooks/useLibraryUtils';
import imageUtils from '@/services/image-utils';

import ButtonWithIcon from '@/components/ButtonWithIcon/ButtonWithIcon';

const HorizontalAddToCollectionZone = (props) => {
  const addPrintsToLibrary = useAddPrintsToLibrary();
  const removePrintsFromLibrary = useRemovePrintsFromLibrary();

  const handleCollectionClick = (e) => {
    e.preventDefault();
    const { isOwned, user, album, albumInLibrary } = props;

    const albumDefaultPrint = {
      album: { objectId: album.objectId },
      objectId: album.defaultPrintObjectId,
    };
    const previousAlbumDefaultPrints = [album, ...props.previousAlbumsFromSerie].map((album) => ({
      album: { objectId: album.objectId },
      objectId: album.defaultPrintObjectId,
    }));
    const ownedAlbumPrints = Object.entries(albumInLibrary?.userPrints || {})
      .map((print) => {
        if (print.isInLibrary) {
          return {
            album: { objectId: album.objectId },
            objectId: print.objectId,
          };
        } else return null;
      })
      .filter((row) => row);

    if (!isOwned) {
      if (
        props.isNoPreviousAlbumsOwned &&
        window.confirm('Voulez-vous ajouter tous les tomes précédents ?')
      ) {
        addPrintsToLibrary(user, previousAlbumDefaultPrints);
      } else {
        addPrintsToLibrary(user, [albumDefaultPrint]);
      }
    } else {
      removePrintsFromLibrary(
        user,
        ownedAlbumPrints.length ? ownedAlbumPrints : [albumDefaultPrint],
        true,
      );
    }
  };

  return (
    <div className="px-3">
      <ButtonWithIcon
        onClick={handleCollectionClick}
        icon={
          props.isOwned
            ? imageUtils.getAssetImage('icon_remove_collection.svg')
            : imageUtils.getAssetImage('icon_add_collection.svg')
        }
      >
        <span className={props.isOwned ? 'text-success' : ''}>
          {props.isOwned ? 'Supprimer' : 'Ajouter'}
        </span>
      </ButtonWithIcon>
    </div>
  );
};

HorizontalAddToCollectionZone.propTypes = {
  album: PropTypes.object,
  serie: PropTypes.object,
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.album === nextProps.album &&
    prevProps.serie === nextProps.serie &&
    prevProps.isOwned === nextProps.isOwned &&
    prevProps.noPreviousAlbumsOwned === nextProps.noPreviousAlbumsOwned &&
    prevProps.previousAlbumsFromSerie.map((album) => album.objectId).join('') ===
      nextProps.previousAlbumsFromSerie.map((album) => album.objectId).join('')
  );
};

const makeMapStateToProps = () => {
  const isNoPreviousAlbumsOwned = makeIsNoPreviousAlbumsOwned();
  const previousAlbumsFromSerie = makeGetPreviousAlbumsFromSerie();
  const mapStateToProps = (state, props) => {
    const album = props.album || {};
    const serie = props.serie || {};

    return {
      user: state.user.user,
      isOwned: isAlbumOwned(state, album.objectId),
      albumInLibrary: state.profiles.myAlbums[album.objectId],
      previousAlbumsFromSerie: previousAlbumsFromSerie(state, {
        albumObjectId: album.objectId,
        serieObjectId: serie.objectId,
      }),
      isNoPreviousAlbumsOwned: isNoPreviousAlbumsOwned(state, {
        albumObjectId: album.objectId,
        serieObjectId: serie.objectId,
      }),
    };
  };
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  null,
)(React.memo(HorizontalAddToCollectionZone, areEqual));

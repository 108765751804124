import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  addLikeApi,
  addReviewApi,
  deleteLikeApi,
  editReviewApi,
  loadAlbumReviewsApi,
  loadAppReviewsApi,
  loadMyReviewForSerieApi,
  loadMyReviewsApi,
  loadMyReviewsForAlbumApi,
  loadReviewApi,
  loadSerieReviewsApi,
} from '../services/api';

import {
  ADD_ALBUM_REVIEW,
  ADD_LIKE,
  ADD_SERIE_REVIEW,
  DELETE_LIKE,
  EDIT_ALBUM_REVIEW,
  EDIT_SERIE_REVIEW,
  LOAD_ALBUM_REVIEWS,
  LOAD_APP_REVIEWS,
  LOAD_MY_REVIEWS,
  LOAD_MY_REVIEWS_FOR_ALBUM,
  LOAD_MY_REVIEW_FOR_SERIE,
  LOAD_REVIEW,
  LOAD_SERIE_REVIEWS,
} from '../reducers/reviews';
import {
  addAlbumReviewFail,
  addAlbumReviewSuccess,
  addLikeFail,
  addLikeSuccess,
  addSerieReviewFail,
  addSerieReviewSuccess,
  deleteLikeFail,
  deleteLikeSuccess,
  editAlbumReviewFail,
  editAlbumReviewSuccess,
  editSerieReviewFail,
  editSerieReviewSuccess,
  loadAlbumReviews,
  loadAlbumReviewsFail,
  loadAlbumReviewsSuccess,
  loadAppReviewsFail,
  loadAppReviewsSuccess,
  loadMyReviewForSerie,
  loadMyReviewForSerieFail,
  loadMyReviewForSerieSuccess,
  loadMyReviewsFail,
  loadMyReviewsForAlbum,
  loadMyReviewsForAlbumFail,
  loadMyReviewsForAlbumSuccess,
  loadMyReviewsSuccess,
  loadReviewFail,
  loadReviewSuccess,
  loadSerieReviews,
  loadSerieReviewsFail,
  loadSerieReviewsSuccess,
} from '../reducers/reviews';
import { getClientToken } from '../selectors';
import { storeAlbumsFlow } from './albums';
import { commentLikeFlow, commentUnlikeFlow } from './comments.js';
import { storeSeriesFlow } from './series';
import { loadUserLikesFlow } from './user';

function* loadReviewFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadReviewApi, clientToken, action.payload.reviewObjectId);
    yield put(loadReviewSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadReviewFlow)', error);
    yield put(loadReviewFail(error));
  }
}

function* loadSerieReviewsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadSerieReviewsApi, clientToken, action.payload.serieObjectId);
    yield put(loadSerieReviewsSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadSerieReviewsFlow)', error);
    yield put(loadSerieReviewsFail(error));
  }
}

function* loadAlbumReviewsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadAlbumReviewsApi, clientToken, action.payload.albumObjectId);
    yield put(loadAlbumReviewsSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadAlbumReviewsFlow)', error);
    yield put(loadAlbumReviewsFail(error));
  }
}

function* loadAppReviewsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadAppReviewsApi, clientToken);
    yield put(loadAppReviewsSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadAppReviewsFlow)', error);
    yield put(loadAppReviewsFail(error));
  }
}

function* addSerieReviewFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(addReviewApi, clientToken, action.payload.review);
    yield put(loadSerieReviews(action.payload.review.serieObjectId));
    yield put(addSerieReviewSuccess(response, action.payload.review.serieObjectId));
  } catch (error) {
    console.log('saga middleware error (addSerieReviewFlow)', error);
    yield put(addSerieReviewFail(error));
  }
}

function* editSerieReviewFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      editReviewApi,
      clientToken,
      action.payload.reviewObjectId,
      action.payload.review,
    );
    yield all([
      put(loadSerieReviews(action.payload.review.serieObjectId)),
      put(
        loadMyReviewForSerie(
          action.payload.review.userObjectId,
          action.payload.review.serieObjectId,
        ),
      ),
    ]);
    yield put(editSerieReviewSuccess(response));
  } catch (error) {
    console.log('saga middleware error (editSerieReviewFlow)', error);
    yield put(editSerieReviewFail(error));
  }
}

function* loadMyReviewForSerieFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      loadMyReviewForSerieApi,
      clientToken,
      action.payload.userObjectId,
      action.payload.serieObjectId,
    );
    yield put(loadMyReviewForSerieSuccess(response, action.payload.serieObjectId));
  } catch (error) {
    console.log('saga middleware error (loadMyReviewForSerieFlow)', error);
    yield put(loadMyReviewForSerieFail(error));
  }
}

function* addAlbumReviewFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(addReviewApi, clientToken, action.payload.review);
    const albumObjectId = action.payload.albumObjectId;
    yield all([
      put(loadAlbumReviews(albumObjectId)),
      put(loadMyReviewsForAlbum(action.payload.review.userObjectId, albumObjectId)),
    ]);

    yield put(addAlbumReviewSuccess(response, albumObjectId));
  } catch (error) {
    console.log('saga middleware error (addAlbumReviewFlow)', error);
    yield put(addAlbumReviewFail(error));
  }
}

function* editAlbumReviewFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      editReviewApi,
      clientToken,
      action.payload.reviewObjectId,
      action.payload.review,
    );
    const albumObjectId = action.payload.albumObjectId;
    yield all([
      put(loadAlbumReviews(albumObjectId)),
      put(loadMyReviewsForAlbum(action.payload.review.userObjectId, albumObjectId)),
    ]);
    yield put(editAlbumReviewSuccess(response));
  } catch (error) {
    console.log('saga middleware error (editAlbumReviewFlow)', error);
    yield put(editAlbumReviewFail(error));
  }
}

function* loadMyReviewsForAlbumFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      loadMyReviewsForAlbumApi,
      clientToken,
      action.payload.userObjectId,
      action.payload.albumObjectId,
    );
    yield put(loadMyReviewsForAlbumSuccess(response, action.payload.albumObjectId));
  } catch (error) {
    console.log('saga middleware error (loadMyReviewsForAlbumFlow)', error);
    yield put(loadMyReviewsForAlbumFail(error));
  }
}

function* loadMyReviewsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadMyReviewsApi, clientToken, action.payload.userObjectId);
    yield all([
      call(storeSeriesFlow, {
        payload: { series: response[0]?.series || [], source: 'loadMyReviewsFlow' },
      }),
      call(storeAlbumsFlow, {
        payload: { albums: response[1]?.albums || [], source: 'storeAlbumsFlow' },
      }),
    ]);

    yield put(loadMyReviewsSuccess(response, action.payload.userObjectId));
  } catch (error) {
    console.log('saga middleware error (loadMyReviewsFlow)', error);
    yield put(loadMyReviewsFail(error));
  }
}

function* addLikeFlow(action) {
  try {
    const { like } = action.payload;
    const clientToken = yield select(getClientToken);
    yield call(addLikeApi, clientToken, like);
    if (like.serieObjectId) {
      yield call(loadSerieReviewsFlow, { payload: { serieObjectId: like.serieObjectId } });
    }
    if (like.albumObjectId) {
      yield call(loadAlbumReviewsFlow, { payload: { albumObjectId: like.albumObjectId } });
    }
    if (like.commentObjectId) {
      yield call(commentLikeFlow, { payload: { commentObjectId: like.commentObjectId } });
    }
    yield call(loadUserLikesFlow, { payload: { userObjectId: like.userObjectId } });
    yield put(addLikeSuccess());
  } catch (error) {
    console.log('saga middleware error (addLikeFlow)', error);
    yield put(addLikeFail(error));
  }
}

function* deleteLikeFlow(action) {
  try {
    const { like } = action.payload;
    const clientToken = yield select(getClientToken);
    yield call(deleteLikeApi, clientToken, like);

    if (like.serieObjectId) {
      yield call(loadSerieReviewsFlow, { payload: { serieObjectId: like.serieObjectId } });
    }

    if (like.albumObjectId) {
      yield call(loadAlbumReviewsFlow, { payload: { albumObjectId: like.albumObjectId } });
    }

    if (like.commentObjectId) {
      yield call(commentUnlikeFlow, { payload: { commentObjectId: like.commentObjectId } });
    }
    yield call(loadUserLikesFlow, { payload: { userObjectId: like.userObjectId } });
    yield put(deleteLikeSuccess());
  } catch (error) {
    console.log('saga middleware error (deleteLikeFlow)', error);
    yield put(deleteLikeFail(error));
  }
}

export default function* reviewsWatcher() {
  yield all([
    takeLatest(LOAD_REVIEW, loadReviewFlow),
    takeLatest(LOAD_SERIE_REVIEWS, loadSerieReviewsFlow),
    takeLatest(LOAD_ALBUM_REVIEWS, loadAlbumReviewsFlow),
    takeLatest(LOAD_APP_REVIEWS, loadAppReviewsFlow),
    takeLatest(ADD_SERIE_REVIEW, addSerieReviewFlow),
    takeLatest(LOAD_MY_REVIEW_FOR_SERIE, loadMyReviewForSerieFlow),
    takeLatest(EDIT_SERIE_REVIEW, editSerieReviewFlow),
    takeLatest(ADD_ALBUM_REVIEW, addAlbumReviewFlow),
    takeLatest(LOAD_MY_REVIEWS_FOR_ALBUM, loadMyReviewsForAlbumFlow),
    takeLatest(EDIT_ALBUM_REVIEW, editAlbumReviewFlow),
    takeLatest(LOAD_MY_REVIEWS, loadMyReviewsFlow),
    takeLatest(ADD_LIKE, addLikeFlow),
    takeLatest(DELETE_LIKE, deleteLikeFlow),
  ]);
}

import PropTypes from 'prop-types';
import React from 'react';

import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';

import placeholderJpegUrl from '@/assets/placeholders/placeholder-item.jpg';
import placeholderWebpUrl from '@/assets/placeholders/placeholder-item.webp';

import './Cover.css';

class Cover extends React.Component {
  static propTypes = {
    rounded: PropTypes.bool,
    roundedLeft: PropTypes.bool,
    hoverable: PropTypes.bool,
    shadow: PropTypes.bool,
    height: PropTypes.number,
    fullWidth: PropTypes.bool,
    imageUrl: PropTypes.string,
    alt: PropTypes.string,
  };

  constructor(props) {
    super(props);

    const isFullWidth = 'fullWidth' in props;
    this.styles = Object.assign(
      this.props.width ? { width: this.props.width } : {},
      !isFullWidth
        ? {
            height: this.props.height ? this.props.height : null,
          }
        : {},
    );

    this.classNames = [
      'overflow-hidden',
      'rounded' in props ? 'rounded' : '',
      'roundedLeft' in props ? 'rounded-start' : '',
      'shadow' in props ? 'bb-shadow-hover' : '',
      isFullWidth ? 'w-100' : '',
      this.props.className,
    ]
      .filter((row) => row)
      .join(' ');

    this.state = {
      imageLoaded: false,
      imageUrl: props.imageUrl,
    };
  }

  static getDerivedStateFromProps(nextProps, lastState) {
    if (nextProps.imageUrl !== lastState.imageUrl && lastState.imageUrl !== placeholderJpegUrl) {
      return {
        imageUrl: nextProps.imageUrl,
        imageLoaded: false,
      };
    }
    return null;
  }

  addDefaultSrc = (event) => {
    this.setState({
      imageLoaded: true,
      imageUrl: placeholderJpegUrl,
    });
  };

  addSrc = (event) => {
    this.setState({
      imageLoaded: true,
    });
  };

  renderFinalImage() {
    return (
      <div>
        <img
          style={this.styles}
          className={this.classNames}
          alt={this.props.alt || ''}
          src={this.state.imageUrl || placeholderJpegUrl}
        />
      </div>
    );
  }

  render() {
    return (
      <>
        {!!this.state.imageUrl && !this.state.imageLoaded && (
          <div>
            <img
              onLoad={this.addSrc}
              onError={this.addDefaultSrc}
              className="d-none"
              alt={this.props.alt || ''}
              src={this.state.imageUrl + '#' + new Date().getTime()}
            />
          </div>
        )}

        {!this.state.imageLoaded ? (
          <WebpWrapper
            webp={placeholderWebpUrl}
            default={placeholderJpegUrl}
            style={this.styles}
            className={this.classNames}
            alt={this.props.alt || ''}
          />
        ) : (
          this.renderFinalImage()
        )}
      </>
    );
  }
}

export default Cover;

import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  createAuthorRevisionApi,
  loadAuthorApi,
  loadAuthorTagsApi,
  loadAuthorsAtAlphanumericIndexApi,
  loadRelatedAuthorsApi,
} from '../services/api';

import {
  CREATE_AUTHOR_REVISION,
  LOAD_AUTHOR,
  LOAD_AUTHORS_ALPHANUMERIC_INDEX,
  LOAD_AUTHOR_TAGS,
  LOAD_RELATED_AUTHORS,
  loadAuthorTagsFail,
  loadAuthorTagsSuccess,
  storeAuthors,
} from '../reducers/authors';
import {
  createAuthorRevisionFail,
  createAuthorRevisionSuccess,
  loadAuthorFail,
  loadAuthorSuccess,
  loadAuthorsAtAlphanumericIndexFail,
  loadAuthorsAtAlphanumericIndexSuccess,
  loadRelatedAuthorsFail,
  loadRelatedAuthorsSuccess,
} from '../reducers/authors';
import { getClientToken } from '../selectors';

export function* loadAuthorFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadAuthorApi, clientToken, action.payload.authorObjectId);
    yield put(storeAuthors([response], 'loadAuthor'));
    yield put(loadAuthorSuccess());
  } catch (error) {
    console.log('saga middleware error (loadAuthorFlow)', error);
    yield put(loadAuthorFail(error));
  }
}

function* loadRelatedAuthorsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const relatedAuthors = yield call(
      loadRelatedAuthorsApi,
      clientToken,
      action.payload.authorObjectId,
    );
    yield put(storeAuthors(relatedAuthors, 'loadRelatedAuthors'));
    yield put(loadRelatedAuthorsSuccess(relatedAuthors, action.payload.authorObjectId));
  } catch (error) {
    console.log('saga middleware error (loadRelatedAuthorsFlow)', error);
    yield put(loadRelatedAuthorsFail(error));
  }
}

function* loadAuthorTagsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const tags = yield call(loadAuthorTagsApi, clientToken, action.payload.authorObjectId);
    yield put(loadAuthorTagsSuccess(action.payload.authorObjectId, tags));
  } catch (error) {
    console.log('saga middleware error (loadAuthorTagsFlow)', error);
    yield put(loadAuthorTagsFail(error));
  }
}

function* createAuthorRevisionFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      createAuthorRevisionApi,
      clientToken,
      action.payload.authorObjectId,
      action.payload.revisions,
    );
    yield put(createAuthorRevisionSuccess(response));
  } catch (error) {
    console.log('saga middleware error (createAuthorRevisionFlow)', error);
    yield put(createAuthorRevisionFail(error));
  }
}

function* loadAuthorsAtAlphanumericIndexFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      loadAuthorsAtAlphanumericIndexApi,
      clientToken,
      action.payload.index,
    );
    yield put(loadAuthorsAtAlphanumericIndexSuccess(action.payload.index, response));
  } catch (error) {
    console.log('saga middleware error (loadAuthorsAtAlphanumericIndexFlow)', error);
    yield put(loadAuthorsAtAlphanumericIndexFail(error));
  }
}

export default function* authorsWatcher() {
  yield all([
    takeEvery(LOAD_AUTHOR, loadAuthorFlow),
    takeLatest(LOAD_RELATED_AUTHORS, loadRelatedAuthorsFlow),
    takeEvery(CREATE_AUTHOR_REVISION, createAuthorRevisionFlow),
    takeEvery(LOAD_AUTHORS_ALPHANUMERIC_INDEX, loadAuthorsAtAlphanumericIndexFlow),
    takeLatest(LOAD_AUTHOR_TAGS, loadAuthorTagsFlow),
  ]);
}

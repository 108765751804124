import config from '@/project-config';

import { saveJwtToken, saveSessionId } from 'bubble-reducers/src/reducers/auth';
import {
  loadGuestSuccess,
  loadUser,
  loadUserSuccess,
  setUserObjectIdOnly,
} from 'bubble-reducers/src/reducers/user';

import {
  createCookie,
  getCookie,
  getInLocalStorage,
  parseJwtToken,
} from 'bubble-utils/src/auth-utils';

import bubbleUtils from 'bubble-utils';

export const checkAuthentication = (store, allowedRoles = null) => {
  const storedJwtToken = getCookie(config.cookies.jwtToken.name);
  const storedSessionId =
    getCookie(config.cookies.sessionId.name) ||
    createCookie(
      config.cookies.sessionId.name,
      bubbleUtils.string.generateRandomString(24, false, true),
      config.cookies.defaultOptions,
    );

  if (storedJwtToken) {
    const tokenContent = parseJwtToken(storedJwtToken);
    const userInLocalStorage = getInLocalStorage(config.localStorage.user.keyName);

    // dispatch the token to our saveJwtToken action
    // which will update our redux state with the token and return true
    // save the token first for future api calls
    store.dispatch(saveJwtToken(storedJwtToken));
    // reuse local stored user/guest if it matches the objectId in JWT token otherwise load the new user
    if (
      userInLocalStorage &&
      userInLocalStorage.user &&
      tokenContent &&
      tokenContent.objectId === userInLocalStorage.user.objectId &&
      tokenContent.objectId
    ) {
      store.dispatch(loadUserSuccess(userInLocalStorage.user));
    } else if (
      userInLocalStorage &&
      userInLocalStorage.guest &&
      tokenContent.objectId === userInLocalStorage.guest.objectId &&
      tokenContent.objectId
    ) {
      store.dispatch(loadGuestSuccess(userInLocalStorage.guest));
    } else if (!tokenContent.isGuest) {
      //TODO: load guest maybe?
      store.dispatch(loadUser(tokenContent.objectId));
    }
  }

  store.dispatch(saveSessionId(storedSessionId));
};

export const checkAuthenticationOnServer = (store, cookies, allowedRoles = null) => {
  let storedJwtToken = cookies[config.cookies.jwtToken.name];
  const storedSessionId = cookies[config.cookies.sessionId.name];

  // testing token for server side rendering
  // storedJwtToken =
  //   'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiaVBqWXRKRXlRTyIsIm9iamVjdElkIjoiaVBqWXRKRXlRTyIsInJvbGVzIjpbInVzZXIiLCJhZG1pbiIsImNhdGFsb2dfZWRpdG9yIl0sImlhdCI6MTU2NzQxOTkxOSwiZXhwIjoxNTcwMDExOTE5fQ.MmAbYzt1ML702ijMqzhOzKVMbGonnPssrv4OOnesF1g';
  if (storedJwtToken) {
    const tokenContent = parseJwtToken(storedJwtToken);

    // dispatch the token to our saveJwtToken action
    // which will update our redux state with the token
    // save the token first for future api calls and load the user
    store.dispatch(saveJwtToken(storedJwtToken));
    if (tokenContent && tokenContent.objectId) {
      store.dispatch(setUserObjectIdOnly(tokenContent.objectId));
      store.dispatch(loadUser(tokenContent.objectId));
    }
  }
  store.dispatch(saveSessionId(storedSessionId));
};

export const UPDATE_PROMOTION = 'UPDATE_PROMOTION';
export const UPDATE_PROMOTION_SUCCESS = 'UPDATE_PROMOTION_SUCCESS';
export const UPDATE_PROMOTION_FAIL = 'UPDATE_PROMOTION_FAIL';

export const CREATE_PROMOTION = 'CREATE_PROMOTION';
export const CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS';
export const CREATE_PROMOTION_FAIL = 'CREATE_PROMOTION_FAIL';

export const DELETE_PROMOTION = 'DELETE_PROMOTION';
export const DELETE_PROMOTION_SUCCESS = 'DELETE_PROMOTION_SUCCESS';
export const DELETE_PROMOTION_FAIL = 'DELETE_PROMOTION_FAIL';

export const LOAD_PROMOTIONS = 'LOAD_PROMOTIONS';
export const LOAD_PROMOTIONS_SUCCESS = 'LOAD_PROMOTIONS_SUCCESS';
export const LOAD_PROMOTIONS_FAIL = 'LOAD_PROMOTIONS_FAIL';

export const LOAD_PROMOTION = 'LOAD_PROMOTION';
export const LOAD_PROMOTION_SUCCESS = 'LOAD_PROMOTION_SUCCESS';
export const LOAD_PROMOTION_FAIL = 'LOAD_PROMOTION_FAIL';

const initialState = {
  promotionsMapByObjectId: [],
  loading: {},
  errors: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_PROMOTION: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_PROMOTION]: true },
      };
    }

    case LOAD_PROMOTION_SUCCESS: {
      return {
        ...state,
        promotionsMapByObjectId: {
          ...state.promotionsMapByObjectId,
          [action.payload.promotion.objectId]: action.payload.promotion,
        },
        loading: { ...state.loading, [LOAD_PROMOTION]: false },
      };
    }

    case LOAD_PROMOTION_FAIL: {
      return {
        ...state,
        errors: state.errors.concat([{ body: action.payload.error.toString() }]),
        loading: { ...state.loading, [LOAD_PROMOTION]: true },
      };
    }

    case LOAD_PROMOTIONS: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_PROMOTIONS]: true },
      };
    }

    case LOAD_PROMOTIONS_SUCCESS: {
      const promotionsMapByObjectId = (action.payload.promotions || []).reduce(
        (map, cur) => ((map[cur.objectId] = cur), map),
        {},
      );
      return {
        ...state,
        promotionsMapByObjectId,
        loading: { ...state.loading, [LOAD_PROMOTIONS]: false },
      };
    }
    case LOAD_PROMOTIONS_FAIL: {
      return {
        ...state,
        errors: state.errors.concat([{ body: action.payload.error.toString() }]),
        loading: { ...state.loading, [LOAD_PROMOTIONS]: false },
      };
    }

    case CREATE_PROMOTION: {
      return {
        ...state,
        loading: { ...state.loading, [CREATE_PROMOTION]: true },
      };
    }
    case CREATE_PROMOTION_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, [CREATE_PROMOTION]: false },
      };
    }
    case CREATE_PROMOTION_FAIL: {
      return {
        ...state,
        errors: state.errors.concat([{ body: action.payload.error.toString() }]),
        loading: { ...state.loading, [CREATE_PROMOTION]: false },
      };
    }

    case UPDATE_PROMOTION: {
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_PROMOTION]: true },
      };
    }
    case UPDATE_PROMOTION_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_PROMOTION]: false },
      };
    }
    case UPDATE_PROMOTION_FAIL: {
      return {
        ...state,
        errors: state.errors.concat([{ body: action.payload.error.toString() }]),
        loading: { ...state.loading, [UPDATE_PROMOTION]: false },
      };
    }

    case DELETE_PROMOTION: {
      return {
        ...state,
        loading: { ...state.loading, [DELETE_PROMOTION]: true },
      };
    }
    case DELETE_PROMOTION_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, [DELETE_PROMOTION]: false },
      };
    }
    case DELETE_PROMOTION_FAIL: {
      return {
        ...state,
        errors: state.errors.concat([{ body: action.payload.error.toString() }]),
        loading: { ...state.loading, [DELETE_PROMOTION]: false },
      };
    }
    default:
      return state;
  }
}

// ======= loadPromotions

export const loadPromotions = (paramsString) => {
  return {
    type: LOAD_PROMOTIONS,
    payload: { paramsString },
  };
};

export const loadPromotionsSuccess = (promotions) => {
  return {
    type: LOAD_PROMOTIONS_SUCCESS,
    payload: {
      promotions,
    },
  };
};

export const loadPromotionsFail = (error) => {
  return {
    type: LOAD_PROMOTIONS_FAIL,
    payload: {
      error,
    },
  };
};

// ======= loadPromotion

export const loadPromotion = (promotionObjectId) => {
  return {
    type: LOAD_PROMOTION,
    payload: {
      promotionObjectId,
    },
  };
};

export const loadPromotionSuccess = (promotion) => {
  return {
    type: LOAD_PROMOTION_SUCCESS,
    payload: {
      promotion,
    },
  };
};

export const loadPromotionFail = (error) => {
  return {
    type: LOAD_PROMOTION_FAIL,
    payload: {
      payload: error,
    },
  };
};

// ======= deletePromotion

export const deletePromotion = (promotionObjectId) => {
  return {
    type: DELETE_PROMOTION,
    payload: {
      promotionObjectId,
    },
  };
};

export const deletePromotionSuccess = () => {
  return {
    type: DELETE_PROMOTION_SUCCESS,
  };
};

export const deletePromotionFail = (error) => {
  return {
    type: DELETE_PROMOTION_FAIL,
    payload: {
      payload: error,
    },
  };
};

// ======= updatePromotion

export const updatePromotion = (promotionObjectId, fieldsAndData) => {
  return {
    type: UPDATE_PROMOTION,
    payload: {
      promotionObjectId,
      fieldsAndData,
    },
  };
};

export const updatePromotionSuccess = () => {
  return {
    type: UPDATE_PROMOTION_SUCCESS,
  };
};

export const updatePromotionFail = (error) => {
  return {
    type: UPDATE_PROMOTION_FAIL,
    payload: {
      payload: error,
    },
  };
};

// ======= createPromotion

export const createPromotion = (promotion) => {
  return {
    type: CREATE_PROMOTION,
    payload: {
      promotion,
    },
  };
};

export const createPromotionSuccess = () => {
  return {
    type: CREATE_PROMOTION_SUCCESS,
  };
};

export const createPromotionFail = (error) => {
  return {
    type: CREATE_PROMOTION_FAIL,
    payload: {
      payload: error,
    },
  };
};

import config from '@/project-config';

const imageUtils = {};

imageUtils.getAssetImage = (imageName) => {
  return config.resources.domain + '/assets/' + imageName;
};
imageUtils.getBannerImage = (imageName) => {
  return config.resources.domain + '/highlighted/' + imageName;
};

export default imageUtils;

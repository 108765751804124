import React, { useEffect, useState } from 'react';

import config from '@/project-config';
import FacebookLogin from 'react-facebook-login';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { login, loginWithFacebook, logout, signup } from 'bubble-reducers/src/reducers/auth';

import { guestCheckoutRedirect } from '@/engine/reducers/website-guest';

import { isValidEmail } from 'bubble-utils/src/validity-utils';

import BubbleCheckbox from '@/components/BubbleCheckbox/BubbleCheckbox';
import { Icon } from '@/components/Icon/Icon';
import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';
import ForgotPasswordModal from '@/components/Modals/ForgotPasswordModal';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import './Login.scss';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const user = useSelector((state) => state.user.user);
  const guest = useSelector((state) => state.user.guest);
  const errors = useSelector((state) => state.auth.errors);
  const signupErrors = useSelector((state) => state.auth.signupErrors);

  const canLogAsGuest = location?.state?.canLogAsGuest;

  const [loginInfos, setLoginInfos] = useState({});
  const [signupInfos, setSignupInfos] = useState({});
  const [guestInfos, setGuestInfos] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showReferral, setShowReferral] = useState(false);

  const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false);

  // force clean guest infos at every page opening to avoid guest being stuck in logged in mode
  useEffect(() => {
    if (!!guest?.objectId) {
      dispatch(
        logout(
          {
            name: config.cookies.jwtToken.name,
            options: config.cookies.defaultOptions,
          },
          {
            keyName: config.localStorage.user.keyName,
          },
        ),
      );
    }
  }, []);

  // using guestInfos.email to avoid execution at page opening and wait for guest to signup
  useEffect(() => {
    if (!!guest?.objectId && guestInfos.email && !!canLogAsGuest) {
      return navigate('/checkout');
    }
  }, [guest?.objectId, guestInfos.email, canLogAsGuest]);

  useEffect(() => {
    const redirectTo =
      (location?.state?.to ? location?.state?.to : location?.state?.from) || '/my-account/home';

    if (!!user?.objectId) {
      return navigate(redirectTo);
    }
  }, [user?.objectId]);

  useEffect(() => {
    const signup = { ...signupInfos };
    // precheck newsletter for newsletter optin button
    if (searchParams.get('nl')) {
      signup.hasAcceptedNewsletters = true;
    }
    // precheck newsletter for everybody
    signup.hasAcceptedNewsletters = true;
    if (location.state && location.state.signupEmail) {
      signup.email = location.state.signupEmail;
    }
    setSignupInfos(signup);
  }, []);

  const onChangeField = (type, field, value) => {
    if (type === 'login') {
      setLoginInfos({ ...loginInfos, [field]: value });
    } else if (type === 'signup') {
      setSignupInfos({ ...signupInfos, [field]: value });
    } else if (type === 'guest') {
      setGuestInfos({ ...guestInfos, [field]: value });
    }
  };

  const handleLogin = () => {
    if (!loginInfos.email || !loginInfos.password) {
      return alert('Veuillez remplir tous les champs obligatoires');
    }

    // We force stay logged at true for now
    dispatch(
      login(
        loginInfos.email,
        loginInfos.password,
        true,
        {
          name: config.cookies.jwtToken.name,
          options: config.cookies.defaultOptions,
        },
        {
          keyName: config.localStorage.user.keyName,
        },
      ),
    );
  };

  const handleSignup = () => {
    if (!signupInfos.email || !signupInfos.password) {
      return alert('Veuillez remplir tous les champs obligatoires');
    }
    if (!signupInfos.isGdprCompliant) {
      return alert("Veuillez accepter les Conditions Générales d'Utilisation pour continuer");
    }
    if (!isValidEmail(signupInfos.email)) {
      return alert('Votre e-mail semble incorrect');
    }
    if (signupInfos.password.length < 7) {
      return alert('Votre mot de passe doit contenir au moins 7 caractères');
    }

    dispatch(
      signup(
        signupInfos.email,
        signupInfos.password,
        signupInfos.hasAcceptedNewsletters,
        signupInfos.isGdprCompliant,
        null,
        {
          name: config.cookies.jwtToken.name,
          options: config.cookies.defaultOptions,
        },
        {
          keyName: config.localStorage.user.keyName,
        },
        signupInfos.referralCode || null,
        'website',
      ),
    );
  };

  const handleEnterLogin = (event) => {
    event.preventDefault();
    handleLogin();
  };

  const handleEnterSignup = (event) => {
    event.preventDefault();
    handleSignup();
  };

  const guestSignin = (e) => {
    if (!isValidEmail(guestInfos.email)) {
      return alert('Votre e-mail semble invalide');
    }

    dispatch(
      guestCheckoutRedirect(
        guestInfos.email,
        {
          name: config.cookies.jwtToken.name,
          options: config.cookies.defaultOptions,
        },
        {
          keyName: config.localStorage.user.keyName,
        },
      ),
    );
  };

  const onFacebookResponse = (response, method) => {
    dispatch(
      loginWithFacebook(
        // while debugging missing access token
        response.accessToken ? response.accessToken : JSON.stringify(response),
        response.data_access_expiration_time,
        config.platform,
        method,
        {
          name: config.cookies.jwtToken.name,
          options: config.cookies.defaultOptions,
        },
        {
          keyName: config.localStorage.user.keyName,
        },
        signupInfos?.referralCode || null,
      ),
    );
  };

  const onClickShowPassword = (e) => {
    setShowPassword(!showPassword);
  };

  const switchShowReferral = () => setShowReferral(!showReferral);

  const handleShowForgetPasswordModal = () => {
    setShowForgetPasswordModal(true);
  };

  const renderLogin = () => {
    return (
      <div className="col-md-6 col-lg-5 col-xl-4 offset-lg-1 offset-xl-2 mt-3">
        <div className="p-5 text-center bg-white shadow rounded">
          <h3 className="mb-4">J'ai déjà un compte Bubble</h3>
          {/* <button className="btn bb-btn-google btn-lg mb-2">
            <Icon className="me-3" prefix="fab" name="google" />
            Connexion avec Google
          </button> */}
          <FacebookLogin
            appId={config.facebook.appId}
            autoLoad={false}
            version={config.facebook.apiVersion}
            fields="name,email,picture"
            callback={(response) => onFacebookResponse(response, 'signin')}
            textButton="Connexion avec Facebook"
            disableMobileRedirect={true}
            icon={<Icon className="me-3" prefix="fab" name="facebook-f" />}
            cssClass="btn bb-btn-fb btn-lg mb-2 w-100"
          />

          <div className="d-flex align-items-center my-3">
            <div className="d-flex flex-fill h-separator reverse" />
            <div className="mx-3">ou</div>
            <div className="d-flex flex-fill  h-separator " />
          </div>

          <div className="text-start">
            <form>
              <div className="pb-3">
                <LabeledTextInput
                  mandatory
                  onEnterPressed={handleEnterLogin}
                  title="Adresse e-mail"
                  value={loginInfos.email || ''}
                  onChange={(e) => onChangeField('login', 'email', e.target.value)}
                  id="emailform"
                  autoComplete="email"
                  type="text"
                />
              </div>
              <div>
                <LabeledTextInput
                  mandatory
                  onEnterPressed={handleEnterLogin}
                  title="Mot de passe"
                  value={loginInfos.password || ''}
                  onChange={(e) => onChangeField('login', 'password', e.target.value)}
                  id="passwordform"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  rightComponent={
                    <WithClickHandler onClick={() => handleShowForgetPasswordModal()}>
                      <div className="text-muted">
                        <u>Mot de passe oublié?</u>
                      </div>
                    </WithClickHandler>
                  }
                  buttonStyle={'btn btn-link labeled-text-input no-left-boder no-decoration'}
                  buttonLabel={<Icon name={showPassword ? 'eye-slash' : 'eye'} />}
                  buttonCallback={onClickShowPassword}
                />
              </div>
            </form>
          </div>
          <div className="text-start mt-3">
            <button onClick={handleLogin} className="btn px-5 btn-lg text-white btn-secondary">
              Connexion
            </button>
          </div>
          {errors.length > 0 && errors[0] && (
            <div className="text-danger mt-4 text-start">{errors[0].message || errors[0]}</div>
          )}
        </div>
      </div>
    );
  };

  const renderSignup = () => {
    let errorMessage = null;
    if (signupErrors.length) {
      errorMessage = signupErrors[0].message;
      if (errorMessage.includes('Vous êtes')) {
        const i = errorMessage.indexOf('Vous');
        errorMessage = errorMessage.substr(0, i - 1);
      }
    }

    return (
      <div className="col-md-6 col-lg-5 col-xl-4 mt-3">
        <div className="p-5 text-center bg-white rounded">
          <h3 className="mb-4">Je n'ai pas de compte Bubble</h3>
          {canLogAsGuest && (
            <div className="pb-5 gap-2 d-grid">
              <LabeledTextInput
                title="Adresse e-mail"
                value={guestInfos.email}
                onChange={(e) => onChangeField('guest', 'email', e.target.value)}
                id="guestEmail"
                type="email"
                autoComplete="email"
                rightComponent={<div />}
              />
              <button
                className="btn bb-btn-guest btn-secondary text-white btn-lg"
                onClick={guestSignin}
              >
                Continuer en tant qu'invité
              </button>
            </div>
          )}
          <div className="text-start mb-4">
            Je crée mon compte maintenant pour{' '}
            <span className="fw-bold">bénéficier gratuitement de Bubble Infinity</span> pendant 1
            mois
          </div>

          {/* <button className="btn bb-btn-google btn-lg mb-2">
            <Icon className="me-3" prefix="fab" name="google" />
            Inscription avec Goggle
          </button> */}
          <FacebookLogin
            appId={config.facebook.appId}
            autoLoad={false}
            version={config.facebook.apiVersion}
            fields="name,email,picture"
            callback={(response) => onFacebookResponse(response, 'signup')}
            textButton="Inscription avec Facebook"
            disableMobileRedirect={true}
            icon={<Icon className="me-3" prefix="fab" name="facebook-f" />}
            cssClass="btn bb-btn-fb btn-lg mb-2 w-100"
          />

          <div className="d-flex align-items-center my-3">
            <div className="d-flex flex-fill h-separator reverse" />
            <div className="mx-3">ou</div>
            <div className="d-flex flex-fill  h-separator " />
          </div>

          <div className="text-start">
            <form>
              <div className="pb-3">
                <LabeledTextInput
                  mandatory
                  onEnterPressed={handleEnterSignup}
                  title="Adresse e-mail"
                  value={signupInfos.email || ''}
                  onChange={(e) => onChangeField('signup', 'email', e.target.value)}
                  id="signupemailform"
                  autoComplete="email"
                  type="text"
                />
              </div>

              <div>
                <LabeledTextInput
                  mandatory
                  rightComponent="7 caractères minimum"
                  onEnterPressed={handleEnterSignup}
                  title="Mot de passe"
                  value={signupInfos.password || ''}
                  onChange={(e) => onChangeField('signup', 'password', e.target.value)}
                  id="signuppasswordform"
                  autoComplete="new-password"
                  type="password"
                />
              </div>

              <div className="pt-3 pb-4">
                <div className="d-flex align-items-center">
                  <WithClickHandler
                    className="text-secondary no-select"
                    onClick={switchShowReferral}
                  >
                    J'ai un code de parrainage
                  </WithClickHandler>
                  <Icon name="chevron-down ps-1 text-secondary" style={{ fontSize: 10 }} />
                </div>

                {showReferral && (
                  <LabeledTextInput
                    onEnterPressed={handleEnterSignup}
                    title="Code parrain"
                    value={signupInfos.referralCode || ''}
                    onChange={(e) => onChangeField('signup', 'referralCode', e.target.value)}
                    id="referralform"
                    type="text"
                  />
                )}
              </div>

              <div className="d-flex align-items-center">
                <BubbleCheckbox
                  checked={signupInfos.hasAcceptedNewsletters || false}
                  onChange={(e) =>
                    onChangeField(
                      'signup',
                      'hasAcceptedNewsletters',
                      !signupInfos.hasAcceptedNewsletters,
                    )
                  }
                  id="newslettercheck"
                />
                <label className="text-muted mb-0 ms-2" htmlFor="newslettercheck">
                  S'inscrire aux newsletters
                </label>
              </div>
              <div className="d-flex align-items-center pt-2">
                <BubbleCheckbox
                  checked={signupInfos.isGdprCompliant || false}
                  onChange={(e) =>
                    onChangeField('signup', 'isGdprCompliant', !signupInfos.isGdprCompliant)
                  }
                  id="cguvcheck"
                />
                <label className="text-muted mb-0 ms-2 mt-1" htmlFor="cguvcheck">
                  J'accepte les{' '}
                  <Link
                    target="_blank"
                    to="/conditions-generales-de-vente-et-d-utilisation-marketplace"
                  >
                    Conditions Générales d'Utilisation
                  </Link>
                </label>
              </div>
            </form>
          </div>
          <div className="text-start mt-4">
            <button onClick={handleSignup} className="btn btn-lg text-white px-5 btn-secondary">
              Créer mon compte
            </button>
          </div>
          {errorMessage && <div className="text-danger mt-4 text-start">{errorMessage}</div>}
        </div>
      </div>
    );
  };

  return (
    <div className="bb-background-light-grey py-5">
      <div className="container">
        <div className="row">
          {showForgetPasswordModal && (
            <ForgotPasswordModal
              show={showForgetPasswordModal}
              callback={() => setShowForgetPasswordModal(false)}
            />
          )}
          {renderLogin()}
          {renderSignup()}
        </div>
      </div>
    </div>
  );
};

export default Login;

import {
  all,
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  checkJwtTokenValidityApi,
  loginApi,
  loginGuestApi,
  loginWithAppleApi,
  loginWithFacebookApi,
  loginWithFacebookJwtTokenApi,
  resetPasswordApi,
  resetPasswordVerifyTokenApi,
  signupApi,
  updatePasswordApi,
  updatePasswordFromEmailApi,
} from '../services/api';

import {
  CHECK_JWT_TOKEN_VALIDITY,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_GUEST_REQUEST,
  LOGIN_WITH_APPLE,
  LOGIN_WITH_APPLE_ERROR,
  LOGIN_WITH_FACEBOOK,
  LOGIN_WITH_FACEBOOK_ERROR,
  LOGIN_WITH_FACEBOOK_JWT_TOKEN,
  LOGOUT,
  RESET_PASSWORD,
  SIGNUP,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FROM_EMAIL,
  UPDATE_PASSWORD_VERIFY_TOKEN,
} from '../reducers/auth';
import {
  checkJwtTokenValidityFail,
  checkJwtTokenValiditySuccess,
  deleteJwtToken,
  loginFail,
  loginGuestFail,
  loginGuestSuccess,
  loginSuccess,
  loginWithAppleFail,
  loginWithAppleSuccess,
  loginWithFacebookFail,
  loginWithFacebookSuccess,
  logout,
  resetPasswordFail,
  resetPasswordSuccess,
  saveJwtToken,
  signupFail,
  signupSuccess,
  updatePasswordFail,
  updatePasswordFromEmailFail,
  updatePasswordFromEmailSuccess,
  updatePasswordSuccess,
  updatePasswordVerifyFail,
  updatePasswordVerifySuccess,
} from '../reducers/auth';
import { resetAvailabilityAlertsReducer } from '../reducers/availability-alerts';
import { resetCartReducer } from '../reducers/cart';
import { logEvent } from '../reducers/events';
import { resetOrdersReducer } from '../reducers/orders';
import { resetPaymentMethodsReducer } from '../reducers/payment-methods';
import { resetProfilesReducer } from '../reducers/profiles';
import { resetReviewsReducer } from '../reducers/reviews';
import { loadGuestSuccess, loadUserSuccess, resetUserReducer } from '../reducers/user';
import { getGuestId, getSessionId } from '../selectors';
import { getClientToken } from '../selectors';

import { EVENT } from 'bubble-constants';

function* signupFlow(action) {
  try {
    const payload = action.payload;
    const sessionId = yield select(getSessionId);
    const guestId = yield select(getGuestId);
    const options = {};
    if (guestId) {
      options.guestId = guestId;
    } else {
      options.sessionId = sessionId;
    }
    if (payload.referralCode) {
      options.referralCode = payload.referralCode;
    }
    const authData = yield call(
      signupApi,
      payload.email,
      payload.password,
      payload.hasAcceptedNewsletter,
      payload.isGdprCompliant,
      payload.token,
      payload.platform,
      options,
    );

    yield put(saveJwtToken(authData.token));
    yield put(loadUserSuccess(authData.user));
    yield put(signupSuccess(payload, authData));
    yield put(logEvent(EVENT.SIGNUP, { method: 'Email' }));
  } catch (error) {
    console.log('saga middleware error (signupFlow)', error);
    yield put(signupFail(error));
  }
}

function* logoutFlow(action) {
  // call event first to get user information before deletion
  yield put(logEvent(EVENT.LOGOUT));

  yield put(deleteJwtToken());
  yield all([
    put(resetUserReducer()),
    put(resetProfilesReducer()),
    put(resetPaymentMethodsReducer()),
    put(resetOrdersReducer()),
    put(resetCartReducer()),
    put(resetAvailabilityAlertsReducer()),
    put(resetReviewsReducer()),
  ]);
}

function* loginFlow(action) {
  try {
    const payload = action.payload;
    const sessionId = yield select(getSessionId);
    const guestId = yield select(getGuestId);
    const options = {};
    if (guestId) {
      options.guestId = guestId;
    } else {
      options.sessionId = sessionId;
    }
    const authData = yield call(loginApi, payload.email, payload.password, options);

    yield put(saveJwtToken(authData.token));
    yield put(loadUserSuccess(authData.user));
    yield put(loginSuccess(payload, authData));
    yield put(logEvent(EVENT.LOGIN, { method: 'Email' }));
  } catch (error) {
    console.log('saga middleware error (loginFlow)', error);
    yield put(loginFail(error));
  }
}

export function* loginGuestFlow(action) {
  try {
    const payload = action.payload;
    const sessionId = yield select(getSessionId);
    const guestId = yield select(getGuestId);
    const options = {};
    if (guestId) {
      options.guestId = guestId;
    } else {
      options.sessionId = sessionId;
    }
    const authData = yield call(loginGuestApi, payload.email, options);

    yield put(saveJwtToken(authData.token));
    yield put(loadGuestSuccess(authData.guest));
    yield put(loginGuestSuccess(payload, authData));
    yield put(logEvent(EVENT.SIGNUP_GUEST, { method: 'Email' }));
  } catch (error) {
    console.log('saga middleware error (loginGuestFlow)', error);
    yield put(loginGuestFail(error));
  }
}

function* loginWithFacebookFlow(action) {
  try {
    const payload = action.payload;
    const authData = yield call(
      loginWithFacebookApi,
      payload.accessToken,
      payload.expirationTime,
      payload.method,
      payload.platform,
      payload.referralCode,
    );
    yield put(saveJwtToken(authData.token));
    yield put(loadUserSuccess(authData.user));
    yield put(loginWithFacebookSuccess(payload, authData));
    yield put(
      logEvent(authData.user.isNewUser ? EVENT.SIGNUP : EVENT.LOGIN, { method: 'Facebook' }),
    );
  } catch (error) {
    console.log('saga middleware error (loginWithFacebookFlow)', error);
    yield put(loginWithFacebookFail(error));
  }
}

function* loginWithFacebookJwtTokenFlow(action) {
  try {
    const payload = action.payload;
    const authData = yield call(
      loginWithFacebookJwtTokenApi,
      payload.authenticationToken,
      payload.method,
      payload.platform,
      payload.nonce,
      payload.referralCode,
    );
    yield put(saveJwtToken(authData.token));
    yield put(loadUserSuccess(authData.user));
    yield put(loginWithFacebookSuccess(payload, authData));
    yield put(
      logEvent(authData.user.isNewUser ? EVENT.SIGNUP : EVENT.LOGIN, { method: 'Facebook' }),
    );
  } catch (error) {
    console.log('saga middleware error (loginWithFacebookFlow)', error);
    yield put(loginWithFacebookFail(error));
  }
}

function* loginWithAppleFlow(action) {
  try {
    const payload = action.payload;
    const authData = yield call(
      loginWithAppleApi,
      payload.authentificationData,
      payload.platform,
      payload.referralCode,
    );
    yield put(saveJwtToken(authData.token));
    yield put(loadUserSuccess(authData.user));
    yield put(loginWithAppleSuccess(payload, authData));
    yield put(logEvent(authData.user.isNewUser ? EVENT.SIGNUP : EVENT.LOGIN, { method: 'Apple' }));
  } catch (error) {
    console.log('saga middleware error (loginWithAppleFlow)', error);
    yield put(loginWithAppleFail(error));
  }
}

function* resetPasswordFlow(action) {
  try {
    const payload = action.payload;
    const resetStatus = yield call(resetPasswordApi, payload.email);
    yield put(resetPasswordSuccess(resetStatus));
  } catch (error) {
    yield put(resetPasswordFail(error));
  }
}

function* loginWatcher() {
  while (true) {
    const actionLoginRequest = yield take(LOGIN);
    const task = yield fork(loginFlow, actionLoginRequest);
    const action = yield take([LOGOUT, LOGIN_ERROR]);
    if (action.type === LOGOUT) {
      yield cancel(task);
    }
    yield call(logout);
  }
}

function* loginWithFacebookWatcher() {
  while (true) {
    const actionLoginWithFacebook = yield take(LOGIN_WITH_FACEBOOK);
    const task = yield fork(loginWithFacebookFlow, actionLoginWithFacebook);
    const action = yield take([LOGOUT, LOGIN_WITH_FACEBOOK_ERROR]);
    if (action.type === LOGOUT) {
      yield cancel(task);
    }
    yield call(logout);
  }
}

function* loginWithFacebookJwtTokenWatcher() {
  while (true) {
    const actionLoginWithFacebook = yield take(LOGIN_WITH_FACEBOOK_JWT_TOKEN);
    const task = yield fork(loginWithFacebookJwtTokenFlow, actionLoginWithFacebook);
    const action = yield take([LOGOUT, LOGIN_WITH_FACEBOOK_ERROR]);
    if (action.type === LOGOUT) {
      yield cancel(task);
    }
    yield call(logout);
  }
}

function* loginWithAppleWatcher() {
  while (true) {
    const actionLoginWithApple = yield take(LOGIN_WITH_APPLE);
    const task = yield fork(loginWithAppleFlow, actionLoginWithApple);
    const action = yield take([LOGOUT, LOGIN_WITH_APPLE_ERROR]);
    if (action.type === LOGOUT) {
      yield cancel(task);
    }
    yield call(logout);
  }
}

function* logoutWatcher() {
  while (true) {
    const action = yield take(LOGOUT);
    yield call(logoutFlow, action);
  }
}

function* checkJwtTokenValidityFlow() {
  try {
    const clientToken = yield select(getClientToken);
    const authData = yield call(checkJwtTokenValidityApi, clientToken);
    yield put(saveJwtToken(authData.token));
    yield put(checkJwtTokenValiditySuccess());
  } catch (error) {
    console.log('saga middleware error (checkJwtTokenValidityFlow)', error);
    yield put(checkJwtTokenValidityFail(error));
  }
}

function* resetPasswordVerifyTokenFlow(action) {
  try {
    const payload = action.payload;
    const result = yield call(resetPasswordVerifyTokenApi, payload.email, payload.token);
    yield put(updatePasswordVerifySuccess(result));
  } catch (error) {
    console.log('saga middleware error (resetPasswordVerifyTokenFlow)', error);
    yield put(updatePasswordVerifyFail(error));
  }
}
function* updatePasswordFromEmailFlow(action) {
  try {
    const payload = action.payload;
    const result = yield call(
      updatePasswordFromEmailApi,
      payload.email,
      payload.token,
      payload.newPassword,
    );
    yield put(updatePasswordFromEmailSuccess(result));
  } catch (error) {
    console.log('saga middleware error (PasswordUpdateFlow)', error);
    yield put(updatePasswordFromEmailFail(error));
  }
}
function* updatePasswordFlow(action) {
  try {
    const payload = action.payload;
    const clientToken = yield select(getClientToken);
    const result = yield call(
      updatePasswordApi,
      clientToken,
      payload.userObjectId,
      payload.newPassword,
    );
    yield put(updatePasswordSuccess(result));
  } catch (error) {
    console.log('saga middleware error (PasswordUpdateFlow)', error);
    yield put(updatePasswordFail(error));
  }
}

export default function* authWatcher() {
  yield all([
    takeLatest(SIGNUP, signupFlow),
    loginWatcher(),
    takeEvery(LOGIN_GUEST_REQUEST, loginGuestFlow),
    loginWithFacebookWatcher(),
    loginWithFacebookJwtTokenWatcher(),
    loginWithAppleWatcher(),
    logoutWatcher(),
    takeLatest(CHECK_JWT_TOKEN_VALIDITY, checkJwtTokenValidityFlow),
    takeLatest(RESET_PASSWORD, resetPasswordFlow),
    takeLatest(UPDATE_PASSWORD_VERIFY_TOKEN, resetPasswordVerifyTokenFlow),
    takeLatest(UPDATE_PASSWORD_FROM_EMAIL, updatePasswordFromEmailFlow),
    takeLatest(UPDATE_PASSWORD, updatePasswordFlow),
  ]);
}

import React from 'react';

import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import {
  loadArticle,
  loadArticleResources,
  loadRelatedArticles,
} from 'bubble-reducers/src/reducers/articles';
import { loadArticleComments } from 'bubble-reducers/src/reducers/comments';
import { loadUserLikes } from 'bubble-reducers/src/reducers/user';
import { getRelatedArticles, getResourcesForArticle } from 'bubble-reducers/src/selectors';

import { isServer } from 'bubble-utils/src/env-utils';

import { getSeoForArticle } from '@/services/seo-utils';

import GenericNewsSection from '@/scenes/NArt/components/GenericNewsSection/GenericNewsSection';
import NArtAlbumCard from '@/scenes/NArt/components/NArtAlbumCard/NArtAlbumCard';
import NArtAuthorCard from '@/scenes/NArt/components/NArtAuthorCard/NArtAuthorCard';
import NArtNewsletterOptin from '@/scenes/NArt/components/NArtNewsletterOptin/NArtNewsletterOptin';

import asyncComponent from '@/components/AsyncComponent/AsyncComponent';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import SerieCard from '@/components/SerieCard/SerieCard';
import withRouter from '@/components/withRouter';

import ArticleCard from '../../../../components/ArticleCard/ArticleCard';
import TitledListContainer from '../../../../components/TitledListContainer/TitledListContainer';
import ArticleContentHeader from './components/ArticleContentHeader';
import ArticleHeaderImage from './components/ArticleHeaderImage';
import ArticleTag from './components/ArticleTag';

import './Article.scss';

const AsyncComments = asyncComponent(() => import('../../../Comments/Comments'));

class Article extends React.Component {
  constructor(props) {
    super(props);
    if (isServer && props.permalink) {
      const { loadArticle } = this.props;
      loadArticle(props.permalink);
    }
  }

  componentDidMount() {
    this.loadInfos(this.props.permalink);
  }

  loadInfos = (permalink) => {
    if (permalink) {
      const {
        loadArticle,
        loadArticleResources,
        loadArticleComments,
        loadRelatedArticles,
        user,
        loadUserLikes,
      } = this.props;
      if (user && user.objectId) {
        loadUserLikes(user.objectId);
      }
      loadArticle(permalink);
      loadArticleResources(permalink);
      loadArticleComments(permalink);
      loadRelatedArticles(permalink);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.permalink !== this.props.permalink) {
      this.loadInfos(this.props.permalink);
    }
  }

  //TODO:9eart I don't remember why this exists
  handleClickInArticleContent = (event) => {
    const targetLink = event.target.closest('a');
    if (!targetLink) return;

    if (window.URL) {
      const newLocation = new URL(targetLink.href);
      const currentLocation = window.location;
      // use router only if the host is the same and the pathname are differents
      if (
        currentLocation.host === newLocation.host &&
        currentLocation.pathname !== newLocation.pathname
      ) {
        this.props.router.navigate(newLocation.pathname + newLocation.hash);
        event.preventDefault();
      }
    }
  };

  render() {
    const albumsResources = this.props.resources.albums || [];
    const seriesResources = this.props.resources.series || [];
    const authorsResources = this.props.resources.authors || [];
    const article = this.props.article;
    const seoSchema = article ? getSeoForArticle(article) : null;

    if (!!article?.objectId && !article?.wordpressId && !article?.isPublished) {
      return <Navigate to="/404" />;
    }

    return (
      <>
        {!!article.objectId && <BubbleHelmet seo={seoSchema} />}
        <article>
          <ArticleHeaderImage article={article} />
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8 py-4 py-md-paper px-4 px-md-paper">
                <ArticleContentHeader article={article} />
                <div
                  className="article-content"
                  onClick={this.handleClickInArticleContent}
                  dangerouslySetInnerHTML={{
                    __html: this.props.articleContent || '',
                  }}
                />
                <div className="d-flex w-100 flex-wrap">
                  {article?.tags &&
                    article.tags.map((tag) => (
                      <div key={`tag_^${tag}`} className="pe-2 pb-2">
                        <ArticleTag tag={tag} />
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-lg-4 px-4 px-md-paper bb-background-light-grey pt-paper">
                {!!albumsResources.length && <div className="h2 fw-bold">Oeuvre(s)</div>}
                {albumsResources.slice(0, 4).map((album) => (
                  <div className="pb-4" key={'resource-album-' + album.objectId}>
                    <NArtAlbumCard albumObjectId={album.objectId} />
                  </div>
                ))}
                {(!!seriesResources.length || !!authorsResources.length) && (
                  <>
                    <div className="h2 fw-bold pt-2">Série(s) et Auteur(s)</div>
                    {seriesResources.slice(0, 3).map((serie) => (
                      <div className="pb-4" key={'resource_serie_' + serie.objectId}>
                        <SerieCard serieObjectId={serie.objectId} />
                      </div>
                    ))}
                    {authorsResources.slice(0, 3).map((author, index) => (
                      <div className="pb-4" key={`resource-author-${author.objectId}-${index}`}>
                        <NArtAuthorCard authorObjectId={author.objectId} />
                      </div>
                    ))}
                  </>
                )}
                <GenericNewsSection />
              </div>
            </div>
          </div>
        </article>

        <div className="nart-background-grey px-4 px-md-paper">
          <TitledListContainer
            noWrap
            title={<div className="h2 fw-bold">Publications similaires</div>}
            list={this.props.relatedArticles.slice(0, 3).map((article) => (
              <ArticleCard
                className="col-10 col-md-6 col-lg-4 pt-3"
                key={article.objectId}
                article={article}
              />
            ))}
          />
        </div>

        <div className="p-4 p-md-paper bb-background-light-grey">
          {article.objectId && <AsyncComments articleObjectId={article.objectId} />}
        </div>

        <NArtNewsletterOptin />
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { permalink } = props.router?.params || {};
  const articleObjectId = state.articles.articlesObjectIdsMapByPermalink[permalink] || null;
  return {
    user: state.user.user,
    article: state.articles.articlesMapByObjectId[articleObjectId] || {},
    articleContent: state.articles.articleContent || null,
    articlesMapByObjectId: state.articles.articlesMapByObjectId || [], //TODO:articles not needed ?
    resources: getResourcesForArticle(state, permalink),
    relatedArticles: getRelatedArticles(state, permalink),
    permalink,
    articleObjectId,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadUserLikes,
    loadArticle,
    loadArticleComments,
    loadArticleResources,
    loadRelatedArticles,
  })(Article),
);

export const CREATE_ALBUM_REVISION = 'CREATE_ALBUM_REVISION';
export const CREATE_ALBUM_REVISION_SUCCESS = 'CREATE_ALBUM_REVISION_SUCCESS';
export const CREATE_ALBUM_REVISION_FAIL = 'CREATE_ALBUM_REVISION_FAIL';

export const CREATE_ALBUM_ADDITION = 'CREATE_ALBUM_ADDITION';
export const CREATE_ALBUM_ADDITION_SUCCESS = 'CREATE_ALBUM_ADDITION_SUCCESS';
export const CREATE_ALBUM_ADDITION_FAIL = 'CREATE_ALBUM_ADDITION_FAIL';

export const CREATE_SERIE_REVISION = 'CREATE_SERIE_REVISION';
export const CREATE_SERIE_REVISION_SUCCESS = 'CREATE_SERIE_REVISION_SUCCESS';
export const CREATE_SERIE_REVISION_FAIL = 'CREATE_SERIE_REVISION_FAIL';

export const CREATE_PRINT_REVISION = 'CREATE_PRINT_REVISION';
export const CREATE_PRINT_REVISION_SUCCESS = 'CREATE_PRINT_REVISION_SUCCESS';
export const CREATE_PRINT_REVISION_FAIL = 'CREATE_PRINT_REVISION_FAIL';

export const CHECK_EAN_STATUS = 'CHECK_EAN_STATUS';
export const CHECK_EAN_STATUS_SUCCESS = 'CHECK_EAN_STATUS_SUCCESS';
export const CHECK_EAN_STATUS_FAIL = 'CHECK_EAN_STATUS_FAIL';

export const EDIT_PRINT_BEING_ADDED = 'EDIT_PRINT_BEING_ADDED';
export const DELETE_PRINT_BEING_ADDED = 'DELETE_PRINT_BEING_ADDED';
export const SET_PRINT_BEING_ADDED_STATUS = 'SET_PRINT_BEING_ADDED_STATUS';

const initialState = {
  printBeingAddedStatus: {},
  printBeingAdded: {},
  actionStatus: null,
  loading: false,
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_ALBUM_REVISION: {
      return {
        ...state,
        loading: true,
        actionStatus: CREATE_ALBUM_REVISION,
      };
    }
    case CREATE_ALBUM_REVISION_SUCCESS: {
      return {
        ...state,
        loading: false,
        actionStatus: CREATE_ALBUM_REVISION_SUCCESS,
      };
    }
    case CREATE_ALBUM_REVISION_FAIL: {
      return {
        ...state,
        loading: false,
        actionStatus: CREATE_ALBUM_REVISION_FAIL,
      };
    }

    case CREATE_ALBUM_ADDITION: {
      return {
        ...state,
        loading: true,
        actionStatus: CREATE_ALBUM_ADDITION,
      };
    }
    case CREATE_ALBUM_ADDITION_SUCCESS: {
      return {
        ...state,
        loading: false,
        status: CREATE_ALBUM_ADDITION_FAIL,
      };
    }
    case CREATE_ALBUM_ADDITION_FAIL: {
      return {
        ...state,
        loading: false,
        errors: [].concat(state.errors).concat(action.payload),
        actionStatus: CREATE_ALBUM_ADDITION_FAIL,
      };
    }

    case CREATE_SERIE_REVISION: {
      return {
        ...state,
        loading: true,
        actionStatus: CREATE_SERIE_REVISION,
      };
    }
    case CREATE_SERIE_REVISION_SUCCESS: {
      return {
        ...state,
        loading: false,
        actionStatus: CREATE_SERIE_REVISION_SUCCESS,
      };
    }
    case CREATE_SERIE_REVISION_FAIL: {
      return {
        ...state,
        loading: false,
        actionStatus: CREATE_SERIE_REVISION_FAIL,
      };
    }

    case CHECK_EAN_STATUS:
      return {
        ...state,
        loading: true,
        printBeingAddedStatus: {},
        printBeingAdded: {},
      };
    case CHECK_EAN_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        printBeingAddedStatus: {
          status: action.payload.eanStatus.status,
          isRejected: action.payload.eanStatus.data.isRejected,
          isInProduction: action.payload.eanStatus.data.isInProduction,
        },
        printBeingAdded:
          action.payload.eanStatus.data && action.payload.eanStatus.data.print
            ? action.payload.eanStatus.data.print
            : {},
      };
    case CHECK_EAN_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        printBeingAddedStatus: {},
        printBeingAdded: {},
      };

    case EDIT_PRINT_BEING_ADDED: {
      return {
        ...state,
        printBeingAdded: Object.assign({}, action.payload.printBeingAdded) || {},
      };
    }
    case SET_PRINT_BEING_ADDED_STATUS: {
      return {
        ...state,
        printBeingAddedStatus: {
          status: action.payload.status,
        },
        printBeingAdded: {},
      };
    }
    case DELETE_PRINT_BEING_ADDED: {
      return {
        ...state,
        printBeingAddedStatus: {},
        printBeingAdded: {},
      };
    }

    default: {
      return state;
    }
  }
}

export function editPrintBeingAdded(printBeingAdded) {
  return {
    type: EDIT_PRINT_BEING_ADDED,
    payload: { printBeingAdded },
  };
}

export function setPrintBeingAddedStatus(status) {
  return {
    type: SET_PRINT_BEING_ADDED_STATUS,
    payload: { status },
  };
}

export function deletePrintBeingAdded() {
  return {
    type: DELETE_PRINT_BEING_ADDED,
  };
}

// createAlbumRevision ====================================================

export function createAlbumRevision(albumObjectId, revisions) {
  return {
    type: CREATE_ALBUM_REVISION,
    payload: {
      albumObjectId,
      revisions,
    },
  };
}
export function createAlbumRevisionSuccess() {
  return {
    type: CREATE_ALBUM_REVISION_SUCCESS,
    payload: {},
  };
}
export function createAlbumRevisionFail(error) {
  return {
    type: CREATE_ALBUM_REVISION_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// createAlbumAddition ====================================================

export function createAlbumAddition(userObjectId, userEmail, addition) {
  return {
    type: CREATE_ALBUM_ADDITION,
    payload: {
      userObjectId,
      userEmail,
      addition,
    },
  };
}
export function createAlbumAdditionSuccess() {
  return {
    type: CREATE_ALBUM_ADDITION_SUCCESS,
    payload: {},
  };
}
export function createAlbumAdditionFail(error) {
  return {
    type: CREATE_ALBUM_ADDITION_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// checkEanStatus ====================================================

export function checkEanStatus(ean) {
  return {
    type: CHECK_EAN_STATUS,
    payload: {
      ean,
    },
  };
}
export function checkEanStatusSuccess(eanStatus) {
  return {
    type: CHECK_EAN_STATUS_SUCCESS,
    payload: { eanStatus },
  };
}
export function checkEanStatusFail(error) {
  return {
    type: CHECK_EAN_STATUS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// createSerieRevision ====================================================

export function createSerieRevision(serieObjectId, revisions) {
  return {
    type: CREATE_SERIE_REVISION,
    payload: {
      serieObjectId,
      revisions,
    },
  };
}
export function createSerieRevisionSuccess() {
  return {
    type: CREATE_SERIE_REVISION_SUCCESS,
    payload: {},
  };
}
export function createSerieRevisionFail(error) {
  return {
    type: CREATE_SERIE_REVISION_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// createPrintRevision ====================================================

export function createPrintRevision(printObjectId, revisions) {
  return {
    type: CREATE_PRINT_REVISION,
    payload: {
      printObjectId,
      revisions,
    },
  };
}
export function createPrintRevisionSuccess() {
  return {
    type: CREATE_PRINT_REVISION_SUCCESS,
    payload: {},
  };
}
export function createPrintRevisionFail(error) {
  return {
    type: CREATE_PRINT_REVISION_FAIL,
    payload: new Error(error),
    error: true,
  };
}

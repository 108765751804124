import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { updateLibrary } from 'bubble-reducers/src/reducers/profiles';
import { getUserOrGuest, isPrintForSale } from 'bubble-reducers/src/selectors';

import { formatCurrency } from 'bubble-utils/src/currency-utils';

import { useSetForSaleFlagOnPrints } from '@/services/hooks/useLibraryUtils';
import { useIsUserlogged } from '@/services/hooks/useUserUtils';

import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import InfoModalRow from '../components/InfoModalRow';

import { GENERAL } from 'bubble-constants';

import '../Modals.css';
import './CollectionBuyAndSellModal.scss';

const CollectionBuyAndSellModal = (props) => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();
  const setForSaleFlagOnPrints = useSetForSaleFlagOnPrints();

  const user = useSelector((state) => getUserOrGuest(state));
  const albumObjectId = props.albumObjectId;
  const printObjectId = props.printObjectId;
  const print = useSelector((state) => state.prints.prints[printObjectId]);
  const initialBuyPrice = useSelector(
    (state) => state.profiles.buyPricePrintsIdsMap[printObjectId],
  );
  const initialResellValue = useSelector(
    (state) => state.profiles.resellValuePrintsIdsMap[printObjectId],
  );
  const condition = useSelector((state) => state.profiles.conditionPrintsIdsMap[printObjectId]);
  const isForSale = useSelector((state) => isPrintForSale(state, printObjectId));

  const [buyPrice, setBuyPrice] = useState(initialBuyPrice || '');
  const [resellValue, setResellValue] = useState(initialResellValue || '');

  const dismiss = () => {
    props.callback();
  };

  useEffect(() => {
    setBuyPrice(initialBuyPrice || '');
    setResellValue(initialResellValue || '');
  }, [initialBuyPrice, initialResellValue]);

  const onClickForSale = () => {
    setForSaleFlagOnPrints(
      user,
      [{ objectId: printObjectId, album: { objectId: albumObjectId }, isForSale: !isForSale }],
      !isForSale,
    );
  };

  const onChangePrintCondition = (e) => {
    if (isUserlogged()) {
      dispatch(
        updateLibrary(user.objectId, [
          { printObjectId, albumObjectId, condition: e.target.value || null },
        ]),
      );
    }
  };

  const onChangeBuyPrice = (e) => {
    setBuyPrice(e.target.value ? e.target.value.replace(',', '.') : null);
  };

  const onChangeResellValue = (e) => {
    setResellValue(e.target.value ? e.target.value.replace(',', '.') : null);
  };

  const onSave = () => {
    if (isUserlogged()) {
      const toUpdate = {};
      if (buyPrice || null !== initialBuyPrice || null) toUpdate.buyPrice = buyPrice || null;
      if (resellValue || null !== initialResellValue || null)
        toUpdate.resellValue = resellValue || null;
      if (Object.keys(toUpdate).length) {
        dispatch(updateLibrary(user?.objectId, [{ printObjectId, albumObjectId, ...toUpdate }]));
      }
    }

    dismiss();
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="md"
      centered
      contentClassName="bb-background-light-grey"
    >
      <div className="modal-header header-no-separator">
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={dismiss}
        ></button>
      </div>
      <div className="modal-body mx-sm-3">
        <div className="pb-5">
          <div className="bb-l-text-size fw-bold text-center">
            Modifier mes informations
            <br />
            d'achat-vente
          </div>

          <div className="pt-4">
            <InfoModalRow
              img="icon_price.svg"
              text="Prix d'achat"
              rightComponent={
                <input
                  type="number"
                  className="info-modal-text-end form-control-sm border-0"
                  value={buyPrice || ''}
                  onChange={onChangeBuyPrice}
                  placeholder={formatCurrency(print?.sellingInfo?.price || 0)}
                />
              }
            />
            <hr className="m-0" />
            <InfoModalRow
              img="icon_price.svg"
              text="Cote"
              rightComponent={
                <input
                  type="number"
                  className="info-modal-text-end form-control-sm border-0"
                  value={resellValue || ''}
                  onChange={onChangeResellValue}
                  placeholder={formatCurrency(0)}
                />
              }
            />
            <hr className="m-0" />
            <InfoModalRow
              img="icon_original_edition.svg"
              text="État de l'album"
              rightComponent={
                <select
                  onChange={onChangePrintCondition}
                  className="info-modal-text-end form-select-sm border-0"
                  defaultValue={condition ? `${condition}` : null}
                >
                  <option value={null} />
                  {Object.values(GENERAL.PRINT_CONDITION).map((conditionId) => (
                    <option key={`condition_${conditionId}`} value={conditionId}>
                      {GENERAL.PRINT_CONDITION_TO_STRING[conditionId]}
                    </option>
                  ))}
                </select>
              }
            />
            <hr className="m-0" />
            <WithClickHandler onClick={onClickForSale}>
              <InfoModalRow img="icon_pig.svg" text="A vendre" value={isForSale} />
            </WithClickHandler>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button onClick={onSave} className="btn btn-success btn-lg text-white">
          Valider
        </button>
      </div>
      <div className="d-md-none mobile-spacer" />
    </Modal>
  );
};

CollectionBuyAndSellModal.propTypes = {
  itemObjectId: PropTypes.string,
  itemType: PropTypes.string,
};

export default CollectionBuyAndSellModal;

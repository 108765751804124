export const LOAD_REVIEW = 'LOAD_REVIEW';
export const LOAD_REVIEW_SUCCESS = 'LOAD_REVIEW_SUCCESS';
export const LOAD_REVIEW_FAIL = 'LOAD_REVIEW_FAIL';

export const LOAD_SERIE_REVIEWS = 'LOAD_SERIE_REVIEWS';
export const LOAD_SERIE_REVIEWS_SUCCESS = 'LOAD_SERIE_REVIEWS_SUCCESS';
export const LOAD_SERIE_REVIEWS_FAIL = 'LOAD_SERIE_REVIEWS_FAIL';

export const LOAD_ALBUM_REVIEWS = 'LOAD_ALBUM_REVIEWS';
export const LOAD_ALBUM_REVIEWS_SUCCESS = 'LOAD_ALBUM_REVIEWS_SUCCESS';
export const LOAD_ALBUM_REVIEWS_FAIL = 'LOAD_ALBUM_REVIEWS_FAIL';

export const LOAD_APP_REVIEWS = 'LOAD_APP_REVIEWS';
export const LOAD_APP_REVIEWS_SUCCESS = 'LOAD_APP_REVIEWS_SUCCESS';
export const LOAD_APP_REVIEWS_FAIL = 'LOAD_APP_REVIEWS_FAIL';

export const ADD_SERIE_REVIEW = 'ADD_SERIE_REVIEW';
export const ADD_SERIE_REVIEW_SUCCESS = 'ADD_SERIE_REVIEW_SUCCESS';
export const ADD_SERIE_REVIEW_FAIL = 'ADD_SERIE_REVIEW_FAIL';

export const EDIT_SERIE_REVIEW = 'EDIT_SERIE_REVIEW';
export const EDIT_SERIE_REVIEW_SUCCESS = 'EDIT_SERIE_REVIEW_SUCCESS';
export const EDIT_SERIE_REVIEW_FAIL = 'EDIT_SERIE_REVIEW_FAIL';

export const LOAD_MY_REVIEW_FOR_SERIE = 'LOAD_MY_REVIEW_FOR_SERIE';
export const LOAD_MY_REVIEW_FOR_SERIE_SUCCESS = 'LOAD_MY_REVIEW_FOR_SERIE_SUCCESS';
export const LOAD_MY_REVIEW_FOR_SERIE_FAIL = 'LOAD_MY_REVIEW_FOR_SERIE_FAIL';

export const LOAD_MY_REVIEWS = 'LOAD_MY_REVIEWS';
export const LOAD_MY_REVIEWS_SUCCESS = 'LOAD_MY_REVIEWS_SUCCESS';
export const LOAD_MY_REVIEWS_FAIL = 'LOAD_MY_REVIEWS_FAIL';

export const ADD_ALBUM_REVIEW = 'ADD_ALBUM_REVIEW';
export const ADD_ALBUM_REVIEW_SUCCESS = 'ADD_ALBUM_REVIEW_SUCCESS';
export const ADD_ALBUM_REVIEW_FAIL = 'ADD_ALBUM_REVIEW_FAIL';

export const EDIT_ALBUM_REVIEW = 'EDIT_ALBUM_REVIEW';
export const EDIT_ALBUM_REVIEW_SUCCESS = 'EDIT_ALBUM_REVIEW_SUCCESS';
export const EDIT_ALBUM_REVIEW_FAIL = 'EDIT_ALBUM_REVIEW_FAIL';

export const LOAD_MY_REVIEWS_FOR_ALBUM = 'LOAD_MY_REVIEWS_FOR_ALBUM';
export const LOAD_MY_REVIEWS_FOR_ALBUM_SUCCESS = 'LOAD_MY_REVIEWS_FOR_ALBUM_SUCCESS';
export const LOAD_MY_REVIEWS_FOR_ALBUM_FAIL = 'LOAD_MY_REVIEWS_FOR_ALBUM_FAIL';

export const ADD_LIKE = 'ADD_LIKE';
export const ADD_LIKE_SUCCESS = 'ADD_LIKE_SUCCESS';
export const ADD_LIKE_FAIL = 'ADD_LIKE_FAIL';

export const DELETE_LIKE = 'DELETE_LIKE';
export const DELETE_LIKE_SUCCESS = 'DELETE_LIKE_SUCCESS';
export const DELETE_LIKE_FAIL = 'DELETE_LIKE_FAIL';

export const RESET_REVIEWS_REDUCER = 'RESET_REVIEWS_REDUCER';

const initialState = {
  mySerieReviewsMap: {},
  myAlbumReviewsMap: {},
  serieReviewsMap: {},
  albumReviewsMap: {},
  reviewsMap: {},
  appReviews: {},
  loading: false,
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_REVIEW: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_REVIEW_SUCCESS: {
      const data = action.payload.review;
      const review = { [data.objectId]: data };
      return {
        ...state,
        loading: false,
        reviewsMap: Object.assign({}, state.reviewsMap, review),
        errors: [],
      };
    }
    case LOAD_REVIEW_FAIL: {
      return {
        ...state,
        loading: false,
        reviewsMap: {},
      };
    }

    case LOAD_SERIE_REVIEWS: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_SERIE_REVIEWS_SUCCESS: {
      const data = action.payload.serieReviewsMap;
      const reviews = { [data.serieObjectId]: data };
      return {
        ...state,
        loading: false,
        serieReviewsMap: Object.assign({}, state.serieReviewsMap, reviews),
        errors: [],
      };
    }
    case LOAD_SERIE_REVIEWS_FAIL: {
      return {
        ...state,
        loading: false,
        serieReviewsMap: {},
      };
    }

    case LOAD_ALBUM_REVIEWS: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_ALBUM_REVIEWS_SUCCESS: {
      const data = action.payload.albumReviewsMap;
      const reviews = { [data.albumObjectId]: data };
      return {
        ...state,
        loading: false,
        albumReviewsMap: Object.assign({}, state.albumReviewsMap, reviews),
        errors: [],
      };
    }
    case LOAD_ALBUM_REVIEWS_FAIL: {
      return {
        ...state,
        loading: false,
        albumReviewsMap: {},
      };
    }

    case LOAD_APP_REVIEWS: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_APP_REVIEWS_SUCCESS: {
      let reviews = { ...state.appReviews };
      (action.payload.appReviews || []).forEach(
        (appReview) => (reviews[appReview.objectId] = appReview),
      );
      return {
        ...state,
        loading: false,
        appReviews: reviews,
        errors: [],
      };
    }
    case LOAD_APP_REVIEWS_FAIL: {
      return {
        ...state,
        loading: false,
        appReviews: {},
      };
    }

    case ADD_SERIE_REVIEW: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_SERIE_REVIEW_SUCCESS: {
      const myReview =
        Object.values(action.payload.myReview).length === 0 ? null : action.payload.myReview;
      const mySerieReviewsMap = Object.assign({}, state.mySerieReviewsMap);
      if (myReview) {
        mySerieReviewsMap[action.payload.serieObjectId] = myReview;
      }

      return {
        ...state,
        mySerieReviewsMap: mySerieReviewsMap,
        loading: false,
        errors: [],
      };
    }
    case ADD_SERIE_REVIEW_FAIL: {
      return {
        ...state,
        loading: false,
        serieReviewsMap: {},
      };
    }

    case EDIT_SERIE_REVIEW: {
      return {
        ...state,
        loading: true,
      };
    }
    case EDIT_SERIE_REVIEW_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: [],
      };
    }
    case EDIT_SERIE_REVIEW_FAIL: {
      return {
        ...state,
        loading: false,
        serieReviewsMap: {},
      };
    }

    case LOAD_MY_REVIEW_FOR_SERIE: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_MY_REVIEW_FOR_SERIE_SUCCESS: {
      const myReview =
        Object.values(action.payload.myReview).length === 0 ? null : action.payload.myReview;
      const mySerieReviewsMap = Object.assign({}, state.mySerieReviewsMap);
      if (myReview) {
        mySerieReviewsMap[action.payload.serieObjectId] = myReview;
      }
      return {
        ...state,
        mySerieReviewsMap: mySerieReviewsMap,
        loading: false,
        errors: [],
      };
    }
    case LOAD_MY_REVIEW_FOR_SERIE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case ADD_ALBUM_REVIEW: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_ALBUM_REVIEW_SUCCESS: {
      const myReview =
        Object.values(action.payload.myReview).length === 0 ? null : action.payload.myReview;
      const myAlbumReviewsMap = Object.assign({}, state.myAlbumReviewsMap);
      if (myReview) {
        myAlbumReviewsMap[action.payload.albumObjectId] = [myReview];
      }
      return {
        ...state,
        myAlbumReviewsMap: myAlbumReviewsMap,
        loading: false,
        errors: [],
      };
    }
    case ADD_ALBUM_REVIEW_FAIL: {
      return {
        ...state,
        loading: false,
        albumReviewsMap: {},
      };
    }

    case EDIT_ALBUM_REVIEW: {
      return {
        ...state,
        loading: true,
      };
    }
    case EDIT_ALBUM_REVIEW_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: [],
      };
    }
    case EDIT_ALBUM_REVIEW_FAIL: {
      return {
        ...state,
        loading: false,
        albumReviewsMap: {},
      };
    }

    case LOAD_MY_REVIEWS_FOR_ALBUM: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_MY_REVIEWS_FOR_ALBUM_SUCCESS: {
      const myReviews = action.payload.myReviews.length === 0 ? null : action.payload.myReviews;
      const myAlbumReviewsMap = Object.assign({}, state.myAlbumReviewsMap);
      if (myReviews) {
        myAlbumReviewsMap[action.payload.albumObjectId] = myReviews;
      }
      return {
        ...state,
        myAlbumReviewsMap: myAlbumReviewsMap,
        loading: false,
        errors: [],
      };
    }
    case LOAD_MY_REVIEWS_FOR_ALBUM_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LOAD_MY_REVIEWS: {
      return {
        ...state,
        loadingMyReviews: true,
        errors: [],
      };
    }
    case LOAD_MY_REVIEWS_SUCCESS: {
      const [serieReviewsMapObject, albumReviewsMapObject] = action.payload?.allReviews;
      const mySerieReviewsMap = Object.assign({}, state.mySerieReviewsMap);
      const myAlbumReviewsMap = Object.assign({}, state.myAlbumReviewsMap);
      serieReviewsMapObject.series?.forEach(
        (serie) => (mySerieReviewsMap[serie.objectId] = serie.extraInfos.userReview),
      );
      albumReviewsMapObject.albums?.forEach(
        (album) => (myAlbumReviewsMap[album.objectId] = [album.extraInfos.userReview]),
      );
      return {
        ...state,
        mySerieReviewsMap,
        myAlbumReviewsMap,
        loadingMyReviews: false,
        errors: [],
      };
    }
    case LOAD_MY_REVIEWS_FAIL: {
      return {
        ...state,
        loadingMyReviews: false,
      };
    }

    case RESET_REVIEWS_REDUCER: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}

// loadReview ====================================================

export function loadReview(reviewObjectId) {
  return {
    type: LOAD_REVIEW,
    payload: { reviewObjectId },
  };
}
export function loadReviewSuccess(review) {
  return {
    type: LOAD_REVIEW_SUCCESS,
    payload: { review },
  };
}
export function loadReviewFail(error) {
  return {
    type: LOAD_REVIEW_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// addSerieReview ====================================================

export function addSerieReview(review) {
  return {
    type: ADD_SERIE_REVIEW,
    payload: { review },
  };
}
export function addSerieReviewSuccess(myReview, serieObjectId) {
  return {
    type: ADD_SERIE_REVIEW_SUCCESS,
    payload: {
      myReview,
      serieObjectId,
    },
  };
}
export function addSerieReviewFail(error) {
  return {
    type: ADD_SERIE_REVIEW_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// editSerieReview ====================================================

export function editSerieReview(reviewObjectId, review) {
  return {
    type: EDIT_SERIE_REVIEW,
    payload: { reviewObjectId, review },
  };
}
export function editSerieReviewSuccess(review) {
  return {
    type: EDIT_SERIE_REVIEW_SUCCESS,
    payload: {
      review,
    },
  };
}
export function editSerieReviewFail(error) {
  return {
    type: EDIT_SERIE_REVIEW_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadSerieReviews ====================================================

export function loadSerieReviews(serieObjectId) {
  return {
    type: LOAD_SERIE_REVIEWS,
    payload: { serieObjectId },
  };
}
export function loadSerieReviewsSuccess(serieReviewsMap) {
  return {
    type: LOAD_SERIE_REVIEWS_SUCCESS,
    payload: {
      serieReviewsMap,
    },
  };
}
export function loadSerieReviewsFail(error) {
  return {
    type: LOAD_SERIE_REVIEWS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadAlbumReviews ====================================================

export function loadAlbumReviews(albumObjectId) {
  return {
    type: LOAD_ALBUM_REVIEWS,
    payload: { albumObjectId },
  };
}
export function loadAlbumReviewsSuccess(albumReviewsMap) {
  return {
    type: LOAD_ALBUM_REVIEWS_SUCCESS,
    payload: {
      albumReviewsMap,
    },
  };
}
export function loadAlbumReviewsFail(error) {
  return {
    type: LOAD_ALBUM_REVIEWS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// addAlbumReview ====================================================

export function addAlbumReview(review, albumObjectId) {
  return {
    type: ADD_ALBUM_REVIEW,
    payload: { review, albumObjectId },
  };
}
export function addAlbumReviewSuccess(myReview, albumObjectId) {
  return {
    type: ADD_ALBUM_REVIEW_SUCCESS,
    payload: {
      myReview,
      albumObjectId,
    },
  };
}
export function addAlbumReviewFail(error) {
  return {
    type: ADD_ALBUM_REVIEW_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// editSerieReview ====================================================

export function editAlbumReview(reviewObjectId, review, albumObjectId) {
  return {
    type: EDIT_ALBUM_REVIEW,
    payload: { reviewObjectId, review, albumObjectId },
  };
}
export function editAlbumReviewSuccess(review) {
  return {
    type: EDIT_ALBUM_REVIEW_SUCCESS,
    payload: {
      review,
    },
  };
}
export function editAlbumReviewFail(error) {
  return {
    type: EDIT_ALBUM_REVIEW_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadAppReviews ====================================================

export function loadAppReviews() {
  return {
    type: LOAD_APP_REVIEWS,
  };
}
export function loadAppReviewsSuccess(appReviews) {
  return {
    type: LOAD_APP_REVIEWS_SUCCESS,
    payload: {
      appReviews,
    },
  };
}
export function loadAppReviewsFail(error) {
  return {
    type: LOAD_APP_REVIEWS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadMyReviewForSerie ====================================================

export function loadMyReviewForSerie(userObjectId, serieObjectId) {
  return {
    type: LOAD_MY_REVIEW_FOR_SERIE,
    payload: { userObjectId, serieObjectId },
  };
}
export function loadMyReviewForSerieSuccess(myReview, serieObjectId) {
  return {
    type: LOAD_MY_REVIEW_FOR_SERIE_SUCCESS,
    payload: {
      myReview,
      serieObjectId,
    },
  };
}
export function loadMyReviewForSerieFail(error) {
  return {
    type: LOAD_MY_REVIEW_FOR_SERIE_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadMyReviewsForAlbum ====================================================

export function loadMyReviewsForAlbum(userObjectId, albumObjectId) {
  return {
    type: LOAD_MY_REVIEWS_FOR_ALBUM,
    payload: { userObjectId, albumObjectId },
  };
}
export function loadMyReviewsForAlbumSuccess(myReviews, albumObjectId) {
  return {
    type: LOAD_MY_REVIEWS_FOR_ALBUM_SUCCESS,
    payload: {
      myReviews,
      albumObjectId,
    },
  };
}
export function loadMyReviewsForAlbumFail(error) {
  return {
    type: LOAD_MY_REVIEWS_FOR_ALBUM_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadMyReviews ====================================================

export function loadMyReviews(userObjectId) {
  return {
    type: LOAD_MY_REVIEWS,
    payload: { userObjectId },
  };
}
export function loadMyReviewsSuccess(allReviews) {
  return {
    type: LOAD_MY_REVIEWS_SUCCESS,
    payload: {
      allReviews,
    },
  };
}
export function loadMyReviewsFail(error) {
  return {
    type: LOAD_MY_REVIEWS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// addLike ====================================================

export function addLike(like) {
  return {
    type: ADD_LIKE,
    payload: { like },
  };
}
export function addLikeSuccess() {
  return {
    type: ADD_LIKE_SUCCESS,
    payload: {},
  };
}
export function addLikeFail(error) {
  return {
    type: ADD_LIKE_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// deleteLike ====================================================

export function deleteLike(like) {
  return {
    type: DELETE_LIKE,
    payload: { like },
  };
}
export function deleteLikeSuccess() {
  return {
    type: DELETE_LIKE_SUCCESS,
    payload: {},
  };
}
export function deleteLikeFail(error) {
  return {
    type: DELETE_LIKE_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// resetReviewsReducer ====================================================

export function resetReviewsReducer() {
  return {
    type: RESET_REVIEWS_REDUCER,
  };
}

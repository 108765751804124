import React from 'react';

import config from '@/project-config';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { logout } from 'bubble-reducers/src/reducers/auth';

class Logout extends React.Component {
  constructor(props) {
    super(props);

    const { logout } = this.props;
    logout(
      {
        name: config.cookies.jwtToken.name,
        options: config.cookies.defaultOptions,
      },
      {
        keyName: config.localStorage.user.keyName,
      },
    );
  }

  render() {
    return <Navigate to={'login'} />;
  }
}

export default connect(null, { logout })(Logout);

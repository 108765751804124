import React, { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getUserOrGuest } from 'bubble-reducers/src/selectors';

import { reOptinNewsletter, updateSpecificOtherVariable } from '@/engine/reducers/website-other';

import { isValidEmail } from 'bubble-utils/src/validity-utils';

import { Icon } from '@/components/Icon/Icon';
import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';

import pinkLight from '@/assets/icon_alert.svg';

import './NewsletterOptin.scss';

export const TYPES = {
  MIXT: 0,
  BD: 1,
  COMICS: 2,
  MANGAS: 3,
};

const typeMap = ['', '-bd', '-comics', '-mangas'];
const typeMapText = ['de la bande dessinée', 'de la bande dessinée', 'des Comics', 'des Mangas'];

const NewsletterOptin = (props) => {
  const dispatch = useDispatch();
  const optedIn = useSelector((state) => state.websiteOther.reOptinNewsletter);
  const user = useSelector(getUserOrGuest);
  const [email, setEmail] = useState(user?.email || '');
  useEffect(() => {
    return () => dispatch(updateSpecificOtherVariable('reOptinNewsletter', false));
  }, [dispatch]);

  const handleButton = useCallback(
    (value) => {
      if (!isValidEmail(value)) {
        alert('Votre email semble incorrect');
      } else {
        dispatch(reOptinNewsletter(user.objectId, value));
      }
    },
    [dispatch, user?.objectId],
  );

  return (
    <div className={`sidebar-optin-background${typeMap[props.type || TYPES.MIXT]} rounded`}>
      <div className="d-flex flex-fill h-100 align-items-center p-4">
        {!optedIn && (
          <>
            <img className="light-icon" alt="light" src={pinkLight} />
            <div className="d-flex flex-column flex-fill ps-3">
              <p className={`fw-bold my-0${'big' in props ? ' big-text' : ''}`}>
                Chaque semaine, toute l'effervescence {typeMapText[props.type || TYPES.MIXT]} dans
                votre boîte mail
              </p>
              <div className="mb-n2 pt-2">
                {!!user?.objectId ? (
                  <LabeledTextInput
                    value={email}
                    noRightLabel
                    type="email"
                    white
                    buttonLabel={props.buttonText || `Go\u00A0!`}
                    onChange={(e) => setEmail(e.target.value)}
                    buttonCallback={handleButton}
                    buttonStyle={`w-25 btn ${
                      props.type === TYPES.COMICS ? 'btn-black' : 'btn-bubble-color'
                    } no-start-border`}
                  />
                ) : (
                  <Link to="/login?nl=t" className="btn btn-bubble-color">
                    Je m'inscris !
                  </Link>
                )}
              </div>
            </div>
          </>
        )}
        {optedIn && (
          <>
            <div
              className={`${
                props.type === TYPES.MANGAS ? 'white-border ' : ''
              }bg-success rounded-circle d-flex align-items-center justify-content-center valid-circle`}
            >
              <Icon className="text-white bb-l-text-size" name="check" />
            </div>
            <p className={`ps-3 fw-bold my-0${'big' in props ? ' big-text' : ''}`}>
              Votre inscription à la newsletter a été prise en compte.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default NewsletterOptin;

import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  loadArticleApi,
  loadArticleResourcesApi,
  loadArticlesApi,
  loadArticlesForResourceApi,
  loadHomeArticlesApi,
  loadRelatedArticlesApi,
} from '../services/api';

import {
  LOAD_ARTICLE,
  LOAD_ARTICLES,
  LOAD_ARTICLES_FOR_RESOURCES,
  LOAD_ARTICLE_RESOURCES,
  LOAD_HOME_ARTICLES,
  LOAD_RELATED_ARTICLES,
} from '../reducers/articles';
import {
  loadArticleFail,
  loadArticleResourcesFail,
  loadArticleResourcesSuccess,
  loadArticleSuccess,
  loadArticlesFail,
  loadArticlesForResourceFail,
  loadArticlesForResourceSuccess,
  loadArticlesSuccess,
  loadHomeArticlesFail,
  loadHomeArticlesSuccess,
  loadRelatedArticlesFail,
  loadRelatedArticlesSuccess,
  storeArticles,
} from '../reducers/articles';
import { storeAuthors } from '../reducers/authors';
import { storeAlbumsFlow } from '../sagas/albums';
import { storeSeriesFlow } from '../sagas/series';
import { getClientToken } from '../selectors';

function* loadArticlesFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadArticlesApi, clientToken, action.payload.params);
    yield call(storeArticlesFlow, {
      payload: { articles: response.articles, source: 'loadArticlesFlow' },
    });
    yield put(loadArticlesSuccess(response, action.payload.params));
  } catch (error) {
    console.log('saga middleware error (loadArticlesFlow)', error);
    yield put(loadArticlesFail(error));
  }
}

function* loadArticleFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      loadArticleApi,
      clientToken,
      action.payload.articleObjectId,
      action.payload.params,
    );
    yield call(storeArticlesFlow, {
      payload: { articles: [response], source: 'loadArticleFlow' },
    });
    yield put(loadArticleSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadArticleFlow)', error);
    yield put(loadArticleFail(error));
  }
}

function* loadHomeArticlesFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const sections = yield call(loadHomeArticlesApi, clientToken, action.payload.category);
    yield call(storeArticlesFlow, {
      payload: { articles: [...Object.values(sections).flat()], source: 'loadHomeArticlesFlow' },
    });
    yield put(loadHomeArticlesSuccess(action.payload.category, sections));
  } catch (error) {
    console.log('saga middleware error (loadHomeArticlesFlow)', error);
    yield put(loadHomeArticlesFail(error));
  }
}

function* loadArticleResourcesFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      loadArticleResourcesApi,
      clientToken,
      action.payload.articleObjectId,
    );
    yield all([
      call(storeSeriesFlow, {
        payload: { series: response.series, source: 'loadArticleResourcesFlow' },
      }),
      call(storeAlbumsFlow, {
        payload: { albums: response.albums, source: 'loadArticleResourcesFlow' },
      }),
      put(storeAuthors(response.authors, 'loadArticle')),
    ]);
    response.series = response.series.map((serie) => serie.objectId);
    response.albums = response.albums.map((album) => album.objectId);
    response.authors = response.authors.map((author) => author.objectId);
    yield put(loadArticleResourcesSuccess(response, action.payload.articleObjectId));
  } catch (error) {
    console.log('saga middleware error (loadArticleResourcesFlow)', error);
    yield put(loadArticleResourcesFail(error));
  }
}

function* loadArticlesForResourceFlow(action) {
  try {
    //TODO: store the resources in their own reducers (storeAlbums, etc)
    const clientToken = yield select(getClientToken);
    const response = yield call(
      loadArticlesForResourceApi,
      clientToken,
      action.payload.resourceObjectId,
    );
    yield call(storeArticlesFlow, {
      payload: { articles: response, source: 'loadHomeArticlesFlow' },
    });
    yield put(loadArticlesForResourceSuccess(response, action.payload.resourceObjectId));
  } catch (error) {
    console.log('saga middleware error (loadArticlesForResourceFlow)', error);
    yield put(loadArticlesForResourceFail(error));
  }
}

function* loadRelatedArticlesFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      loadRelatedArticlesApi,
      clientToken,
      action.payload.articleObjectId,
    );
    yield call(storeArticlesFlow, {
      payload: { articles: response, source: 'loadArticlesForResourceFlow' },
    });
    yield put(loadRelatedArticlesSuccess(action.payload.articleObjectId, response));
  } catch (error) {
    console.log('saga middleware error (loadRelatedArticlesFlow)', error);
    yield put(loadRelatedArticlesFail(error));
  }
}

export function* storeArticlesFlow(action) {
  try {
    const articles = action.payload.articles;
    yield put(storeArticles(articles, action.payload.source));
  } catch (error) {
    console.log('saga middleware error (storeArticlesFlow)', error, 'action was', action);
  }
}

export default function* articlesWatcher() {
  yield all([
    takeEvery(LOAD_ARTICLES, loadArticlesFlow),
    takeLatest(LOAD_HOME_ARTICLES, loadHomeArticlesFlow),
    takeLatest(LOAD_ARTICLE, loadArticleFlow),
    takeLatest(LOAD_ARTICLE_RESOURCES, loadArticleResourcesFlow),
    takeLatest(LOAD_ARTICLES_FOR_RESOURCES, loadArticlesForResourceFlow),
    takeLatest(LOAD_RELATED_ARTICLES, loadRelatedArticlesFlow),
  ]);
}

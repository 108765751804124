import { createAction } from '@reduxjs/toolkit';

export const LOAD_CART = 'LOAD_CART';
export const LOAD_CART_SUCCESS = 'LOAD_CART_SUCCESS';
export const LOAD_CART_FAIL = 'LOAD_CART_FAIL';

export const UPDATE_CART = 'UPDATE_CART';
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS';
export const UPDATE_CART_FAIL = 'UPDATE_CART_FAIL';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const ADD_SKUS_TO_CART = 'ADD_SKUS_TO_CART';
export const REMOVE_SKUS_FROM_CART = 'REMOVE_SKUS_FROM_CART';

// for interface, must be handle in app/website sagas
export const ITEMS_ADDED_TO_CART = 'ITEMS_ADDED_TO_CART';

export const RESET_CART_REDUCER = 'RESET_CART_REDUCER';

const initialState = {
  cart: { items: [] },
  loading: false,
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_CART: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_CART_SUCCESS: {
      return {
        ...state,
        loading: false,
        cart: action.payload.cart,
        errors: [],
      };
    }
    case LOAD_CART_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case ADD_TO_CART: {
      return {
        ...state,
        loading: true,
      };
    }

    case ADD_SKUS_TO_CART: {
      return {
        ...state,
        loading: true,
      };
    }

    case REMOVE_SKUS_FROM_CART: {
      return {
        ...state,
        loading: true,
      };
    }

    case REMOVE_FROM_CART: {
      return {
        ...state,
        loading: true,
      };
    }

    case UPDATE_CART: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_CART_SUCCESS: {
      return {
        ...state,
        loading: false,
        cart: action.payload.cart,
        errors: [],
      };
    }
    case UPDATE_CART_FAIL: {
      const errors = state.errors.slice();
      return {
        ...state,
        loading: false,
        errors: errors.concat(action.payload),
      };
    }

    case RESET_CART_REDUCER: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}

// resetCartReducer ====================================================

export function resetCartReducer() {
  return {
    type: RESET_CART_REDUCER,
  };
}

// loadCart ====================================================

// export function loadCart() {
//   return {
//     type: LOAD_CART,
//   };
// }
// export function loadCartSuccess(cart) {
//   return {
//     type: LOAD_CART_SUCCESS,
//     payload: { cart },
//   };
// }
// export function loadCartFail(error) {
//   return {
//     type: LOAD_CART_FAIL,
//     payload: new Error(error),
//     error: true,
//   };
// }

export const loadCart = createAction(LOAD_CART);
export const loadCartSuccess = createAction(LOAD_CART_SUCCESS, (cart) => ({
  payload: { cart },
}));
export const loadCartFail = createAction(LOAD_CART_FAIL, (error) => ({
  payload: new Error(error),
  error: true,
}));

// updateCart ====================================================

export function updateCart(options) {
  return {
    type: UPDATE_CART,
    payload: { options },
  };
}
export function updateCartSuccess(cart) {
  return {
    type: UPDATE_CART_SUCCESS,
    payload: { cart },
  };
}
export function updateCartFail(error) {
  return {
    type: UPDATE_CART_FAIL,
    payload: error,
    error: true,
  };
}

// addToCart / removeFromCart ====================================================

export function addToCart(prints, isBatch) {
  return {
    type: ADD_TO_CART,
    payload: { prints, isBatch },
  };
}

export function removeFromCart(prints, isBatch) {
  return {
    type: REMOVE_FROM_CART,
    payload: { prints, isBatch },
  };
}

// addSkusToCart / removeSkusFromCart ====================================================

export function addSkusToCart(skus) {
  return {
    type: ADD_SKUS_TO_CART,
    payload: { skus },
  };
}

export function removeSkusFromCart(skus) {
  return {
    type: REMOVE_SKUS_FROM_CART,
    payload: { skus },
  };
}

// itemsAddedToCart ====================================================

export function itemsAddedToCart(addedItems) {
  return {
    type: ITEMS_ADDED_TO_CART,
    payload: { addedItems },
  };
}

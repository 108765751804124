import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { addPrintLoan } from 'bubble-reducers/src/reducers/profiles';

import Toaster from '@/components/DUPLICATE/Toaster/Toaster';
import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';

import './Modals.css';

const LoanAlbumModal = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);

  const printObjectId = props.printObjectId;
  const albumObjectId = props.albumObjectId;

  const [name, setName] = useState('');

  const dismiss = () => {
    props.callback();
  };

  const handleClick = () => {
    if (!name) {
      return alert('Veuillez entrer un nom');
    }
    dispatch(addPrintLoan(user.objectId, { printObjectId, albumObjectId, loan: { name } }));
    Toaster.addToast('Mes prêts', '1 album ajouté à ma liste de prêts');
    dismiss();
  };

  const submitOnEnter = (e) => {
    e.preventDefault();
    handleClick();
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="md"
      centered
      contentClassName="bb-background-light-grey"
    >
      <div className="modal-header header-no-separator">
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={dismiss}
        ></button>
      </div>
      <div className="modal-body mx-sm-3 ">
        <div className="bb-l-text-size text-center pb-4">A qui avez-vous prêté ce livre ?</div>
        <LabeledTextInput
          onEnterPressed={submitOnEnter}
          mandatory
          placeholder="Nom"
          value={name}
          onChange={(e) => setName(e.target.value)}
          title="Nom"
        />
        <button className="float-end mt-4 btn btn-success text-white" onClick={handleClick}>
          Valider
        </button>
      </div>
    </Modal>
  );
};

LoanAlbumModal.propTypes = {
  albumObjectId: PropTypes.string,
  printObjectId: PropTypes.string,
};

export default LoanAlbumModal;

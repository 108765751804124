import React from 'react';

import config from '@/project-config';
import { connect } from 'react-redux';

import { searchMultiIndex } from 'bubble-reducers/src/reducers/search';
import { getHitsForWebsiteSearchBar } from 'bubble-reducers/src/selectors';

import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';
import withRouter from '@/components/withRouter';

import GlobalOverlay from '../GlobalOverlay/GlobalOverlay';
import SearchResults from '../SearchResults/SearchResults';

import { KEYCODES } from 'bubble-constants';

import './HomeSearchBar.scss';

class HomeSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.searchInput = props.inputRef || React.createRef();
    this.state = {
      searching: false,
      hasFocus: false,
      location: props.location.pathname,
      locationSearch: props.location.search,
      text: '',
    };
  }

  static getDerivedStateFromProps(nextProps, lastState) {
    if (
      nextProps.location.pathname !== lastState.location ||
      nextProps.location.search !== lastState.locationSearch
    ) {
      return {
        searching: false,
        location: nextProps.location.pathname,
        locationSearch: nextProps.location.search,
      };
    }
    return null;
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyboardEvent);
  }

  exitAndClear = (refocus = true) => {
    this.setState({ searching: false, hasFocus: false, text: '' });
    if (this.searchInput.current) this.searchInput.current.blur();
    if (refocus) {
      setTimeout(() => {
        if (this.searchInput.current) this.searchInput.current.focus();
      }, 50);
    }
    if (!!this.props.exitAndClearCallback) {
      this.props.exitAndClearCallback();
    }
  };

  handleKeyboardEvent = (event) => {
    // ESCAPE
    if (event.keyCode === 27) {
      this.exitAndClear(false);
    }
    // ENTER
    if (this.state.text && event.keyCode === KEYCODES.ENTER) {
      this.props.router.navigate(`/search?q=${encodeURI(this.state.text.replace(/%/g, ''))}`);
      this.setState({ searching: false, hasFocus: false });
      if (this.searchInput.current) this.searchInput.current.blur();
    }
  };

  handleClickOnOverlay = () => {
    this.setState({ searching: false, hasFocus: false, text: '' });
  };

  search = (event) => {
    const text = event.target.value;
    const { searchMultiIndex } = this.props;
    searchMultiIndex(
      [
        { name: config.algolia.seriesIndex, params: { hitsPerPage: 4 } },
        { name: config.algolia.authorsIndex, params: { hitsPerPage: 4 } },
        { name: config.algolia.articlesIndex, params: { hitsPerPage: 3 } },
        { name: config.algolia.albumsIndex, params: { hitsPerPage: 4 } },
        { name: config.algolia.storesIndex, params: { hitsPerPage: 3, filters: 'isPublic=1' } },
      ],
      text,
    );
    this.setState({ searching: !!text, text });
  };

  focus = () => {
    document.addEventListener('keyup', this.handleKeyboardEvent);
    this.setState({
      hasFocus: true,
      searching: !!this.state.text,
    });
  };

  blur = () => {
    document.removeEventListener('keyup', this.handleKeyboardEvent);
    this.setState({
      hasFocus: false,
    });
  };

  putFocusOnInput = () => {
    this.searchInput.current.focus();
  };

  render() {
    return (
      <div className="d-flex flex-fill justify-content-center" ref={(ref) => (this.barRef = ref)}>
        <GlobalOverlay
          onClick={this.handleClickOnOverlay}
          showGlobalOverlay={!!this.state.searching}
        />
        <div
          className={`home-search-bar rounded-medium input-group bg-grey align-items-center ${
            this.state.searching ? 'over-global-overlay' : ''
          }`}
        >
          {!this.state.hasFocus && !this.state.searching && !this.state.text && (
            <div className="placeholder-text">
              <span> Rechercher un album, une série, un auteur...</span>
            </div>
          )}
          <input
            ref={this.searchInput}
            onFocus={this.focus}
            onBlur={this.blur}
            onChange={this.search}
            value={this.state.text}
            className="form-control transparent-container my-2 text-white"
            aria-label="Recherche"
          />
          {(!!this.props.forceExitButton || this.state.text) && (
            <div className="search-clear-icon">
              <WithClickHandler onClick={this.exitAndClear}>
                <Icon name="times" />
              </WithClickHandler>
            </div>
          )}
          {!!!this.props.forceExitButton && (
            <div className="input-group-append" onClick={this.putFocusOnInput}>
              <div className="bg-grey rounded-medium" id="basic-addon1">
                <button className="btn btn-transparent py-3 px-3">
                  <Icon name="search" className="text-white" />
                </button>
              </div>
            </div>
          )}
          {this.state.searching && (
            <SearchResults
              hits={this.props.hits}
              text={this.state.text.repeat(1)}
              searchBarRef={this.barRef}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hits: getHitsForWebsiteSearchBar(state),
});

export default withRouter(connect(mapStateToProps, { searchMultiIndex })(HomeSearchBar));

import { MODES } from '@/scenes/Serie/components/SerieModeSwitcher/SerieModeSwitcher';

export const SET_CLICKED_HEADER_MENU = 'website/SET_CLICKED_HEADER_MENU';
export const SET_SHOW_HEADER_MENU = 'website/SET_SHOW_HEADER_MENU';

export const SET_COLLECTION_VIEW_MODE = 'website/SET_COLLECTION_VIEW_MODE';

const initialState = {
  clickedHeaderMenu: 'bd',
  showHeaderMenu: false,
  collectionViewMode: MODES.LIST,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CLICKED_HEADER_MENU: {
      return {
        ...state,
        clickedHeaderMenu: action.payload.clickedHeaderMenu,
      };
    }
    case SET_SHOW_HEADER_MENU: {
      return {
        ...state,
        showHeaderMenu: action.payload.showHeaderMenu,
      };
    }
    case SET_COLLECTION_VIEW_MODE: {
      return {
        ...state,
        collectionViewMode: action.payload.collectionViewMode,
      };
    }

    default: {
      return state;
    }
  }
}

// setClickedHeaderMenu ====================================================

export function setClickedHeaderMenu(clickedHeaderMenu) {
  return {
    type: SET_CLICKED_HEADER_MENU,
    payload: { clickedHeaderMenu },
  };
}

// setShowHeaderMenu ====================================================

export function setShowHeaderMenu(showHeaderMenu) {
  return {
    type: SET_SHOW_HEADER_MENU,
    payload: { showHeaderMenu },
  };
}

// setCollectionViewMode ====================================================

export function setCollectionViewMode(collectionViewMode) {
  return {
    type: SET_COLLECTION_VIEW_MODE,
    payload: { collectionViewMode },
  };
}

import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { loadSerieApi, loadSeriesApi, loadSeriesAtAlphanumericIndexApi } from '../services/api';

import { LOAD_SERIE, LOAD_SERIES, LOAD_SERIES_ALPHANUMERIC_INDEX } from '../reducers/series';
import { storeSeries } from '../reducers/series';
import {
  loadSerieFail,
  loadSerieSuccess,
  loadSeriesAtAlphanumericIndexFail,
  loadSeriesAtAlphanumericIndexSuccess,
  loadSeriesFail,
  loadSeriesSuccess,
} from '../reducers/series';
import { storeAlbumsFlow } from '../sagas/albums';
import { getClientToken } from '../selectors';

function* loadSerieFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      loadSerieApi,
      clientToken,
      action.payload.serieObjectId,
      action.payload.params,
    );
    //TODO: '/serie/:id' albums are not normalized
    yield call(storeSeriesFlow, { payload: { series: [response], source: 'loadSerieFlow' } });
    yield put(loadSerieSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadSerieFlow)', error);
    yield put(loadSerieFail(error));
  }
}

function* loadSeriesAtAlphanumericIndexFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      loadSeriesAtAlphanumericIndexApi,
      clientToken,
      action.payload.index,
    );
    yield put(loadSeriesAtAlphanumericIndexSuccess(action.payload.index, response));
  } catch (error) {
    console.log('saga middleware error (loadSeriesAtAlphanumericIndexFlow)', error);
    yield put(loadSeriesAtAlphanumericIndexFail(error));
  }
}

function* loadSeriesFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadSeriesApi, clientToken, action.payload.params);
    yield call(storeSeriesFlow, { payload: { series: response, source: 'loadSeriesFlow' } });
    yield put(loadSeriesSuccess(response, action.payload.params));
  } catch (error) {
    console.log('saga middleware error (loadSeriesFlow)', error);
    yield put(loadSeriesFail(error));
  }
}

export function* storeSeriesFlow(action) {
  try {
    const series = action.payload.series;
    const albums = series.reduce((tab, cur) => tab.concat(cur.albums), []);
    if (albums.every((album) => typeof album !== 'string')) {
      yield call(storeAlbumsFlow, {
        payload: { albums: albums, source: `${action.payload.source || '?'}>storeSeriesFlow` },
      });
    }
    yield put(storeSeries(series));
  } catch (error) {
    console.log('saga middleware error (storeSeriesFlow)', error);
    yield put(loadSeriesFail(error));
  }
}

export default function* seriesWatcher() {
  yield all([
    takeEvery(LOAD_SERIE, loadSerieFlow),
    takeEvery(LOAD_SERIES, loadSeriesFlow),
    takeEvery(LOAD_SERIES_ALPHANUMERIC_INDEX, loadSeriesAtAlphanumericIndexFlow),
  ]);
}

import utils from 'bubble-utils';

const LibraryUtils = {};

LibraryUtils.getSectionsForAgenda = (albums) => {
  const weeks = {};
  albums.forEach((album) => {
    const firstDayDate = utils.date
      .getFirstDayOfTheWeekDate(album.publicationDate || new Date())
      .toISOString();
    if (!weeks[firstDayDate]) {
      weeks[firstDayDate] = {
        firstDayDate: firstDayDate,
        albums: [],
      };
    }
    weeks[firstDayDate].albums.push(album);
  });
  return weeks || {};
};

LibraryUtils.getSectionFromSerie = (serie) => {
  const titleIndexed = serie.serie.titleIndexed || '#';
  let section = titleIndexed.charAt(0).toUpperCase();
  if (
    Number(section) === parseInt(section, 10) ||
    !~'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.indexOf(section)
  ) {
    section = '_';
  }
  return section;
};

LibraryUtils.getSectionsFromSeries = (series) => {
  const sections = new Set(series.map((serie) => LibraryUtils.getSectionFromSerie(serie)));
  return Array.from(sections);
};

export default LibraryUtils;

import { all, select, takeEvery } from 'redux-saga/effects';

import { INIT_EVENT, LOG_EVENT } from 'bubble-reducers/src/reducers/events';
import { getUserOrGuest } from 'bubble-reducers/src/selectors';

import { addEvent } from '../../services/events';

import bubbleUtils from 'bubble-utils';

let isSent = null;
let globalParams = null;

function initEventFlow(action) {
  isSent = action.payload.isSent;
  globalParams = action.payload.globalParams;
}

function* logEventFlow(action) {
  try {
    if (isSent) {
      const payload = action.payload;
      const user = yield select(getUserOrGuest);
      const userParams = {
        userObjectId: user?.objectId || null,
        isUserInfinity: bubbleUtils.infinity.isUserInfinity(user) || null,
      };
      // classic event
      addEvent(
        payload.name,
        payload.value,
        Object.assign(payload.properties || {}, globalParams, userParams),
      );
      // increment event
      if (payload.incrementName) {
        addEvent(
          payload.incrementName,
          payload.incrementValue,
          Object.assign(payload.properties || {}, globalParams, userParams),
        );
      }
    }
  } catch (error) {
    console.log('saga middleware error (logEventFlow)', error);
  }
}

export default function* eventsWatcher() {
  yield all([takeEvery(INIT_EVENT, initEventFlow), takeEvery(LOG_EVENT, logEventFlow)]);
}

import React from 'react';

import { connect } from 'react-redux';

import { loadAgendas } from 'bubble-reducers/src/reducers/agendas';
import { updateFilterForItem } from 'bubble-reducers/src/reducers/filters';
import { getFiltersForItemId } from 'bubble-reducers/src/selectors';

import LibraryUtils from '@/services/library-utils';

import BubbleCheckbox from '@/components/BubbleCheckbox/BubbleCheckbox';
import { Icon } from '@/components/Icon/Icon';

class AgendaFilter extends React.Component {
  shouldComponentUpdate(nextProps) {
    const listChanged =
      nextProps.list.reduce((acc, cur) => {
        return acc + cur.ean;
      }, '') !==
      this.props.list.reduce((acc, cur) => {
        return acc + cur.ean;
      }, '');
    const filtersChanged = nextProps.filters !== this.props.filters;
    return listChanged || filtersChanged;
  }

  filterList = (filter) => {
    const { updateFilterForItem, filters } = this.props;
    updateFilterForItem('agenda', 'category', filters.category.has(filter) ? 'del' : 'add', filter);
  };

  loadWithDate = (date) => {
    const { loadAgendas } = this.props;
    loadAgendas(null, date);
  };

  loadPast = () => {
    let date = new Date();
    date.setDate(date.getDate() - 31);
    this.loadWithDate(date);
  };

  loadPresent = () => {
    this.loadWithDate(new Date());
  };

  handleDateChange = (e) => {
    const value = e.target.value;
    if (value === 'past') return this.loadPast();
    if (value === 'present') return this.loadPresent();
  };

  render() {
    const categoryFilters = Array.from(this.props.filters.category);
    let filteredList = this.props.list;
    if (categoryFilters.length) {
      filteredList = this.props.list.filter((album) =>
        categoryFilters.some((category) => album.serie.category === category),
      );
    }
    const sections = LibraryUtils.getSectionsForAgenda(filteredList);

    return (
      <>
        <div className="row px-3 pb-5">
          <div className="offset-lg-2 col-lg-8 bg-white rounded bb-shadow-hover p-3">
            <div className="d-sm-flex align-items-center bb-medium-text-size">
              <div className="me-5 py-2">
                <BubbleCheckbox
                  label="Bande dessinées"
                  checked={categoryFilters.includes('BD')}
                  onChange={() => this.filterList('BD')}
                  id="checkBd"
                />
              </div>
              <div className="me-5 py-2">
                <BubbleCheckbox
                  label="Comics"
                  checked={categoryFilters.includes('Comics')}
                  onChange={() => this.filterList('Comics')}
                  id="checkComics"
                />
              </div>
              <div className="me-5 py-2 ">
                <BubbleCheckbox
                  label="Mangas"
                  checked={categoryFilters.includes('Mangas')}
                  onChange={() => this.filterList('Mangas')}
                  id="checkMangas"
                />
              </div>
              {/* DATES */}
              <div className="input-group agenda-time-chooser-prepend">
                <div className="input-group-prepend">
                  <div className="mt-n1 input-group-text transparent-container">
                    <Icon className="text-secondary" name="calendar-alt" />
                  </div>
                </div>
                <select
                  className="form-select agenda-time-chooser text-secondary"
                  onChange={this.handleDateChange}
                  defaultValue="present"
                >
                  <option value="past">Le mois dernier</option>
                  <option value="present">Les sorties à venir</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {this.props.children(sections)}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: getFiltersForItemId(state, 'agenda'),
});

export default connect(mapStateToProps, { loadAgendas, updateFilterForItem })(AgendaFilter);

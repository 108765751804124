import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { connect, useDispatch } from 'react-redux';

import { addToCart } from 'bubble-reducers/src/reducers/cart';
import { isAlbumOwned } from 'bubble-reducers/src/selectors';

import { isBinaryFlagEnabled } from 'bubble-utils/src/code-utils';

import {
  useAddPrintsToLibrary,
  useRemoveFromCart,
  useRemovePrintsFromLibrary,
} from '@/services/hooks/useLibraryUtils';
import imageUtils from '@/services/image-utils';

import AddedToCartModal from '@/components/Modals/AddedToCartModal';

import SerieModeSwitcher from '../SerieModeSwitcher/SerieModeSwitcher';

import { GENERAL } from 'bubble-constants';
import bubbleUtils from 'bubble-utils';

const SerieBatchActions = (props) => {
  const dispatch = useDispatch();

  const addPrintsToLibrary = useAddPrintsToLibrary();
  const removePrintsFromLibrary = useRemovePrintsFromLibrary();
  const removeFromCart = useRemoveFromCart();

  const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);

  const serie = props.serie;
  const user = props.user;

  const missingAlbumsPrice = props.missingAvailablePrints.reduce((acc, cur) => {
    acc += Number(cur.sellingInfo.price);
    return acc;
  }, 0);

  const onClickCart = () => {
    if (props.allMissingsInCart) {
      removeFromCart(props.missingAvailablePrints, true);
    } else {
      dispatch(addToCart(props.missingAvailablePrints, true));
      setShowAddedToCartModal(true);
    }
  };

  const onClickCollection = () => {
    props.missingAlbumsIds.length === 0
      ? removePrintsFromLibrary(user, props.ownedSeriePrints)
      : addPrintsToLibrary(user, props.serieMissingPrints);
  };

  return (
    <div className="row mt-3 mb-4">
      {showAddedToCartModal && (
        <AddedToCartModal
          show={showAddedToCartModal}
          itemIds={props.missingAvailablePrints.map((print) => print.objectId)}
          callback={() => setShowAddedToCartModal(false)}
        />
      )}
      <div className="col-12 d-flex flex-fill align-items-center">
        <div className="d-none d-md-block">
          {serie.numberOfAlbums
            ? `${serie.numberOfAlbums} tome${serie.numberOfAlbums > 1 ? 's :' : ':'}`
            : ''}
        </div>
        <div className="d-flex flex-column flex-md-row mt-n1">
          <span>
            <button
              onClick={onClickCollection}
              className="btn btn-link no-decoration d-flex align-items-center"
            >
              <img
                className="mt-n2"
                alt="tout ajouter collection"
                src={
                  props.missingAlbumsIds.length === 0
                    ? imageUtils.getAssetImage('icon_add_collection_green.svg')
                    : imageUtils.getAssetImage('icon_add_collection.svg')
                }
              />
              <div
                className={`ms-1 mt-md-1 ${
                  props.missingAlbumsIds.length === 0 ? 'text-success' : ''
                }`}
              >
                {props.missingAlbumsIds.length === 0 ? 'Tout retirer de' : 'Tout ajouter à'} ma
                collection
              </div>
            </button>
          </span>
          <span>
            <button
              disabled={props.missingAvailablePrints.length === 0}
              onClick={onClickCart}
              className="ms-md-2 btn btn-link no-decoration d-flex align-items-center"
            >
              <img
                className="mt-n2"
                alt="tout ajouter panier"
                src={
                  props.allMissingsInCart
                    ? imageUtils.getAssetImage('icon_remove_cart_red.svg')
                    : imageUtils.getAssetImage('icon_add_cart_grey.svg')
                }
              />
              <div className={`ms-1 mt-md-1 ${props.allMissingsInCart ? 'text-danger' : ''}`}>
                {props.allMissingsInCart
                  ? 'Retirer les albums de mon panier'
                  : 'Ajouter les manquants au panier'}
              </div>
              <div className={`ms-1 mt-md-1 ${props.allMissingsInCart ? 'text-danger' : ''}`}>
                ({bubbleUtils.currency.formatCurrency(missingAlbumsPrice) || '-'})
              </div>
            </button>
          </span>
        </div>

        <div className="flex-fill" />
        <span className="d-none d-md-block">
          <SerieModeSwitcher mode={props.serieMode} callback={props.setSerieMode} />
        </span>
      </div>
    </div>
  );
};

SerieBatchActions.propTypes = {
  serie: PropTypes.object,
  serieMode: PropTypes.any,
  setSerieMode: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  const serie = props.serie || {};
  const ownedSeriePrints = (serie.albums || []).reduce((acc, cur) => {
    if (state.profiles.ownedAlbumsIdsMap[cur]) {
      Object.entries(state.profiles.myAlbums[cur]?.userPrints || {}).forEach(
        ([printObjectId, flag]) => {
          if (isBinaryFlagEnabled(flag, GENERAL.USER_PRINT.OWNED)) {
            acc.push({
              album: { objectId: cur },
              objectId: printObjectId,
            });
          }
        },
      );
    }
    return acc;
  }, []);
  const serieMissingPrints = (serie.albums || []).reduce((acc, cur) => {
    if (!isAlbumOwned(state, cur))
      acc.push({
        album: { objectId: cur },
        objectId: state.albums.albums[cur].defaultPrintObjectId,
      });
    return acc;
  }, []);
  const missingAlbumsIds = (serie.albums || []).reduce((acc, cur) => {
    if (!isAlbumOwned(state, cur)) acc.push(cur);
    return acc;
  }, []);
  const missingAvailablePrints = missingAlbumsIds
    .map(
      (albumId) =>
        state.prints.prints[(state.albums.albums[albumId] || {}).defaultSellingPrintObjectId] || {},
    )
    .filter((print) => {
      const availabilities = bubbleUtils.album.getAvailabilities(print);
      return availabilities.atLeastOneChannel;
    });
  return {
    ownedSeriePrints,
    user: state.user.user,
    serieMissingPrints,
    missingAlbumsIds,
    missingAvailablePrints,
    allMissingsInCart:
      missingAvailablePrints.length &&
      missingAvailablePrints.every(
        (print) => !!state.cart.cart.items.find((item) => item.itemObjectId === print.objectId),
      ),
  };
};

export default connect(mapStateToProps, null)(SerieBatchActions);

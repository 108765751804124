export const INIT_EVENT = 'INIT_EVENT';
export const LOG_EVENT = 'LOG_EVENT';

const initialState = {
  isSent: false,
  globalParams: null,
  trackers: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INIT_EVENT: {
      return {
        ...state,
        isSent: action.payload.isSent,
        globalParams: action.payload.globalParams,
      };
    }

    default: {
      return state;
    }
  }
}

export function initEvents(isSent, globalParams) {
  return {
    type: INIT_EVENT,
    payload: { isSent, globalParams },
  };
}

export function logEvent(name, properties, value, incrementName, incrementValue) {
  return {
    type: LOG_EVENT,
    payload: { name, properties, value, incrementName, incrementValue },
  };
}

export const LOAD_AVAILABILITY_ALERTS = 'LOAD_AVAILABILITY_ALERTS';
export const LOAD_AVAILABILITY_ALERTS_SUCCESS = 'LOAD_AVAILABILITY_ALERTS_SUCCESS';
export const LOAD_AVAILABILITY_ALERTS_FAIL = 'LOAD_AVAILABILITY_ALERTS_FAIL';

export const CREATE_AVAILABILITY_ALERT = 'CREATE_AVAILABILITY_ALERT';
export const CREATE_AVAILABILITY_ALERT_SUCCESS = 'CREATE_AVAILABILITY_ALERT_SUCCESS';
export const CREATE_AVAILABILITY_ALERT_FAIL = 'CREATE_AVAILABILITY_ALERT_FAIL';

export const DELETE_AVAILABILITY_ALERT = 'DELETE_AVAILABILITY_ALERT';
export const DELETE_AVAILABILITY_ALERT_SUCCESS = 'DELETE_AVAILABILITY_ALERT_SUCCESS';
export const DELETE_AVAILABILITY_ALERT_FAIL = 'DELETE_AVAILABILITY_ALERT_FAIL';

const RESET_AVAILABILITY_ALERTS_REDUCER = 'RESET_AVAILABILITY_ALERTS_REDUCER';

const initialState = {
  availabilityAlertsMapByPrintObjectId: {},
  loading: {},
  errors: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_AVAILABILITY_ALERTS: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_AVAILABILITY_ALERTS]: true },
        errors: { ...state.errors, [LOAD_AVAILABILITY_ALERTS]: null },
      };
    }
    case LOAD_AVAILABILITY_ALERTS_SUCCESS: {
      const availabilityAlertsMapByPrintObjectId = action.payload.availabilityAlerts.reduce(
        (acc, cur) => {
          delete cur.album;
          acc[cur.printObjectId] = cur;
          return acc;
        },
        {},
      );
      return {
        ...state,
        availabilityAlertsMapByPrintObjectId: availabilityAlertsMapByPrintObjectId,
        loading: { ...state.loading, [LOAD_AVAILABILITY_ALERTS]: false },
        errors: { ...state.errors, [LOAD_AVAILABILITY_ALERTS]: null },
      };
    }
    case LOAD_AVAILABILITY_ALERTS_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_AVAILABILITY_ALERTS]: false },
        errors: { ...state.errors, [LOAD_AVAILABILITY_ALERTS]: action.payload },
      };
    }

    case CREATE_AVAILABILITY_ALERT: {
      return {
        ...state,
        loading: { ...state.loading, [CREATE_AVAILABILITY_ALERT]: action.payload.printObjectId },
        errors: { ...state.errors, [CREATE_AVAILABILITY_ALERT]: null },
      };
    }
    case CREATE_AVAILABILITY_ALERT_SUCCESS: {
      delete action.payload.availabilityAlert.album;
      return {
        ...state,
        availabilityAlertsMapByPrintObjectId: {
          ...state.availabilityAlertsMapByPrintObjectId,
          [action.payload.availabilityAlert.printObjectId]: action.payload.availabilityAlert,
        },
        loading: { ...state.loading, [CREATE_AVAILABILITY_ALERT]: false },
        errors: { ...state.errors, [CREATE_AVAILABILITY_ALERT]: null },
      };
    }
    case CREATE_AVAILABILITY_ALERT_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [CREATE_AVAILABILITY_ALERT]: false },
        errors: { ...state.errors, [CREATE_AVAILABILITY_ALERT]: action.payload },
      };
    }

    case DELETE_AVAILABILITY_ALERT: {
      return {
        ...state,
        loading: { ...state.loading, [DELETE_AVAILABILITY_ALERT]: true },
        errors: { ...state.errors, [DELETE_AVAILABILITY_ALERT]: null },
      };
    }
    case DELETE_AVAILABILITY_ALERT_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, [DELETE_AVAILABILITY_ALERT]: false },
        errors: { ...state.errors, [DELETE_AVAILABILITY_ALERT]: null },
      };
    }
    case DELETE_AVAILABILITY_ALERT_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [DELETE_AVAILABILITY_ALERT]: false },
        errors: { ...state.errors, [DELETE_AVAILABILITY_ALERT]: action.payload },
      };
    }

    case RESET_AVAILABILITY_ALERTS_REDUCER: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}

// createAvailabilityAlert ====================================================

export function createAvailabilityAlert(userObjectId, printObjectId) {
  return {
    type: CREATE_AVAILABILITY_ALERT,
    payload: {
      userObjectId,
      printObjectId,
    },
  };
}
export function createAvailabilityAlertSuccess(availabilityAlert) {
  return {
    type: CREATE_AVAILABILITY_ALERT_SUCCESS,
    payload: {
      availabilityAlert,
    },
  };
}
export function createAvailabilityAlertFail(error) {
  return {
    type: CREATE_AVAILABILITY_ALERT_FAIL,
    payload: new Error(error),
  };
}

// loadAvailabilityAlerts ====================================================

export function loadAvailabilityAlerts(userObjectId) {
  return {
    type: LOAD_AVAILABILITY_ALERTS,
    payload: {
      userObjectId,
    },
  };
}
export function loadAvailabilityAlertsSuccess(availabilityAlerts) {
  return {
    type: LOAD_AVAILABILITY_ALERTS_SUCCESS,
    payload: {
      availabilityAlerts,
    },
  };
}
export function loadAvailabilityAlertsFail(error) {
  return {
    type: LOAD_AVAILABILITY_ALERTS_FAIL,
    payload: new Error(error),
  };
}

// deleteAvailabilityAlert ====================================================

export function deleteAvailabilityAlert(userObjectId, availabilityAlertObjectId) {
  return {
    type: DELETE_AVAILABILITY_ALERT,
    payload: {
      userObjectId,
      availabilityAlertObjectId,
    },
  };
}
export function deleteAvailabilityAlertSuccess() {
  return {
    type: DELETE_AVAILABILITY_ALERT_SUCCESS,
  };
}
export function deleteAvailabilityAlertFail(error) {
  return {
    type: DELETE_AVAILABILITY_ALERT_FAIL,
    payload: new Error(error),
  };
}

// resetAvailabilityAlertsReducer

export function resetAvailabilityAlertsReducer() {
  return {
    type: RESET_AVAILABILITY_ALERTS_REDUCER,
  };
}

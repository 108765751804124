import { all } from 'redux-saga/effects';

import agendas from 'bubble-reducers/src/sagas/agendas';
import albums from 'bubble-reducers/src/sagas/albums';
import articles from 'bubble-reducers/src/sagas/articles';
import auth from 'bubble-reducers/src/sagas/auth';
import authors from 'bubble-reducers/src/sagas/authors';
import availabilityAlerts from 'bubble-reducers/src/sagas/availability-alerts';
import cart from 'bubble-reducers/src/sagas/cart';
import catalogs from 'bubble-reducers/src/sagas/catalogs';
import comments from 'bubble-reducers/src/sagas/comments';
import crowdfundings from 'bubble-reducers/src/sagas/crowdfundings';
import highlightedResources from 'bubble-reducers/src/sagas/highlighted-resources';
import inAppPurchases from 'bubble-reducers/src/sagas/in-app-purchases';
import orders from 'bubble-reducers/src/sagas/orders';
import paymentMethods from 'bubble-reducers/src/sagas/payment-methods';
import prints from 'bubble-reducers/src/sagas/prints';
import profiles from 'bubble-reducers/src/sagas/profiles';
import promotions from 'bubble-reducers/src/sagas/promotions';
import reviews from 'bubble-reducers/src/sagas/reviews';
import search from 'bubble-reducers/src/sagas/search';
import series from 'bubble-reducers/src/sagas/series';
import stores from 'bubble-reducers/src/sagas/stores';
import tags from 'bubble-reducers/src/sagas/tags';
import tops from 'bubble-reducers/src/sagas/tops';
import user from 'bubble-reducers/src/sagas/user';

import websiteAuth from '../engine/sagas/website-auth';
import websiteEvents from '../engine/sagas/website-events';
import websiteGuest from '../engine/sagas/website-guest';
import websiteInterface from '../engine/sagas/website-interface';
import websiteOther from '../engine/sagas/website-other';

export default function* IndexSaga() {
  yield all([
    // from bubble-reducers
    agendas(),
    albums(),
    articles(),
    auth(),
    authors(),
    availabilityAlerts(),
    cart(),
    catalogs(),
    comments(), // not in app
    crowdfundings(),
    highlightedResources(), // not in app
    inAppPurchases(),
    orders(),
    paymentMethods(),
    prints(),
    profiles(),
    promotions(),
    reviews(),
    search(),
    series(),
    stores(),
    tags(),
    tops(),
    user(),
    // from bubble-website
    websiteAuth(),
    websiteEvents(),
    websiteGuest(),
    websiteInterface(),
    websiteOther(),
  ]);
}

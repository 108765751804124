export const MOVE_CART_ALBUM_TO_WISHLIST = 'website/MOVE_CART_ALBUM_TO_WISHLIST';

export const ACTIVATE_TRIAL_PERIOD = 'website/ACTIVATE_TRIAL_PERIOD';

export const REOPTIN_NEWSLETTER = 'website/REOPTIN_NEWSLETTER';
export const REOPTIN_NEWSLETTER_SUCCESS = 'website/REOPTIN_NEWSLETTER_SUCCESS';

export const ADD_ALBUM_TO_ADDITION = 'website/ADD_ALBUM_TO_ADDITION';
export const ADD_ALBUM_TO_ADDITION_SUCCESS = 'website/ADD_ALBUM_TO_ADDITION_SUCCESS';
export const ADD_ALBUM_TO_ADDITION_FAIL = 'website/ADD_ALBUM_TO_ADDITION_FAIL';

export const UPDATE_SPECIFIC_OTHER_VARIABLE = 'website/UPDATE_SPECIFIC_OTHER_VARIABLE';

const initialState = {
  actionStatus: null,
  reOptinNewsletter: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REOPTIN_NEWSLETTER_SUCCESS: {
      return {
        ...state,
        reOptinNewsletter: true,
      };
    }
    case UPDATE_SPECIFIC_OTHER_VARIABLE: {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }

    case ADD_ALBUM_TO_ADDITION: {
      return {
        ...state,
        actionStatus: ADD_ALBUM_TO_ADDITION,
      };
    }
    case ADD_ALBUM_TO_ADDITION_SUCCESS: {
      return {
        ...state,
        actionStatus: ADD_ALBUM_TO_ADDITION_SUCCESS,
      };
    }
    case ADD_ALBUM_TO_ADDITION_FAIL: {
      return {
        ...state,
        actionStatus: ADD_ALBUM_TO_ADDITION_FAIL,
      };
    }

    default: {
      return state;
    }
  }
}

// updateSpecificOtherVariable ====================================================

export function updateSpecificOtherVariable(name, value) {
  return {
    type: UPDATE_SPECIFIC_OTHER_VARIABLE,
    payload: { name, value },
  };
}
// moveCartAlbumToWishlist ====================================================

export function moveCartAlbumToWishlist(item) {
  return {
    type: MOVE_CART_ALBUM_TO_WISHLIST,
    payload: { item },
  };
}

// activateTrialPeriod ====================================================

export function activateTrialPeriod(userObjectId) {
  return {
    type: ACTIVATE_TRIAL_PERIOD,
    payload: { userObjectId },
  };
}

// reOptinNewsletter

export function reOptinNewsletter(userObjectId, email) {
  return {
    type: REOPTIN_NEWSLETTER,
    payload: { userObjectId, email },
  };
}
export function reOptinNewsletterSuccess() {
  return {
    type: REOPTIN_NEWSLETTER_SUCCESS,
  };
}

// addAlbumToAddition ====================================================

export function addAlbumToAddition(userObjectId, userEmail, addition) {
  return {
    type: ADD_ALBUM_TO_ADDITION,
    payload: { userObjectId, userEmail, addition },
  };
}
export function addAlbumToAdditionSuccess() {
  return {
    type: ADD_ALBUM_TO_ADDITION_SUCCESS,
    payload: {},
  };
}
export function addAlbumToAdditionFail(error) {
  return {
    type: ADD_ALBUM_TO_ADDITION_FAIL,
    payload: new Error(error),
    error: true,
  };
}

import React from 'react';

import { connect } from 'react-redux';

import { loadTop, loadTops } from 'bubble-reducers/src/reducers/tops';

import { getSeoForTop } from '@/services/seo-utils';

import BreadCrumbs from '@/components/Breadcrumbs/BreadCrumbs';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import ShareZone from '@/components/ShareZone/ShareZone';
import Sidebar from '@/components/Sidebar/Sidebar';
import TopHorizontalCard from '@/components/TopHorizontalCard/TopHorizontalCard';
import withRouter from '@/components/withRouter';

import TopAlbumCard from './components/TopAlbumCard/TopAlbumCard';
import TopSerieCard from './components/TopSerieCard/TopSerieCard';

import './Top.scss';

class Top extends React.Component {
  constructor(props) {
    super(props);
    this.loadInfos(props.router?.params?.topName);
  }

  loadInfos(info) {
    const { loadTop, loadTops } = this.props;
    loadTops();
    loadTop(info);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.router?.params?.topName !== this.props.router?.params?.topName) {
      this.loadInfos(this.props.router?.params?.topName);
    }
  }

  render() {
    const topName = this.props.router?.params?.topName;
    const top = this.props.tops[topName] || {};
    const topData = Array.isArray(this.props.loadedTops[topName])
      ? this.props.loadedTops[topName]
      : [];
    const dataType = top.dataType;

    return (
      <div className="bb-background-light-grey">
        {!!top.name && !!topData && <BubbleHelmet seo={getSeoForTop(top, topData)} />}
        <div alt="placeholder" className="top-image-container">
          <div className="container">
            <div className="d-flex align-items-center px-sm-n3 mb-n3">
              <BreadCrumbs
                currentObject={{ custom: true, name: top.nameFrench }}
                previousObject={{ custom: true, name: 'Les Tops Bubble', link: '/tops' }}
              />
              <ShareZone title="Faire découvrir ce top à des amis" />
            </div>
            <div className="px-sm-3 pt-4">
              <div className="row">
                <div className="col-md-8">
                  <h1 className="bb-xxl-text-size">{top.nameFrench}</h1>
                  <p className="py-3">{top.description}</p>
                  {topData.map((topItem) => (
                    <div key={`${topItem.score}${topItem.position}`} className="py-3 w-lg-80">
                      {dataType === 'albums' && <TopAlbumCard topItem={topItem} />}
                      {dataType === 'series' && <TopSerieCard topItem={topItem} />}
                    </div>
                  ))}
                </div>
                <div className="d-md-block col-md-4 d-none pt-5">
                  <div className="mb-3 fw-bold pt-4 pb-2" style={{ position: 'relative', top: 9 }}>
                    Retrouvez tous les classements de la communauté Bubble :
                  </div>
                  {Object.values(this.props.tops).map((top) => (
                    <div key={`thumbnail_${top.name}`} className="mb-3">
                      <TopHorizontalCard topInfo={top} />
                    </div>
                  ))}
                  <div className="pt-5">
                    <Sidebar />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tops: state.tops.tops,
  loadedTops: state.tops.loadedTops,
});

export default withRouter(connect(mapStateToProps, { loadTop, loadTops })(Top));

import PropTypes from 'prop-types';
import React from 'react';

import { PROJECT } from '@/project-constants';

import HighlightBanner from '@/components/HighlightBanner/HighlightBanner';

import '../Listing.css';

class ListHeader extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.category.name !== this.props.category.name ||
      nextProps.category.text !== this.props.category.text ||
      nextProps.genre.name !== this.props.genre.name ||
      nextProps.genre.text !== this.props.genre.text ||
      nextProps.banner !== this.props.banner
    );
  }

  render() {
    const category = this.props.category || {};
    const genre = this.props.genre || {};
    const tags = this.props.tags || null;
    let title = (tags ? tags.join(', ') : genre.name ? genre.name : category.name) || ' ';
    const text = genre?.text || category?.text || '';
    //TODO: hotfix for 'Bd' instead of 'BD'

    title = title === 'bd' ? 'BD' : title[0].toUpperCase() + title.substring(1, title.length);

    const banner = this.props.banner;
    return (
      <div>
        <HighlightBanner
          slim
          imageUrl={banner.imageUrl}
          imageUrlMobile={banner.imageUrlMobile}
          alt={banner.imageAlt}
          link={banner.link}
        />
        <div className="mt-5">
          {genre.name && (
            <h4>
              <span className="badge bg-bubble-color badge-padding text-small px-2 mb-2 fw-normal">
                {PROJECT.CATEGORY_NAMES_LONG[category.name]}
              </span>
            </h4>
          )}
          <h1 className="h2">{title}</h1>
          <p>{text}</p>
        </div>
      </div>
    );
  }
}

ListHeader.propTypes = {
  category: PropTypes.object,
  genre: PropTypes.object,
  tags: PropTypes.array,
  banner: PropTypes.object,
};

export default ListHeader;

import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  checkEanStatusApi,
  createAlbumAdditionApi,
  createAlbumRevisionApi,
  createPrintRevisionApi,
  createSerieRevisionApi,
} from '../services/api';

import {
  CHECK_EAN_STATUS,
  CREATE_ALBUM_ADDITION,
  CREATE_ALBUM_REVISION,
  CREATE_PRINT_REVISION,
  CREATE_SERIE_REVISION,
} from '../reducers/catalogs';
import {
  checkEanStatusFail,
  checkEanStatusSuccess,
  createAlbumAdditionFail,
  createAlbumAdditionSuccess,
  createAlbumRevisionFail,
  createAlbumRevisionSuccess,
  createPrintRevisionFail,
  createPrintRevisionSuccess,
  createSerieRevisionFail,
  createSerieRevisionSuccess,
} from '../reducers/catalogs';
import { getClientToken } from '../selectors';

export function* createAlbumRevisionFlow(action) {
  try {
    const clientToken = yield select(getClientToken);

    const response = yield call(
      createAlbumRevisionApi,
      clientToken,
      action.payload.albumObjectId,
      action.payload.revisions,
    );
    yield put(createAlbumRevisionSuccess(response));
  } catch (error) {
    console.log('saga middleware error (createAlbumRevisionFlow)', error);
    yield put(createAlbumRevisionFail(error));
  }
}

export function* createAlbumAdditionFlow(action) {
  try {
    const clientToken = yield select(getClientToken);

    const response = yield call(
      createAlbumAdditionApi,
      clientToken,
      action.payload.userObjectId,
      action.payload.userEmail,
      action.payload.addition,
    );
    yield put(createAlbumAdditionSuccess(response));
  } catch (error) {
    console.log('saga middleware error (createAlbumAdditionFlow)', error);
    yield put(createAlbumAdditionFail(error));
  }
}

function* createSerieRevisionFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      createSerieRevisionApi,
      clientToken,
      action.payload.serieObjectId,
      action.payload.revisions,
    );
    yield put(createSerieRevisionSuccess(response));
  } catch (error) {
    console.log('saga middleware error (createSerieRevisionFlow)', error);
    yield put(createSerieRevisionFail(error));
  }
}

export function* checkEanStatusFlow(action) {
  try {
    const clientToken = yield select(getClientToken);

    // * 'missingEan': EAN is missing, please fill one
    // * 'notFound': the EAN hasn't been found anywhere
    // * 'foundInProduction': the EAN is already on our production database
    // * 'dataFound': data has been found for the EAN in catalog
    // * 'alreadyAdded': the EAN has already been added
    const response = yield call(checkEanStatusApi, clientToken, action.payload.ean);
    yield put(checkEanStatusSuccess(response));
    return response;
  } catch (error) {
    console.log('saga middleware error (checkEanStatusFlow)', error);
    yield put(checkEanStatusFail(error));
  }
}

function* createPrintRevisionFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      createPrintRevisionApi,
      clientToken,
      action.payload.printObjectId,
      action.payload.revisions,
    );
    yield put(createPrintRevisionSuccess(response));
  } catch (error) {
    console.log('saga middleware error (createPrintRevisionFlow)', error);
    yield put(createPrintRevisionFail(error));
  }
}

export default function* catalogsWatcher() {
  yield all([
    takeLatest(CREATE_ALBUM_ADDITION, createAlbumAdditionFlow),
    takeLatest(CREATE_ALBUM_REVISION, createAlbumRevisionFlow),
    takeLatest(CREATE_SERIE_REVISION, createSerieRevisionFlow),
    takeLatest(CREATE_PRINT_REVISION, createPrintRevisionFlow),
    takeLatest(CHECK_EAN_STATUS, checkEanStatusFlow),
  ]);
}

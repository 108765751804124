import React from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useRef } from 'react';

import { PROJECT } from '@/project-constants';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { loadHomeArticles } from 'bubble-reducers/src/reducers/articles';
import { searchArticles } from 'bubble-reducers/src/reducers/search';
import { getArticlesForHomeCategory } from 'bubble-reducers/src/selectors';

import GenericNewsSection from '@/scenes/NArt/components/GenericNewsSection/GenericNewsSection';
import NArtPagination from '@/scenes/NArt/components/NArtPagination';

import ArticleHorizontalCardWhite from '@/components/ArticleHorizontalCardWhite/ArticleHorizontalCardWhite';
import { Icon } from '@/components/Icon/Icon';
import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import ArticleBriefCard from '../../components/ArticleBriefCard/ArticleBriefCard';
import NArtNewsletterOptin from '../../components/NArtNewsletterOptin/NArtNewsletterOptin';

import bubbleUtils from 'bubble-utils';

import '../../NArt.scss';
import './NArtSearch.scss';

export default function NArtSearch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef();
  const urlParams = new URLSearchParams(location.search);
  const queryText = urlParams.get('q');
  const page = Number(urlParams.get('page')) || 1;
  const [searchText, setSearchText] = useState(queryText || '');
  const articlesPage = useSelector(
    (state) =>
      state.search.paginatedArticlesHits[
        bubbleUtils.string.buildSortedQueryString({
          text: searchText,
          page: Number(page) - 1 || 0,
          filters: 'isPublished=1',
        })
      ],
  ) || { hits: [] };

  const sectionActualites =
    useSelector((state) =>
      getArticlesForHomeCategory(state, {
        section: PROJECT.PAPER_SECTIONS_ID_MAP['actualites'],
      }),
    ) || [];

  useEffect(() => {
    if (inputRef?.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  }, [inputRef?.current]);

  useEffect(() => {
    dispatch(loadHomeArticles());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      searchArticles(
        queryText,
        { page: Number(page) - 1 || 0, filters: 'isPublished=1' },
        false,
        true,
      ),
    );
  }, [dispatch, queryText, page]);

  const onSearch = useCallback(
    (e) => {
      setSearchText(e.target.value);
      dispatch(
        searchArticles(
          e.target.value,
          { page: Number(page) - 1 || 0, filters: 'isPublished=1' },
          false,
          true,
        ),
      );
    },
    [dispatch, page],
  );

  const onEnterPressed = useCallback(
    () => navigate('/9emeart/recherche?q=' + searchText),
    [searchText, navigate],
  );

  const clearText = useCallback(() => setSearchText(''), []);

  const title = articlesPage.query ? `Résultats pour "${articlesPage.query}"` : null;
  return (
    <>
      <div className="container-fluid p-4 p-lg-paper bb-background-light-grey">
        <div className="row pb-6">
          <div className="col-lg-9">
            {!queryText && (
              <div>
                <LabeledTextInput
                  inputRef={(ref) => (inputRef.current = ref)}
                  placeholder="Rechercher un article"
                  onEnterPressed={onEnterPressed}
                  noRightLabel
                  buttonCallback={onEnterPressed}
                  buttonLabel="Rechercher"
                  value={searchText}
                  white
                  onChange={onSearch}
                />
                {!!searchText && (
                  <div className="nart-search-clear-icon">
                    <WithClickHandler onClick={clearText}>
                      <Icon name="times" />
                    </WithClickHandler>
                  </div>
                )}
              </div>
            )}

            {!!title && (
              <div className="d-flex align-items-center justify-content-between">
                <h1 className={`${!queryText ? 'pt-4' : ''} h2 m-0 fw-bold`}>
                  {title}{' '}
                  <span className="ps-2 h5 text-secondary">({articlesPage.nbHits} articles)</span>
                </h1>
                {!queryText && <Link to={`/9emeart/recherche?q=${searchText}`}>Voir tout</Link>}
                {!!page && page !== '1' && <span>Page {page}</span>}
              </div>
            )}
            {articlesPage.hits?.map((article, index) => (
              <div key={`listing_article_${article.objectId}`} className="pt-4">
                <ArticleHorizontalCardWhite big article={article} />
              </div>
            ))}
            {!!queryText && (
              <div className="pt-paper">
                <NArtPagination
                  page={{
                    page: articlesPage.page,
                    totalPages: articlesPage.nbPages,
                  }}
                />
              </div>
            )}
          </div>
          <div className="d-none d-lg-block col-lg-3">
            <div className="d-flex align-items-center justify-content-between pb-2">
              <div className="d-flex">
                <div className="nart-bg-highlight nart-colored-macaron rounded me-3" />
                <div className="h2 fw-bold">Actualités</div>
              </div>
              <Link to="/9emeart/actualites">Voir tout</Link>
            </div>
            {sectionActualites.map((article) => (
              <ArticleBriefCard
                key={`brief_${article.objectId}`}
                articleObjectId={article.objectId}
              />
            ))}
            <div className="pt-4">
              <GenericNewsSection title="Les News" maxArticlesCount={6} />
            </div>
          </div>
        </div>
      </div>
      <NArtNewsletterOptin />
    </>
  );
}

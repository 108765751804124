import React, { useRef, useState } from 'react';

import bubbleConfig from 'bubble-config';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { getCookie } from 'bubble-utils/src/auth-utils';

import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';

import blankBannerJpg from '@/assets/placeholders/blank-banner.jpg';
import blankBannerWebp from '@/assets/placeholders/blank-banner.webp';

import HighlightBanner from './HighlightBanner';

import './HighlightBanner.css';

const HighlightBanners = () => {
  const bannersMap = useSelector((state) => state.banners.banners || {});
  const [loaded, setLoaded] = useState(false);
  const nodeRef = useRef(null);

  const banners = Object.values(bannersMap);
  const sessionCount = ((getCookie(bubbleConfig.cookies.sessionCount.name) || {}).count || 1) - 1;
  const startingBanner = sessionCount % banners.length;

  const display = () => setLoaded(true);

  const renderSlider = () => (
    <CSSTransition
      nodeRef={nodeRef}
      in={banners.length > 0 && loaded}
      unmountOnExit
      timeout={10000}
      classNames="banner-anim"
    >
      <div
        ref={nodeRef}
        id="carouselHome"
        data-pause="hover"
        className="carousel slide banner"
        data-ride="carousel"
      >
        <div className="carousel-indicators">
          {banners.map((_, index) => (
            <button
              type="button"
              key={`indicator_${index}`}
              data-bs-target="#carouselHome"
              data-bs-slide-to={index}
              className={index === startingBanner ? 'active' : ''}
            />
          ))}
        </div>
        <div className="carousel-inner">
          {banners.map((banner, index) => (
            <HighlightBanner
              className={`carousel-item ${index === startingBanner ? 'active' : ''}`}
              key={banner.objectId + index}
              imageUrl={banner.imageUrl}
              imageUrlMobile={banner.imageUrlMobile}
              alt={banner.imageAlt}
              link={banner.link}
            />
          ))}
        </div>
        <a
          className="carousel-control-prev"
          data-bs-target="#carouselHome"
          data-bs-slide="prev"
          role="button"
          data-slide="prev"
        >
          <span aria-hidden="true">
            <div className="ms-lg-n5 carousel-arrow-container rounded-circle d-flex align-items-center justify-content-center">
              <span className="me-1 carousel-control-prev-icon" />
              <span className="visually-hidden">Précédent</span>
            </div>
          </span>
        </a>
        <a
          className="carousel-control-next"
          data-bs-target="#carouselHome"
          data-bs-slide="next"
          role="button"
          data-slide="next"
        >
          <div className="me-lg-n5 carousel-arrow-container rounded-circle d-flex align-items-center justify-content-center">
            <span className="ms-1 carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Suivant</span>
          </div>
        </a>
      </div>
    </CSSTransition>
  );

  return (
    <>
      {/* Loading display */}
      <div className="d-flex justify-content-center align-items-center">
        {!loaded && (
          <WebpWrapper
            webp={blankBannerWebp}
            default={blankBannerJpg}
            className="w-100 placeholder-container"
            alt="placeholder"
          />
        )}

        <div className="d-flex flex-fill h-100 position-absolute align-items-center justify-content-center">
          <div className="spinner-border position-relative text-secondary" role="status" />
        </div>
        {renderSlider()}
      </div>

      {/* Preload image so slider doesnt flicker */}
      {!loaded && !!banners.length && (
        <img
          alt="preloader"
          className="d-none"
          src={banners[startingBanner].imageUrl}
          onLoad={display}
        />
      )}
    </>
  );
};

export default HighlightBanners;

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { addAlbumReview, editAlbumReview } from 'bubble-reducers/src/reducers/reviews';
import { editUser, loadUser, loadUserVerifiedPurchases } from 'bubble-reducers/src/reducers/user';

import { countReviewCharacters } from 'bubble-utils/src/string-utils';

import Cover from '@/components/Cover/Cover';
import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';
import RewardBadge from '@/components/RewardBadge/RewardBadge';
import Star from '@/components/StarLine/components/Star';
import VerifiedPurchaseBadge from '@/components/VerifiedPurchaseBadge/VerifiedPurchaseBadge';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import { GENERAL } from 'bubble-constants';

import './Modals.css';

const AddAlbumReviewModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const printObjectId = props.printObjectId;
  const albumObjectId = props.albumObjectId;
  const reviewObjectId = props.reviewObjectId;

  const user = useSelector((state) => state.user.user);
  const album = useSelector((state) => state.albums.albums[albumObjectId]) || {};
  const print = useSelector((state) => state.prints.prints[printObjectId]) || {};
  const loading = useSelector((state) => state.reviews.loading);
  const isVerifiedPurchase = useSelector((state) => state.user.verifiedPurchasesMap[printObjectId]);

  const userObjectId = user?.objectId;
  const initialNote = props.initialNote;
  const initialComment = props.initialComment;

  const [note, setNote] = useState(initialNote);
  const [comment, setComment] = useState(initialComment);
  const [username, setUsername] = useState(null);

  useEffect(() => {
    dispatch(loadUser(userObjectId));
    dispatch(loadUserVerifiedPurchases(userObjectId));
  }, []);

  const dismiss = () => {
    setComment(null);
    setNote(null);
    props.callback();
  };

  const sendReview = () => {
    if (!user.username && !username) {
      return alert('Il vous faut un pseudonyme pour pouvoir commenter 💬');
    }
    if (!note) {
      return alert('Vous avez oublié de mettre des étoiles ⭐ ;)');
    }

    if (username) {
      dispatch(editUser(userObjectId, { username: username }));
    }
    const review = { userObjectId, printObjectId, note: note, comment: comment };
    reviewObjectId
      ? dispatch(editAlbumReview(reviewObjectId, review, albumObjectId))
      : dispatch(addAlbumReview(review, albumObjectId));

    navigate(`/${album.permalink}/album/${albumObjectId}/avis`);
    dismiss();
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="lg"
      centered
      contentClassName="bb-background-light-grey"
    >
      <div className="modal-header header-no-separator">
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={dismiss}
        ></button>
      </div>

      <div className="modal-body mx-sm-3">
        <div className="mb-3 bb-l-text-size">Mon avis</div>
        <div className="d-flex mb-3 align-items-center">
          <Cover
            width={print?.images && !print?.images?.front ? 70 : null}
            height={90}
            alt={`Couverture de l'album ${album.title || album?.serie?.title}`}
            imageUrl={print?.images?.front?.medium}
          />
          <div className="ms-2">
            <div className="fw-bold">
              {album.title || album?.serie?.title}
              {print.ean ? ` (${print.ean})` : ''}
            </div>
            <div className="text-secondary mt-2">
              {album?.tome ? `Tome ${album?.tome} - ` : ''}
              {album?.serie?.title}
            </div>
            <div className="mt-1">
              {isVerifiedPurchase ? <VerifiedPurchaseBadge className="me-2" /> : null}
              <RewardBadge
                value={
                  isVerifiedPurchase
                    ? GENERAL.REWARD_PROGRAM.POINTS_FOR_NEW_REVIEW_WITH_VERIFIED_PURCHASE
                    : GENERAL.REWARD_PROGRAM.POINTS_FOR_NEW_REVIEW
                }
              />
            </div>
          </div>
        </div>

        <div className="d-inline-block rounded overflow-hidden bb-medium-text-size mt-3">
          {['Bof', 'Pas mal', 'Bien', 'Très bien', 'Génial'].map((value, index) => (
            <WithClickHandler
              key={index}
              className="star-and-text d-inline-block"
              onClick={() => setNote(index + 1)}
            >
              <Star size={'big'} percentFill={(note >= index + 1 ? 100 : 0) + '%'} />
              <div
                className={`pt-2 text-center ${
                  note < index + 1 || note > index + 1 ? 'test-gray-light' : ''
                }`}
              >
                {value}
              </div>
            </WithClickHandler>
          ))}
        </div>

        {!user.username && (
          <div className="mt-3">
            <LabeledTextInput
              white
              title="Pseudonyme"
              mandatory
              value={username}
              placeholder="Pseudonyme"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
        )}

        <div className="form-group mt-3">
          <label
            className="text-secondary font-style-italic d-block"
            htmlFor="exampleFormControlTextarea1"
          >
            {countReviewCharacters(comment)}/{GENERAL.REWARD_PROGRAM.MINIMUM_REVIEW_LENGTH}
            <div className="text-pink float-end">
              Gagnez{' '}
              {isVerifiedPurchase
                ? GENERAL.REWARD_PROGRAM.POINTS_FOR_NEW_REVIEW_WITH_VERIFIED_PURCHASE
                : GENERAL.REWARD_PROGRAM.POINTS_FOR_NEW_REVIEW}{' '}
              bubbles pour chaque avis &gt; {GENERAL.REWARD_PROGRAM.MINIMUM_REVIEW_LENGTH} signes
            </div>
          </label>
          <textarea
            value={comment || ''}
            onChange={(e) => setComment(e.target.value)}
            className="form-control"
            placeholder="Rédigez une critique pour cet album. Qu'avez-vous aimé ? Moins apprécié ?"
            id="exampleFormControlTextarea1"
            rows="6"
          />
        </div>
        <div>
          Charte de bonne conduite : pas de gros mot, pas d'insulte, on reste sympa et bienveillant
          🤓.
          <br />
          <Link to={'programme-de-fidelite'}>Cliquez-ici</Link> pour en savoir plus sur les critères
          de validation de votre avis.
        </div>
      </div>
      <div className="modal-footer">
        <button
          onClick={sendReview}
          disabled={loading}
          className="btn btn-success btn-lg text-white"
        >
          Publier
        </button>
      </div>
    </Modal>
  );
};

AddAlbumReviewModal.propTypes = {
  reviewObjectId: PropTypes.string,
  printObjectId: PropTypes.string,
  albumObjectId: PropTypes.string,
  initialNote: PropTypes.number,
  initialComment: PropTypes.string,
};

export default AddAlbumReviewModal;

export const LOAD_AGENDAS = 'LOAD_AGENDAS';
export const LOAD_AGENDAS_SUCCESS = 'LOAD_AGENDAS_SUCCESS';
export const LOAD_AGENDAS_FAIL = 'LOAD_AGENDAS_FAIL';

const initialState = {
  agendaAlbumObjectIds: [],
  pastAgendaLoaded: false,
  loading: false,
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_AGENDAS: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_AGENDAS_SUCCESS: {
      const agendaAlbumObjectIds = action.payload.agendas.map((agenda) => agenda.objectId);
      return {
        ...state,
        loading: false,
        agendaAlbumObjectIds: agendaAlbumObjectIds,
        pastAgendaLoaded: !!action.payload.daysPrior,
        errors: [],
      };
    }
    case LOAD_AGENDAS_FAIL: {
      return {
        ...state,
        loading: false,
        agendaAlbumObjectIds: [],
      };
    }

    default: {
      return state;
    }
  }
}

// loadAgendas ====================================================

export function loadAgendas(daysPrior = null, startDate = null) {
  return {
    type: LOAD_AGENDAS,
    payload: {
      daysPrior,
      startDate,
    },
  };
}
export function loadAgendasSuccess(agendas, daysPrior) {
  return {
    type: LOAD_AGENDAS_SUCCESS,
    payload: {
      agendas,
      daysPrior,
    },
  };
}
export function loadAgendasFail(error) {
  return {
    type: LOAD_AGENDAS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

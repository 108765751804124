import React from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadAlbum } from 'bubble-reducers/src/reducers/albums';
import { loadArticles } from 'bubble-reducers/src/reducers/articles';
import { loadReview } from 'bubble-reducers/src/reducers/reviews';
import { loadSerie } from 'bubble-reducers/src/reducers/series';

import { getSeoForReview } from '@/services/seo-utils';

import ArticleCard from '@/components/ArticleCard/ArticleCard';
import ArticleHorizontalCardWhite from '@/components/ArticleHorizontalCardWhite/ArticleHorizontalCardWhite';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import ReviewCard from '@/components/ReviewCard/ReviewCard';
import withRouter from '@/components/withRouter';

class Review extends React.Component {
  constructor(props) {
    super(props);

    const reviewObjectId = props.router?.params?.reviewObjectId;
    const serieObjectId = props.router?.params?.serieObjectId;
    const albumObjectId = props.router?.params?.albumObjectId;

    this.state = { reviewObjectId, serieObjectId, albumObjectId };
    this.loadInfos(reviewObjectId, serieObjectId, albumObjectId);
  }

  loadInfos(reviewObjectId, serieObjectId, albumObjectId) {
    const { loadReview, loadSerie, loadAlbum, loadArticles, articles } = this.props;
    if (!articles?.length) {
      loadArticles();
      loadArticles({ highlighted: 1 });
    }
    if (reviewObjectId) {
      loadReview(reviewObjectId);
    }
    if (serieObjectId) {
      loadSerie(serieObjectId);
    }
    if (albumObjectId) {
      loadAlbum(albumObjectId);
    }
  }

  render() {
    const review = this.props.reviewsMap[this.state.reviewObjectId];
    const serie = this.props.series[this.state.serieObjectId];
    const album = this.props.albums[this.state.albumObjectId];
    const articles = Object.values(this.props.articles);
    const newsArticles = this.props.highlightedArticleObjectIds.map(
      (articleObjectId) => this.props.articles[articleObjectId],
    );
    const isSerieReviewType = !!serie?.objectId;

    const relatedArticles =
      articles &&
      articles
        .filter((article) => !~this.props.highlightedArticleObjectIds.indexOf(article.objectId))
        .slice(0, 2);

    return (
      <div className="bb-background-light-grey">
        {!!review?.objectId && <BubbleHelmet seo={getSeoForReview(review, serie, album)} />}
        <div className="container">
          <div className="pt-4 d-flex align-items-center">
            {!!serie ? (
              <Link to={`/${serie?.permalink}/serie/${serie?.objectId}/avis`}>Retour aux avis</Link>
            ) : (
              <Link to={`/${album?.permalink}/album/${album?.objectId}/avis`}>Retour aux avis</Link>
            )}
          </div>

          <div className="row py-5">
            <div className="col-lg-8">
              <div className="bb-medium-large-text-size bb-text-500 d-md-flex">
                <div>L'avis de: {review?.username} </div>
              </div>
              <div className="mt-3" key={review?.objectId}>
                <ReviewCard
                  reviewType={isSerieReviewType ? 'serie' : 'album'}
                  serieObjectId={serie?.objectId}
                  review={{
                    ...review,
                    reviewer: review?.username,
                  }}
                  removeLinkToOpenReview={true}
                />
              </div>
            </div>
            <div className="col-lg-4">
              {!!relatedArticles?.length && (
                <div className="mb-4">
                  <div className="d-block d-md-flex bb-medium-large-text-size bb-text-500 ">
                    <div>
                      Suivez toute l'actualité de {album?.title || 'cette série'} sur notre blog
                    </div>
                  </div>
                  {relatedArticles.map((article) => (
                    <ArticleCard className="pt-3" key={article.objectId} article={article} />
                  ))}
                </div>
              )}
              {!!newsArticles?.length && (
                <div>
                  <div className="d-block d-md-flex bb-medium-large-text-size bb-text-500 ">
                    <div>Actualités</div>
                  </div>
                  {newsArticles.map((article) => (
                    <ArticleHorizontalCardWhite
                      className="pt-3"
                      key={article.objectId}
                      article={article}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: state.user.user,
  reviewsMap: state.reviews.reviewsMap,
  series: state.series.series,
  albums: state.albums.albums,
  userObjectId: (state.user.user || {}).objectId,
  highlightedArticleObjectIds: state.articles.highlightedArticleObjectIds,
  articles: state.articles.articlesMapByObjectId,
});

export default withRouter(
  connect(mapStateToProps, {
    loadReview,
    loadSerie,
    loadAlbum,
    loadArticles,
  })(Review),
);

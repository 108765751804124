import React from 'react';

import bubbleConfig from 'bubble-config';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  resetSearch,
  searchAlbums,
  searchArticles,
  searchAuthors,
  searchMultiIndex,
  searchSeries,
  searchStores,
} from 'bubble-reducers/src/reducers/search';

import ListSelection from '@/scenes/Listing/components/ListSelections';

import ArticleHorizontalCard from '@/components/ArticleHorizontalCard/ArticleHorizontalCard';
import BreadCrumbs from '@/components/Breadcrumbs/BreadCrumbs';
import { Icon } from '@/components/Icon/Icon';
import InfinityCard from '@/components/InfinityCard/InfinityCard';
import SearchAlbumCard from '@/components/SearchResults/components/SearchAlbumCard';
import SearchAuthorCard from '@/components/SearchResults/components/SearchAuthorCard';
import SearchSerieCard from '@/components/SearchResults/components/SearchSerieCard';
import SearchStoreCard from '@/components/SearchResults/components/SearchStoreCard';
import withRouter from '@/components/withRouter';

import './SearchSeeAll.css';

class SearchSeeAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      index: null,
    };
    const urlParams = new URLSearchParams(this.props.location.search);
    const search = decodeURIComponent(urlParams.get('q')) || '';
    const index = urlParams.get('i');
    this.state = {
      search: search === undefined ? '' : search,
      index,
    };
    this.loadInfos(true);
  }

  loadInfos = (isConstructor) => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const search = decodeURIComponent(urlParams.get('q')) || '';
    const index = urlParams.get('i');
    if (!isConstructor) {
      this.setState({
        search: search === undefined ? '' : search,
        index,
      });
    } else {
    }
    const {
      resetSearch,
      searchAlbums,
      searchSeries,
      searchAuthors,
      searchArticles,
      searchStores,
      searchMultiIndex,
    } = this.props;
    resetSearch();
    if (index === 'albums') {
      searchAlbums(search, { hitsPerPage: 20 }, true);
    } else if (index === 'series') {
      searchSeries(search, { hitsPerPage: 20 }, true);
    } else if (index === 'authors') {
      searchAuthors(search, { hitsPerPage: 20 }, true);
    } else if (index === 'articles') {
      searchArticles(search, { hitsPerPage: 20, filters: 'isPublished=1' }, true);
    } else if (index === 'stores') {
      searchStores(search, { hitsPerPage: 20 }, true);
    } else {
      searchMultiIndex(
        [
          { name: bubbleConfig.algolia.albumsIndex, params: { hitsPerPage: 20 } },
          { name: bubbleConfig.algolia.seriesIndex, params: { hitsPerPage: 20 } },
          { name: bubbleConfig.algolia.authorsIndex, params: { hitsPerPage: 20 } },
          {
            name: bubbleConfig.algolia.articlesIndex,
            params: { hitsPerPage: 20, filters: 'isPublished=1' },
          },
          {
            name: bubbleConfig.algolia.storesIndex,
            params: { hitsPerPage: 20, filters: 'isPublic=1' },
          },
        ],
        search,
        true,
      );
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const urlParams = new URLSearchParams(this.props.location.search);
    const newSearch = urlParams.get('q');
    const newIndex = urlParams.get('i');
    if (newSearch !== this.state.search || newIndex !== this.state.index) {
      this.loadInfos();
    }
  }

  getTitleForType = (type) => {
    const map = {
      serie: 'Séries',
      album: 'Albums populaires',
      author: 'Auteurs / dessinateurs',
      article: 'Articles',
      store: 'Librairies',
    };

    return map[type] || '';
  };
  renderCard = (type, hit) => {
    switch (type) {
      case 'serie':
        return (
          <SearchSerieCard key={hit.objectId} className="col-sm-6 col-md-4 mt-3" serie={hit} />
        );
      case 'album':
        return (
          <SearchAlbumCard key={hit.objectId} className="col-sm-6 col-md-4 mt-3" album={hit} />
        );
      case 'author':
        return (
          <SearchAuthorCard key={hit.objectId} className="col-sm-6 col-md-4 mt-3" author={hit} />
        );
      case 'article':
        return (
          <div key={hit.objectId} className="col-sm-6 mt-3 d-flex flex-fill">
            <ArticleHorizontalCard article={hit} />
          </div>
        );
      case 'store':
        return (
          <div key={hit.objectId} className="col-sm-6 col-md-4 mt-3">
            <SearchStoreCard store={hit} />
          </div>
        );
      default:
        return (
          <div key={hit.objectId} className="col-6">
            {hit.name}
          </div>
        );
    }
  };

  render() {
    const authorsMenu = (this.props.menuAuthorsObjectIds['bd'] || []).map(
      (objectId) => this.props.authors[objectId],
    );
    return (
      <div className="bb-background-light-grey">
        <div className="container">
          <BreadCrumbs
            currentObject={{ search: true, text: this.state.search, index: this.state.index }}
          />
          <div className="row pt-3 px-3">
            <div className="d-none d-lg-block col-2">
              <ListSelection authors={authorsMenu} />
              <div className="d-none d-md-block">
                <InfinityCard />
              </div>
            </div>
            <div className="col-12 col-lg-10">
              <h1>Résultat de recherche pour "{this.state.search}"</h1>
              <div>
                {this.props.hits.map((index) => {
                  if (index.data.length === 0) return null;

                  return (
                    <div key={'search_all_' + index.type}>
                      <div className="mt-5 bb-l-text-size d-flex justify-content-between align-items-center">
                        {this.getTitleForType(index.type)}
                        {index.type === 'album' && (
                          <div className="div h6 m-0">
                            <Link to="/ajouter-un-album">
                              <Icon name="plus" /> Vous n'avez pas trouvé votre album ?
                            </Link>
                          </div>
                        )}
                      </div>
                      <div className="row">
                        {index.data.map((data) => this.renderCard(index.type, data))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: 150 }} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hits: [
    state.search[state.search.sorting[0] + 'Persistent'] || null,
    state.search[state.search.sorting[1] + 'Persistent'] || null,
    state.search[state.search.sorting[2] + 'Persistent'] || null,
    state.search[state.search.sorting[3] + 'Persistent'] || null,
    state.search[state.search.sorting[4] + 'Persistent'] || null,
  ].filter((row) => row),
  menuAuthorsObjectIds: state.authors.menuAuthorsObjectIds,
  authors: state.authors.authors,
});

export default withRouter(
  connect(mapStateToProps, {
    resetSearch,
    searchAuthors,
    searchSeries,
    searchStores,
    searchAlbums,
    searchArticles,
    searchMultiIndex,
  })(SearchSeeAll),
);

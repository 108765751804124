import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  checkStocksForEansApi,
  createStorePartnershipRequestApi,
  loadStoreApi,
  loadStoreBestSellersApi,
  loadStoresApi,
} from '../services/api';

import { SEARCH_STORES_SUCCESS } from '../reducers/search';
import {
  CHECK_STOCKS_FOR_EANS,
  CHECK_STOCKS_FOR_EANS_SUCCESS,
  CREATE_STORE_PARTNERSHIP_REQUEST,
  LOAD_STORE,
  LOAD_STORES,
  LOAD_STORE_BEST_SELLERS,
  SET_AVAILABILITY_SEARCH_FLAG,
  SET_FAVORITE_SEARCH_FLAG,
  loadStoreBestSellersSuccess,
} from '../reducers/stores';
import {
  checkStocksForEansFail,
  checkStocksForEansSuccess,
  createStorePartnershipRequestError,
  createStorePartnershipRequestSuccess,
  loadStoreFail,
  loadStoreSuccess,
  loadStoresFail,
  loadStoresSuccess,
  setReservationsStoresToShow,
} from '../reducers/stores';
import {
  getClientToken,
  getHitsStores,
  getStoresReducer,
  getUserFavoriteStoresIds,
} from '../selectors';
import { storeAlbumsFlow } from './albums';

function* loadStoreFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadStoreApi, clientToken, action.payload.storeObjectId);
    yield put(loadStoreSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadStoreFlow)', error);
    yield put(loadStoreFail(error));
  }
}

function* loadStoresFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadStoresApi, clientToken);
    yield put(loadStoresSuccess(response));
  } catch (error) {
    yield put(loadStoresFail(error));
  }
}

function* createStorePartnershipRequestFlow(action) {
  try {
    const storeObjectId = action.payload.storeObjectId;
    const userObjectId = action.payload.userObjectId;
    const clientToken = yield select(getClientToken);
    const storePartnershipRequestCreated = yield call(
      createStorePartnershipRequestApi,
      clientToken,
      storeObjectId,
      userObjectId,
    );
    yield put(createStorePartnershipRequestSuccess(storePartnershipRequestCreated));
  } catch (error) {
    console.log('saga middleware error', error);
    yield put(createStorePartnershipRequestError(error));
  }
}

function* checkStocksForEansFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      checkStocksForEansApi,
      clientToken,
      action.payload.eans,
      action.payload.storeObjectId,
    );
    yield put(checkStocksForEansSuccess(response));
  } catch (error) {
    console.log('saga middleware error (checkStocksForEansFlow)', error);
    yield put(checkStocksForEansFail(error));
  }
}

function* loadStoreBestSellersFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const albums = yield call(loadStoreBestSellersApi, clientToken, action.payload.storeObjectId);

    yield call(storeAlbumsFlow, {
      payload: { albums: albums, source: 'loadStoreBestSellersFlow' },
    });

    yield put(
      loadStoreBestSellersSuccess(
        action.payload.storeObjectId,
        albums.map((album) => ({ objectId: album.objectId, score: album.score })),
      ),
    );
  } catch (error) {
    console.log('saga middleware error (checkStocksForEansFlow)', error);
    yield put(checkStocksForEansFail(error));
  }
}

function* processStoresToShowFlow(action) {
  //TODO: add a top level boolean that dictates if we should execute this function or not
  const favoriteStoresIds = yield select(getUserFavoriteStoresIds);
  const storesReducer = yield select(getStoresReducer);

  const allStoresAsMap = storesReducer.stores;
  const allStores = Object.values(storesReducer.stores);
  const hitsStores = yield select(getHitsStores);
  const favoriteStores = (favoriteStoresIds || []).map((id) => allStoresAsMap[id]).filter(Boolean);
  const isFavoriteEnabled = storesReducer.favoriteSearchFlag;
  const isAvailableOnlyEnabled = storesReducer.availabilitySearchFlag;
  const stocks = storesReducer.itemsAvailabilitiesPerStores;

  try {
    // build list of stores to display
    let selectedStoresToDisplay = [];
    // case 1: favorite filter is active
    if (isFavoriteEnabled) {
      selectedStoresToDisplay = favoriteStores;
      if (hitsStores.length) {
        selectedStoresToDisplay = selectedStoresToDisplay.filter(
          (storeToDisplay) => !!hitsStores.find((hit) => hit.objectId === storeToDisplay.objectId),
        );
      }
    }
    // case 2: availabilites filter is active
    //    => get all stores, and filter based on availabilities after ( => case 2 following)
    if (isAvailableOnlyEnabled) {
      selectedStoresToDisplay = allStores;
      if (hitsStores.length) {
        selectedStoresToDisplay = selectedStoresToDisplay.filter(
          (storeToDisplay) => !!hitsStores.find((hit) => hit.objectId === storeToDisplay.objectId),
        );
      }
    }
    // case 3: no filter is active but with an ongoing search
    if (hitsStores.length && !isFavoriteEnabled && !isAvailableOnlyEnabled) {
      selectedStoresToDisplay = hitsStores.slice();
    }

    // add isFavorite info
    if (selectedStoresToDisplay.length) {
      selectedStoresToDisplay.map((selectedStore) => {
        return Object.assign({}, selectedStore, {
          isFavorite: !!favoriteStores.find(
            (favoriteStore) => favoriteStore.objectId === selectedStore.objectId,
          ),
        });
      });
    }

    let storesWithStocks = [];
    if (selectedStoresToDisplay.length) {
      storesWithStocks = selectedStoresToDisplay.map((selectedStore) => {
        const itemsAvailabilitiesForStore =
          stocks.find(
            (storeItemsAvailabilities) =>
              storeItemsAvailabilities.objectId === selectedStore.objectId,
          ) || {};
        return Object.assign({}, selectedStore, {
          isClosed: itemsAvailabilitiesForStore.isClosed,
          stocks: {
            availableItems: itemsAvailabilitiesForStore.availableItems || [],
            orderableItems: itemsAvailabilitiesForStore.orderableItems || [],
          },
        });
      });
    }

    // case 2 following, remove stores with no availabilities
    if (isAvailableOnlyEnabled) {
      storesWithStocks = storesWithStocks.filter(
        (store) => store.stocks.availableItems.length || store.stocks.orderableItems.length,
      );
    }

    // sort stores with the biggest available items first
    storesWithStocks = storesWithStocks.sort((a, b) => {
      const aAvailableItems = a.stocks.availableItems.length;
      const aOrderableItems = a.stocks.orderableItems.length;
      const bAvailableItems = b.stocks.availableItems.length;
      const bOrderableItems = b.stocks.orderableItems.length;

      const n = bAvailableItems - aAvailableItems;
      if (n !== 0) {
        return n;
      }
      return bAvailableItems + bOrderableItems - (aAvailableItems + aOrderableItems);
    });

    yield put(setReservationsStoresToShow(storesWithStocks));
  } catch (error) {
    console.log('saga middleware error (processStoresToShowFlow)', error);
  }
}

export default function* storesWatcher() {
  yield all([
    takeLatest(LOAD_STORE, loadStoreFlow),
    takeLatest(CHECK_STOCKS_FOR_EANS, checkStocksForEansFlow),
    takeLatest(LOAD_STORES, loadStoresFlow),
    takeLatest(CREATE_STORE_PARTNERSHIP_REQUEST, createStorePartnershipRequestFlow),
    takeLatest(CHECK_STOCKS_FOR_EANS_SUCCESS, processStoresToShowFlow),
    takeLatest(SET_AVAILABILITY_SEARCH_FLAG, processStoresToShowFlow),
    takeLatest(SET_FAVORITE_SEARCH_FLAG, processStoresToShowFlow),
    takeLatest(SEARCH_STORES_SUCCESS, processStoresToShowFlow),
    takeLatest(LOAD_STORE_BEST_SELLERS, loadStoreBestSellersFlow),
  ]);
}

import React, { useRef, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { updateLibrary } from 'bubble-reducers/src/reducers/profiles';
import {
  getUserOrGuest,
  isPrintDeluxe,
  isPrintDigital,
  isPrintEo,
  isPrintLimited,
  isPrintSigned,
  isPrintWithExLibris,
} from 'bubble-reducers/src/selectors';

import {
  useSetDeluxeFlagOnPrints,
  useSetDigitalFlagOnPrints,
  useSetEoFlagOnPrints,
  useSetExLibrisFlagOnPrints,
  useSetLimitedFlagOnPrints,
  useSetSignedFlagOnPrints,
} from '@/services/hooks/useLibraryUtils';
import { useIsUserInfinity, useIsUserlogged } from '@/services/hooks/useUserUtils';
import imageUtils from '@/services/image-utils';

import InfinityFunctionalityModal from '@/components/Modals/InfinityFunctionalityModal';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import InfoModalRow from '../components/InfoModalRow';

import '../Modals.css';
import './CollectionInfoModal.scss';

const CollectionInfoModal = (props) => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();
  const isUserInfinity = useIsUserInfinity();
  const setEoFlagOnPrints = useSetEoFlagOnPrints();
  const setSignedFlagOnPrints = useSetSignedFlagOnPrints();
  const setDeluxeFlagOnPrints = useSetDeluxeFlagOnPrints();
  const setLimitedFlagOnPrints = useSetLimitedFlagOnPrints();
  const setDigitalFlagOnPrints = useSetDigitalFlagOnPrints();
  const setExLibrisFlagOnPrints = useSetExLibrisFlagOnPrints();

  const user = useSelector((state) => getUserOrGuest(state));
  const printObjectId = props.printObjectId;
  const albumObjectId = props.albumObjectId;
  const isEo = useSelector((state) => isPrintEo(state, printObjectId));
  const isDeluxe = useSelector((state) => isPrintDeluxe(state, printObjectId));
  const isLimited = useSelector((state) => isPrintLimited(state, printObjectId));
  const isWithExLibris = useSelector((state) => isPrintWithExLibris(state, printObjectId));
  const isSigned = useSelector((state) => isPrintSigned(state, printObjectId));
  const isDigital = useSelector((state) => isPrintDigital(state, printObjectId));
  const memo = useSelector((state) => state.profiles.memoPrintsIdsMap[printObjectId]);
  const initialNumbering =
    useSelector((state) => state.profiles.numberingPrintsIdsMap[printObjectId]) || '';

  const [memoText, setMemoText] = useState(memo || '');
  const [numbering, setNumbering] = useState(initialNumbering);
  const [showInfinityFunctionalityModal, setShowInfinityFunctionalityModal] = useState(false);

  const infnityModalFunction = useRef(null);

  const dismiss = () => {
    setMemoText('');
    setNumbering('');
    props.callback();
  };

  const onClickEo = () => {
    if (isUserlogged(user)) {
      if (isUserInfinity(user)) {
        setEoFlagOnPrints(
          user,
          [
            {
              objectId: printObjectId,
              album: { objectId: albumObjectId },
              isOriginalEdition: !isEo,
            },
          ],
          !isEo,
        );
      } else {
        infnityModalFunction.current = InfinityFunctionalityModal.types.OE;
        setShowInfinityFunctionalityModal(true);
      }
    }
  };

  const onClickDeluxe = () => {
    if (isUserlogged(user)) {
      if (isUserInfinity(user)) {
        setDeluxeFlagOnPrints(
          user,
          [{ objectId: printObjectId, album: { objectId: albumObjectId }, isDeluxe: !isDeluxe }],
          !isDeluxe,
        );
      } else {
        infnityModalFunction.current = null;
        setShowInfinityFunctionalityModal(true);
      }
    }
  };

  const onClickLimited = () => {
    if (isUserlogged(user)) {
      if (isUserInfinity(user)) {
        setLimitedFlagOnPrints(
          user,
          [{ objectId: printObjectId, album: { objectId: albumObjectId }, isLimited: !isLimited }],
          !isLimited,
        );
      } else {
        infnityModalFunction.current = null;
        setShowInfinityFunctionalityModal(true);
      }
    }
  };

  const onClickExLibris = () => {
    if (isUserlogged(user)) {
      if (isUserInfinity(user)) {
        setExLibrisFlagOnPrints(
          user,
          [
            {
              objectId: printObjectId,
              album: { objectId: albumObjectId },
              isWithExLibris: !isWithExLibris,
            },
          ],
          !isWithExLibris,
        );
      } else {
        infnityModalFunction.current = null;
        setShowInfinityFunctionalityModal(true);
      }
    }
  };

  const onClickSigned = () => {
    if (isUserlogged(user)) {
      if (isUserInfinity(user)) {
        setSignedFlagOnPrints(
          user,
          [
            {
              objectId: printObjectId,
              album: { objectId: albumObjectId },
              isOriginalEdition: !isSigned,
            },
          ],
          !isSigned,
        );
      } else {
        infnityModalFunction.current = InfinityFunctionalityModal.types.SIGNED;
        setShowInfinityFunctionalityModal(true);
      }
    }
  };
  const onClickDigital = () => {
    if (isUserlogged(user)) {
      if (isUserInfinity(user)) {
        setDigitalFlagOnPrints(
          user,
          [{ objectId: printObjectId, album: { objectId: albumObjectId }, isDigital: !isDigital }],
          !isDigital,
        );
      } else {
        infnityModalFunction.current = null;
        setShowInfinityFunctionalityModal(true);
      }
    }
  };

  const onChangeMemo = (e) => setMemoText(e.target.value);

  const onChangeNumbering = (e) =>
    setNumbering(e.target.value ? e.target.value.replace(',', '.') : null);

  const onSave = () => {
    if (isUserlogged()) {
      if (isUserInfinity(user)) {
        const toUpdate = {};
        if (numbering || null) toUpdate.numbering = numbering || null;
        if ((memoText || null) !== (memo || null)) toUpdate.memo = memoText || null;

        if (Object.keys(toUpdate).length) {
          dispatch(updateLibrary(user?.objectId, [{ printObjectId, albumObjectId, ...toUpdate }]));
        }
        dismiss();
      } else {
        infnityModalFunction.current = null;
        setShowInfinityFunctionalityModal(true);
      }
    } else {
      dismiss();
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="md"
      centered
      contentClassName="bb-background-light-grey"
    >
      {showInfinityFunctionalityModal && (
        <InfinityFunctionalityModal
          show={showInfinityFunctionalityModal}
          infinityFunctionalityType={infnityModalFunction.current}
          callback={() => {
            dismiss();
            setShowInfinityFunctionalityModal(false);
          }}
        />
      )}
      <div className="modal-header header-no-separator">
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={dismiss}
        ></button>
      </div>
      <div className="modal-body mx-sm-3">
        <div className="pb-5">
          <div className="bb-l-text-size fw-bold text-center">
            Modifier mes informations de collection
          </div>
          <div className="text-secondary text-center pt-3">
            <Link to="/mobile-app">
              Pour ajouter vos propres photos d'ex-libris et dédicaces :<br />
              <strong>téléchargez l'application 📱</strong>
            </Link>
          </div>
          <div className="pt-4">
            <WithClickHandler onClick={onClickEo}>
              <InfoModalRow
                img="icon_original_edition.svg"
                text="Edition Originale (EO)"
                value={!!isEo}
              />
            </WithClickHandler>
            <hr className="m-0" />
            <WithClickHandler onClick={onClickDeluxe}>
              <InfoModalRow
                img="icon_original_edition.svg"
                text="Tirage de tête"
                value={!!isDeluxe}
              />
            </WithClickHandler>
            <hr className="m-0" />
            <InfoModalRow
              img="icon_original_edition.svg"
              text="Numérotation"
              rightComponent={
                <input
                  type="number"
                  className="info-modal-text-end form-control-sm border-0"
                  value={numbering || ''}
                  onChange={onChangeNumbering}
                />
              }
            />
            <hr className="m-0" />
            <WithClickHandler onClick={onClickLimited}>
              <InfoModalRow
                img="icon_original_edition.svg"
                text="Série limitée"
                value={!!isLimited}
              />
            </WithClickHandler>
            <hr className="m-0" />
            <WithClickHandler onClick={onClickExLibris}>
              <InfoModalRow img="icon_exlibris.svg" text="Ex-Libris" value={!!isWithExLibris} />
            </WithClickHandler>
            <hr className="m-0" />
            <WithClickHandler onClick={onClickSigned}>
              <InfoModalRow img="icon_dedicace_grey.svg" text="Dédicacé" value={!!isSigned} />
            </WithClickHandler>
            <hr className="m-0" />
            <WithClickHandler onClick={onClickDigital}>
              <InfoModalRow img="icon_digital.svg" text="Version numérique" value={!!isDigital} />
            </WithClickHandler>
            <hr className="m-0" />
            <div className="bg-white ps-2 d-flex align-items-start">
              <div className="info-modal-row-icon pt-2">
                <img
                  className="info-modal-row-icon-image"
                  alt="edition originale"
                  src={imageUtils.getAssetImage('icon_album_memo_grey.svg')}
                />
              </div>
              <textarea
                placeholder="Laisser une note personnelle"
                className="form form-control bg-white border-0 ps-1"
                onChange={onChangeMemo}
                value={memoText}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button onClick={onSave} className="btn btn-success btn-lg text-white">
          Valider
        </button>
      </div>
      <div className="d-md-none mobile-spacer" />
    </Modal>
  );
};

export default CollectionInfoModal;

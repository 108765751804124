import PropTypes from 'prop-types';
import React from 'react';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  addSkusToCart,
  addToCart,
  removeFromCart,
  removeSkusFromCart,
} from 'bubble-reducers/src/reducers/cart';

import Cover from '@/components/Cover/Cover';
import Toaster from '@/components/DUPLICATE/Toaster/Toaster';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import PrintContent from './components/PrintContent';
import ProductContent from './components/ProductContent';

import bubbleUtils from 'bubble-utils';

import './ItemCard.css';

const maxQuantity = 10;

const ItemCard = (props) => {
  const dispatch = useDispatch();

  const item = props.item || {
    extra: { images: {}, serie: {}, album: {}, tome: 0 },
    sellingInfo: {},
    quantity: 1,
  };
  const quantity = item.quantity;
  const maximumNumberOfItemsOrderableAtOnce =
    item.maximumNumberOfItemsOrderableAtOnce ||
    (props.isQuantitySelectable &&
    ((item.itemType === 'print' && !!item.printObjectId) || item.itemType === 'gift-card')
      ? null
      : 1);
  const extra = item.extra || {};
  const serie = (extra || {}).serie || {};
  const album = (extra || {}).album || {};
  const sellingInfo = item.sellingInfo || {};

  const handleQuantityChange = (event) => {
    event.preventDefault();
    let value = event.target.value;
    if (typeof parseInt(value) === 'number' && value !== '') {
      const newQuantity = Math.min(Math.max(parseInt(value), 1), maxQuantity);
      if (item.sku) {
        dispatch(addSkusToCart([{ sku: item.sku, quantity: newQuantity }]));
      } else {
        item.quantity = newQuantity;
        item.objectId = item.itemObjectId;
        item.album = { objectId: item.extra.album.objectId };
        dispatch(addToCart(item));
      }
    }
  };

  const onPressQuantityChange = (value) => {
    if (quantity !== value) {
      if (item.sku) {
        dispatch(addSkusToCart([{ sku: item.sku, quantity: value }]));
      } else {
        item.quantity = value;
        item.objectId = item.itemObjectId;
        item.album = { objectId: item.extra.album.objectId };
        dispatch(addToCart(item));
      }
    }
  };

  const onPressRemoveFromCart = (e) => {
    Toaster.addToast(
      'Mon panier',
      `${album.title ? `"${album.title}"` : 'Un article'} retiré du panier`,
    );
    item.sku
      ? dispatch(removeSkusFromCart([{ sku: item.sku }]))
      : dispatch(
          removeFromCart({
            objectId: item.itemObjectId,
            sellingInfo: item.sellingInfo,
            album: item?.extra?.album,
          }),
        );
  };

  return (
    <div className="bg-white p-3 mt-3 rounded">
      <div className="row">
        <div className="col-md-8 col-12">
          <div className="d-flex align-items-center">
            <div className="me-3">
              {!!album.objectId ? (
                <Link to={`/${album.permalink}/album/${album.objectId}`}>
                  <Cover
                    rounded
                    shadow
                    width={item.images && !item.images.front ? 85 : null}
                    height={110}
                    imageUrl={((item.images || {}).front || {}).smallFramed || item.images}
                  />
                </Link>
              ) : (
                <Cover
                  rounded
                  shadow
                  height={110}
                  width={item.images && !item.images.front ? 85 : null}
                  imageUrl={((item.images || {}).front || {}).smallFramed || item.images}
                />
              )}
            </div>

            {
              /*item.itemType !== 'print' */ item.sku ? (
                <ProductContent item={item} noDate={'noDate' in props} sellingInfo={sellingInfo} />
              ) : (
                <PrintContent
                  serie={serie}
                  album={album}
                  extra={extra}
                  item={item}
                  noDate={'noDate' in props}
                  noWishlist={'noWishlist' in props}
                  sellingInfo={sellingInfo}
                  user={props.user}
                />
              )
            }
          </div>
        </div>

        <div className="col-md-2 col-6 mt-3 mt-md-0 d-flex flex-column align-items-center justify-content-center">
          {maximumNumberOfItemsOrderableAtOnce === null ||
          maximumNumberOfItemsOrderableAtOnce >= 2 ? (
            <div>
              <div className="input-group input-group-sm">
                <button
                  className="input-group-text"
                  onClick={() => onPressQuantityChange(Math.max(quantity - 1, 1))}
                >
                  -
                </button>
                <input
                  type="text"
                  value={quantity}
                  onChange={handleQuantityChange}
                  className="form-control quantity-input"
                  aria-label="Dollar amount (with dot and two decimal places)"
                />
                <button
                  className="input-group-text"
                  onClick={() => onPressQuantityChange(Math.min(quantity + 1, maxQuantity))}
                >
                  +
                </button>
              </div>
            </div>
          ) : (
            <div className="bb-medium-large-text-size">Qté: {quantity}</div>
          )}

          {!('noClose' in props) && !item.forceInCart && (
            <div className="close-button-item-card bb-s-text-size text-secondary mt-2">
              <WithClickHandler onClick={onPressRemoveFromCart}>Supprimer</WithClickHandler>
            </div>
          )}
        </div>

        <div className="col-md-2 col-6 mt-3 mt-md-0 d-flex align-items-center justify-content-end pe-3">
          <div className="bb-medium-text-size">
            <h5>{bubbleUtils.currency.formatCurrency(sellingInfo.price || 0)}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

ItemCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    images: PropTypes.any,
    extra: PropTypes.object,
    sellingInfo: PropTypes.object,
  }),
  noDate: PropTypes.bool,
  noClose: PropTypes.bool,
  noWishlist: PropTypes.bool,
  isQuantitySelectable: PropTypes.bool,
};

export default ItemCard;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loadProductsApi } from '../services/api';

import { getClientToken } from '../selectors';

const LOAD_PRODUCTS = 'products/loadProducts';

const initialState = {
  products: [],
  loading: false,
  errors: [],
};

export const loadProducts = createAsyncThunk(LOAD_PRODUCTS, async (arg, { getState }) => {
  const clientToken = getClientToken(getState());
  const products = await loadProductsApi(clientToken);
  return { products };
});

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadProducts.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadProducts.fulfilled, (state, action) => {
        const products = Object.assign({}, state.products);
        action.payload.products.map((product) => (products[product.objectId] = product));
        state.products = products;
        state.loading = false;
      })
      .addCase(loadProducts.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default productsSlice.reducer;

import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { loadTopApi, loadTopsApi } from '../services/api';

import { LOAD_TOP, LOAD_TOPS } from '../reducers/tops';
import { loadTopFail, loadTopSuccess, loadTopsFail, loadTopsSuccess } from '../reducers/tops';
import { storeAlbumsFlow } from '../sagas/albums';
import { storeSeriesFlow } from '../sagas/series';
import { getClientToken } from '../selectors';

function* loadTopsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadTopsApi, clientToken, action.payload.withData);
    yield put(loadTopsSuccess(response, action.payload.withData));
  } catch (error) {
    console.log('saga middleware error (loadTopsFlow)', error);
    yield put(loadTopsFail(error));
  }
}

function* loadTopFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadTopApi, clientToken, action.payload.topName);
    const type = response[0]?.dataType;
    if (type === 'albums') {
      yield call(storeAlbumsFlow, {
        payload: {
          albums: response.map((element) => Object.assign({}, element.album)),
          source: 'loadTopByNameFlow',
        },
      });
    } else if (type === 'series') {
      yield call(storeSeriesFlow, {
        payload: {
          series: response.map((element) => Object.assign({}, element.serie)),
          source: 'loadTopByNameFlow',
        },
      });
    }
    yield put(loadTopSuccess(response, action.payload.topName));
  } catch (error) {
    console.log('saga middleware error (loadTopFlow)', error);
    yield put(loadTopFail(error));
  }
}

export default function* topsWatcher() {
  yield all([takeLatest(LOAD_TOPS, loadTopsFlow), takeLatest(LOAD_TOP, loadTopFlow)]);
}

import React, { useCallback, useEffect, useState } from 'react';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';

import {
  buyIap,
  buyIapWithNewCard,
  resetBuyStatus,
  resetIapReducer,
  updateIapPaymentIntent,
} from 'bubble-reducers/src/reducers/in-app-purchases';
import { updatePaymentMethod } from 'bubble-reducers/src/reducers/payment-methods';

import Card from '@/components/Card/Card';
import GlobalOverlay from '@/components/GlobalOverlay/GlobalOverlay';
import { Icon } from '@/components/Icon/Icon';
import Tooltip from '@/components/Tooltip/Tooltip';

import { GENERAL } from 'bubble-constants';
import bubbleUtils from 'bubble-utils';

export default function InfinityBuyZoneContent({ iap, callback }) {
  const dispatch = useDispatch();
  const [isNewCard, _setIsNewCard] = useState(false);
  const user = useSelector((state) => state.user.user);
  const userInfinityExpirationDate = useSelector(
    (state) => state.user.user?.infinityExpirationDate,
  );
  const isPaymentMethodLoading = useSelector((state) => state.paymentMethods.loading);
  const isIapLoading = useSelector((state) => state.inAppPurchases.loading);
  const isIapBought = useSelector((state) => state.inAppPurchases.iapBought);
  const paymentIntent = useSelector((state) => state.inAppPurchases.intent);
  const iapError = useSelector((state) => state.inAppPurchases.errors.length !== 0);
  const paymentMethods = useSelector((state) => state.paymentMethods.paymentMethods);
  const [forceLoading, _setForceLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const defaultPaymentMethod = (paymentMethods || []).find(
    (paymentmethod) => paymentmethod.isDefault,
  );
  const otherPaymentMethods = paymentMethods.filter((source) => !source.isDefault);

  const handleIapError = useCallback(() => {
    alert(
      "Une erreur est survenue lors de l'achat, réessayez plus tard ou contactez-nous sur le chat",
    );
    _setForceLoading(false);
    _setIsNewCard(false);
    dispatch(resetIapReducer());
  }, [dispatch]);

  useEffect(() => {
    if (iapError) {
      handleIapError();
    }
  }, [dispatch, iapError, handleIapError]);

  useEffect(() => {
    // updates regarding SCA/3Dsecure happen here
    if (paymentIntent && paymentIntent.paymentIntentStatus === 'requires_action') {
      _setForceLoading(true);
      stripe.handleCardAction(paymentIntent.paymentIntentClientSecret).then((newPaymentIntent) => {
        if (newPaymentIntent?.error) {
          //TODO:iap event 3dsecure error
          return handleIapError();
        }
        dispatch(
          updateIapPaymentIntent(user.objectId, iap.productId, newPaymentIntent.paymentIntent.id),
        );
        _setForceLoading(false);
      });
    }
  }, [paymentIntent, stripe, handleIapError, dispatch, iap.productId, user.objectId]);

  useEffect(() => {
    if (isIapBought) {
      dispatch(resetBuyStatus());
      alert(
        `Vous êtes maintenant membre Bubble Infinity jusqu'au ${bubbleUtils.date.formatDateWithFormat(
          userInfinityExpirationDate,
        )} 🎉`,
      );
      if (callback) {
        callback(iap.productId);
      }
    }
  }, [dispatch, callback, userInfinityExpirationDate, isIapBought, iap.productId]);

  const onClickContinue = async () => {
    _setForceLoading(true);
    try {
      // retrieve card element
      const element = elements.getElement(CardNumberElement);
      // create payment_method from card element
      // if no card is present, this will throw and continue in the catch
      const paymentMethod = await stripe.createPaymentMethod({ type: 'card', card: element });

      if (paymentMethod?.error) {
        // card input error, such as missing field or obviously wrong numbers
        _setForceLoading(false);
        //TODO:iap this is an english message
        return alert(paymentMethod.error.message);
      }

      if (paymentMethod?.paymentMethod?.id) {
        dispatch(buyIapWithNewCard(user.objectId, iap.productId, paymentMethod.paymentMethod.id));
        _setForceLoading(false);
      }
    } catch (e) {
      // no new card, just call buyIap
      if (!defaultPaymentMethod) {
        _setForceLoading(false);
        return alert("Vous n'avez pas entré de moyen de paiement");
      }
      _setForceLoading(false);
      dispatch(buyIap(user.objectId, iap.productId));
    }
  };

  const setDefaultCard = (paymentMethod) => {
    dispatch(
      updatePaymentMethod(user.objectId, {
        action: GENERAL.PAYMENT_METHOD_ACTIONS.DEFAULT,
        paymentMethodId: paymentMethod.paymentMethodId,
      }),
    );
  };

  const formStyle = {
    base: {
      color: '#ffffff',
      fontFamily: 'Avenir, "Segoe UI", "Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '15px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  };

  return (
    <div className="border rounded p-4">
      <GlobalOverlay showGlobalOverlay={!!(isPaymentMethodLoading || isIapLoading || forceLoading)}>
        <div className="d-flex flex-fill h-75 justify-content-center align-items-center">
          <div className="spinner-border" role="status" />
        </div>
      </GlobalOverlay>
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-6">
            {(!!defaultPaymentMethod || (!isNewCard && defaultPaymentMethod)) && (
              <>
                <div className="bb-l-text-size">Vos cartes :</div>
                {defaultPaymentMethod && (
                  <div className="row">
                    <div className="col-6">
                      <Card card={defaultPaymentMethod} />
                    </div>
                  </div>
                )}
                {!defaultPaymentMethod && (
                  <div>Vous n'avez pas de moyen de paiement par défaut</div>
                )}
                <div className="h-separator my-3" />
                {!!otherPaymentMethods.length && (
                  <div className="row">
                    {otherPaymentMethods.map((paymentMethod) => (
                      <div className="col-6 mb-1" key={paymentMethod.paymentMethodId}>
                        <Card
                          card={paymentMethod}
                          button={
                            <button
                              onClick={() => setDefaultCard(paymentMethod)}
                              className="btn btn-link m-0 p-0 text-white"
                            >
                              Utiliser
                            </button>
                          }
                        />
                      </div>
                    ))}
                  </div>
                )}

                {!otherPaymentMethods.length && (
                  <div>Vous n'avez pas d'autre moyen de paiement</div>
                )}
                <button onClick={() => _setIsNewCard(true)} className="btn btn-link m-0 p-0">
                  Ajouter un moyen de paiement
                </button>
              </>
            )}
            {(!defaultPaymentMethod || isNewCard) && (
              <>
                <div className="bb-l-text-size">Utiliser une autre carte :</div>
                <div>
                  <div className="form-group" style={{ maxWidth: 270 }}>
                    <div className="d-flex text-secondary">
                      <label className="mb-0" htmlFor="bob">
                        Numéro de carte: <span className="text-danger">*</span>
                      </label>
                      <div className="flex-fill" />
                    </div>
                    <CardNumberElement
                      className="border p-2 rounded"
                      options={{ style: formStyle }}
                    />
                  </div>
                  <div className="d-flex">
                    <div className="form-group">
                      <div className="d-flex text-secondary">
                        <label className="mb-0" htmlFor="bob">
                          Date d'expiration: <span className="text-danger">*</span>
                        </label>
                        <div className="flex-fill" />
                      </div>
                      <CardExpiryElement
                        className="border p-2 rounded"
                        options={{ style: formStyle }}
                      />
                    </div>
                    <div className="form-group ms-4">
                      <div className="d-flex text-secondary">
                        <label className="mb-0" htmlFor="bob">
                          Cryptogramme: <span className="text-danger">*</span>{' '}
                          <Tooltip tip="Il s'agit des 3 chiffres imprimés au dos de votre carte">
                            ( <Icon className="bb-s-text-size" name="info" /> )
                          </Tooltip>
                        </label>
                        <div className="flex-fill" />
                      </div>
                      <CardCvcElement
                        className="border p-2 rounded"
                        options={{ style: formStyle }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="col-md-6">
            <div className="text-center fw-bold text-infinity bb-xl-text-size">
              {iap.description}
            </div>
            <p className="text-secondary mt-3">
              Pas de renouvellement automatique, vous êtes libre de continuer l'aventure avec nous
              une fois votre abonnement expiré.
              <br />
              Si vous choisissez de revenir à l'offre Bubble Starter, AUCUNE donnée ne sera
              supprimée
            </p>
            <button onClick={onClickContinue} className="mt-4 btn btn-lg btn-warning">
              Valider et payer {bubbleUtils.currency.formatCurrency(iap.price)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useCallback, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';

import BubbleCheckbox from '@/components/BubbleCheckbox/BubbleCheckbox';
import PriceRangeSlider from '@/components/PriceRangeSlider/PriceRangeSlider';

import AgendaDates from './components/AgendaDates';
import AgendaRow from './components/AgendaRow';
import AgendaSectionsFilter from './components/AgendaSectionsFilter';

import './AgendaSections.css';

const listStyle = { outline: 'none' };

const AgendaSections = (props) => {
  const user = useSelector((state) => state.user.user);
  const optedIn = useSelector((state) => state.websiteOther.reOptinNewsletter);
  const [scrollTo, setScrollTo] = useState(undefined);
  const [filters, setFilters] = useState({});

  let itemsPerRow = 1;
  const sectionsArray = useMemo(
    () => Object.values(props.sections || {}).slice(),
    [props.sections],
  );

  const onScrollEditHeader = useCallback(
    (e) => {
      if (scrollTo !== undefined) {
        setScrollTo(undefined);
      }

      // direct dom manipulation is bad and you should feel bad
      const inDomAgendaRows = Array.from(document.getElementsByClassName('Row'));
      const headerDates = Array.from(document.querySelectorAll('*[id^="header_agenda_"]'));
      window.requestAnimationFrame(() => {
        headerDates.forEach((header) => {
          const classList = header.classList;
          if (classList.contains('text-black')) {
            classList.remove('text-black');
            classList.remove('bb-medium-large-text-size');
            classList.remove('fw-bold');
          }
        });
        for (let i = 2; i < inDomAgendaRows.length; i++) {
          const inDomAgendaRow = inDomAgendaRows[i];
          const date = inDomAgendaRow.getAttribute('data-publicationdate').toString();
          const headerDate = document.getElementById(`header_agenda_${date}`);
          if (inDomAgendaRow.getAttribute('data-isvisible') === 'true') {
            if (headerDate && !headerDate.classList.contains('text-black')) {
              headerDate.classList.add('text-black');
              headerDate.classList.add('bb-medium-large-text-size');
              headerDate.classList.add('fw-bold');
            }
            break;
          }
        }
      });
    },
    [scrollTo],
  );

  const scrollToIndex = (index) => {
    let indexToScrollTo = 0;
    for (let i = 0; i < index; i++) {
      indexToScrollTo += sectionsArray[i].numberOfAlbumsPlusFillers / itemsPerRow;
    }
    indexToScrollTo = Math.ceil(indexToScrollTo);
    setScrollTo(indexToScrollTo);
  };

  const addFilter = useCallback(
    (type, name, value = null) => {
      if (!filters[type]) filters[type] = {};
      filters[type][name] = value ? value : !filters[type][name];
      setFilters(Object.assign({}, filters));
    },
    [filters],
  );

  const renderRow = useCallback(
    (params, albumsWithSpaces, itemsPerRow) => {
      return (
        <AgendaRow
          style={params.style}
          key={'row-' + params.key}
          params={params}
          albums={albumsWithSpaces}
          itemsPerRow={itemsPerRow}
          isSelf={props.isSelf}
        />
      );
    },
    [props.isSelf],
  );

  return (
    <>
      {!!(props.agendaLoading && sectionsArray.length === 0) && (
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-column align-items-center justify-content-center py-5">
              <div className="spinner-grow text-bubble-color" role="status" />
              <div className="text-bubble-color mt-2">
                Vos albums arrivent{' '}
                <span role="img" aria-label="hug">
                  🤗
                </span>
              </div>
              <div className="my-5" />
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-md-2 d-none d-md-block text-start">
          <div className="position-sticky sticky-top agenda-top-spacer">
            <AgendaDates sectionsArray={sectionsArray} callback={scrollToIndex} />

            <div className="h-separator my-4" />
            <div className="fw-bold pb-2">Disponibilité</div>
            <BubbleCheckbox
              checked={filters.availability?.inStockOnline || false}
              onChange={() => addFilter('availability', 'inStockOnline')}
              className="mb-1"
              label="En stock en ligne"
            />
            <BubbleCheckbox
              checked={filters.availability?.inStockClickAndCollect || false}
              onChange={() => addFilter('availability', 'inStockClickAndCollect')}
              className="mb-1"
              label="En stock en librairie"
            />
            <div className="fw-bold pt-2">Prix</div>
            <PriceRangeSlider onChange={(value) => addFilter('price', 'max', value)} />
          </div>
        </div>

        <div className="col-md-10">
          <AgendaSectionsFilter sections={sectionsArray} filters={filters}>
            {(filteredSections) => (
              <WindowScroller
                onScroll={onScrollEditHeader}
                scrollingResetTimeInterval={300}
                serverHeight={300}
                serverWidth={300}
              >
                {({ height, registerChild, isScrolling, scrollTop, onChildScroll }) => (
                  <AutoSizer disableHeight>
                    {({ width }) => {
                      let shouldAddNewsletterOptin = !user?.hasAcceptedNewsletter || optedIn;
                      const ITEM_WIDTH = 282;
                      itemsPerRow = Math.floor(width / ITEM_WIDTH);

                      // flattened array of albums with null objects so that every new week starts on a new row
                      const albumsWithSpaces = filteredSections.reduce((acc, week, index) => {
                        acc = acc.concat(week.albums);
                        if (shouldAddNewsletterOptin && acc.length / itemsPerRow >= 2) {
                          acc.splice(itemsPerRow * 2, 0, { isReoptin: true });
                          shouldAddNewsletterOptin = false;
                        }
                        let toFill = itemsPerRow - (acc.length % itemsPerRow);
                        if (toFill === itemsPerRow) {
                          toFill = 0;
                        }
                        const filler = new Array(toFill || 0).fill(null);
                        filteredSections[index].numberOfAlbumsPlusFillers =
                          week.albums.length + filler.length;
                        acc = acc.concat(filler);
                        return acc;
                      }, []);
                      const rowCount = Math.ceil(albumsWithSpaces.length / (itemsPerRow || 1));
                      return (
                        <div ref={registerChild}>
                          <List
                            style={listStyle}
                            autoHeight
                            height={height}
                            width={width}
                            rowCount={rowCount}
                            rowHeight={props.isSelf ? 450 : 525}
                            scrollToAlignment="center"
                            onScroll={onChildScroll}
                            isScrolling={isScrolling}
                            // 'isScrolling' needed below otherwise scrollToIndex does not work
                            // see https://github.com/bvaughn/react-virtualized/issues/1675
                            scrollTop={isScrolling && scrollTop}
                            scrollToIndex={scrollTo}
                            rowRenderer={(params) =>
                              renderRow(params, albumsWithSpaces, itemsPerRow)
                            }
                          />
                        </div>
                      );
                    }}
                  </AutoSizer>
                )}
              </WindowScroller>
            )}
          </AgendaSectionsFilter>
        </div>
      </div>
    </>
  );
};

export default AgendaSections;

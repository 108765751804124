import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { loadPrintApi } from '../services/api';

import { LOAD_PRINT } from '../reducers/prints';
import { loadPrintFail, loadPrintSuccess } from '../reducers/prints';
import { storePrints } from '../reducers/prints';
import { getClientToken } from '../selectors';

import bubbleUtils from 'bubble-utils';

export function* loadPrintFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const { printObjectId, options } = action.payload;
    const queryString = bubbleUtils.string.buildQueryString(options);
    const print = yield call(loadPrintApi, clientToken, printObjectId, queryString);
    yield put(loadPrintSuccess(print));
  } catch (error) {
    yield put(loadPrintFail(error));
  }
}

export function* storePrintsFlow(action) {
  yield put(storePrints(action.payload.prints, action.payload.source));
}

export default function* printsWatcher() {
  yield all([takeEvery(LOAD_PRINT, loadPrintFlow)]);
}

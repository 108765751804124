import React, { useEffect } from 'react';

import { connect, useDispatch } from 'react-redux';

import { loadHighlightedResources } from 'bubble-reducers/src/reducers/highlighted-resources';
import { get4HighlightedResources, getHighlightedArticles } from 'bubble-reducers/src/selectors';

import ArticleHorizontalCard from '@/components/ArticleHorizontalCard/ArticleHorizontalCard';
import SerieCard from '@/components/SerieCard/SerieCard';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

import HighlightAlbumCard from './HighlightAlbumCard';
import HighlightAuthorCard from './HighlightAuthorCard';
import HighlightStoreCard from './HighlightStoreCard';

const HighlightSection = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadHighlightedResources());
  }, [dispatch]);

  return (
    <>
      <TitledListContainer
        title={
          <div className="h1 text-center fw-bold pt-5 pb-3">
            BD, Comics, Mangas : les dernières sorties, les dossiers et l'actu décryptée pour vous
          </div>
        }
        list={props.highlightedArticles.slice(0, 3).map((article) => (
          <div
            className="col-md-6 col-lg-4 mt-3 d-flex flex-fill"
            key={`selection-${article.objectId}`}
          >
            <ArticleHorizontalCard article={article} />
          </div>
        ))}
      />
      <div className="mt-n5 pb-5 w-100">
        <div className="row">
          {props.highlightedResources?.series?.map((resource) => (
            <div key={'highlight_' + resource.objectId} className="col-md-4 col-lg-3 mt-3">
              <SerieCard serie={resource} />
            </div>
          ))}
          {props.highlightedResources?.albums?.map((resource) => (
            <div className="col-md-4 col-lg-3 mt-3" key={'highlight_' + resource.objectId}>
              <HighlightAlbumCard
                album={resource}
                serie={props.series[resource.serie.objectId]}
                prints={(resource.prints || []).map((printObjectId) => props.prints[printObjectId])}
              />
            </div>
          ))}
          {props.highlightedResources?.authors?.map((resource) => (
            <div className="col-md-4 col-lg-3 mt-3" key={'highlight_' + resource.objectId}>
              <HighlightAuthorCard author={resource} />
            </div>
          ))}
          {props.highlightedResources?.stores?.map((resource) => (
            <div className="col-md-4 col-lg-3 mt-3" key={'highlight_' + resource.objectId}>
              <HighlightStoreCard store={resource} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  highlightedArticles: getHighlightedArticles(state),
  highlightedResources: get4HighlightedResources(state),
  series: state.series.series,
  prints: state.prints.prints,
});

export default connect(mapStateToProps, null)(HighlightSection);

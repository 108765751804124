import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { createAlbumRevision } from 'bubble-reducers/src/reducers/catalogs';

import { Icon } from '@/components/Icon/Icon';
import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import ElementCell from './components/ElementCell';

import './Modals.css';

const AlbumRevisionModal = (props) => {
  const dispatch = useDispatch();
  const { albumObjectId, serieObjectId, allowGoBack } = props;

  const album = useSelector((state) => state.albums.albums[albumObjectId]);
  const serie = useSelector((state) => state.series.series[serieObjectId]);
  const userObjectId = useSelector((state) => state.user.user.objectId);

  const [editedFields, setEditedFields] = useState({});

  const authors = Array.isArray(album?.authors) ? album.authors.slice() : [];

  const dismiss = () => {
    setEditedFields({});
    props.callback();
  };

  const handleGoBack = () => {
    dismiss();
    props.handleGoBack();
  };

  const displayField = (field) => {
    if (field in editedFields) return editedFields[field];
    return album[field] ? album[field].toString() : '';
  };

  const isRevisionEmpty = (newValue, oldValue) => {
    const convertedOldValue =
      oldValue && typeof oldValue !== 'string' ? oldValue.toString() : oldValue;
    if (newValue === convertedOldValue) {
      return true;
      //This is to prevent revisions like null => null or null => ''
    } else if (
      (convertedOldValue === null || convertedOldValue === '' || convertedOldValue === undefined) &&
      (newValue === null || newValue === '' || newValue === undefined)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const sendRevision = () => {
    const revisions = [];
    Object.keys(editedFields).forEach((field) => {
      if (!isRevisionEmpty(editedFields[field], album[field])) {
        revisions.push({
          userObjectId,
          oldValue: album[field],
          newValue: editedFields[field],
          fieldRevised: field,
        });
      }
    });
    if (revisions.length) {
      dispatch(createAlbumRevision(albumObjectId, revisions));
      dismiss();
      props.openThankYourModal();
    } else {
      alert("Aucun champ n'a l'air d'avoir été modifié, êtes vous bien sûr de vos modifications ?");
    }
  };

  const renderAuthorSection = () => {
    const authorsToDisplay = authors;

    return (
      <div className="col-12 px-0">
        <div className="row text-secondary">
          <div className="col-12">Auteurs</div>
        </div>
        <div className="row">
          {authorsToDisplay.map((author) => (
            <div className="col-lg-3 col-md-6" key={author.objectId}>
              <ElementCell
                key={author.objectId}
                text={
                  (author.firstName && `${author.firstName || ''} ${author.lastName || ''}`) ||
                  author.pseudo
                }
                iconName="lock"
              />
            </div>
          ))}
        </div>
        <div className="text-secondary py-2">
          Vous pouvez modifier les auteurs dans les informations liées a l'édition
        </div>
      </div>
    );
  };

  const renderSummarySection = () => {
    return (
      <div className="col-12 px-0">
        <div className="form-group mt-3">
          <label className="text-secondary font-style-italic" htmlFor="exampleFormControlTextarea1">
            Résumé de l'album
          </label>
          <textarea
            value={displayField('summary')}
            onChange={(e) => setEditedFields({ ...editedFields, summary: e.target.value })}
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="5"
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="lg"
      centered
      contentClassName="bb-background-light-grey"
    >
      {(serie && album && (
        <>
          <div className="modal-header header-no-separator">
            {allowGoBack && (
              <WithClickHandler onClick={handleGoBack}>
                <Icon name="chevron-left" />
              </WithClickHandler>
            )}
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={dismiss}
            ></button>
          </div>
          <div className="modal-body mx-sm-3">
            <div className="row">
              <div className="col">
                <div className="bb-l-text-size d-flex flex-column justify-content-center align-items-center">
                  <span>Signalement</span>
                  <span className="fw-bold">
                    {serie.title ? serie.title + ' - ' : ''}
                    {album.title} Tome {album.tome}
                  </span>
                </div>
                <div className="text-secondary d-flex justify-content-center">
                  Informations sur l'album
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="row d-flex justify-content-between">
                <div className="col-9 mt-3">
                  <LabeledTextInput
                    white
                    noRightLabel
                    title="Titre"
                    placeholder="Titre"
                    value={displayField('title')}
                    onChange={(e) => setEditedFields({ ...editedFields, title: e.target.value })}
                  />
                </div>
                <div className="mt-3 col-3">
                  <LabeledTextInput
                    white
                    noRightLabel
                    title="Tome"
                    type="number"
                    placeholder="Tome"
                    value={displayField('tome')}
                    onChange={(e) => setEditedFields({ ...editedFields, tome: e.target.value })}
                  />
                </div>
              </div>
              {renderSummarySection()}
              {renderAuthorSection()}
            </div>
          </div>
          <div className="h-separator mt-2" />
          <div className="d-flex flex-row justify-content-between p-4 align-items-center">
            {allowGoBack && (
              <WithClickHandler onClick={handleGoBack}>
                <div className="text-start bb-medium-text-size">
                  <u>Retour</u>
                </div>
              </WithClickHandler>
            )}
            <div className="text-end">
              <button onClick={sendRevision} className="btn btn-success btn-lg text-white">
                Soumettre
              </button>
            </div>
          </div>
        </>
      )) ||
        null}
    </Modal>
  );
};

export default AlbumRevisionModal;

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { searchStores } from 'bubble-reducers/src/reducers/search';
import {
  checkStocksForEans,
  clearOneClickRelatedInfos,
  loadStores,
  setAvailabilitySearchFlag,
  setFavoriteSearchFlag,
} from 'bubble-reducers/src/reducers/stores';
import { loadUserFavoriteStores } from 'bubble-reducers/src/reducers/user';
import { getUserOrGuest } from 'bubble-reducers/src/selectors';

import BubbleCheckbox from '@/components/BubbleCheckbox/BubbleCheckbox';
import OneClickStoreCard from '@/components/OneClickStoreCard/OneClickStoreCard';
import SearchBar from '@/components/SearchBar/SearchBar';

import './Modals.css';

const ReservationOneClickModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reservationOneClickAlbums = props.reservationOneClickAlbums;
  const reservationStoreSelectionCallback = props.reservationStoreSelectionCallback;
  const hasCheckoutRedirect = props.hasCheckoutRedirect;

  const user = useSelector((state) => getUserOrGuest(state));
  const availabilitySearchFlag = useSelector((state) => state.stores.availabilitySearchFlag);
  const favoriteSearchFlag = useSelector((state) => state.stores.favoriteSearchFlag);
  const reservationsStoresToShow = useSelector((state) => state.stores.reservationsStoresToShow);

  const [text, setText] = useState('');

  useEffect(() => {
    dispatch(setAvailabilitySearchFlag(true));
    dispatch(loadStores());
    if (user?.objectId) {
      dispatch(loadUserFavoriteStores(user.objectId));
    }
  }, []);

  const dismiss = () => {
    dispatch(clearOneClickRelatedInfos());
    setText('');
    props.callback();
  };

  const handleStoreSelectionCallback = (store, itemObjectIds, storeHasEverythingInStock) => {
    dismiss();
    if (hasCheckoutRedirect) {
      navigate('/checkout', {
        state: {
          shippingMethodSelected: 'store',
          itemObjectIds,
          storeObjectId: store.objectId,
          noShippingEdit: true,
        },
      });
    } else {
      reservationStoreSelectionCallback(store, itemObjectIds, storeHasEverythingInStock);
    }
  };

  useEffect(() => {
    const eans = (reservationOneClickAlbums || []).map((album) => album.extra.ean || album.sku);
    if (eans?.length) {
      dispatch(
        checkStocksForEans(
          (reservationOneClickAlbums || []).map((album) => album.extra.ean || album.sku),
        ),
      );
    }
  }, [reservationOneClickAlbums, favoriteSearchFlag, availabilitySearchFlag]);

  const searchStore = (event) => {
    // TODO: set in store that i'm searching, so we dont use the bigass stock checking function
    // everytime anything changes in the store reducer (see stores sagas)
    dispatch(searchStores(event.target.value));
    setText(event.target.value);
  };

  const setFavoriteFlag = (event) => {
    dispatch(setFavoriteSearchFlag(!favoriteSearchFlag));
  };

  const onPressSetAvailabilitySearchFlag = (event) => {
    dispatch(setAvailabilitySearchFlag(!availabilitySearchFlag));
  };

  const onClickMissingStore = () =>
    window &&
    window.Intercom &&
    window.Intercom(
      'showNewMessage',
      `Bonjour,

Merci pour ta demande, afin que nous puissions identifier cette librairie, peux-tu nous transmettre les informations suivantes :

- Nom de la librairie :
- Son adresse complète :
- Son numéro de téléphone :
- Son email :

Merci pour ta demande et n'hésite pas à en parler à ton libraire également. Il peut nous envoyer un mail à librairie@bubblebd.com (email spécial libraires) 

L'équipe Bubble`,
    );

  const albums = reservationOneClickAlbums || [];
  const isSingleAlbum = albums.length === 1;

  return (
    <Modal show={props.show} onHide={dismiss} size="xl" contentClassName="bb-background-light-grey">
      <div className="modal-header header-no-separator">
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={dismiss}
        ></button>
      </div>
      <div className="modal-body mx-sm-3">
        <div className="d-flex flex-fill justify-content-center">
          <div className="text-center bb-l-text-size fw-bold reservation-title">
            Retirez gratuitement {isSingleAlbum ? albums[0].title || 'cet album' : 'vos albums'}{' '}
            dans une librairie près de chez vous
          </div>
        </div>
        <div className="row my-3">
          <div className="col text-center">Paiement en magasin</div>
          <div className="col text-center">Retrait gratuit en quelques heures</div>
        </div>

        <div className="row align-items-center">
          <div className="col-8">
            <SearchBar text={text} onChange={searchStore} />
          </div>
          <div className="col-auto">
            <BubbleCheckbox
              checked={favoriteSearchFlag}
              onChange={setFavoriteFlag}
              label="Favoris"
              id="favs"
            />
          </div>
          <div className="col-auto">
            <BubbleCheckbox
              className="text-end"
              checked={availabilitySearchFlag}
              onChange={onPressSetAvailabilitySearchFlag}
              label="Disponible immédiatement"
              id="availability"
            />
          </div>
        </div>
        <div className="mt-4">
          {reservationsStoresToShow.map((store) => (
            <div className="mb-3" key={store.objectId}>
              <OneClickStoreCard
                bookseller={store}
                requestedItems={reservationOneClickAlbums}
                buttonCallback={handleStoreSelectionCallback}
              />
            </div>
          ))}
        </div>
        {text && (
          <div className="text-secondary text-center">
            Vous n'avez pas trouvé votre librairie ?{' '}
            <button className="btn btn-link" onClick={onClickMissingStore}>
              Envoyez nous une demande{' '}
              <span role="img" aria-label="up">
                👍
              </span>
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

ReservationOneClickModal.propTypes = {
  reservationOneClickAlbums: PropTypes.any,
  reservationStoreSelectionCallback: PropTypes.func,
};

export default ReservationOneClickModal;

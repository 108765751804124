import { isBinaryFlagEnabled } from 'bubble-utils/src/code-utils';
import { formatAsFrenchUtcDoubleDigitFromIso } from 'bubble-utils/src/date-utils';

import { GENERAL } from 'bubble-constants';

export const LOAD_LIBRARY = 'LOAD_LIBRARY';
export const LOAD_LIBRARY_SUCCESS = 'LOAD_LIBRARY_SUCCESS';
export const LOAD_LIBRARY_FAIL = 'LOAD_LIBRARY_FAIL';

export const LOAD_LIBRARY_PRINTS_OPTIONS = 'LOAD_LIBRARY_PRINTS_OPTIONS';
export const LOAD_LIBRARY_PRINTS_OPTIONS_SUCCESS = 'LOAD_LIBRARY_PRINTS_OPTIONS_SUCCESS';
export const LOAD_LIBRARY_PRINTS_OPTIONS_FAIL = 'LOAD_LIBRARY_PRINTS_OPTIONS_FAIL';

export const LOAD_FOLLOWED = 'LOAD_FOLLOWED';
export const LOAD_FOLLOWED_SUCCESS = 'LOAD_FOLLOWED_SUCCESS';
export const LOAD_FOLLOWED_FAIL = 'LOAD_FOLLOWED_FAIL';

export const ADD_PRINTS_TO_LIBRARY = 'ADD_PRINTS_TO_LIBRARY';
export const REMOVE_PRINTS_FROM_LIBRARY = 'REMOVE_PRINTS_FROM_LIBRARY';

export const ADD_SERIE_TO_FOLLOW = 'ADD_SERIE_TO_FOLLOW';
export const ADD_SERIE_TO_FOLLOW_SUCCESS = 'ADD_SERIE_TO_FOLLOW_SUCCESS';
export const ADD_SERIE_TO_FOLLOW_FAIL = 'ADD_SERIE_TO_FOLLOW_FAIL';

export const REMOVE_SERIE_FROM_FOLLOW = 'REMOVE_SERIE_FROM_FOLLOW';
export const REMOVE_SERIE_FROM_FOLLOW_SUCCESS = 'REMOVE_SERIE_FROM_FOLLOW_SUCCESS';
export const REMOVE_SERIE_FROM_FOLLOW_FAIL = 'REMOVE_SERIE_FROM_FOLLOW_FAIL';

export const SET_READ_FLAG_ON_PRINTS = 'SET_READ_FLAG_ON_PRINTS';
export const SET_EO_FLAG_ON_PRINTS = 'SET_EO_FLAG_ON_PRINTS';
export const SET_SIGNED_FLAG_ON_PRINTS = 'SET_SIGNED_FLAG_ON_PRINTS';
export const SET_DELUXE_FLAG_ON_PRINTS = 'SET_DELUXE_FLAG_ON_PRINTS';
export const SET_LIMITED_FLAG_ON_PRINTS = 'SET_LIMITED_FLAG_ON_PRINTS';
export const SET_EXLIBRIS_FLAG_ON_PRINTS = 'SET_EXLIBRIS_FLAG_ON_PRINTS';
export const SET_DIGITAL_FLAG_ON_PRINTS = 'SET_DIGITAL_FLAG_ON_PRINTS';
export const SET_FOR_SALE_FLAG_ON_PRINTS = 'SET_FOR_SALE_FLAG_ON_PRINTS';
export const SET_BUY_PRICE_FLAG_ON_PRINTS = 'SET_BUY_PRICE_FLAG_ON_PRINTS';
export const SET_RESELL_VALUE_FLAG_ON_PRINTS = 'SET_RESELL_VALUE_FLAG_ON_PRINTS';
export const SET_CONDITION_FLAG_ON_PRINTS = 'SET_CONDITION_FLAG_ON_PRINTS';
export const SET_NUMBERING_FLAG_ON_PRINTS = 'SET_NUMBERING_FLAG_ON_PRINTS';

export const SET_SELECTED_ALBUM_PRINT = 'SET_SELECTED_ALBUM_PRINT';
export const SET_SELECTED_ALBUM_PRINT_SUCCESS = 'SET_SELECTED_ALBUM_PRINT_SUCCESS';
export const SET_SELECTED_ALBUM_PRINT_FAIL = 'SET_SELECTED_ALBUM_PRINT_FAIL';

export const LOAD_USER_AGENDA = 'LOAD_USER_AGENDA';
export const LOAD_USER_AGENDA_SUCCESS = 'LOAD_USER_AGENDA_SUCCESS';
export const LOAD_USER_AGENDA_FAIL = 'LOAD_USER_AGENDA_FAIL';

export const LOAD_USER_STATS = 'LOAD_USER_STATS';
export const LOAD_USER_STATS_SUCCESS = 'LOAD_USER_STATS_SUCCESS';
export const LOAD_USER_STATS_FAIL = 'LOAD_USER_STATS_FAIL';

export const LOAD_SIGNED_ALBUMS = 'LOAD_SIGNED_ALBUMS';
export const LOAD_SIGNED_ALBUMS_SUCCESS = 'LOAD_SIGNED_ALBUMS_SUCCESS';
export const LOAD_SIGNED_ALBUMS_FAIL = 'LOAD_SIGNED_ALBUMS_FAIL';

export const LOAD_SELECTED_ALBUM_PRINTS = 'LOAD_SELECTED_ALBUM_PRINTS';
export const LOAD_SELECTED_ALBUM_PRINTS_SUCCESS = 'LOAD_SELECTED_ALBUM_PRINTS_SUCCESS';
export const LOAD_SELECTED_ALBUM_PRINTS_FAIL = 'LOAD_SELECTED_ALBUM_PRINTS_FAIL';

export const LOAD_LOANED_ALBUMS = 'LOAD_LOANED_ALBUMS';
export const LOAD_LOANED_ALBUMS_SUCCESS = 'LOAD_LOANED_ALBUMS_SUCCESS';
export const LOAD_LOANED_ALBUMS_FAIL = 'LOAD_LOANED_ALBUMS_FAIL';

export const LOAD_WISHLIST_ALBUMS = 'LOAD_WISHLIST_ALBUMS';
export const LOAD_WISHLIST_ALBUMS_SUCCESS = 'LOAD_WISHLIST_ALBUMS_SUCCESS';
export const LOAD_WISHLIST_ALBUMS_FAIL = 'LOAD_WISHLIST_ALBUMS_FAIL';

export const UPDATE_WISHLIST_PRINTS = 'UPDATE_WISHLIST_PRINTS';
export const UPDATE_WISHLIST_PRINTS_SUCCESS = 'UPDATE_WISHLIST_PRINTS_SUCCESS';
export const UPDATE_WISHLIST_PRINTS_FAIL = 'UPDATE_WISHLIST_PRINTS_FAIL';

export const ADD_PRINTS_TO_WISHLIST = 'ADD_PRINTS_TO_WISHLIST';
export const REMOVE_PRINTS_FROM_WISHLIST = 'REMOVE_PRINTS_FROM_WISHLIST';
export const ADD_PRINT_LOAN = 'ADD_PRINT_LOAN';
export const REMOVE_PRINT_LOAN = 'REMOVE_PRINT_LOAN';

export const LOAD_RECOMMENDATION = 'LOAD_RECOMMENDATION';
export const LOAD_RECOMMENDATION_SUCCESS = 'LOAD_RECOMMENDATION_SUCCESS';
export const LOAD_RECOMMENDATION_FAIL = 'LOAD_RECOMMENDATION_FAIL';

export const UPDATE_MEMO_PRINTS = 'UPDATE_MEMO_PRINTS';

export const UPDATE_LIBRARY = 'UPDATE_LIBRARY';
export const UPDATE_LIBRARY_SUCCESS = 'UPDATE_LIBRARY_SUCCESS';
export const UPDATE_LIBRARY_FAIL = 'UPDATE_LIBRARY_FAIL';

export const ADD_AUTHOR_TO_FOLLOW = 'ADD_AUTHOR_TO_FOLLOW';
export const ADD_AUTHOR_TO_FOLLOW_SUCCESS = 'ADD_AUTHOR_TO_FOLLOW_SUCCESS';
export const ADD_AUTHOR_TO_FOLLOW_FAIL = 'ADD_AUTHOR_TO_FOLLOW_FAIL';

export const REMOVE_AUTHOR_FROM_FOLLOW = 'REMOVE_AUTHOR_FROM_FOLLOW';
export const REMOVE_AUTHOR_FROM_FOLLOW_SUCCESS = 'REMOVE_AUTHOR_FROM_FOLLOW_SUCCESS';
export const REMOVE_AUTHOR_FROM_FOLLOW_FAIL = 'REMOVE_AUTHOR_FROM_FOLLOW_FAIL';

export const LOAD_LIBRARY_AUTHOR_FOLLOW = 'LOAD_LIBRARY_AUTHOR_FOLLOW';
export const LOAD_LIBRARY_AUTHOR_FOLLOW_SUCCESS = 'LOAD_LIBRARY_AUTHOR_FOLLOW_SUCCESS';
export const LOAD_LIBRARY_AUTHOR_FOLLOW_FAIL = 'LOAD_LIBRARY_AUTHOR_FOLLOW_FAIL';

export const RESET_PROFILES_REDUCER = 'RESET_PROFILES_REDUCER';

export const initialProfile = {
  ownedAlbumsIdsMap: {},
  ownedPrintsIdsMap: {},
  readAlbumsIdsMap: {},
  readPrintsIdsMap: {},
  signedAlbumsIdsMap: {},
  signedPrintsIdsMap: {},
  wishlistAlbumsIdsMap: {},
  wishlistPrintsIdsMap: {},
  originalEditionsAlbumsIdsMap: {},
  originalEditionsPrintsIdsMap: {},
  loanAlbumsIdsMap: {},
  loanPrintsIdsMap: {},
  memoPrintsIdsMap: {},
  deluxePrintsIdsMap: {},
  deluxeAlbumsIdsMap: {},
  digitalPrintsIdsMap: {},
  digitalAlbumsIdsMap: {},
  exlibrisPrintsIdsMap: {},
  exlibrisAlbumsIdsMap: {},
  limitedPrintsIdsMap: {},
  limitedAlbumsIdsMap: {},
  forSalePrintsIdsMap: {},
  forSaleAlbumsIdsMap: {},
  buyPricePrintsIdsMap: {},
  resellValuePrintsIdsMap: {},
  conditionPrintsIdsMap: {},
  numberingPrintsIdsMap: {},
  recommendedAlbumsIdsMap: {},
  followedSeriesIdsMap: {},
  unfollowedSeriesIdsMap: {},
  followedAuthorsIdsMap: {},
  selectedAlbumPrintObjectIdsMap: {},
  selectedAlbumPrintsObjectIdsMap: {},
  formattedReceivedLibrary: [],
  formattedReceivedFollowedLibrary: [],
  mySeries: [],
  myAlbums: {},
  myAgenda: [],
  stats: {},
  loanAlbums: [],
  myCompletionSeries: [],
  temporaryAddedToLibraryAlbumsMap: {},
  temporaryRemovedFromLibraryAlbumsMap: {},
  temporaryAddedToLibraryPrintsMap: {},
  temporaryRemovedFromLibraryPrintsMap: {},
};

const initialState = {
  otherProfiles: {},
  ...initialProfile,
  loading: {},
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_LIBRARY: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_LIBRARY]: true },
      };
    }
    case LOAD_LIBRARY_SUCCESS: {
      const { isGuest, libraryObjectId, library } = action.payload;

      const rootProfile = isGuest
        ? state.otherProfiles[libraryObjectId] || { ...initialProfile }
        : state;

      const unfollowedSeriesIdsMap = {};
      const selectedAlbumPrintObjectIdsMap = { ...state.selectedAlbumPrintObjectIdsMap };
      const selectedAlbumPrintsObjectIdsMap = { ...state.selectedAlbumPrintsObjectIdsMap };
      const followedSeriesIdsMap = { ...rootProfile.followedSeriesIdsMap };
      const myAlbums = { ...rootProfile.myAlbums };

      // only used to filter serie.albums, not stored afterward
      const ownedAlbumsIdsMap = { ...state.temporaryAddedToLibraryAlbumsMap };
      const readAlbumsIdsMap = {};
      const ownedPrintsIdsMap = { ...state.temporaryAddedToLibraryPrintsMap };
      const readPrintsIdsMap = {};

      // immediately populate albums map
      library?.forEach((serie) => {
        if (serie.unfollowed) {
          unfollowedSeriesIdsMap[serie.serie.objectId] = true;
          delete followedSeriesIdsMap[serie.serie.objectId];
        } else {
          followedSeriesIdsMap[serie.serie.objectId] = true;
        }
        serie.albums?.forEach((album) => {
          Object.entries(album.userPrints).forEach(([printObjectId, flag]) => {
            if (!state.temporaryRemovedFromLibraryPrintsMap[printObjectId]) {
              if (isBinaryFlagEnabled(flag, GENERAL.USER_PRINT.OWNED)) {
                ownedAlbumsIdsMap[album.objectId] = true;
                ownedPrintsIdsMap[printObjectId] = true;
              }
              if (isBinaryFlagEnabled(flag, GENERAL.USER_PRINT.READ)) {
                readAlbumsIdsMap[album.objectId] = true;
                readPrintsIdsMap[printObjectId] = true;
              }
            }
          });
          myAlbums[album.objectId] = { ...myAlbums[album.objectId], ...formatAlbum(album) };
          if (album.selectedPrintObjectId) {
            selectedAlbumPrintObjectIdsMap[album.objectId] = album.selectedPrintObjectId;
          }
        });
      });

      // create the formattedReceivedLibrary
      const formattedReceivedLibrary = formatAndUpdateReceivedLibraryArray(library);

      let mySeries = deepCloneLibraryArray(formattedReceivedLibrary).map((serie) => {
        // keep only album read or in library
        serie.albums = serie.albums.filter(
          (albumObjectId) =>
            !!ownedAlbumsIdsMap[albumObjectId] || !!readAlbumsIdsMap[albumObjectId],
        );
        return serie;
      });

      const myCompletionSeries = createCompletionData(
        deepCloneLibraryArray(formattedReceivedLibrary),
        rootProfile.formattedReceivedFollowedLibrary,
        unfollowedSeriesIdsMap,
      ).map((serie) => {
        // keep only album not in library, either read or not
        serie.albums = serie.albums.filter((albumObjectId) => !ownedAlbumsIdsMap[albumObjectId]);
        return serie;
      });

      if (!isGuest) {
        return {
          ...state,
          followedSeriesIdsMap: followedSeriesIdsMap,
          unfollowedSeriesIdsMap: unfollowedSeriesIdsMap,
          formattedReceivedLibrary: formattedReceivedLibrary,
          selectedAlbumPrintObjectIdsMap,
          selectedAlbumPrintsObjectIdsMap,
          mySeries: mySeries,
          myAlbums: myAlbums,
          myCompletionSeries: myCompletionSeries,
          temporaryAddedToLibraryAlbumsMap: {},
          temporaryRemovedFromLibraryAlbumsMap: {},
          temporaryAddedToLibraryPrintsMap: {},
          temporaryRemovedFromLibraryPrintsMap: {},
          loading: { ...state.loading, [LOAD_LIBRARY]: false },
        };
      } else {
        const otherProfiles = state.otherProfiles;
        otherProfiles[action.payload.libraryObjectId] =
          otherProfiles[action.payload.libraryObjectId] || rootProfile;
        const otherProfile = otherProfiles[action.payload.libraryObjectId];
        otherProfile.unfollowedSeriesIdsMap = unfollowedSeriesIdsMap;
        otherProfile.followedSeriesIdsMap = followedSeriesIdsMap;
        otherProfile.formattedReceivedLibrary = formattedReceivedLibrary;
        otherProfile.mySeries = mySeries;
        otherProfile.myAlbums = myAlbums;
        otherProfile.myCompletionSeries = myCompletionSeries;

        return {
          ...state,
          otherProfiles,
          loading: { ...state.loading, [LOAD_LIBRARY]: false },
        };
      }
    }
    case LOAD_LIBRARY_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_LIBRARY]: false },
      };
    }

    case LOAD_LIBRARY_PRINTS_OPTIONS_SUCCESS: {
      const { isGuest, libraryObjectId, libraryPrintsOptions } = action.payload;

      const rootProfile = isGuest
        ? state.otherProfiles[libraryObjectId] || Object.assign({}, initialProfile)
        : {};
      const maps = {};
      maps.ownedAlbumsIdsMap = rootProfile.ownedAlbumsIdsMap || {};
      maps.ownedPrintsIdsMap = rootProfile.ownedPrintsIdsMap || {};
      maps.readAlbumsIdsMap = rootProfile.readAlbumsIdsMap || {};
      maps.readPrintsIdsMap = rootProfile.readPrintsIdsMap || {};
      maps.wishlistPrintsIdsMap = rootProfile.wishlistPrintsIdsMap || {};
      maps.wishlistAlbumsIdsMap = rootProfile.wishlistAlbumsIdsMap || {};
      maps.originalEditionsAlbumsIdsMap = rootProfile.originalEditionsAlbumsIdsMap || {};
      maps.originalEditionsPrintsIdsMap = rootProfile.originalEditionsPrintsIdsMap || {};
      maps.loanAlbumsIdsMap = rootProfile.loanAlbumsIdsMap || {};
      maps.loanPrintsIdsMap = rootProfile.loanPrintsIdsMap || {};
      maps.signedPrintsIdsMap = rootProfile.signedPrintsIdsMap || {};
      maps.signedAlbumsIdsMap = rootProfile.signedAlbumsIdsMap || {};
      maps.memoPrintsIdsMap = rootProfile.memoPrintsIdsMap || {};
      maps.temporaryAddedToLibraryPrintsMap = rootProfile.temporaryAddedToLibraryPrintsMap || {};
      maps.temporaryRemovedFromLibraryPrintsMap =
        rootProfile.temporaryRemovedFromLibraryPrintsMap || {};
      maps.deluxePrintsIdsMap = rootProfile.deluxePrintsIdsMap || {};
      maps.deluxeAlbumsIdsMap = rootProfile.deluxeAlbumsIdsMap || {};
      maps.digitalPrintsIdsMap = rootProfile.digitalPrintsIdsMap || {};
      maps.digitalAlbumsIdsMap = rootProfile.digitalAlbumsIdsMap || {};
      maps.exlibrisPrintsIdsMap = rootProfile.exlibrisPrintsIdsMap || {};
      maps.exlibrisAlbumsIdsMap = rootProfile.exlibrisAlbumsIdsMap || {};
      maps.forSalePrintsIdsMap = rootProfile.forSalePrintsIdsMap || {};
      maps.forSaleAlbumsIdsMap = rootProfile.forSaleAlbumsIdsMap || {};
      maps.limitedPrintsIdsMap = rootProfile.limitedPrintsIdsMap || {};
      maps.limitedAlbumsIdsMap = rootProfile.limitedAlbumsIdsMap || {};
      maps.buyPricePrintsIdsMap = rootProfile.buyPricePrintsIdsMap || {};
      maps.resellValuePrintsIdsMap = rootProfile.resellValuePrintsIdsMap || {};
      maps.conditionPrintsIdsMap = rootProfile.conditionPrintsIdsMap || {};
      maps.numberingPrintsIdsMap = rootProfile.numberingPrintsIdsMap || {};

      libraryPrintsOptions?.forEach((option) =>
        // fill maps by reference
        getIdsMapFromBinaryFlags(maps, option.albumObjectId, option.printObjectId, option),
      );

      if (!isGuest) {
        return {
          ...state,
          ...maps,
        };
      } else {
        const otherProfiles = state.otherProfiles;
        otherProfiles[libraryObjectId] = {
          ...(otherProfiles[libraryObjectId] || rootProfile),
          ...maps,
        };

        return {
          ...state,
          otherProfiles,
        };
      }
    }

    case LOAD_FOLLOWED: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_FOLLOWED]: true },
      };
    }
    case LOAD_FOLLOWED_SUCCESS: {
      const { isGuest, libraryObjectId, followed } = action.payload;

      const rootProfile = isGuest
        ? state.otherProfiles[libraryObjectId] || { ...initialProfile }
        : { ...state };

      const followedSeriesIdsMap = { ...rootProfile.followedSeriesIdsMap };

      // immediately populate albums map
      let myAlbums = { ...rootProfile.myAlbums };
      followed?.forEach((serie) =>
        serie.albums.map(
          (album) =>
            (myAlbums[album.objectId] = Object.assign(
              {},
              myAlbums[album.objectId] || {},
              formatAlbum(album),
            )),
        ),
      );

      // create the formattedReceivedLibrary
      const formattedReceivedFollowedLibrary = formatAndUpdateReceivedLibraryArray(followed);
      formattedReceivedFollowedLibrary.forEach((serie) => {
        const serieObjectId = serie.serie.objectId;
        if (serie.followed) {
          followedSeriesIdsMap[serieObjectId] = true;
        }
      });

      const myCompletionSeries = createCompletionData(
        deepCloneLibraryArray(rootProfile.formattedReceivedLibrary),
        formattedReceivedFollowedLibrary,
        rootProfile.unfollowedSeriesIdsMap,
      ).map((serie) => {
        // keeping only album not in library, either read or not
        serie.albums = serie.albums.filter(
          (albumObjectId) => !rootProfile.ownedAlbumsIdsMap[albumObjectId],
        );
        return serie;
      });

      if (!isGuest) {
        return {
          ...state,
          followedSeriesIdsMap: followedSeriesIdsMap,
          formattedReceivedFollowedLibrary: formattedReceivedFollowedLibrary,
          myAlbums: myAlbums,
          myCompletionSeries: myCompletionSeries,
          loading: { ...state.loading, [LOAD_FOLLOWED]: false },
        };
      } else {
        const otherProfiles = state.otherProfiles;
        otherProfiles[libraryObjectId] = otherProfiles[libraryObjectId] || rootProfile;
        const otherProfile = otherProfiles[libraryObjectId];
        otherProfile.followedSeriesIdsMap = followedSeriesIdsMap;
        otherProfile.formattedReceivedFollowedLibrary = formattedReceivedFollowedLibrary;
        otherProfile.myAlbums = myAlbums;
        otherProfile.myCompletionSeries = myCompletionSeries;
        return {
          ...state,
          otherProfiles,
          loading: { ...state.loading, [LOAD_FOLLOWED]: false },
        };
      }
    }
    case LOAD_FOLLOWED_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_FOLLOWED]: false },
      };
    }

    case ADD_PRINTS_TO_LIBRARY: {
      const { prints } = action.payload;
      const selectedAlbumPrintsObjectIdsMap = { ...state.selectedAlbumPrintsObjectIdsMap };
      const ownedAlbumsIdsMap = { ...state.ownedAlbumsIdsMap };
      const ownedPrintsIdsMap = { ...state.ownedPrintsIdsMap };
      const temporaryAddedToLibraryAlbumsMap = { ...state.temporaryAddedToLibraryAlbumsMap };
      const temporaryRemovedFromLibraryAlbumsMap = {
        ...state.temporaryRemovedFromLibraryAlbumsMap,
      };
      const temporaryAddedToLibraryPrintsMap = { ...state.temporaryAddedToLibraryPrintsMap };
      const temporaryRemovedFromLibraryPrintsMap = {
        ...state.temporaryRemovedFromLibraryPrintsMap,
      };
      prints.forEach((print) => {
        ownedAlbumsIdsMap[print.albumObjectId] = (ownedAlbumsIdsMap[print.albumObjectId] || [])
          .concat([print.printObjectId])
          .filter((item, pos, self) => self.indexOf(item) == pos)
          .sort((a, b) => a > b);
        temporaryAddedToLibraryAlbumsMap[print.albumObjectId] = (
          temporaryAddedToLibraryAlbumsMap[print.albumObjectId] || []
        )
          .concat([print.printObjectId])
          .filter((item, pos, self) => self.indexOf(item) == pos)
          .sort((a, b) => a > b);
        ownedPrintsIdsMap[print.printObjectId] = true;
        temporaryAddedToLibraryPrintsMap[print.printObjectId] = true;

        delete temporaryRemovedFromLibraryPrintsMap[print.printObjectId];
        delete temporaryRemovedFromLibraryAlbumsMap[print.albumObjectId];
      });

      const arrays = updateArrays(
        state,
        temporaryAddedToLibraryAlbumsMap,
        temporaryRemovedFromLibraryAlbumsMap,
      );

      return {
        ...state,
        ownedAlbumsIdsMap: ownedAlbumsIdsMap,
        ownedPrintsIdsMap: ownedPrintsIdsMap,
        selectedAlbumPrintsObjectIdsMap,
        temporaryAddedToLibraryAlbumsMap: temporaryAddedToLibraryAlbumsMap,
        temporaryRemovedFromLibraryAlbumsMap: temporaryRemovedFromLibraryAlbumsMap,
        temporaryAddedToLibraryPrintsMap: temporaryAddedToLibraryPrintsMap,
        temporaryRemovedFromLibraryPrintsMap: temporaryRemovedFromLibraryPrintsMap,
        myCompletionSeries: arrays[0],
        mySeries: arrays[1],
      };
    }

    case SET_READ_FLAG_ON_PRINTS: {
      const { prints, isBatch } = action.payload;

      let readAlbumsIdsMap = { ...state.readAlbumsIdsMap };
      let readPrintsIdsMap = { ...state.readPrintsIdsMap };
      prints.forEach((print) => {
        if (isBatch || prints.length > 1 || print.isRead === true) {
          readAlbumsIdsMap[print.albumObjectId] = print.isRead;
        }
        if (print.isRead === true) {
          readPrintsIdsMap[print.printObjectId] = print.isRead;
        } else {
          delete readPrintsIdsMap[print.printObjectId];
        }
      });

      let mySeries = [...state.mySeries];

      // update mySeries so that "Mes bd visu album" acts properly on unreading
      if (isBatch) {
        mySeries = deepCloneLibraryArray(state.formattedReceivedLibrary).map((serie) => {
          // keeping only album read or in library
          serie.albums = serie.albums.filter(
            (albumObjectId) =>
              readAlbumsIdsMap[albumObjectId] ||
              (state.ownedAlbumsIdsMap[albumObjectId] &&
                !state.temporaryRemovedFromLibraryAlbumsMap[albumObjectId]),
          );
          return serie;
        });
      }
      return {
        ...state,
        mySeries: mySeries,
        readAlbumsIdsMap: readAlbumsIdsMap,
        readPrintsIdsMap: readPrintsIdsMap,
      };
    }

    case LOAD_LOANED_ALBUMS: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_LOANED_ALBUMS]: true },
      };
    }
    case LOAD_LOANED_ALBUMS_SUCCESS: {
      const loanedAlbums = action.payload.loanedAlbums;
      const loanAlbumsIdsMap = loanedAlbums.reduce((acc, cur) => {
        acc[cur.objectId] = cur?.extraUserOptions?.loan;
        return acc;
      }, {});

      if (!action.payload.isGuest) {
        return {
          ...state,
          loading: { ...state.loading, [LOAD_LOANED_ALBUMS]: false },
          loanAlbums: loanedAlbums,
          loanAlbumsIdsMap: loanAlbumsIdsMap,
        };
      } else {
        const otherProfiles = state.otherProfiles;
        otherProfiles[action.payload.libraryObjectId] =
          otherProfiles[action.payload.libraryObjectId] || Object.assign({}, initialProfile);
        const otherProfile = otherProfiles[action.payload.libraryObjectId];
        otherProfile.loanAlbumsIdsMap = loanAlbumsIdsMap;
        return {
          ...state,
          loading: { ...state.loading, [LOAD_LOANED_ALBUMS]: false },
          otherProfiles,
        };
      }
    }
    case LOAD_LOANED_ALBUMS_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_LOANED_ALBUMS]: false },
      };
    }

    case SET_EO_FLAG_ON_PRINTS: {
      const { prints, isBatch } = action.payload;

      const originalEditionsAlbumsIdsMap = { ...state.originalEditionsAlbumsIdsMap };
      const originalEditionsPrintsIdsMap = { ...state.originalEditionsPrintsIdsMap };
      prints.forEach((print) => {
        // For collection batch actions
        if (isBatch || prints.length > 1 || print.isOriginalEdition === true) {
          originalEditionsAlbumsIdsMap[print.albumObjectId] = print.isOriginalEdition;
        }
        originalEditionsPrintsIdsMap[print.printObjectId] = print.isOriginalEdition;
      });
      return {
        ...state,
        originalEditionsAlbumsIdsMap: originalEditionsAlbumsIdsMap,
        originalEditionsPrintsIdsMap: originalEditionsPrintsIdsMap,
      };
    }

    case SET_SIGNED_FLAG_ON_PRINTS: {
      const { prints, isBatch } = action.payload;
      const signedAlbumsIdsMap = { ...state.signedAlbumsIdsMap };
      const signedPrintsIdsMap = { ...state.signedPrintsIdsMap };
      prints.forEach((print) => {
        if (isBatch || prints.length > 1 || print.isSigned === true) {
          signedAlbumsIdsMap[print.albumObjectId] = print.isSigned;
        }
        signedPrintsIdsMap[print.printObjectId] = print.isSigned;
      });

      return {
        ...state,
        signedAlbumsIdsMap,
        signedPrintsIdsMap,
      };
    }

    case SET_DELUXE_FLAG_ON_PRINTS: {
      const { prints, isBatch } = action.payload;
      const deluxeAlbumsIdsMap = { ...state.deluxeAlbumsIdsMap };
      const deluxePrintsIdsMap = { ...state.deluxePrintsIdsMap };
      prints.forEach((print) => {
        if (isBatch || prints.length > 1 || print.isDeluxe) {
          deluxeAlbumsIdsMap[print.albumObjectId] = print.isDeluxe;
        }
        deluxePrintsIdsMap[print.printObjectId] = print.isDeluxe;
      });

      return {
        ...state,
        deluxeAlbumsIdsMap,
        deluxePrintsIdsMap,
      };
    }

    case SET_LIMITED_FLAG_ON_PRINTS: {
      const { prints, isBatch } = action.payload;
      const limitedAlbumsIdsMap = { ...state.limitedAlbumsIdsMap };
      const limitedPrintsIdsMap = { ...state.limitedPrintsIdsMap };
      prints.forEach((print) => {
        if (isBatch || prints.length > 1 || print.isLimited) {
          limitedAlbumsIdsMap[print.albumObjectId] = print.isLimited;
        }
        limitedPrintsIdsMap[print.printObjectId] = print.isLimited;
      });

      return {
        ...state,
        limitedAlbumsIdsMap,
        limitedPrintsIdsMap,
      };
    }

    case SET_EXLIBRIS_FLAG_ON_PRINTS: {
      const { prints, isBatch } = action.payload;
      const exlibrisAlbumsIdsMap = { ...state.exlibrisAlbumsIdsMap };
      const exlibrisPrintsIdsMap = { ...state.exlibrisPrintsIdsMap };
      prints.forEach((print) => {
        if (isBatch || prints.length > 1 || print.isWithExLibris) {
          exlibrisAlbumsIdsMap[print.albumObjectId] = print.isWithExLibris;
        }
        exlibrisPrintsIdsMap[print.printObjectId] = print.isWithExLibris;
      });

      return {
        ...state,
        exlibrisAlbumsIdsMap,
        exlibrisPrintsIdsMap,
      };
    }

    case SET_DIGITAL_FLAG_ON_PRINTS: {
      const { prints, isBatch } = action.payload;
      const digitalAlbumsIdsMap = { ...state.digitalAlbumsIdsMap };
      const digitalPrintsIdsMap = { ...state.digitalPrintsIdsMap };
      prints.forEach((print) => {
        if (isBatch || prints.length > 1 || print.isDigital) {
          digitalAlbumsIdsMap[print.albumObjectId] = print.isDigital;
        }
        digitalPrintsIdsMap[print.printObjectId] = print.isDigital;
      });

      return {
        ...state,
        digitalAlbumsIdsMap,
        digitalPrintsIdsMap,
      };
    }

    case SET_FOR_SALE_FLAG_ON_PRINTS: {
      const { prints, isBatch } = action.payload;
      const forSaleAlbumsIdsMap = { ...state.forSaleAlbumsIdsMap };
      const forSalePrintsIdsMap = { ...state.forSalePrintsIdsMap };
      prints.forEach((print) => {
        if (isBatch || prints.length > 1 || print.isForSale) {
          forSaleAlbumsIdsMap[print.albumObjectId] = print.isForSale;
        }
        forSalePrintsIdsMap[print.printObjectId] = print.isForSale;
      });

      return {
        ...state,
        forSaleAlbumsIdsMap,
        forSalePrintsIdsMap,
      };
    }

    case SET_BUY_PRICE_FLAG_ON_PRINTS: {
      const { prints, isBatch } = action.payload;

      const buyPriceAlbumsIdsMap = { ...state.buyPriceAlbumsIdsMap };
      const buyPricePrintsIdsMap = { ...state.buyPricePrintsIdsMap };
      prints.forEach((print) => {
        if (isBatch || prints.length > 1 || print.buyPrice) {
          buyPriceAlbumsIdsMap[print.printObjectId] = print.buyPrice;
        }
        buyPricePrintsIdsMap[print.printObjectId] = print.buyPrice;
      });
      return {
        ...state,
        buyPriceAlbumsIdsMap,
        buyPricePrintsIdsMap,
      };
    }

    case SET_RESELL_VALUE_FLAG_ON_PRINTS: {
      const { prints, isBatch } = action.payload;

      const resellValueAlbumsIdsMap = { ...state.resellValueAlbumsIdsMap };
      const resellValuePrintsIdsMap = { ...state.resellValuePrintsIdsMap };
      prints.forEach((print) => {
        if (isBatch || prints.length > 1 || print.resellValue) {
          resellValueAlbumsIdsMap[print.printObjectId] = print.resellValue;
        }
        resellValuePrintsIdsMap[print.printObjectId] = print.resellValue;
      });
      return {
        ...state,
        resellValueAlbumsIdsMap,
        resellValuePrintsIdsMap,
      };
    }

    case SET_CONDITION_FLAG_ON_PRINTS: {
      const { prints, isBatch } = action.payload;

      const conditionAlbumsIdsMap = { ...state.conditionAlbumsIdsMap };
      const conditionPrintsIdsMap = { ...state.conditionPrintsIdsMap };
      prints.forEach((print) => {
        if (isBatch || prints.length > 1 || print.condition) {
          conditionAlbumsIdsMap[print.printObjectId] = print.condition;
        }
        conditionPrintsIdsMap[print.printObjectId] = print.condition;
      });
      return {
        ...state,
        conditionAlbumsIdsMap,
        conditionPrintsIdsMap,
      };
    }

    case SET_NUMBERING_FLAG_ON_PRINTS: {
      const { prints, isBatch } = action.payload;

      const numberingAlbumsIdsMap = { ...state.numberingAlbumsIdsMap };
      const numberingPrintsIdsMap = { ...state.numberingPrintsIdsMap };
      prints.forEach((print) => {
        if (isBatch || prints.length > 1 || print.numbering) {
          numberingAlbumsIdsMap[print.printObjectId] = print.numbering;
        }
        numberingPrintsIdsMap[print.printObjectId] = print.numbering;
      });
      return {
        ...state,
        numberingAlbumsIdsMap,
        numberingPrintsIdsMap,
      };
    }

    case LOAD_SIGNED_ALBUMS: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_SIGNED_ALBUMS]: true },
      };
    }
    case LOAD_SIGNED_ALBUMS_SUCCESS: {
      const signedAlbumsIdsMap = {};
      action.payload.albums.forEach((album) => (signedAlbumsIdsMap[album.objectId] = true));

      if (!action.payload.isGuest) {
        return {
          ...state,
          loading: { ...state.loading, [LOAD_SIGNED_ALBUMS]: false },
          signedAlbumsIdsMap: signedAlbumsIdsMap,
        };
      } else {
        const otherProfiles = state.otherProfiles;
        otherProfiles[action.payload.libraryObjectId] =
          otherProfiles[action.payload.libraryObjectId] || Object.assign({}, initialProfile);
        const otherProfile = otherProfiles[action.payload.libraryObjectId];
        otherProfile.signedAlbumsIdsMap = signedAlbumsIdsMap;
        return {
          ...state,
          loading: { ...state.loading, [LOAD_SIGNED_ALBUMS]: false },
          otherProfiles,
        };
      }
    }
    case LOAD_SIGNED_ALBUMS_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_SIGNED_ALBUMS]: false },
      };
    }

    case LOAD_SELECTED_ALBUM_PRINTS: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_SELECTED_ALBUM_PRINTS]: true },
      };
    }
    case LOAD_SELECTED_ALBUM_PRINTS_SUCCESS: {
      const selectedAlbumPrintObjectIdsMap = {};
      action.payload.selectedPrints.forEach(
        (pair) => (selectedAlbumPrintObjectIdsMap[pair.album.objectId] = pair.objectId),
      );

      if (!action.payload.isGuest) {
        return {
          ...state,
          loading: { ...state.loading, [LOAD_SELECTED_ALBUM_PRINTS]: false },
          selectedAlbumPrintObjectIdsMap,
        };
      } else {
        const otherProfiles = state.otherProfiles;
        otherProfiles[action.payload.libraryObjectId] =
          otherProfiles[action.payload.libraryObjectId] || Object.assign({}, initialProfile);
        const otherProfile = otherProfiles[action.payload.libraryObjectId];
        otherProfile.selectedAlbumPrintObjectIdsMap = selectedAlbumPrintObjectIdsMap;
        return {
          ...state,
          loading: { ...state.loading, [LOAD_SELECTED_ALBUM_PRINTS]: false },
          otherProfiles,
        };
      }
    }
    case LOAD_SELECTED_ALBUM_PRINTS_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_SELECTED_ALBUM_PRINTS]: false },
      };
    }

    case REMOVE_PRINTS_FROM_LIBRARY: {
      const { prints, isBatch } = action.payload;
      const ownedAlbumsIdsMap = { ...state.ownedAlbumsIdsMap };
      const ownedPrintsIdsMap = { ...state.ownedPrintsIdsMap };

      const temporaryAddedToLibraryAlbumsMap = { ...state.temporaryAddedToLibraryAlbumsMap };
      const temporaryRemovedFromLibraryAlbumsMap = {
        ...state.temporaryRemovedFromLibraryAlbumsMap,
      };
      const temporaryAddedToLibraryPrintsMap = { ...state.temporaryAddedToLibraryPrintsMap };
      const temporaryRemovedFromLibraryPrintsMap = {
        ...state.temporaryRemovedFromLibraryPrintsMap,
      };

      prints.forEach((print) => {
        // For collection batch actions
        if (isBatch) {
          delete temporaryAddedToLibraryAlbumsMap[print.albumObjectId];
          temporaryRemovedFromLibraryAlbumsMap[print.albumObjectId] = true;
        }
        delete ownedPrintsIdsMap[print.printObjectId];
        delete temporaryAddedToLibraryPrintsMap[print.printObjectId];
        temporaryRemovedFromLibraryPrintsMap[print.printObjectId] = true;
        ownedAlbumsIdsMap[print.albumObjectId] = (
          ownedAlbumsIdsMap[print.albumObjectId] || []
        ).filter((printObjectId) => printObjectId !== print.printObjectId);
        if (temporaryAddedToLibraryAlbumsMap[print.albumObjectId]) {
          temporaryAddedToLibraryAlbumsMap[print.albumObjectId] = (
            temporaryAddedToLibraryAlbumsMap[print.albumObjectId] || []
          ).filter((printObjectId) => printObjectId !== print.printObjectId);
        }
        if (!(ownedAlbumsIdsMap[print.albumObjectId] || []).length) {
          delete ownedAlbumsIdsMap[print.albumObjectId];
          temporaryRemovedFromLibraryAlbumsMap[print.albumObjectId] = true;
        }
        if (!(temporaryAddedToLibraryAlbumsMap[print.albumObjectId] || []).length) {
          delete temporaryAddedToLibraryAlbumsMap[print.albumObjectId];
        }
      });

      //TODO: clean selectedAlbumPrintsObjectIdsMap, that are supposed to be obsolete
      const selectedAlbumPrintsObjectIdsMap = { ...state.selectedAlbumPrintsObjectIdsMap };
      prints.forEach((print) => {
        selectedAlbumPrintsObjectIdsMap[print.albumObjectId] = (
          selectedAlbumPrintsObjectIdsMap[print.albumObjectId] || []
        ).filter((el) => el !== print.printObjectId);
      });

      const arrays = updateArrays(
        state,
        temporaryAddedToLibraryAlbumsMap,
        temporaryRemovedFromLibraryAlbumsMap,
      );

      return {
        ...state,
        ownedAlbumsIdsMap: ownedAlbumsIdsMap,
        ownedPrintsIdsMap: ownedPrintsIdsMap,
        selectedAlbumPrintsObjectIdsMap,
        temporaryAddedToLibraryAlbumsMap: temporaryAddedToLibraryAlbumsMap,
        temporaryRemovedFromLibraryAlbumsMap: temporaryRemovedFromLibraryAlbumsMap,
        temporaryAddedToLibraryPrintsMap: temporaryAddedToLibraryPrintsMap,
        temporaryRemovedFromLibraryPrintsMap: temporaryRemovedFromLibraryPrintsMap,
        myCompletionSeries: arrays[0],
        mySeries: arrays[1],
      };
    }

    case ADD_SERIE_TO_FOLLOW: {
      const serieObjectId = action.payload.serieObjectId;
      let followedSeriesIdsMap = Object.assign({}, state.followedSeriesIdsMap);
      let unfollowedSeriesIdsMap = Object.assign({}, state.unfollowedSeriesIdsMap);
      followedSeriesIdsMap[serieObjectId] = true;
      delete unfollowedSeriesIdsMap[serieObjectId];
      return {
        ...state,
        followedSeriesIdsMap: followedSeriesIdsMap,
        unfollowedSeriesIdsMap: unfollowedSeriesIdsMap,
        loading: { ...state.loading, [ADD_SERIE_TO_FOLLOW]: true },
      };
    }
    case ADD_SERIE_TO_FOLLOW_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, [ADD_SERIE_TO_FOLLOW]: false },
      };
    }
    case ADD_SERIE_TO_FOLLOW_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [ADD_SERIE_TO_FOLLOW]: false },
      };
    }

    case LOAD_USER_STATS: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_USER_STATS]: true },
      };
    }
    case LOAD_USER_STATS_SUCCESS: {
      const stats = action.payload.stats;
      if (!action.payload.isGuest) {
        return {
          ...state,
          stats: stats,
        };
      } else {
        const otherProfiles = state.otherProfiles;
        otherProfiles[action.payload.libraryObjectId] =
          otherProfiles[action.payload.libraryObjectId] || Object.assign({}, initialProfile);
        const otherProfile = otherProfiles[action.payload.libraryObjectId];
        otherProfile.stats = stats;
        return {
          ...state,
          otherProfiles,
          loading: { ...state.loading, [LOAD_USER_STATS]: false },
        };
      }
    }
    case LOAD_USER_STATS_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_USER_STATS]: false },
      };
    }

    case REMOVE_SERIE_FROM_FOLLOW: {
      const serieObjectId = action.payload.serieObjectId;
      let followedSeriesIdsMap = Object.assign({}, state.followedSeriesIdsMap);
      let unfollowedSeriesIdsMap = Object.assign({}, state.unfollowedSeriesIdsMap);
      delete followedSeriesIdsMap[serieObjectId];
      unfollowedSeriesIdsMap[serieObjectId] = true;
      return {
        ...state,
        followedSeriesIdsMap: followedSeriesIdsMap,
        unfollowedSeriesIdsMap: unfollowedSeriesIdsMap,
        loading: { ...state.loading, [REMOVE_SERIE_FROM_FOLLOW]: true },
      };
    }
    case REMOVE_SERIE_FROM_FOLLOW_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, [REMOVE_SERIE_FROM_FOLLOW]: false },
      };
    }
    case REMOVE_SERIE_FROM_FOLLOW_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [REMOVE_SERIE_FROM_FOLLOW]: false },
      };
    }

    case SET_SELECTED_ALBUM_PRINT: {
      const selectedAlbumPrintObjectIdsMap = { ...state.selectedAlbumPrintObjectIdsMap };
      selectedAlbumPrintObjectIdsMap[action.payload.albumObjectId] = action.payload.printObjectId;
      return {
        ...state,
        selectedAlbumPrintObjectIdsMap,
        loading: { ...state.loading, [SET_SELECTED_ALBUM_PRINT]: true },
      };
    }
    case SET_SELECTED_ALBUM_PRINT_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, [SET_SELECTED_ALBUM_PRINT]: false },
      };
    }
    case SET_SELECTED_ALBUM_PRINT_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [SET_SELECTED_ALBUM_PRINT]: false },
      };
    }

    case LOAD_USER_AGENDA:
      return {
        ...state,
        pastMyAgendaLoaded: false,
        loading: { ...state.loading, [LOAD_USER_AGENDA]: true },
      };
    case LOAD_USER_AGENDA_SUCCESS: {
      const myAgenda = action.payload.myAgenda;
      if (!action.payload.isGuest) {
        return {
          ...state,
          pastMyAgendaLoaded: !!action.payload.months,
          myAgenda,
          loading: { ...state.loading, [LOAD_USER_AGENDA]: false },
        };
      } else {
        const otherProfiles = state.otherProfiles;
        otherProfiles[action.payload.libraryObjectId] =
          otherProfiles[action.payload.libraryObjectId] || Object.assign({}, initialProfile);
        const otherProfile = otherProfiles[action.payload.libraryObjectId];
        otherProfile.myAgenda = myAgenda;
        return {
          ...state,
          otherProfiles,
          loading: { ...state.loading, [LOAD_USER_AGENDA]: false },
        };
      }
    }
    case LOAD_USER_AGENDA_FAIL:
      return {
        ...state,
        loading: { ...state.loading, [LOAD_USER_AGENDA]: false },
      };

    case LOAD_WISHLIST_ALBUMS: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_WISHLIST_ALBUMS]: true },
      };
    }
    case LOAD_WISHLIST_ALBUMS_SUCCESS: {
      let wishlistAlbumsIdsMap = {};
      let wishlistPrintsIdsMap = {};
      action.payload.wishlistAlbums.forEach((album) => {
        wishlistAlbumsIdsMap[album.objectId] = album.extraUserOptions.wishlist;
        (album.userPrints || []).forEach((print) => {
          wishlistPrintsIdsMap[print.objectId] = album.extraUserOptions.wishlist;
        });
      });

      if (!action.payload.isGuest) {
        return {
          ...state,
          wishlistAlbumsIdsMap,
          wishlistPrintsIdsMap,
          loading: { ...state.loading, [LOAD_WISHLIST_ALBUMS]: false },
        };
      } else {
        const otherProfiles = state.otherProfiles;
        otherProfiles[action.payload.libraryObjectId] =
          otherProfiles[action.payload.libraryObjectId] || Object.assign({}, initialProfile);
        const otherProfile = otherProfiles[action.payload.libraryObjectId];
        otherProfile.wishlistAlbumsIdsMap = wishlistAlbumsIdsMap;
        otherProfile.wishlistPrintsIdsMap = wishlistPrintsIdsMap;
        return {
          ...state,
          otherProfiles,
          loading: { ...state.loading, [LOAD_WISHLIST_ALBUMS]: false },
        };
      }
    }
    case LOAD_WISHLIST_ALBUMS_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_WISHLIST_ALBUMS]: false },
      };
    }

    case ADD_PRINTS_TO_WISHLIST: {
      const { prints } = action.payload;
      const wishlistAlbumsIdsMap = { ...state.wishlistAlbumsIdsMap };
      const wishlistPrintsIdsMap = { ...state.wishlistPrintsIdsMap };

      prints.forEach((print) => {
        wishlistAlbumsIdsMap[print.albumObjectId] = print.isWishlist;
        wishlistPrintsIdsMap[print.printObjectId] = print.isWishlist;
      });

      return {
        ...state,
        wishlistAlbumsIdsMap,
        wishlistPrintsIdsMap,
      };
    }
    case REMOVE_PRINTS_FROM_WISHLIST: {
      const { prints } = action.payload;
      const wishlistAlbumsIdsMap = { ...state.wishlistAlbumsIdsMap };
      const wishlistPrintsIdsMap = { ...state.wishlistPrintsIdsMap };

      prints.forEach((print) => {
        // For collection batch actions
        if (prints.length > 1) delete wishlistAlbumsIdsMap[print.albumObjectId];
        delete wishlistPrintsIdsMap[print.objectId];
      });

      return {
        ...state,
        wishlistAlbumsIdsMap,
        wishlistPrintsIdsMap,
      };
    }
    case ADD_PRINT_LOAN: {
      const { prints } = action.payload;
      const loanAlbumsIdsMap = { ...state.loanAlbumsIdsMap };
      const loanPrintsIdsMap = { ...state.loanPrintsIdsMap };

      prints.forEach((print) => {
        loanAlbumsIdsMap[print.albumObjectId] = print.loan;
        loanPrintsIdsMap[print.printObjectId] = print.loan;
      });

      return {
        ...state,
        loanAlbumsIdsMap,
        loanPrintsIdsMap,
      };
    }
    case REMOVE_PRINT_LOAN: {
      const { prints, isBatch } = action.payload;
      const loanAlbumsIdsMap = { ...state.loanAlbumsIdsMap };
      const loanPrintsIdsMap = { ...state.loanPrintsIdsMap };

      prints.forEach((print) => {
        // For collection batch actions
        if (isBatch || prints.length) delete loanAlbumsIdsMap[print.albumObjectId];
        delete loanPrintsIdsMap[print.printObjectId];
      });

      return {
        ...state,
        loanAlbumsIdsMap,
        loanPrintsIdsMap,
      };
    }

    case LOAD_RECOMMENDATION: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_RECOMMENDATION]: true },
      };
    }
    case LOAD_RECOMMENDATION_SUCCESS: {
      return {
        ...state,
        recommendedAlbumsIdsMap: action.payload.albums.reduce((acc, cur) => {
          acc[cur.objectId] = true;
          return acc;
        }, {}),
        loading: { ...state.loading, [LOAD_RECOMMENDATION]: false },
      };
    }
    case LOAD_RECOMMENDATION_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_RECOMMENDATION]: false },
      };
    }

    case RESET_PROFILES_REDUCER: {
      return { ...initialState };
    }

    case UPDATE_MEMO_PRINTS: {
      const { prints } = action.payload;
      const memoPrintsIdsMap = { ...state.memoPrintsIdsMap };
      prints.forEach((print) => {
        memoPrintsIdsMap[print.printObjectId] = print.memo;
      });

      return {
        ...state,
        memoPrintsIdsMap,
      };
    }

    case UPDATE_LIBRARY: {
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_LIBRARY]: true },
      };
    }
    case UPDATE_LIBRARY_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_LIBRARY]: false },
      };
    }
    case UPDATE_LIBRARY_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [UPDATE_LIBRARY]: false },
      };
    }

    case ADD_AUTHOR_TO_FOLLOW:
      return {
        ...state,
        loading: { ...state.loading, [ADD_AUTHOR_TO_FOLLOW]: true },
      };
    case ADD_AUTHOR_TO_FOLLOW_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [ADD_AUTHOR_TO_FOLLOW]: false },
      };
    case ADD_AUTHOR_TO_FOLLOW_FAIL:
      return {
        ...state,
        loading: { ...state.loading, [ADD_AUTHOR_TO_FOLLOW]: false },
      };

    case REMOVE_AUTHOR_FROM_FOLLOW: {
      return {
        ...state,
        followedAuthorsIdsMap: {
          ...state.followedAuthorsIdsMap,
          [action.payload.authorObjectId]: false,
        },
        loading: { ...state.loading, [REMOVE_AUTHOR_FROM_FOLLOW]: true },
      };
    }
    case REMOVE_AUTHOR_FROM_FOLLOW_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [REMOVE_AUTHOR_FROM_FOLLOW]: false },
      };
    case REMOVE_AUTHOR_FROM_FOLLOW_FAIL:
      return {
        ...state,
        loading: { ...state.loading, [REMOVE_AUTHOR_FROM_FOLLOW]: false },
      };

    case LOAD_LIBRARY_AUTHOR_FOLLOW: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_LIBRARY_AUTHOR_FOLLOW]: true },
      };
    }
    case LOAD_LIBRARY_AUTHOR_FOLLOW_SUCCESS: {
      const followedAuthorsIdsMap = (action.payload.authors || []).reduce((acc, cur) => {
        acc[cur.objectId] = true;
        return acc;
      }, {});

      return {
        ...state,
        followedAuthorsIdsMap,
        loading: { ...state.loading, [LOAD_LIBRARY_AUTHOR_FOLLOW]: false },
      };
    }
    case LOAD_LIBRARY_AUTHOR_FOLLOW_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_LIBRARY_AUTHOR_FOLLOW]: false },
      };
    }

    default: {
      return state;
    }
  }
}

const defaultObject = {};
const getIdsMapFromBinaryFlags = (maps, albumObjectId, printObjectId, printOptions) => {
  const flags = printOptions?.optionsBinaryFlags || printOptions;
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.OWNED)) {
    if (!maps.temporaryRemovedFromLibraryPrintsMap[printObjectId]) {
      maps.ownedPrintsIdsMap[printObjectId] = true;
      maps.ownedAlbumsIdsMap[albumObjectId] = (maps.ownedAlbumsIdsMap[albumObjectId] || [])
        .concat([printObjectId])
        .filter((item, pos, self) => self.indexOf(item) == pos)
        .sort((a, b) => a > b);
      // we should use what above instead of the array for each key
      // maps.ownedAlbumsIdsMap[albumObjectId] = true;
    }
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.READ)) {
    maps.readPrintsIdsMap[printObjectId] = true;
    maps.readAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.ORIGINAL_EDITION)) {
    maps.originalEditionsPrintsIdsMap[printObjectId] = true;
    maps.originalEditionsAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.SIGNED)) {
    maps.signedPrintsIdsMap[printObjectId] = printOptions?.autographImagePath || true;
    maps.signedAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.WISHLIST)) {
    maps.wishlistPrintsIdsMap[printObjectId] = printOptions?.wishlist || defaultObject;
    maps.wishlistAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.DELUXE)) {
    maps.deluxePrintsIdsMap[printObjectId] = printOptions?.deluxeImagePath || true;
    maps.deluxeAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.DIGITAL)) {
    maps.digitalPrintsIdsMap[printObjectId] = true;
    maps.digitalAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.EXLIBRIS)) {
    maps.exlibrisPrintsIdsMap[printObjectId] = printOptions?.exLibrisImagePath || true;
    maps.exlibrisAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.LIMITED)) {
    maps.limitedPrintsIdsMap[printObjectId] = true;
    maps.limitedAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.LOAN)) {
    maps.loanPrintsIdsMap[printObjectId] = printOptions?.loan || defaultObject;
    maps.loanAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.FOR_SALE)) {
    maps.forSalePrintsIdsMap[printObjectId] = true;
    maps.forSaleAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.MEMO)) {
    maps.memoPrintsIdsMap[printObjectId] = printOptions?.memo || true;
  }
  if (printOptions?.buyPrice) {
    maps.buyPricePrintsIdsMap[printObjectId] = printOptions?.buyPrice || null;
  }
  if (printOptions?.resellValue) {
    maps.resellValuePrintsIdsMap[printObjectId] = printOptions?.resellValue || null;
  }
  if (printOptions?.condition) {
    maps.conditionPrintsIdsMap[printObjectId] = printOptions?.condition || null;
  }
  if (printOptions?.numbering) {
    maps.numberingPrintsIdsMap[printObjectId] = printOptions?.numbering || null;
  }
  return maps;
};

// resetProfilesReducer ====================================================

export function resetProfilesReducer() {
  return {
    type: RESET_PROFILES_REDUCER,
  };
}

// loadLibrary ====================================================

export function loadLibrary(libraryObjectId, isGuest = false) {
  return {
    type: LOAD_LIBRARY,
    payload: { libraryObjectId, isGuest },
  };
}
export function loadLibrarySuccess(libraryObjectId, library, isGuest) {
  return {
    type: LOAD_LIBRARY_SUCCESS,
    payload: {
      libraryObjectId,
      library,
      isGuest,
    },
  };
}
export function loadLibraryFail(error) {
  return {
    type: LOAD_LIBRARY_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadLibraryPrintsOptions ====================================================

export function loadLibraryPrintsOptions(libraryObjectId, isGuest = false) {
  return {
    type: LOAD_LIBRARY_PRINTS_OPTIONS,
    payload: { libraryObjectId, isGuest },
  };
}
export function loadLibraryPrintsOptionsSuccess(libraryObjectId, libraryPrintsOptions, isGuest) {
  return {
    type: LOAD_LIBRARY_PRINTS_OPTIONS_SUCCESS,
    payload: {
      libraryObjectId,
      libraryPrintsOptions,
      isGuest,
    },
  };
}
export function loadLibraryPrintsOptionsFail(error) {
  return {
    type: LOAD_LIBRARY_PRINTS_OPTIONS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadRecommendation ====================================================

export function loadRecommendation(libraryObjectId) {
  return {
    type: LOAD_RECOMMENDATION,
    payload: { libraryObjectId },
  };
}
export function loadRecommendationSuccess(albums) {
  return {
    type: LOAD_RECOMMENDATION_SUCCESS,
    payload: {
      albums,
    },
  };
}
export function loadRecommendationFail(error) {
  return {
    type: LOAD_RECOMMENDATION_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// setReadFlagOnPrints ====================================================

export function setReadFlagOnPrints(userObjectId, prints, isBatch = false) {
  return {
    type: SET_READ_FLAG_ON_PRINTS,
    payload: { userObjectId, prints, isBatch },
  };
}

// setEoFlagOnPrints ====================================================

export function setEoFlagOnPrints(userObjectId, prints, isBatch = false) {
  return {
    type: SET_EO_FLAG_ON_PRINTS,
    payload: { userObjectId, prints, isBatch },
  };
}

// setSignedFlagOnPrints ====================================================

export function setSignedFlagOnPrints(userObjectId, prints, isBatch = false) {
  return {
    type: SET_SIGNED_FLAG_ON_PRINTS,
    payload: { userObjectId, prints, isBatch },
  };
}

// setDeluxeFlagOnPrints ====================================================

export function setDeluxeFlagOnPrints(userObjectId, prints, isBatch = false) {
  return {
    type: SET_DELUXE_FLAG_ON_PRINTS,
    payload: { userObjectId, prints, isBatch },
  };
}

// setLimitedFlagOnPrints ====================================================

export function setLimitedFlagOnPrints(userObjectId, prints, isBatch = false) {
  return {
    type: SET_LIMITED_FLAG_ON_PRINTS,
    payload: { userObjectId, prints, isBatch },
  };
}

// setExlibrisFlagOnPrints ====================================================

export function setExlibrisFlagOnPrints(userObjectId, prints, isBatch = false) {
  return {
    type: SET_EXLIBRIS_FLAG_ON_PRINTS,
    payload: { userObjectId, prints, isBatch },
  };
}

// setDigitalFlagOnPrints ====================================================

export function setDigitalFlagOnPrints(userObjectId, prints, isBatch = false) {
  return {
    type: SET_DIGITAL_FLAG_ON_PRINTS,
    payload: { userObjectId, prints, isBatch },
  };
}

// setForSaleFlagOnPrints ====================================================

export function setForSaleFlagOnPrints(userObjectId, prints, isBatch = false) {
  return {
    type: SET_FOR_SALE_FLAG_ON_PRINTS,
    payload: { userObjectId, prints, isBatch },
  };
}

// setNumberingFlagOnPrints ====================================================

export function setNumberingFlagOnPrints(userObjectId, prints, isBatch = false) {
  return {
    type: SET_NUMBERING_FLAG_ON_PRINTS,
    payload: { userObjectId, prints, isBatch },
  };
}

// setBuyPriceFlagOnPrints ====================================================

export function setBuyPriceFlagOnPrints(userObjectId, prints, isBatch = false) {
  return {
    type: SET_BUY_PRICE_FLAG_ON_PRINTS,
    payload: { userObjectId, prints, isBatch },
  };
}
// setResellValueFlagOnPrints ====================================================

export function setResellValueFlagOnPrints(userObjectId, prints, isBatch = false) {
  return {
    type: SET_RESELL_VALUE_FLAG_ON_PRINTS,
    payload: { userObjectId, prints, isBatch },
  };
}
// setConditionFlagOnPrints ====================================================

export function setConditionFlagOnPrints(userObjectId, prints, isBatch = false) {
  return {
    type: SET_CONDITION_FLAG_ON_PRINTS,
    payload: { userObjectId, prints, isBatch },
  };
}

// loadSignedAlbums ====================================================

export function loadSignedAlbums(libraryObjectId, isGuest) {
  return {
    type: LOAD_SIGNED_ALBUMS,
    payload: { libraryObjectId, isGuest },
  };
}
export function loadSignedAlbumsSuccess(libraryObjectId, albums, isGuest) {
  return {
    type: LOAD_SIGNED_ALBUMS_SUCCESS,
    payload: { libraryObjectId, albums, isGuest },
  };
}
export function loadSignedAlbumsFail(error) {
  return {
    type: LOAD_SIGNED_ALBUMS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadUserStats ====================================================

export function loadUserStats(libraryObjectId, isGuest) {
  return {
    type: LOAD_USER_STATS,
    payload: { libraryObjectId, isGuest },
  };
}
export function loadUserStatsSuccess(libraryObjectId, stats, isGuest) {
  return {
    type: LOAD_USER_STATS_SUCCESS,
    payload: {
      libraryObjectId,
      stats,
      isGuest,
    },
  };
}
export function loadUserStatsFail(error) {
  return {
    type: LOAD_USER_STATS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadFollowed ====================================================

export function loadFollowed(libraryObjectId, isGuest) {
  return {
    type: LOAD_FOLLOWED,
    payload: { libraryObjectId, isGuest },
  };
}
export function loadFollowedSuccess(libraryObjectId, followed, isGuest) {
  return {
    type: LOAD_FOLLOWED_SUCCESS,
    payload: {
      libraryObjectId,
      followed,
      isGuest,
    },
  };
}
export function loadFollowedFail(error) {
  return {
    type: LOAD_FOLLOWED_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// addPrintsToLibrary ====================================================

export function addPrintsToLibrary(userObjectId, prints) {
  return {
    type: ADD_PRINTS_TO_LIBRARY,
    payload: {
      userObjectId,
      prints,
    },
  };
}

// removePrintsFromLibrary ====================================================

export function removePrintsFromLibrary(userObjectId, prints, isBatch = false) {
  return {
    type: REMOVE_PRINTS_FROM_LIBRARY,
    payload: {
      userObjectId,
      prints,
      isBatch,
    },
  };
}

// addSerieToFollow ====================================================

export function addSerieToFollow(libraryObjectId, serieObjectId) {
  return {
    type: ADD_SERIE_TO_FOLLOW,
    payload: {
      libraryObjectId,
      serieObjectId,
    },
  };
}
export function addSerieToFollowSuccess() {
  return {
    type: ADD_SERIE_TO_FOLLOW_SUCCESS,
  };
}
export function addSerieToFollowFail(error) {
  return {
    type: ADD_SERIE_TO_FOLLOW_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// removeSerieFromFollow ====================================================

export function removeSerieFromFollow(libraryObjectId, serieObjectId) {
  return {
    type: REMOVE_SERIE_FROM_FOLLOW,
    payload: {
      libraryObjectId,
      serieObjectId,
    },
  };
}
export function removeSerieFromFollowSuccess() {
  return {
    type: REMOVE_SERIE_FROM_FOLLOW_SUCCESS,
  };
}
export function removeSerieFromFollowFail(error) {
  return {
    type: REMOVE_SERIE_FROM_FOLLOW_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadUserAgenda ====================================================

export function loadUserAgenda(libraryObjectId, isGuest, months = null) {
  return {
    type: LOAD_USER_AGENDA,
    payload: {
      libraryObjectId,
      isGuest,
      months,
    },
  };
}
export function loadUserAgendaSuccess(libraryObjectId, myAgenda, isGuest) {
  return {
    type: LOAD_USER_AGENDA_SUCCESS,
    payload: {
      libraryObjectId,
      myAgenda,
      isGuest,
    },
  };
}
export function loadUserAgendaFail(error) {
  return {
    type: LOAD_USER_AGENDA_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// addPrintLoan ====================================================

export function addPrintLoan(userObjectId, print) {
  return {
    type: ADD_PRINT_LOAN,
    payload: {
      userObjectId,
      prints: [print],
    },
  };
}

// removePrintLoan ====================================================

export function removePrintLoan(userObjectId, prints, isBatch = false) {
  return {
    type: REMOVE_PRINT_LOAN,
    payload: {
      userObjectId,
      prints: prints,
      isBatch,
    },
  };
}

// loadLoanedAlbums ====================================================

export function loadLoanedAlbums(libraryObjectId, isGuest) {
  return {
    type: LOAD_LOANED_ALBUMS,
    payload: {
      libraryObjectId,
      isGuest,
    },
  };
}
export function loadLoanedAlbumsSuccess(libraryObjectId, loanedAlbums, isGuest) {
  return {
    type: LOAD_LOANED_ALBUMS_SUCCESS,
    payload: {
      libraryObjectId,
      loanedAlbums,
      isGuest,
    },
  };
}
export function loadLoanedAlbumsFail(error) {
  return {
    type: LOAD_LOANED_ALBUMS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadWishlistAlbums ====================================================

export function loadWishlistAlbums(libraryObjectId, isGuest) {
  return {
    type: LOAD_WISHLIST_ALBUMS,
    payload: {
      libraryObjectId,
      isGuest,
    },
  };
}
export function loadWishlistAlbumsSuccess(libraryObjectId, wishlistAlbums, isGuest) {
  return {
    type: LOAD_WISHLIST_ALBUMS_SUCCESS,
    payload: {
      libraryObjectId,
      wishlistAlbums,
      isGuest,
    },
  };
}
export function loadWishlistAlbumsFail(error) {
  return {
    type: LOAD_WISHLIST_ALBUMS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// addPrintsToWishlist ====================================================

export function addPrintsToWishlist(userObjectId, prints) {
  return {
    type: ADD_PRINTS_TO_WISHLIST,
    payload: {
      userObjectId,
      prints,
    },
  };
}

// removePrintsFromWishlist ====================================================

export function removePrintsFromWishlist(userObjectId, prints) {
  return {
    type: REMOVE_PRINTS_FROM_WISHLIST,
    payload: {
      userObjectId,
      prints,
    },
  };
}

// updateWishlistPrints ====================================================

export function updateWishlistPrints(libraryObjectId, printIds, wishlistObject) {
  return {
    type: UPDATE_WISHLIST_PRINTS,
    payload: {
      libraryObjectId,
      printIds,
      wishlistObject,
    },
  };
}
export function updateWishlistPrintsSuccess(libraryObjectId) {
  return {
    type: UPDATE_WISHLIST_PRINTS_SUCCESS,
    payload: {
      libraryObjectId,
    },
  };
}
export function updateWishlistPrintsFail(error) {
  return {
    type: UPDATE_WISHLIST_PRINTS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// setSelectedAlbumPrint ====================================================

export function setSelectedAlbumPrint(libraryObjectId, albumObjectId, printObjectId) {
  return {
    type: SET_SELECTED_ALBUM_PRINT,
    payload: {
      libraryObjectId,
      albumObjectId,
      printObjectId,
    },
  };
}
export function setSelectedAlbumPrintSuccess() {
  return {
    type: SET_SELECTED_ALBUM_PRINT_SUCCESS,
    payload: {},
  };
}
export function setSelectedAlbumPrintFail(error) {
  return {
    type: SET_SELECTED_ALBUM_PRINT_FAIL,
  };
}
// updateMemoPrint ====================================================

export function updateMemoPrints(userObjectId, prints) {
  return {
    type: UPDATE_MEMO_PRINTS,
    payload: {
      userObjectId,
      prints,
    },
  };
}

// loadSelectedAlbumPrints ====================================================

export function loadSelectedAlbumPrints(libraryObjectId) {
  return {
    type: LOAD_SELECTED_ALBUM_PRINTS,
    payload: {
      libraryObjectId,
    },
  };
}
export function loadSelectedAlbumPrintsSuccess(selectedPrints) {
  return {
    type: LOAD_SELECTED_ALBUM_PRINTS_SUCCESS,
    payload: { selectedPrints },
  };
}
export function loadSelectedAlbumPrintsFail(error) {
  return {
    type: LOAD_SELECTED_ALBUM_PRINTS_FAIL,
  };
}

// updateLibrary ==========================================

export function updateLibrary(userObjectId, prints) {
  return {
    type: UPDATE_LIBRARY,
    payload: { userObjectId, prints },
  };
}
export function updateLibrarySuccess(payload) {
  return {
    type: UPDATE_LIBRARY_SUCCESS,
    payload,
  };
}
export function updateLibraryFail(error) {
  return {
    type: UPDATE_LIBRARY_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// addAuthorToFollow ====================================================

export function addAuthorToFollow(userObjectId, authorObjectId) {
  return {
    type: ADD_AUTHOR_TO_FOLLOW,
    payload: {
      userObjectId,
      authorObjectId,
    },
  };
}
export function addAuthorToFollowSuccess() {
  return {
    type: ADD_AUTHOR_TO_FOLLOW_SUCCESS,
  };
}
export function addAuthorToFollowFail(error) {
  return {
    type: ADD_AUTHOR_TO_FOLLOW_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// removeAuthorFromFollow ====================================================

export function removeAuthorFromFollow(userObjectId, authorObjectId) {
  return {
    type: REMOVE_AUTHOR_FROM_FOLLOW,
    payload: {
      userObjectId,
      authorObjectId,
    },
  };
}
export function removeAuthorFromFollowSuccess() {
  return {
    type: REMOVE_AUTHOR_FROM_FOLLOW_SUCCESS,
  };
}
export function removeAuthorFromFollowFail(error) {
  return {
    type: REMOVE_AUTHOR_FROM_FOLLOW_FAIL,
    payload: new Error(error),
  };
}

// loadLibraryAuthorFollow ====================================================

export function loadLibraryAuthorFollow(userObjectId) {
  return {
    type: LOAD_LIBRARY_AUTHOR_FOLLOW,
    payload: {
      userObjectId,
    },
  };
}
export function loadLibraryAuthorFollowSuccess(authors) {
  return {
    type: LOAD_LIBRARY_AUTHOR_FOLLOW_SUCCESS,
    payload: { authors },
  };
}
export function loadLibraryAuthorFollowFail(error) {
  return {
    type: LOAD_LIBRARY_AUTHOR_FOLLOW_FAIL,
    payload: new Error(error),
  };
}

// HELPERS ================================================================================

const createdTitleIndexed = (serie) => {
  // add a default serie title because we must have one,
  // with '###' to get it at the top of the ordered list
  var titleIndexed = serie.title ? serie.title.toLowerCase() : '###';
  var twoFirst = titleIndexed.slice(0, 2);
  if (twoFirst === "l'") {
    titleIndexed = titleIndexed.slice(2);
  }
  var threeFirst = titleIndexed.slice(0, 3);
  if (threeFirst === 'le ' || threeFirst === 'la ') {
    titleIndexed = titleIndexed.slice(3);
  }
  var fourFirst = titleIndexed.slice(0, 4);
  if (fourFirst === 'les ') {
    titleIndexed = titleIndexed.slice(4);
  }
  return titleIndexed;
};

const deepCloneLibraryArray = (libraryArray = []) => {
  return libraryArray.map((serie) => {
    try {
      let newSerie = Object.assign({}, serie);
      newSerie.serie = Object.assign({}, serie.serie);
      newSerie.albums = serie.albums.slice();

      return newSerie;
    } catch (error) {
      throw error;
    }
  });
};

const createCompletionData = (
  formattedReceivedLibrary = [],
  formattedReceivedFollowedLibrary = [],
  unfollowedSeriesIdsMap = {},
) => {
  // creating a indexed array of the follow/unfollow series
  let followedSeriesMap = {};
  // formattedReceivedFollowedLibrary only contains followed infos
  formattedReceivedFollowedLibrary.forEach((serie) => {
    followedSeriesMap[serie.serie.objectId] = { ...serie };
  });
  // formattedReceivedLibrary contains followed & unfollowed infos, but not for empty series
  formattedReceivedLibrary.forEach((serie) => {
    followedSeriesMap[serie.serie.objectId] = { ...serie };
  });

  // going through all the owned series
  const completionSeries = formattedReceivedLibrary.filter((serie) => {
    const serieObjectId = serie.serie.objectId;
    // look for the same series in the follow/unfollow series
    const followedInformations = followedSeriesMap[serieObjectId];

    // we don't have a follow/unfollow info, so it is an implicit follow
    if (!followedInformations) {
      return true;
    } else {
      // we have a follow/unfollow info
      if (unfollowedSeriesIdsMap[serieObjectId]) {
        // it is an explicit unfollow,
        // we delete the serie from the follow/unfollow series and don't add it
        delete followedSeriesMap[serieObjectId];
        return false;
      } else {
        // it is an explicit follow,
        // we delete the serie from the follow/unfollow series and do add it
        delete followedSeriesMap[serieObjectId];
        return true;
      }
    }
  });

  // we then add the remaining series from the follow/unfollow series, only if they are explicitly followed
  const remainingFollowedSeries = Object.values(followedSeriesMap).filter(
    (serie) => serie.followed,
  );

  // we concatenate the remainings series, sort them because of the concat which added series in the array
  // and then remove the already completed series
  return completionSeries
    .concat(remainingFollowedSeries)
    .sort((a, b) => a.serie.titleIndexed.localeCompare(b.serie.titleIndexed))
    .filter((serie) => serie.albumsInSerie !== serie.albumsOwned);
};

const formatAndUpdateReceivedLibraryArray = (receivedLibrary) =>
  receivedLibrary
    .map((serie) => {
      serie.serie.titleIndexed = createdTitleIndexed(serie.serie);

      // translate every object album to an objectId
      serie.albums = serie.albums.reverse().map((album) => album.objectId);
      return serie;
    })
    // order based on serie title indexed
    .sort((a, b) => a.serie.titleIndexed.localeCompare(b.serie.titleIndexed));

//TODO: isPublished should use print info
const formatAlbum = (album) => {
  const newAlbum = Object.assign({}, album, {
    isPublished: !album.publicationDate || new Date(album.publicationDate) < new Date(),
  });

  // format dates
  newAlbum.publicationDateFormattedAll = null;
  newAlbum.publicationDateFormattedShort = null;
  if (album.publicationDate && new Date(album.publicationDate)) {
    const publicationDate = new Date(album.publicationDate);
    newAlbum.publicationDateFormattedAll = formatAsFrenchUtcDoubleDigitFromIso(publicationDate);
    newAlbum.publicationDateFormattedShort = formatAsFrenchUtcDoubleDigitFromIso(
      publicationDate,
      false,
    );
  }
  return newAlbum;
};

// this is used to display good informations while loadLibrary & loadUserAgenda are delayed
const updateArrays = (
  state,
  temporaryAddedToLibraryAlbumsMap,
  temporaryRemovedFromLibraryAlbumsMap,
) => {
  const ownedAlbumsIdsMap = state.ownedAlbumsIdsMap;
  const readAlbumsIdsMap = state.readAlbumsIdsMap;

  const myCompletionSeries = createCompletionData(
    deepCloneLibraryArray(state.formattedReceivedLibrary),
    deepCloneLibraryArray(state.formattedReceivedFollowedLibrary),
    state.unfollowedSeriesIdsMap,
  )
    .map((serie) => {
      // keeping only album not in library & not tmp removed, either read or not
      serie.albums = serie.albums.filter(
        (albumId) => !ownedAlbumsIdsMap[albumId] && !temporaryAddedToLibraryAlbumsMap[albumId],
      );
      // if all albums are owned, remove serie from completionSeries
      if (!serie.albums.length) {
        return null;
      }
      return serie;
    })
    .filter((row) => row);

  let mySeries = deepCloneLibraryArray(state.formattedReceivedLibrary).map((serie) => {
    // keeping only album read or in library
    serie.albums = serie.albums.filter(
      (albumId) =>
        readAlbumsIdsMap[albumId] ||
        (!!ownedAlbumsIdsMap[albumId] && !temporaryRemovedFromLibraryAlbumsMap[albumId]),
    );
    return serie;
  });

  return [myCompletionSeries, mySeries];
};

import { buildSortedQueryString } from 'bubble-utils/src/string-utils';

export const LOAD_ALBUM = 'LOAD_ALBUM';
export const LOAD_ALBUM_SUCCESS = 'LOAD_ALBUM_SUCCESS';
export const LOAD_ALBUM_FAIL = 'LOAD_ALBUM_FAIL';

export const LOAD_ALBUM_SERIE = 'LOAD_ALBUM_SERIE';
export const LOAD_ALBUM_SERIE_SUCCESS = 'LOAD_ALBUM_SERIE_SUCCESS';
export const LOAD_ALBUM_SERIE_FAIL = 'LOAD_ALBUM_SERIE_FAIL';

export const LOAD_ALBUMS = 'LOAD_ALBUMS';
export const LOAD_ALBUMS_SUCCESS = 'LOAD_ALBUMS_SUCCESS';
export const LOAD_ALBUMS_FAIL = 'LOAD_ALBUMS_FAIL';

export const STORE_ALBUMS = 'STORE_ALBUMS';

export const LOAD_XMAS_SELECTION = 'LOAD_XMAS_SELECTION';
export const LOAD_XMAS_SELECTION_SUCCESS = 'LOAD_XMAS_SELECTION_SUCCESS';
export const LOAD_XMAS_SELECTION_FAIL = 'LOAD_XMAS_SELECTION_FAIL';

export const UPDATE_TAGS_ON_ALBUM = 'UPDATE_TAGS_ON_ALBUM';
export const UPDATE_TAGS_ON_ALBUM_SUCCESS = 'UPDATE_TAGS_ON_ALBUM_SUCCESS';
export const UPDATE_TAGS_ON_ALBUM_FAIL = 'UPDATE_TAGS_ON_ALBUM_FAIL';

export const RESET_ALBUM_REDUCER = 'RESET_ALBUM_REDUCER';

const initialState = {
  mapOfAlbumIdsLastPublished: {}, //TODO: unnecessary, use albumObjectIdsMapByParams
  mapOfAlbumIdsBestSellers: {}, //TODO: unnecessary, use albumObjectIdsMapByParams
  albumObjectIdsMapByParams: {},
  albums: {},
  // this is necessary because some routes don't send all the prints, thus erasing the ones in the 'albums' map
  albumPrintsObjectIds: {},
  loading: {},
  status: null,
  errors: [],

  // xmas selection tmp
  xmasSelectionAlbumObjectIdsMapByParams: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALBUM: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ALBUM]: true },
      };
    }

    case LOAD_ALBUM_SUCCESS: {
      // the album is actually stored from saga with storeAlbums
      return state;
    }
    case LOAD_ALBUM_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ALBUM]: false },
      };
    }

    case LOAD_ALBUM_SERIE: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ALBUM_SERIE]: true },
      };
    }

    case LOAD_ALBUM_SERIE_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ALBUM_SERIE]: false },
      };
    }
    case LOAD_ALBUM_SERIE_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ALBUM_SERIE]: false },
      };
    }

    case LOAD_ALBUMS: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ALBUMS]: true },
      };
    }
    case LOAD_ALBUMS_SUCCESS: {
      const params = action.payload.params;
      const paramsString = buildSortedQueryString(params);
      const albumObjectIds = action.payload.albums.map((album) => album.objectId);

      let mapOfAlbumIdsLastPublished = state.mapOfAlbumIdsLastPublished;
      let mapOfAlbumIdsBestSellers = state.mapOfAlbumIdsBestSellers;
      const key = (params.category || '') + (params.genre || '') + (params.tags || '') || 'all';
      if (params && params.last_published) {
        mapOfAlbumIdsLastPublished = Object.assign({}, mapOfAlbumIdsLastPublished);
        mapOfAlbumIdsLastPublished[key] = albumObjectIds;
      }
      if (params && params.best_sellers) {
        mapOfAlbumIdsBestSellers = Object.assign({}, mapOfAlbumIdsBestSellers);
        mapOfAlbumIdsBestSellers[key] = albumObjectIds;
      }

      const albumObjectIdsMapByParams = { ...state.albumObjectIdsMapByParams };
      // as of 19/05/2022: albums list route is not paginated, fake it
      albumObjectIdsMapByParams[paramsString] = {
        total: albumObjectIds.length,
        perPage: albumObjectIds.length,
        totalPages: 1,
        page: 1,
        query: paramsString,
        albums: albumObjectIds,
      };

      return {
        ...state,
        mapOfAlbumIdsLastPublished,
        mapOfAlbumIdsBestSellers,
        albumObjectIdsMapByParams,
        loading: { ...state.loading, [LOAD_ALBUMS]: false },
        errors: [],
      };
    }
    case LOAD_ALBUMS_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ALBUMS]: false },
      };
    }

    case STORE_ALBUMS: {
      const albumPrintsObjectIds = { ...state.albumPrintsObjectIds };
      const albums = (action.payload.albums || []).reduce(
        (map, album) => {
          map[album.objectId] = album;
          albumPrintsObjectIds[album.objectId] = new Set(
            albumPrintsObjectIds[album.objectId] || [],
          );
          album.prints.forEach((print) => albumPrintsObjectIds[album.objectId].add(print));
          albumPrintsObjectIds[album.objectId] = Array.from(albumPrintsObjectIds[album.objectId]);
          album.prints = albumPrintsObjectIds[album.objectId];
          return map;
        },
        Object.assign({}, state.albums),
      );
      return {
        ...state,
        loading: { ...state.loading, [LOAD_ALBUMS]: false, [LOAD_ALBUM]: false },
        albums,
        albumPrintsObjectIds,
        errors: [],
      };
    }

    case LOAD_XMAS_SELECTION: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_XMAS_SELECTION]: true },
      };
    }
    case LOAD_XMAS_SELECTION_SUCCESS: {
      return {
        ...state,
        xmasSelectionAlbumObjectIdsMapByParams: {
          ...state.xmasSelectionAlbumObjectIdsMapByParams,
          [buildSortedQueryString(action.payload.params)]: action.payload.albums.map(
            (album) => album.objectId,
          ),
        },
        loading: { ...state.loading, [LOAD_XMAS_SELECTION]: false },
      };
    }
    case LOAD_XMAS_SELECTION_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, [LOAD_XMAS_SELECTION]: false },
      };
    }

    case RESET_ALBUM_REDUCER: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}

// loadAlbum ====================================================

export function loadAlbum(albumObjectId) {
  return {
    type: LOAD_ALBUM,
    payload: {
      albumObjectId,
    },
  };
}
export function loadAlbumSuccess(album) {
  return {
    type: LOAD_ALBUM_SUCCESS,
    payload: {
      album,
    },
  };
}
export function loadAlbumFail(error) {
  return {
    type: LOAD_ALBUM_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadAlbumSerie ====================================================

export function loadAlbumSerie(albumObjectId) {
  return {
    type: LOAD_ALBUM_SERIE,
    payload: {
      albumObjectId: albumObjectId,
    },
  };
}
export function loadAlbumSerieSuccess() {
  return {
    type: LOAD_ALBUM_SERIE_SUCCESS,
  };
}
export function loadAlbumSerieFail(error) {
  return {
    type: LOAD_ALBUM_SERIE_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadAlbums ====================================================

export function loadAlbums(params) {
  return {
    type: LOAD_ALBUMS,
    payload: {
      params,
    },
  };
}
export function loadAlbumsSuccess(albums, params) {
  return {
    type: LOAD_ALBUMS_SUCCESS,
    payload: {
      albums,
      params,
    },
  };
}
export function loadAlbumsFail(error) {
  return {
    type: LOAD_ALBUMS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// storeAlbum ====================================================

export function storeAlbums(albums, source) {
  return {
    type: STORE_ALBUMS,
    payload: {
      albums,
      source,
    },
  };
}

// loadXmasSelection ====================================================

export function loadXmasSelection(params) {
  return {
    type: LOAD_XMAS_SELECTION,
    payload: {
      params,
    },
  };
}
export function loadXmasSelectionSuccess(albums, params) {
  return {
    type: LOAD_XMAS_SELECTION_SUCCESS,
    payload: { albums, params },
  };
}
export function loadXmasSelectionFail(error) {
  return {
    type: LOAD_XMAS_SELECTION_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// updateAlbumTags ====================================================

export function updateAlbumTags(userObjectId, albumObjectId, tags, forcedWeight) {
  return {
    type: UPDATE_TAGS_ON_ALBUM,
    payload: {
      userObjectId,
      albumObjectId,
      tags,
      forcedWeight,
    },
  };
}
export function updateAlbumTagsSuccess(album) {
  return {
    type: UPDATE_TAGS_ON_ALBUM_SUCCESS,
    payload: {
      album,
    },
  };
}
export function updateAlbumTagsFail(error) {
  return {
    type: UPDATE_TAGS_ON_ALBUM_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// resetAlbumReducer ====================================================

export function resetAlbumReducer() {
  return {
    type: RESET_ALBUM_REDUCER,
  };
}

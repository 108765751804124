import React from 'react';

import imageUtils from '@/services/image-utils';

import BubbleCheckbox from '@/components/BubbleCheckbox/BubbleCheckbox';

import './InfoModalRow.scss';

export default function InfoModalRow({ img, text, value, rightComponent }) {
  return (
    <div className="p-2 bg-white d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div className="info-modal-row-icon">
          <img
            className="info-modal-row-icon-image"
            alt="edition originale"
            src={imageUtils.getAssetImage(img)}
          />
        </div>
        <div>{text}</div>
      </div>
      <div>{rightComponent || <BubbleCheckbox disabled readOnly checked={value || false} />}</div>
    </div>
  );
}

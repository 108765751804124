export const LOAD_USER_TAGS = 'LOAD_USER_TAGS';
export const LOAD_USER_TAGS_SUCCESS = 'LOAD_USER_TAGS_SUCCESS';
export const LOAD_USER_TAGS_FAIL = 'LOAD_USER_TAGS_FAIL';

export const LOAD_TAGS = 'LOAD_TAGS';
export const LOAD_TAGS_SUCCESS = 'LOAD_TAGS_SUCCESS';
export const LOAD_TAGS_FAIL = 'LOAD_TAGS_FAIL';

export const UPDATE_TAG = 'UPDATE_TAG';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_FAIL = 'UPDATE_TAG_FAIL';

export const LOAD_TAG = 'LOAD_TAG';
export const LOAD_TAG_SUCCESS = 'LOAD_TAG_SUCCESS';
export const LOAD_TAG_FAIL = 'LOAD_TAG_FAIL';

export const DELETE_TAG = 'DELETE_TAG';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAIL = 'DELETE_TAG_FAIL';

export const DELETE_ITEM_TAG = 'DELETE_ITEM_TAG';
export const DELETE_ITEM_TAG_SUCCESS = 'DELETE_ITEM_TAG_SUCCESS';
export const DELETE_ITEM_TAG_FAIL = 'DELETE_ITEM_TAG_FAIL';

export const UPDATE_ITEM_TAG = 'UPDATE_ITEM_TAG';
export const UPDATE_ITEM_TAG_SUCCESS = 'UPDATE_ITEM_TAG_SUCCESS';
export const UPDATE_ITEM_TAG_FAIL = 'UPDATE_ITEM_TAG_FAIL';

const initialState = {
  // this only stores the user tags and bdcat tags, as album tags each have specific values like "weight"
  // user tags are stored with the itemTagObjectId as key
  userTags: {},
  tags: {},

  loading: false,
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_USER_TAGS: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_USER_TAGS_SUCCESS: {
      const userTags = {};
      action.payload.userTags.forEach((tag) => {
        userTags[tag.itemTagObjectId] = tag;
      });

      return {
        ...state,
        userTags,
        loading: false,
      };
    }
    case LOAD_USER_TAGS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LOAD_TAGS: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_TAGS_SUCCESS: {
      return {
        ...state,
        tags: action.payload.tags.reduce((acc, cur) => ((acc[cur.objectId] = cur), acc), {}),
        loading: false,
      };
    }
    case LOAD_TAGS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LOAD_TAG: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_TAG_SUCCESS: {
      const tags = { ...state.tags.tags };
      tags[action.payload.tag?.objectId] = action.payload.tag;
      return {
        ...state,
        tags,
        loading: false,
      };
    }
    case LOAD_TAG_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_TAG: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_TAG_SUCCESS: {
      const tags = { ...state.tags };
      delete tags[action.payload.tagObjectId];
      return {
        ...state,
        tags,
        loading: false,
      };
    }
    case DELETE_TAG_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_TAG: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_TAG_SUCCESS: {
      const tags = { ...state.tags };
      tags[action.payload.tag?.objectId] = action.payload.tag;
      return {
        ...state,
        tags,
        loading: false,
      };
    }
    case UPDATE_TAG_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}

// loadUserTags ====================================================

export function loadUserTags(userObjectId) {
  return {
    type: LOAD_USER_TAGS,
    payload: {
      userObjectId,
    },
  };
}
export function loadUserTagsSuccess(userTags) {
  return {
    type: LOAD_USER_TAGS_SUCCESS,
    payload: {
      userTags,
    },
  };
}
export function loadUserTagsFail(error) {
  return {
    type: LOAD_USER_TAGS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadTags ====================================================

export function loadTags() {
  return {
    type: LOAD_TAGS,
  };
}
export function loadTagsSuccess(tags) {
  return {
    type: LOAD_TAGS_SUCCESS,
    payload: {
      tags,
    },
  };
}
export function loadTagsFail(error) {
  return {
    type: LOAD_TAGS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadTag ====================================================

export function loadTag(tagObjectId) {
  return {
    type: LOAD_TAG,
    payload: {
      tagObjectId,
    },
  };
}
export function loadTagSuccess(tag) {
  return {
    type: LOAD_TAG_SUCCESS,
    payload: {
      tag,
    },
  };
}
export function loadTagFail(error) {
  return {
    type: LOAD_TAG_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// deleteTag ====================================================

export function deleteTag(tagObjectId) {
  return {
    type: DELETE_TAG,
    payload: {
      tagObjectId,
    },
  };
}
export function deleteTagSuccess(tagObjectId) {
  return {
    type: DELETE_TAG_SUCCESS,
    payload: {
      tagObjectId,
    },
  };
}
export function deleteTagFail(error) {
  return {
    type: DELETE_TAG_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// deleteItemTag ====================================================

export function deleteItemTag(itemTagObjectId, albumObjectId) {
  return {
    type: DELETE_ITEM_TAG,
    payload: {
      itemTagObjectId,
      albumObjectId,
    },
  };
}
export function deleteItemTagSuccess() {
  return {
    type: DELETE_ITEM_TAG_SUCCESS,
  };
}
export function deleteItemTagFail(error) {
  return {
    type: DELETE_ITEM_TAG_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// updateItemTag ====================================================

export function updateItemTag(itemTagObjectId, itemTag) {
  return {
    type: UPDATE_ITEM_TAG,
    payload: {
      itemTagObjectId,
      itemTag,
    },
  };
}
export function updateItemTagSuccess() {
  return {
    type: UPDATE_ITEM_TAG_SUCCESS,
  };
}
export function updateItemTagFail(error) {
  return {
    type: UPDATE_ITEM_TAG_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// updateTag ====================================================

export function updateTag(tagObjectId, tag) {
  return {
    type: UPDATE_TAG,
    payload: {
      tagObjectId,
      tag,
    },
  };
}
export function updateTagSuccess(tag) {
  return {
    type: UPDATE_TAG_SUCCESS,
    payload: {
      tag,
    },
  };
}
export function updateTagFail(error) {
  return {
    type: UPDATE_TAG_FAIL,
    payload: new Error(error),
    error: true,
  };
}

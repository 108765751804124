import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { loadAlbum } from 'bubble-reducers/src/reducers/albums';
import { loadArticles } from 'bubble-reducers/src/reducers/articles';
import { loadAlbumReviews, loadMyReviewsForAlbum } from 'bubble-reducers/src/reducers/reviews';
import { getUserPrintForAlbum } from 'bubble-reducers/src/selectors';

import { useIsUserlogged } from '@/services/hooks/useUserUtils';
import { getSeoForAlbumReviews } from '@/services/seo-utils';

import ArticleCard from '@/components/ArticleCard/ArticleCard';
import ArticleHorizontalCardWhite from '@/components/ArticleHorizontalCardWhite/ArticleHorizontalCardWhite';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import AddAlbumReviewModal from '@/components/Modals/AddAlbumReviewModal';
import ReviewCard from '@/components/ReviewCard/ReviewCard';
import StarLine from '@/components/StarLine/StarLine';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

const AlbumReviews = () => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  const { albumObjectId, printObjectId } = useParams() || {};
  const user = useSelector((state) => state.user.user);
  const album = useSelector((state) => state.albums.albums[albumObjectId]) || {};
  const userObjectId = user?.objectId;
  const print =
    useSelector(
      (state) =>
        state.prints.prints[printObjectId] ||
        getUserPrintForAlbum(state, userObjectId, album?.defaultPrintObjectId, album?.objectId),
    ) || {};
  const articlesMap = useSelector((state) => state.articles.articlesMapByObjectId);
  const albumReviewsMap = useSelector((state) => state.reviews.albumReviewsMap);
  const myAlbumReviews = useSelector((state) => state.reviews.myAlbumReviewsMap[albumObjectId]);
  const highlightedArticleObjectIds = useSelector(
    (state) => state.articles.highlightedArticleObjectIds,
  );

  const [showAlbumReviewModal, setShowAlbumReviewModal] = useState(false);
  const [myReview, setMyReview] = useState({});

  const articles = Object.values(articlesMap);
  const reviews = (albumReviewsMap[albumObjectId] || {}).comments || [];
  const withComments = reviews.filter((review) => review.comment);
  const withoutComments = reviews.filter((review) => !review.comment);

  const newsArticles = highlightedArticleObjectIds
    .map((articleObjectId) => articles[articleObjectId])
    .filter(Boolean);

  const relatedArticles =
    articles &&
    articles
      .filter((article) => !~highlightedArticleObjectIds.indexOf(article.objectId))
      .slice(0, 2);

  useEffect(() => {
    if (albumObjectId) {
      dispatch(loadAlbum(albumObjectId));
      dispatch(loadAlbumReviews(albumObjectId));
    }
    if (!articles?.length) {
      dispatch(loadArticles({ highlighted: 1 }));
      dispatch(loadArticles());
    }
    if (userObjectId && albumObjectId) {
      dispatch(loadMyReviewsForAlbum(userObjectId, albumObjectId));
    }
  }, [dispatch, albumObjectId, userObjectId, articles?.length]);

  const handleShowAlbumReviewModal = (review) => {
    if (review) {
      setMyReview(review);
    }
    setShowAlbumReviewModal(true);
  };

  return (
    <div className="bb-background-light-grey">
      {!!albumObjectId && (
        <BubbleHelmet seo={getSeoForAlbumReviews(album, reviews.length, withComments.length)} />
      )}

      {showAlbumReviewModal && (
        <AddAlbumReviewModal
          show={showAlbumReviewModal}
          key={2}
          albumObjectId={albumObjectId}
          printObjectId={print.objectId || printObjectId || album?.defaultPrintObjectId}
          initialNote={myReview?.note}
          initialComment={myReview?.comment}
          reviewObjectId={myReview?.objectId}
          callback={() => setShowAlbumReviewModal(false)}
        />
      )}
      <div className="container">
        <div className="pt-4 d-flex align-items-center">
          <Link to={`/${album.permalink}/album/${albumObjectId}`}>Retour à l'album</Link>
          {!myAlbumReviews?.length && (
            <>
              <div className="px-1">-</div>
              <button
                onClick={() => {
                  if (isUserlogged()) {
                    handleShowAlbumReviewModal();
                  }
                }}
                className="btn btn-link mx-0 px-0"
              >
                Laisser un avis sur l'album
              </button>
            </>
          )}
        </div>

        <div className="row py-5">
          <div className="col-lg-8">
            {myAlbumReviews?.length && (
              <div className="pb-5">
                <div className="bb-medium-large-text-size bb-text-500 mb-3">
                  {myAlbumReviews?.length > 1 ? 'Mes avis' : 'Mon avis'}
                </div>
                {myAlbumReviews.map((myReview) => (
                  <div className="mt-3" key={myReview.objectId}>
                    <WithClickHandler
                      onClick={() =>
                        handleShowAlbumReviewModal({
                          objectId: myReview.objectId,
                          note: myReview.note,
                          comment: myReview.comment,
                        })
                      }
                    >
                      <ReviewCard
                        noLike
                        removeLinkToOpenReview={true}
                        review={{ ...myReview, reviewer: myReview.username }}
                      />
                    </WithClickHandler>
                  </div>
                ))}
              </div>
            )}
            {!reviews.length && (
              <div className="d-flex align-items-center">
                Cet album n'a pas encore d'avis,
                <button
                  className="btn btn-link m-0 p-0 ps-1"
                  onClick={() => {
                    if (isUserlogged()) {
                      handleShowAlbumReviewModal();
                    }
                  }}
                >
                  soyez le premier à en laisser un
                </button>
              </div>
            )}
            {reviews?.length > 0 && (
              <>
                <div className="bb-medium-large-text-size bb-text-500 d-md-flex">
                  <div>Avis des lecteurs de l'album: </div>
                  <div className="d-flex my-2 my-md-0">
                    <div className="mt-n1 mx-md-1 me-2">
                      <StarLine size="medium" note={album.note} />
                    </div>
                    {(album.note || 0).toFixed(1)}
                    <span className="bb-regular-text-size ps-2 text-secondary fw-normal">
                      ({album.numberOfNotes || 0} avis)
                    </span>
                  </div>
                </div>
                {withComments.map((review) => (
                  <div className="mt-3" key={review.objectId}>
                    <ReviewCard
                      albumObjectId={albumObjectId}
                      sourcePath={`/${album.permalink}/album/${albumObjectId}`}
                      review={{
                        ...review,
                        reviewer: review.username,
                      }}
                    />
                  </div>
                ))}
                {withoutComments.map((review) => (
                  <div className="mt-3" key={review.objectId}>
                    <ReviewCard
                      albumObjectId={albumObjectId}
                      sourcePath={`/${album.permalink}/album/${albumObjectId}`}
                      review={{
                        ...review,
                        reviewer: review.username,
                      }}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="col-lg-4">
            {!!relatedArticles?.length && (
              <div className="mb-4">
                <div className="d-block d-md-flex bb-medium-large-text-size bb-text-500 ">
                  <div>
                    Suivez toute l'actualité de {album?.title || 'cette série'} sur notre blog
                  </div>
                </div>
                {relatedArticles.map((article) => (
                  <ArticleCard className="pt-3" key={article.objectId} article={article} />
                ))}
              </div>
            )}
            {!!newsArticles?.length && (
              <div>
                <div className="d-block d-md-flex bb-medium-large-text-size bb-text-500 ">
                  <div>Actualités</div>
                </div>
                {newsArticles.map((article) => (
                  <ArticleHorizontalCardWhite
                    className="pt-3"
                    key={article.objectId}
                    article={article}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlbumReviews;

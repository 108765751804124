import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { loadArticles } from 'bubble-reducers/src/reducers/articles';
import { loadMyReviewForSerie, loadSerieReviews } from 'bubble-reducers/src/reducers/reviews';
import { loadSerie } from 'bubble-reducers/src/reducers/series';

import { useIsUserlogged } from '@/services/hooks/useUserUtils';
import { getSeoForSerieReviews } from '@/services/seo-utils';

import ArticleCard from '@/components/ArticleCard/ArticleCard';
import ArticleHorizontalCardWhite from '@/components/ArticleHorizontalCardWhite/ArticleHorizontalCardWhite';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import AddSerieReviewModal from '@/components/Modals/AddSerieReviewModal';
import ReviewCard from '@/components/ReviewCard/ReviewCard';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

const SerieReviews = () => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  const { serieObjectId } = useParams();

  const user = useSelector((state) => state.user.user);
  const serieReviewsMap = useSelector((state) => state.reviews.serieReviewsMap);
  const series = useSelector((state) => state.series.series);
  const mySerieReview = useSelector((state) => state.reviews.mySerieReviewsMap[serieObjectId]);
  const highlightedArticleObjectIds = useSelector(
    (state) => state.articles.highlightedArticleObjectIds,
  );
  const articlesMap = useSelector((state) => state.articles.articlesMapByObjectId);

  const [showSerieReviewModal, setShowSerieReviewModal] = useState(false);

  const userObjectId = user?.objectId;
  const reviews = useMemo(
    () => (serieReviewsMap[serieObjectId] || {}).comments || [],
    [serieReviewsMap, serieObjectId],
  );
  const serie = series[serieObjectId] || {};
  const withComments = reviews.filter((review) => review.comment);
  const articles = useMemo(() => Object.values(articlesMap), [articlesMap]);
  const newsArticles = highlightedArticleObjectIds.map(
    (articleObjectId) => articles[articleObjectId],
  );

  const category = serie?.category;

  const relatedArticles =
    articles &&
    articles
      .filter((article) => article.tags.includes((category || '').toLowerCase()))
      .filter((article) => !~highlightedArticleObjectIds.indexOf(article.objectId))
      .slice(0, 2);

  useEffect(() => {
    if (serieObjectId) {
      dispatch(loadSerie(serieObjectId));
      dispatch(loadSerieReviews(serieObjectId));
    }
    if (!articles?.length) {
      dispatch(loadArticles({ highlighted: 1 }));
      dispatch(loadArticles());
    }
    if (serieObjectId && userObjectId) {
      dispatch(loadMyReviewForSerie(userObjectId, serieObjectId));
    }
  }, [dispatch, serieObjectId, userObjectId, articles?.length]);

  const handleShowSerieReviewModal = () => {
    setShowSerieReviewModal(true);
  };

  return (
    <div className="bb-background-light-grey">
      {!!serie.objectId && (
        <BubbleHelmet seo={getSeoForSerieReviews(serie, reviews.length, withComments.length)} />
      )}
      {showSerieReviewModal && (
        <AddSerieReviewModal
          show={showSerieReviewModal}
          key={1}
          serieObjectId={serie.objectId}
          initialNote={mySerieReview?.note}
          initialComment={mySerieReview?.comment}
          reviewObjectId={mySerieReview?.objectId}
          callback={() => setShowSerieReviewModal(false)}
        />
      )}

      <div className="container">
        <div className="pt-4 d-flex align-items-center">
          <Link to={`/${serie.permalink}/serie/${serie.objectId}`}>Retour à la série</Link>
          {!mySerieReview && (
            <>
              <div className="px-1">-</div>
              <button
                onClick={() => {
                  if (isUserlogged()) {
                    handleShowSerieReviewModal();
                  }
                }}
                className="btn btn-link mx-0 px-0"
              >
                Laisser un avis sur la série
              </button>
            </>
          )}
        </div>

        <div className="row py-5">
          <div className="col-lg-8">
            {mySerieReview && (
              <div className="pb-5">
                <div className="bb-medium-large-text-size bb-text-500 mb-3">Mon avis</div>
                <WithClickHandler onClick={() => handleShowSerieReviewModal()}>
                  <ReviewCard
                    noLike
                    reviewType="serie"
                    review={{ ...mySerieReview, reviewer: mySerieReview.username }}
                  />
                </WithClickHandler>
              </div>
            )}
            {!reviews.length && (
              <div className="d-flex align-items-center">
                Cette série n'a pas encore d'avis,
                <button
                  className="btn btn-link m-0 p-0 ps-1"
                  onClick={() => {
                    if (isUserlogged()) {
                      handleShowSerieReviewModal();
                    }
                  }}
                >
                  soyez le premier à en laisser un
                </button>
              </div>
            )}
            {reviews?.length > 0 && (
              <>
                <div className="bb-medium-large-text-size bb-text-500 d-md-flex">
                  <div>Avis des lecteurs de la série </div>
                </div>
                {withComments.map((review) => (
                  <div className="mt-3" key={review.objectId}>
                    <ReviewCard
                      reviewType="serie"
                      sourcePath={`/${serie.permalink}/serie/${serie.objectId}`}
                      serieObjectId={serie.objectId}
                      review={{
                        ...review,
                        reviewer: review.username,
                      }}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="col-lg-4">
            {!!relatedArticles?.length && (
              <div className="mb-4">
                <div className="d-block d-md-flex bb-medium-large-text-size bb-text-500 ">
                  <div>
                    Suivez toute l'actualité de {serie?.title || 'cette série'} sur notre blog
                  </div>
                </div>
                {relatedArticles.map((article) => (
                  <ArticleCard className="pt-3" key={article.objectId} article={article} />
                ))}
              </div>
            )}
            {!!newsArticles?.length && (
              <div>
                <div className="d-block d-md-flex bb-medium-large-text-size bb-text-500 ">
                  <div>Actualités</div>
                </div>
                {newsArticles.map((article) => (
                  <ArticleHorizontalCardWhite
                    className="pt-3"
                    key={article?.objectId}
                    article={article}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SerieReviews;

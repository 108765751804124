import React from 'react';
import { useEffect, useState } from 'react';
import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getUserOrGuest } from 'bubble-reducers/src/selectors';

import { reOptinNewsletter, updateSpecificOtherVariable } from '@/engine/reducers/website-other';

import { isValidEmail } from 'bubble-utils/src/validity-utils';

import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import './NArtNewsletterOptin.scss';

const NArtNewsletterOptin = () => {
  const dispatch = useDispatch();
  const optedIn = useSelector((state) => state.websiteOther.reOptinNewsletter);
  const user = useSelector(getUserOrGuest);
  const [email, setEmail] = useState(user?.email || '');

  useEffect(() => {
    return () => dispatch(updateSpecificOtherVariable('reOptinNewsletter', false));
  }, [dispatch]);

  const handleButton = useCallback(
    (value) => {
      if (!isValidEmail(value)) {
        alert('Votre email semble incorrect');
      } else {
        dispatch(reOptinNewsletter(user.objectId, value));
      }
    },
    [user?.objectId, dispatch],
  );

  const onTextChange = useCallback(
    (event) => {
      setEmail(event.target.value);
    },
    [setEmail],
  );

  // optedIn is only used to display the temporary 'thank you' message
  // and is reset when unmounting
  if (!!user.hasAcceptedNewsletter & !optedIn) return null;

  return (
    <div className="nart-newsletter-optin">
      <div className="d-flex flex-column align-items-center p-4 p-md-paper text-center">
        <div className="h3 fw-bold">Abonnez-vous à la newsletter !</div>
        <div>Le meilleur de l'actualité BD directement dans votre boîte mail</div>
        {!optedIn && (
          <div className="d-none w-50 d-md-block pt-4">
            <LabeledTextInput
              noRightLabel
              placeholder="Votre email"
              buttonStyle="btn border-start-0 btn-9eart-comics text-white fw-bold"
              buttonLabel="M'abonner"
              value={email}
              onChange={onTextChange}
              buttonCallback={handleButton}
            />
          </div>
        )}
        {!optedIn && (
          <>
            <div className="d-block d-md-none pt-4 w-100">
              <LabeledTextInput
                value={email}
                onChange={onTextChange}
                noRightLabel
                placeholder="Votre email"
              />
            </div>
            <div className="d-flex d-md-none flex-fill w-100 justify-content-end mt-n2">
              <WithClickHandler
                onClick={handleButton}
                className="btn btn-9eart-comics text-white fw-bold"
              >
                M'abonner
              </WithClickHandler>
            </div>
          </>
        )}
        {!!optedIn && (
          <div className="py-4 text-white fw-bold">
            Vous êtes maintenant inscrit à notre newsletter !{' '}
            <span role="img" aria-label="confetti">
              🎉
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NArtNewsletterOptin;

export const LOAD_IAPS = 'LOAD_IAPS';
export const LOAD_IAPS_SUCCESS = 'LOAD_IAPS_SUCCESS';
export const LOAD_IAPS_FAIL = 'LOAD_IAPS_FAIL';

export const BUY_IAP = 'BUY_IAP';
export const BUY_IAP_SUCCESS = 'BUY_IAP_SUCCESS';
export const BUY_IAP_FAIL = 'BUY_IAP_FAIL';

export const BUY_IAP_WITH_NEW_CARD = 'BUY_IAP_WITH_NEW_CARD';
export const BUY_IAP_WITH_NEW_CARD_SUCCESS = 'BUY_IAP_WITH_NEW_CARD_SUCCESS';
export const BUY_IAP_WITH_NEW_CARD_FAIL = 'BUY_IAP_WITH_NEW_CARD_FAIL';

export const UPDATE_IAP_PAYMENT_INTENT = 'UPDATE_IAP_PAYMENT_INTENT';
export const UPDATE_IAP_PAYMENT_INTENT_SUCCESS = 'UPDATE_IAP_PAYMENT_INTENT_SUCCESS';
export const UPDATE_IAP_PAYMENT_INTENT_FAIL = 'UPDATE_IAP_PAYMENT_INTENT_FAIL';

export const RESET_BUY_STATUS = 'RESET_BUY_STATUS';

export const RESET_IAP_REDUCER = 'RESET_IAP_REDUCER';

const initialState = {
  iaps: [],
  loading: false,
  iapBought: false,
  intent: null,
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_IAPS: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_IAPS_SUCCESS: {
      const data = action.payload.iaps;
      return {
        ...state,
        loading: false,
        iaps: data,
        errors: [],
      };
    }
    case LOAD_IAPS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case RESET_BUY_STATUS: {
      return {
        ...state,
        iapBought: false,
      };
    }

    case BUY_IAP: {
      return {
        ...state,
        loading: true,
      };
    }
    case BUY_IAP_SUCCESS: {
      const payload = action.payload.response;

      return {
        ...state,
        loading: false,
        intent: payload.paymentIntentId ? payload : null,
        iapBought: payload.success || payload.paymentIntentStatus === 'succeeded',
        errors: [],
      };
    }
    case BUY_IAP_FAIL: {
      return {
        ...state,
        loading: false,
        errors: state.errors.concat(action.payload),
      };
    }

    case UPDATE_IAP_PAYMENT_INTENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_IAP_PAYMENT_INTENT_SUCCESS: {
      const payload = action.payload.response;
      return {
        ...state,
        loading: false,
        errors: [],
        iapBought: payload.success || payload.paymentIntentStatus === 'succeeded',
        intent: payload.paymentIntentId ? payload : null,
      };
    }

    case UPDATE_IAP_PAYMENT_INTENT_FAIL: {
      return {
        ...state,
        loading: false,
        errors: state.errors.concat(action.payload),
      };
    }

    case BUY_IAP_WITH_NEW_CARD: {
      return {
        ...state,
        loading: true,
      };
    }
    case BUY_IAP_WITH_NEW_CARD_SUCCESS: {
      return {
        ...state,
        errors: [],
      };
    }
    case BUY_IAP_WITH_NEW_CARD_FAIL: {
      return {
        ...state,
        loading: false,
        errors: state.errors.concat(action.payload),
      };
    }

    case RESET_IAP_REDUCER: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}

// resetIapReducer ====================================================

export function resetIapReducer() {
  return {
    type: RESET_IAP_REDUCER,
  };
}

// loadIaps ====================================================

export function loadIaps() {
  return {
    type: LOAD_IAPS,
  };
}
export function loadIapsSuccess(iaps) {
  return {
    type: LOAD_IAPS_SUCCESS,
    payload: {
      iaps,
    },
  };
}
export function loadIapsFail(error) {
  return {
    type: LOAD_IAPS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// resetBuyStatus ====================================================

export function resetBuyStatus() {
  return {
    type: RESET_BUY_STATUS,
  };
}

// buyIap ====================================================

export function buyIap(userObjectId, productId) {
  return {
    type: BUY_IAP,
    payload: { userObjectId, productId },
  };
}
export function buyIapSuccess(response) {
  return {
    type: BUY_IAP_SUCCESS,
    payload: {
      response,
    },
  };
}
export function buyIapFail(error) {
  return {
    type: BUY_IAP_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// buyIapWithNewCard ====================================================

export function buyIapWithNewCard(userObjectId, productId, paymentMethodId) {
  return {
    type: BUY_IAP_WITH_NEW_CARD,
    payload: { userObjectId, productId, paymentMethodId },
  };
}
export function buyIapWithNewCardSuccess() {
  return {
    type: BUY_IAP_WITH_NEW_CARD_SUCCESS,
  };
}
export function buyIapWithNewCardFail(error) {
  return {
    type: BUY_IAP_WITH_NEW_CARD_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// updateIapPaymentIntent ====================================================

export function updateIapPaymentIntent(userObjectId, productId, paymentIntentId) {
  return {
    type: UPDATE_IAP_PAYMENT_INTENT,
    payload: { userObjectId, productId, paymentIntentId },
  };
}
export function updateIapPaymentIntentSuccess(response) {
  return {
    type: UPDATE_IAP_PAYMENT_INTENT_SUCCESS,
    payload: { response },
  };
}
export function updateIapPaymentIntentFail(error) {
  return {
    type: UPDATE_IAP_PAYMENT_INTENT_FAIL,
    payload: new Error(error),
    error: true,
  };
}

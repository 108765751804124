import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  deleteCommentApi,
  flagCommentApi,
  loadArticleCommentsApi,
  storeCommentApi,
  updateCommentApi,
} from '../services/api';

import {
  DELETE_COMMENT,
  FLAG_COMMENT,
  LOAD_ARTICLE_COMMENTS,
  STORE_COMMENT,
  UPDATE_COMMENT,
  deleteCommentFail,
  deleteCommentSuccess,
  flagCommentFail,
  flagCommentSuccess,
  likeComment,
  loadArticleCommentsFail,
  loadArticleCommentsSuccess,
  storeCommentFail,
  storeCommentSuccess,
  unlikeComment,
  updateCommentFail,
  updateCommentSuccess,
} from '../reducers/comments';
import { getClientToken } from '../selectors';
import { editUserFlow } from './user';

const flowSpecifics = {
  loadArticleCommentsFlow: {
    apiProc: loadArticleCommentsApi,
    apiCustomArgs: (action) => [action.payload.articleObjectId],
    success: loadArticleCommentsSuccess,
    failure: loadArticleCommentsFail,
  },
  deleteCommentFlow: {
    apiProc: deleteCommentApi,
    apiCustomArgs: (action) => [action.payload.commentObjectId],
    success: deleteCommentSuccess,
    failure: deleteCommentFail,
  },
  updateCommentFlow: {
    apiProc: updateCommentApi,
    apiCustomArgs: (action) => [action.payload.commentObjectId, action.payload.content],
    success: updateCommentSuccess,
    failure: updateCommentFail,
  },
  flagCommentFlow: {
    apiProc: flagCommentApi,
    apiCustomArgs: (action) => [action.payload.commentObjectId],
    success: flagCommentSuccess,
    failure: flagCommentFail,
  },
};

function flowWrap(specificsKey) {
  const { apiProc, apiCustomArgs, success, failure } = flowSpecifics[specificsKey];
  return function* (action) {
    try {
      const clientToken = yield select(getClientToken);
      const apiArgs = [apiProc, clientToken, ...apiCustomArgs(action)];
      const response = yield call.apply(null, apiArgs);
      yield put(success(response));
    } catch (error) {
      console.log(`saga middleware error (${specificsKey})`, error);
      yield put(failure(error));
    }
  };
}

export function* storeCommentFlow(action) {
  try {
    const { articleObjectId, content, userObjectId, user } = action.payload;
    if (user) {
      yield call(editUserFlow, { payload: { user, userObjectId } });
    }
    const clientToken = yield select(getClientToken);
    const response = yield call(storeCommentApi, clientToken, articleObjectId, content);
    yield put(storeCommentSuccess(response));
  } catch (error) {
    console.log('sage middleware error (storeCommentFlow)', error);
    yield put(storeCommentFail(error));
  }
}

// fake flows to trigger reducer success procedure
export function* commentLikeFlow(action) {
  const { commentObjectId } = action.payload;
  yield put(likeComment(commentObjectId));
}

export function* commentUnlikeFlow(action) {
  const { commentObjectId } = action.payload;
  yield put(unlikeComment(commentObjectId));
}

export default function* commentsWatcher() {
  yield takeLatest(LOAD_ARTICLE_COMMENTS, flowWrap('loadArticleCommentsFlow')),
    yield takeLatest(STORE_COMMENT, storeCommentFlow),
    yield takeLatest(DELETE_COMMENT, flowWrap('deleteCommentFlow')),
    yield takeLatest(UPDATE_COMMENT, flowWrap('updateCommentFlow')),
    yield takeLatest(FLAG_COMMENT, flowWrap('flagCommentFlow'));
}

export const LOAD_USER = 'LOAD_USER';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAIL = 'LOAD_USER_FAIL';

export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const LOAD_GUEST_SUCCESS = 'LOAD_GUEST_SUCCESS';

export const LOAD_USER_ORDERS = 'LOAD_USER_ORDERS';
export const LOAD_USER_ORDERS_SUCCESS = 'LOAD_USER_ORDERS_SUCCESS';
export const LOAD_USER_ORDERS_FAIL = 'LOAD_USER_ORDERS_FAIL';

export const LOAD_USER_ORDER = 'LOAD_USER_ORDER';
export const LOAD_USER_ORDER_SUCCESS = 'LOAD_USER_ORDER_SUCCESS';
export const LOAD_USER_ORDER_FAIL = 'LOAD_USER_ORDER_FAIL';

export const LOAD_USER_ADDRESSES = 'LOAD_USER_ADDRESSES';
export const LOAD_USER_ADDRESSES_SUCCESS = 'LOAD_USER_ADDRESSES_SUCCESS';
export const LOAD_USER_ADDRESSES_FAIL = 'LOAD_USER_ADDRESSES_FAIL';

export const CREATE_ADDRESS = 'CREATE_ADDRESS';
export const CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS';
export const CREATE_ADDRESS_FAIL = 'CREATE_ADDRESS_FAIL';

export const EDIT_ADDRESS = 'EDIT_ADDRESS';
export const EDIT_ADDRESS_SUCCESS = 'EDIT_ADDRESS_SUCCESS';
export const EDIT_ADDRESS_FAIL = 'EDIT_ADDRESS_FAIL';

export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAIL = 'DELETE_ADDRESS_FAIL';

export const LOAD_USER_FAVORITE_STORES = 'LOAD_USER_FAVORITE_STORES';
export const LOAD_USER_FAVORITE_STORES_SUCCESS = 'LOAD_USER_FAVORITE_STORES_SUCCESS';
export const LOAD_USER_FAVORITE_STORES_FAIL = 'LOAD_USER_FAVORITE_STORES_FAIL';

export const ADD_STORE_TO_FAVORITE = 'ADD_STORE_TO_FAVORITE';
export const ADD_STORE_TO_FAVORITE_SUCCESS = 'ADD_STORE_TO_FAVORITE_SUCCESS';
export const ADD_STORE_TO_FAVORITE_FAIL = 'ADD_STORE_TO_FAVORITE_FAIL';

export const REMOVE_STORE_FROM_FAVORITE = 'REMOVE_STORE_FROM_FAVORITE';
export const REMOVE_STORE_FROM_FAVORITE_SUCCESS = 'REMOVE_STORE_FROM_FAVORITE_SUCCESS';
export const REMOVE_STORE_FROM_FAVORITE_FAIL = 'REMOVE_STORE_FROM_FAVORITE_FAIL';

export const LOAD_USER_LAST_ADDED_ALBUMS = 'LOAD_USER_LAST_ADDED_ALBUMS';
export const LOAD_USER_LAST_ADDED_ALBUMS_SUCCESS = 'LOAD_USER_LAST_ADDED_ALBUMS_SUCCESS';
export const LOAD_USER_LAST_ADDED_ALBUMS_FAIL = 'LOAD_USER_LAST_ADDED_ALBUMS_FAIL';

export const LOAD_USER_REVISIONS = 'LOAD_USER_REVISIONS';
export const LOAD_USER_REVISIONS_SUCCESS = 'LOAD_USER_REVISIONS_SUCCESS';
export const LOAD_USER_REVISIONS_FAIL = 'LOAD_USER_REVISIONS_FAIL';

export const LOAD_USER_ADDITIONS = 'LOAD_USER_ADDITIONS';
export const LOAD_USER_ADDITIONS_SUCCESS = 'LOAD_USER_ADDITIONS_SUCCESS';
export const LOAD_USER_ADDITIONS_FAIL = 'LOAD_USER_ADDITIONS_FAIL';

export const LOAD_USER_CODES = 'LOAD_USER_CODES';
export const LOAD_USER_CODES_SUCCESS = 'LOAD_USER_CODES_SUCCESS';
export const LOAD_USER_CODES_FAIL = 'LOAD_USER_CODES_FAIL';

export const EDIT_USER_NEWSLETTER_FROM_EMAIL = 'EDIT_USER_NEWSLETTER_FROM_EMAIL';
export const EDIT_USER_NEWSLETTER_FROM_EMAIL_SUCCESS = 'EDIT_USER_NEWSLETTER_FROM_EMAIL_SUCCESS';
export const EDIT_USER_NEWSLETTER_FROM_EMAIL_FAIL = 'EDIT_USER_NEWSLETTER_FROM_EMAIL_FAIL';

export const UPLOAD_USER_IMAGE = 'UPLOAD_USER_IMAGE';
export const UPLOAD_USER_IMAGE_SUCCESS = 'UPLOAD_USER_IMAGE_SUCCESS';
export const UPLOAD_USER_IMAGE_FAIL = 'UPLOAD_USER_IMAGE_FAIL';

export const ACTIVATE_INFINITY_CODE = 'ACTIVATE_INFINITY_CODE';
export const ACTIVATE_INFINITY_CODE_SUCCESS = 'ACTIVATE_INFINITY_CODE_SUCCESS';
export const ACTIVATE_INFINITY_CODE_FAIL = 'ACTIVATE_INFINITY_CODE_FAIL';

export const LOAD_USER_LIKES = 'LOAD_USER_LIKES';
export const LOAD_USER_LIKES_SUCCESS = 'LOAD_USER_LIKES_SUCCESS';
export const LOAD_USER_LIKES_FAIL = 'LOAD_USER_LIKES_FAIL';

export const LOAD_USER_POINTS = 'LOAD_USER_POINTS';
export const LOAD_USER_POINTS_SUCCESS = 'LOAD_USER_POINTS_SUCCESS';
export const LOAD_USER_POINTS_FAIL = 'LOAD_USER_POINTS_FAIL';

export const LOAD_USER_VERIFIED_PURCHASE = 'LOAD_USER_VERIFIED_PURCHASE';
export const LOAD_USER_VERIFIED_PURCHASE_SUCCESS = 'LOAD_USER_VERIFIED_PURCHASE_SUCCESS';
export const LOAD_USER_VERIFIED_PURCHASE_FAIL = 'LOAD_USER_VERIFIED_PURCHASE_FAIL';

export const LOAD_USER_VERIFIED_PURCHASES = 'LOAD_USER_VERIFIED_PURCHASES';
export const LOAD_USER_VERIFIED_PURCHASES_SUCCESS = 'LOAD_USER_VERIFIED_PURCHASES_SUCCESS';
export const LOAD_USER_VERIFIED_PURCHASES_FAIL = 'LOAD_USER_VERIFIED_PURCHASES_FAIL';

export const SET_USER_OBJECT_ID_ONLY = 'SET_USER_OBJECT_ID_ONLY';

export const RESET_USER_REDUCER = 'RESET_USER_REDUCER';

const initialState = {
  user: {},
  guest: {},
  otherUsers: {},
  orders: {},
  addresses: {},
  lastAddedAlbums: [],
  favoriteStoresIds: [],
  revisions: [],
  additions: [],
  codes: [],
  likes: { reviews: [] },
  points: [],
  verifiedPurchasesMap: {},
  loading: {},
  infinityCodeActivation: null,
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_GUEST_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_GUEST: false },
        guest: action.payload.guest,
        errors: [],
      };
    }

    case LOAD_USER: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER: true },
        errors: [],
      };
    }
    case LOAD_USER_SUCCESS: {
      const user = action.payload.user;
      if (!action.payload.isGuest) {
        return {
          ...state,
          loading: { ...state.loading, LOAD_USER: false },
          user: user,
          errors: [],
        };
      } else {
        const newUsers = Object.assign({}, state.otherUsers);
        newUsers[user.objectId] = user;
        return {
          ...state,
          loading: { ...state.loading, LOAD_USER: false },
          otherUsers: newUsers,
          errors: [],
        };
      }
    }
    case LOAD_USER_FAIL: {
      return {
        ...state,
        // don't reset user otherwise it will disconnect the user
        loading: { ...state.loading, LOAD_USER: false },
        errors: [],
      };
    }

    case EDIT_USER: {
      return {
        ...state,
        loading: { ...state.loading, EDIT_USER: true },
        errors: [],
      };
    }
    case EDIT_USER_SUCCESS: {
      const user = action.payload.user;
      return {
        ...state,
        loading: { ...state.loading, EDIT_USER: false },
        user: user,
        errors: [],
      };
    }
    case EDIT_USER_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, EDIT_USER: false },
        errors: state.errors.concat(action.payload),
      };
    }

    case DELETE_USER: {
      return {
        ...state,
        loading: { ...state.loading, DELETE_USER: true },
        errors: [],
      };
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, DELETE_USER: false },
        user: initialState.user,
        errors: [],
      };
    }
    case DELETE_USER_FAIL: {
      return {
        ...state,
        // don't reset user otherwise it will disconnect the user
        loading: { ...state.loading, DELETE_USER: false },
        errors: [],
      };
    }

    case LOAD_USER_ORDERS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_ORDERS: true },
        errors: [],
      };
    }
    case LOAD_USER_ORDERS_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_ORDERS: false },
        orders: action.payload.orders.reduce((acc, cur) => {
          acc[cur.objectId] = cur;
          return acc;
        }, {}),
        errors: [],
      };
    }
    case LOAD_USER_ORDERS_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_ORDERS: false },
        orders: {},
      };
    }

    case LOAD_USER_ORDER: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_ORDER: true },
        errors: [],
      };
    }
    case LOAD_USER_ORDER_SUCCESS: {
      const orders = { ...state.orders };
      orders[action.payload.order.objectId] = action.payload.order;
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_ORDER: false },
        orders,
        errors: [],
      };
    }
    case LOAD_USER_ORDER_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_ORDER: false },
        orders: {},
      };
    }

    case LOAD_USER_ADDRESSES: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_ADDRESSES: true },
      };
    }
    case LOAD_USER_ADDRESSES_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_ADDRESSES: false },
        addresses: action.payload.addresses.reduce((acc, cur) => {
          acc[cur.objectId] = cur;
          return acc;
        }, {}),
        errors: [],
      };
    }
    case LOAD_USER_ADDRESSES_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_ADDRESSES: false },
        addresses: {},
      };
    }

    case CREATE_ADDRESS: {
      return {
        ...state,
        loading: { ...state.loading, CREATE_ADDRESS: true },
      };
    }
    case CREATE_ADDRESS_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, CREATE_ADDRESS: false },
        errors: [],
      };
    }
    case CREATE_ADDRESS_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, CREATE_ADDRESS: false },
      };
    }

    case LOAD_USER_FAVORITE_STORES: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_FAVORITE_STORES: true },
      };
    }
    case LOAD_USER_FAVORITE_STORES_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_FAVORITE_STORES: false },
        favoriteStoresIds: action.payload.favoriteStores,
        errors: [],
      };
    }
    case LOAD_USER_FAVORITE_STORES_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_FAVORITE_STORES: false },
      };
    }

    case ADD_STORE_TO_FAVORITE: {
      return {
        ...state,
        loading: { ...state.loading, ADD_STORE_TO_FAVORITE: true },
      };
    }
    case ADD_STORE_TO_FAVORITE_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, ADD_STORE_TO_FAVORITE: false },
        errors: [],
      };
    }
    case ADD_STORE_TO_FAVORITE_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, ADD_STORE_TO_FAVORITE: false },
      };
    }

    case REMOVE_STORE_FROM_FAVORITE: {
      return {
        ...state,
        loading: { ...state.loading, REMOVE_STORE_FROM_FAVORITE: true },
      };
    }
    case REMOVE_STORE_FROM_FAVORITE_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, REMOVE_STORE_FROM_FAVORITE: false },
        errors: [],
      };
    }
    case REMOVE_STORE_FROM_FAVORITE_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, REMOVE_STORE_FROM_FAVORITE: false },
      };
    }

    case LOAD_USER_REVISIONS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_REVISIONS: true },
      };
    }
    case LOAD_USER_REVISIONS_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_REVISIONS: false },
        revisions: action.payload.revisions,
        errors: [],
      };
    }
    case LOAD_USER_REVISIONS_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_REVISIONS: false },
        revisions: [],
      };
    }

    case LOAD_USER_ADDITIONS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_ADDITIONS: true },
      };
    }
    case LOAD_USER_ADDITIONS_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_ADDITIONS: false },
        additions: action.payload.additions,
        errors: [],
      };
    }
    case LOAD_USER_ADDITIONS_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_ADDITIONS: false },
        additions: [],
      };
    }

    case LOAD_USER_CODES: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_CODES: true },
      };
    }
    case LOAD_USER_CODES_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_CODES: false },
        codes: action.payload.codes,
        errors: [],
      };
    }
    case LOAD_USER_CODES_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_CODES: false },
        codes: [],
      };
    }

    case LOAD_USER_LAST_ADDED_ALBUMS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_LAST_ADDED_ALBUMS: true },
      };
    }
    case LOAD_USER_LAST_ADDED_ALBUMS_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_LAST_ADDED_ALBUMS: false },
        lastAddedAlbums: action.payload.lastAddedAlbums,
        errors: [],
      };
    }
    case LOAD_USER_LAST_ADDED_ALBUMS_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_LAST_ADDED_ALBUMS: false },
      };
    }

    case ACTIVATE_INFINITY_CODE: {
      return {
        ...state,
        loading: { ...state.loading, ACTIVATE_INFINITY_CODE: true },
        infinityCodeActivation: null,
      };
    }
    case ACTIVATE_INFINITY_CODE_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, ACTIVATE_INFINITY_CODE: false },
        infinityCodeActivation: action.payload.codeResult,
        errors: [],
      };
    }
    case ACTIVATE_INFINITY_CODE_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, ACTIVATE_INFINITY_CODE: false },
        infinityCodeActivation: null,
      };
    }

    case RESET_USER_REDUCER: {
      return { ...initialState };
    }

    case SET_USER_OBJECT_ID_ONLY: {
      return {
        ...state,
        user: {
          objectId: action.payload.userObjectId,
        },
      };
    }

    case UPLOAD_USER_IMAGE: {
      return {
        ...state,
        loading: { ...state.loading, UPLOAD_USER_IMAGE: true },
      };
    }
    case UPLOAD_USER_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, UPLOAD_USER_IMAGE: false },
      };
    }
    case UPLOAD_USER_IMAGE_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, UPLOAD_USER_IMAGE: false },
      };
    }

    case LOAD_USER_LIKES: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_LIKES: true },
      };
    }
    case LOAD_USER_LIKES_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_LIKES: false },
        likes: action.payload.likes,
      };
    }
    case LOAD_USER_LIKES_FAIL: {
      return { ...state, loading: { ...state.loading, LOAD_USER_LIKES: false } };
    }
    case EDIT_USER_NEWSLETTER_FROM_EMAIL: {
      return {
        ...state,
        loading: { ...state.loading, EDIT_USER_NEWSLETTER_FROM_EMAIL: true },
        error: false,
      };
    }
    case EDIT_USER_NEWSLETTER_FROM_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, EDIT_USER_NEWSLETTER_FROM_EMAIL: false },
        error: false,
      };
    }
    case EDIT_USER_NEWSLETTER_FROM_EMAIL_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, EDIT_USER_NEWSLETTER_FROM_EMAIL: false },
        error: true,
      };
    }

    case LOAD_USER_POINTS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_POINTS: true },
      };
    }
    case LOAD_USER_POINTS_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_POINTS: false },
        points: action.payload.points,
        errors: [],
      };
    }
    case LOAD_USER_POINTS_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_POINTS: false },
        points: [],
      };
    }

    case LOAD_USER_VERIFIED_PURCHASE: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_VERIFIED_PURCHASE: true },
      };
    }
    case LOAD_USER_VERIFIED_PURCHASE_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_VERIFIED_PURCHASE: false },
        errors: [],
      };
    }
    case LOAD_USER_VERIFIED_PURCHASE_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_VERIFIED_PURCHASE: false },
      };
    }

    case LOAD_USER_VERIFIED_PURCHASES: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_VERIFIED_PURCHASES: true },
      };
    }
    case LOAD_USER_VERIFIED_PURCHASES_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_VERIFIED_PURCHASES: false },
        verifiedPurchasesMap: action.payload.verifiedPurchases.reduce((acc, cur) => {
          acc[cur.printObjectId] = true;
          return acc;
        }, {}),
        errors: [],
      };
    }
    case LOAD_USER_VERIFIED_PURCHASES_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, LOAD_USER_VERIFIED_PURCHASES: false },
        verifiedPurchasesMap: {},
      };
    }

    default: {
      return state;
    }
  }
}

export function resetUserReducer() {
  return {
    type: RESET_USER_REDUCER,
  };
}

// loadUser ====================================================

export function loadUser(userObjectId, isGuest = false) {
  return {
    type: LOAD_USER,
    payload: { userObjectId, isGuest },
  };
}
export function loadUserSuccess(user, isGuest) {
  return {
    type: LOAD_USER_SUCCESS,
    payload: {
      user,
      isGuest,
    },
  };
}
export function loadUserFail(error) {
  return {
    type: LOAD_USER_FAIL,
    payload: new Error(error),
    error: true,
  };
}
// loadGuest ====================================================

export function loadGuestSuccess(guest) {
  return {
    type: LOAD_GUEST_SUCCESS,
    payload: {
      guest,
    },
  };
}

// editUser ====================================================

export function editUser(userObjectId, user) {
  return {
    type: EDIT_USER,
    payload: { userObjectId, user },
  };
}
export function editUserSuccess(user) {
  return {
    type: EDIT_USER_SUCCESS,
    payload: {
      user,
    },
  };
}
export function editUserFail(error) {
  return {
    type: EDIT_USER_FAIL,
    payload: error,
    error: true,
  };
}

// deleteUser ====================================================

export function deleteUser(userObjectId) {
  return {
    type: DELETE_USER,
    payload: { userObjectId },
  };
}
export function deleteUserSuccess() {
  return {
    type: DELETE_USER_SUCCESS,
    payload: {},
  };
}
export function deleteUserFail(error) {
  return {
    type: DELETE_USER_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadUserOrders ====================================================

export function loadUserOrders(userObjectId) {
  return {
    type: LOAD_USER_ORDERS,
    payload: { userObjectId },
  };
}
export function loadUserOrdersSuccess(orders) {
  return {
    type: LOAD_USER_ORDERS_SUCCESS,
    payload: {
      orders,
    },
  };
}
export function loadUserOrdersFail(error) {
  return {
    type: LOAD_USER_ORDERS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadUserOrder ====================================================

export function loadUserOrder(orderObjectId) {
  return {
    type: LOAD_USER_ORDER,
    payload: {
      orderObjectId,
    },
  };
}
export function loadUserOrderSuccess(order) {
  return {
    type: LOAD_USER_ORDER_SUCCESS,
    payload: {
      order,
    },
  };
}
export function loadUserOrderFail(error) {
  return {
    type: LOAD_USER_ORDER_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadUserAddresses ====================================================

export function loadUserAddresses(userObjectId) {
  return {
    type: LOAD_USER_ADDRESSES,
    payload: { userObjectId },
  };
}
export function loadUserAddressesSuccess(addresses) {
  return {
    type: LOAD_USER_ADDRESSES_SUCCESS,
    payload: {
      addresses,
    },
  };
}
export function loadUserAddressesFail(error) {
  return {
    type: LOAD_USER_ADDRESSES_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadUserFavoriteStores ====================================================

export function loadUserFavoriteStores(userObjectId) {
  return {
    type: LOAD_USER_FAVORITE_STORES,
    payload: { userObjectId },
  };
}
export function loadUserFavoriteStoresSuccess(favoriteStores) {
  return {
    type: LOAD_USER_FAVORITE_STORES_SUCCESS,
    payload: {
      favoriteStores,
    },
  };
}
export function loadUserFavoriteStoresFail(error) {
  return {
    type: LOAD_USER_FAVORITE_STORES_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadUserLastAddedAlbums ====================================================

export function loadUserLastAddedAlbums(userObjectId, limit = 10) {
  return {
    type: LOAD_USER_LAST_ADDED_ALBUMS,
    payload: { userObjectId, limit },
  };
}
export function loadUserLastAddedAlbumsSuccess(lastAddedAlbums) {
  return {
    type: LOAD_USER_LAST_ADDED_ALBUMS_SUCCESS,
    payload: {
      lastAddedAlbums,
    },
  };
}
export function loadUserLastAddedAlbumsFail(error) {
  return {
    type: LOAD_USER_LAST_ADDED_ALBUMS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// createAddress ====================================================

export function createAddress(userObjectId, address) {
  return {
    type: CREATE_ADDRESS,
    payload: { userObjectId, address },
  };
}
export function createAddressSuccess(address) {
  return {
    type: CREATE_ADDRESS_SUCCESS,
    payload: { address },
  };
}
export function createAddressFail(error) {
  return {
    type: CREATE_ADDRESS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// addStoreToFavorite ====================================================

export function addStoreToFavorite(userObjectId, storeObjectId) {
  return {
    type: ADD_STORE_TO_FAVORITE,
    payload: { userObjectId, storeObjectId },
  };
}
export function addStoreToFavoriteSuccess() {
  return {
    type: ADD_STORE_TO_FAVORITE_SUCCESS,
    payload: {},
  };
}
export function addStoreToFavoriteFail(error) {
  return {
    type: ADD_STORE_TO_FAVORITE_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// removeStoreFromFavorite ====================================================

export function removeStoreFromFavorite(userObjectId, storeObjectId) {
  return {
    type: REMOVE_STORE_FROM_FAVORITE,
    payload: { userObjectId, storeObjectId },
  };
}
export function removeStoreFromFavoriteSuccess() {
  return {
    type: REMOVE_STORE_FROM_FAVORITE_SUCCESS,
    payload: {},
  };
}
export function removeStoreFromFavoriteFail(error) {
  return {
    type: REMOVE_STORE_FROM_FAVORITE_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadUserRevisions ====================================================

export function loadUserRevisions(userObjectId) {
  return {
    type: LOAD_USER_REVISIONS,
    payload: { userObjectId },
  };
}
export function loadUserRevisionsSuccess(revisions) {
  return {
    type: LOAD_USER_REVISIONS_SUCCESS,
    payload: {
      revisions,
    },
  };
}
export function loadUserRevisionsFail(error) {
  return {
    type: LOAD_USER_REVISIONS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadUserAdditions ====================================================

export function loadUserAdditions(userObjectId) {
  return {
    type: LOAD_USER_ADDITIONS,
    payload: { userObjectId },
  };
}
export function loadUserAdditionsSuccess(additions) {
  return {
    type: LOAD_USER_ADDITIONS_SUCCESS,
    payload: {
      additions,
    },
  };
}
export function loadUserAdditionsFail(error) {
  return {
    type: LOAD_USER_ADDITIONS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadUserCodes ====================================================

export function loadUserCodes(userObjectId) {
  return {
    type: LOAD_USER_CODES,
    payload: { userObjectId },
  };
}
export function loadUserCodesSuccess(codes) {
  return {
    type: LOAD_USER_CODES_SUCCESS,
    payload: {
      codes,
    },
  };
}
export function loadUserCodesFail(error) {
  return {
    type: LOAD_USER_CODES_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// editAddress ====================================================

export function editAddress(userObjectId, addressObjectId, address) {
  return {
    type: EDIT_ADDRESS,
    payload: { userObjectId, addressObjectId, address },
  };
}
export function editAddressSuccess() {
  return {
    type: EDIT_ADDRESS_SUCCESS,
    payload: {},
  };
}
export function editAddressFail(error) {
  return {
    type: EDIT_ADDRESS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// deleteAddress ====================================================

export function deleteAddress(userObjectId, addressObjectId) {
  return {
    type: DELETE_ADDRESS,
    payload: { userObjectId, addressObjectId },
  };
}
export function deleteAddressSuccess() {
  return {
    type: DELETE_ADDRESS_SUCCESS,
    payload: {},
  };
}
export function deleteAddressFail(error) {
  return {
    type: DELETE_ADDRESS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// uploadUserImage ====================================================

export function uploadUserImage(userObjectId, formData) {
  return {
    type: UPLOAD_USER_IMAGE,
    payload: { userObjectId, formData },
  };
}
export function uploadUserImageSuccess() {
  return {
    type: UPLOAD_USER_IMAGE_SUCCESS,
    payload: {},
  };
}
export function uploadUserImageFail(error) {
  return {
    type: UPLOAD_USER_IMAGE_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// activateInfinityCode ====================================================

export function activateInfinityCode(userObjectId, code) {
  return {
    type: ACTIVATE_INFINITY_CODE,
    payload: { userObjectId, code },
  };
}
export function activateInfinityCodeSuccess(codeResult) {
  return {
    type: ACTIVATE_INFINITY_CODE_SUCCESS,
    payload: { codeResult },
  };
}
export function activateInfinityCodeFail(error) {
  return {
    type: ACTIVATE_INFINITY_CODE_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadUserLikes ====================================================

export function loadUserLikes(userObjectId) {
  return {
    type: LOAD_USER_LIKES,
    payload: { userObjectId },
  };
}
export function loadUserLikesSuccess(likes) {
  return {
    type: LOAD_USER_LIKES_SUCCESS,
    payload: { likes },
  };
}
export function loadUserLikesFail(error) {
  return {
    type: LOAD_USER_LIKES_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// editUserNewsletterFromEmail ====================================================

export function editUserNewsletterFromEmail(params) {
  return {
    type: EDIT_USER_NEWSLETTER_FROM_EMAIL,
    payload: {
      params,
    },
  };
}
export function editUserNewsletterFromEmailSuccess() {
  return {
    type: EDIT_USER_NEWSLETTER_FROM_EMAIL_SUCCESS,
  };
}
export function editUserNewsletterFromEmailFail(error) {
  return {
    type: EDIT_USER_NEWSLETTER_FROM_EMAIL_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// setUserObjectIdOnly ====================================================

export function setUserObjectIdOnly(userObjectId) {
  return {
    type: SET_USER_OBJECT_ID_ONLY,
    payload: { userObjectId },
  };
}

// loadUserPoints ====================================================

export function loadUserPoints(userObjectId) {
  return {
    type: LOAD_USER_POINTS,
    payload: { userObjectId },
  };
}
export function loadUserPointsSuccess(points) {
  return {
    type: LOAD_USER_POINTS_SUCCESS,
    payload: {
      points,
    },
  };
}
export function loadUserPointsFail(error) {
  return {
    type: LOAD_USER_POINTS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadUserVerifiedPurchase ====================================================

export function loadUserVerifiedPurchase(userObjectId) {
  return {
    type: LOAD_USER_VERIFIED_PURCHASE,
    payload: { userObjectId },
  };
}
export function loadUserVerifiedPurchaseSuccess(verifiedPurchase) {
  return {
    type: LOAD_USER_VERIFIED_PURCHASE_SUCCESS,
    payload: { verifiedPurchase },
  };
}
export function loadUserVerifiedPurchaseFail(error) {
  return {
    type: LOAD_USER_VERIFIED_PURCHASE_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadUserVerifiedPurchases ====================================================

export function loadUserVerifiedPurchases(userObjectId) {
  return {
    type: LOAD_USER_VERIFIED_PURCHASES,
    payload: { userObjectId },
  };
}
export function loadUserVerifiedPurchasesSuccess(verifiedPurchases) {
  return {
    type: LOAD_USER_VERIFIED_PURCHASES_SUCCESS,
    payload: { verifiedPurchases },
  };
}
export function loadUserVerifiedPurchasesFail(error) {
  return {
    type: LOAD_USER_VERIFIED_PURCHASES_FAIL,
    payload: new Error(error),
    error: true,
  };
}

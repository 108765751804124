import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  loadAlbumApi,
  loadAlbumSerieApi,
  loadAlbumsApi,
  updateAlbumTagsApi,
} from '../services/api';

import {
  LOAD_ALBUM,
  LOAD_ALBUMS,
  LOAD_ALBUM_SERIE,
  LOAD_XMAS_SELECTION,
  UPDATE_TAGS_ON_ALBUM,
} from '../reducers/albums';
import {
  loadAlbumFail,
  loadAlbumSerieFail,
  loadAlbumSerieSuccess,
  loadAlbumSuccess,
  loadAlbumsFail,
  loadAlbumsSuccess,
  loadXmasSelectionFail,
  loadXmasSelectionSuccess,
  storeAlbums,
  updateAlbumTagsFail,
  updateAlbumTagsSuccess,
} from '../reducers/albums';
import { storePrints } from '../reducers/prints';
import { storeSeriesFlow } from '../sagas/series';
import { getClientToken, getClientTokenBackOffice } from '../selectors';
import { loadUserTagsFlow } from './tags';

function* loadAlbumSerieFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadAlbumSerieApi, clientToken, action.payload.albumObjectId);
    yield put(loadAlbumSerieSuccess(response));
    yield call(storeSeriesFlow, { payload: { series: [response], source: 'loadAlbumSerieFlow' } });
  } catch (error) {
    console.log('saga middleware error (loadAlbumSerieFlow)', error);
    yield put(loadAlbumSerieFail(error));
  }
}

export function* loadAlbumFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadAlbumApi, clientToken, action.payload.albumObjectId);
    yield call(storeAlbumsFlow, { payload: { albums: [response], source: 'loadAlbumFlow' } });
    yield put(loadAlbumSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadAlbumFlow)', error);
    yield put(loadAlbumFail(error));
  }
}

function* loadAlbumsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadAlbumsApi, clientToken, action.payload.params);
    yield call(storeAlbumsFlow, { payload: { albums: response, source: 'loadAlbumsFlow' } });
    yield put(loadAlbumsSuccess(response, action.payload.params));
  } catch (error) {
    console.log('saga middleware error (loadAlbumsFlow)', error);
    yield put(loadAlbumsFail(error));
  }
}

function* loadXmasSelectionFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadAlbumsApi, clientToken, action.payload.params);
    yield call(storeAlbumsFlow, {
      payload: { albums: response, source: 'loadXmasSelectionFlow' },
    });
    yield put(loadXmasSelectionSuccess(response, action.payload.params));
  } catch (error) {
    console.log('saga middleware error (loadXmasSelectionFlow)', error);
    yield put(loadXmasSelectionFail(error));
  }
}

function* updateAlbumTagsFlow(action) {
  try {
    let clientToken = yield select(getClientToken);
    if (!clientToken) {
      clientToken = yield select(getClientTokenBackOffice);
    }
    const response = yield call(
      updateAlbumTagsApi,
      clientToken,
      action.payload.userObjectId,
      action.payload.albumObjectId,
      action.payload.tags,
      action.payload.forcedWeight,
    );
    yield call(storeAlbumsFlow, {
      payload: { albums: [response], source: 'updateAlbumTagsFlow' },
    });

    yield call(loadUserTagsFlow, { payload: { userObjectId: action.payload.userObjectId } });
    yield put(updateAlbumTagsSuccess());
  } catch (error) {
    console.log('saga middleware error (updateAlbumTagsFlow)', error);
    yield put(updateAlbumTagsFail(error));
  }
}

export function* storeAlbumsFlow(action) {
  try {
    const albums = (action.payload.albums || []).slice();
    let prints = [];
    albums.forEach((album) => {
      // this is so the albums in store always have the same signature
      if (album.selectedPrint) {
        album.prints = (album.prints || []).concat(album.selectedPrint);
        delete album.selectedPrint;
      }
      prints = prints.concat(album.prints || []);
      album.prints = (album.prints || []).map((print) => print.objectId);
    });
    yield put(storePrints(prints, `${action.payload.source || '?'}>storeAlbumsFlow`));
    yield put(storeAlbums(albums, action.payload.source));
  } catch (error) {
    console.log('saga middleware error (storeAlbumsFlow)', error);
    yield put(loadAlbumFail(error));
  }
}

export default function* albumsWatcher() {
  yield all([
    takeLatest(LOAD_ALBUM_SERIE, loadAlbumSerieFlow),
    takeEvery(LOAD_ALBUM, loadAlbumFlow),
    takeEvery(LOAD_ALBUMS, loadAlbumsFlow),
    takeEvery(LOAD_XMAS_SELECTION, loadXmasSelectionFlow),
    takeLatest(UPDATE_TAGS_ON_ALBUM, updateAlbumTagsFlow),
  ]);
}

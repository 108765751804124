import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { loadAgendasApi } from '../services/api';

import { LOAD_AGENDAS } from '../reducers/agendas';
import { loadAgendasFail, loadAgendasSuccess } from '../reducers/agendas';
import { storeAlbumsFlow } from '../sagas/albums';
import { getClientToken } from '../selectors';

function* loadAgendasFlow(action) {
  try {
    const { daysPrior, startDate } = action.payload;
    const clientToken = yield select(getClientToken);
    const response = yield call(loadAgendasApi, clientToken, daysPrior, startDate);
    yield call(storeAlbumsFlow, { payload: { albums: response, source: 'loadAgendasFlow' } });
    yield put(loadAgendasSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadAgendasFlow)', error);
    yield put(loadAgendasFail(error));
  }
}

export default function* agendasWatcher() {
  yield all([takeLatest(LOAD_AGENDAS, loadAgendasFlow)]);
}

export const LOAD_STORE = 'LOAD_STORE';
export const LOAD_STORE_SUCCESS = 'LOAD_STORE_SUCCESS';
export const LOAD_STORE_FAIL = 'LOAD_STORE_FAIL';

export const LOAD_STORES = 'LOAD_STORES';
export const LOAD_STORES_SUCCESS = 'LOAD_STORES_SUCCESS';
export const LOAD_STORES_FAIL = 'LOAD_STORES_FAIL';

export const LOAD_STORE_BEST_SELLERS = 'LOAD_STORE_BEST_SELLERS';
export const LOAD_STORE_BEST_SELLERS_SUCCESS = 'LOAD_STORE_BEST_SELLERS_SUCCESS';
export const LOAD_STORE_BEST_SELLERS_FAIL = 'LOAD_STORE_BEST_SELLERS_FAIL';

export const CREATE_STORE_PARTNERSHIP_REQUEST = 'CREATE_STORE_PARTNERSHIP_REQUEST';
export const CREATE_STORE_PARTNERSHIP_REQUEST_SUCCESS = 'CREATE_STORE_PARTNERSHIP_REQUEST_SUCCESS';
export const CREATE_STORE_PARTNERSHIP_REQUEST_ERROR = 'CREATE_STORE_PARTNERSHIP_REQUEST_ERROR';

export const CHECK_STOCKS_FOR_EANS = 'CHECK_STOCKS_FOR_EANS';
export const CHECK_STOCKS_FOR_EANS_SUCCESS = 'CHECK_STOCKS_FOR_EANS_SUCCESS';
export const CHECK_STOCKS_FOR_EANS_FAIL = 'CHECK_STOCKS_FOR_EANS_FAIL';

export const STORE_STORES = 'STORE_STORES';

export const CLEAR_ONE_CLICK_RELATED_INFOS = 'CLEAR_ONE_CLICK_RELATED_INFOS';
export const SET_FAVORITE_SEARCH_FLAG = 'SET_FAVORITE_SEARCH_FLAG';
export const SET_AVAILABILITY_SEARCH_FLAG = 'SET_AVAILABILITY_SEARCH_FLAG';
export const SET_RESERVATIONS_STORES_TO_SHOW = 'SET_RESERVATIONS_STORES_TO_SHOW';

const initialState = {
  stores: {},
  storesBestSellers: {},
  itemsAvailabilitiesPerStores: [],
  favoriteSearchFlag: false,
  availabilitySearchFlag: false,
  reservationsStoresToShow: [],
  loading: false,
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case STORE_STORES: {
      const stores = action.payload.stores.reduce((acc, cur) => {
        acc[cur.objectId] = cur;
        return acc;
      }, {});
      return {
        ...state,
        loading: false,
        stores: Object.assign({}, state.stores, stores),
        errors: [],
      };
    }
    case LOAD_STORE: {
      return {
        ...state,
        loading: true,
        stores: initialState.stores,
      };
    }
    case LOAD_STORE_SUCCESS: {
      const store = action.payload.store;
      return {
        ...state,
        loading: false,
        stores: { ...state.stores, [store.objectId]: store },
        errors: [],
      };
    }
    case LOAD_STORE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LOAD_STORES: {
      return {
        ...state,
        loading: true,
        stores: initialState.stores,
      };
    }
    case LOAD_STORES_SUCCESS: {
      const stores = action.payload.stores.reduce((acc, cur) => {
        acc[cur.objectId] = cur;
        return acc;
      }, {});
      return {
        ...state,
        loading: false,
        stores: Object.assign({}, state.stores, stores),
        errors: [],
      };
    }
    case LOAD_STORES_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case LOAD_STORE_BEST_SELLERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_STORE_BEST_SELLERS_SUCCESS: {
      const storesBestSellers = state.storesBestSellers;
      storesBestSellers[action.payload.storeObjectId] = action.payload.bestSellingAlbumObjectIds;

      return {
        ...state,
        loading: false,
        storesBestSellers,
        errors: [],
      };
    }
    case LOAD_STORE_BEST_SELLERS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_FAVORITE_SEARCH_FLAG: {
      return {
        ...state,
        favoriteSearchFlag: action.payload.favoriteSearchFlag,
      };
    }
    case SET_AVAILABILITY_SEARCH_FLAG: {
      return {
        ...state,
        availabilitySearchFlag: action.payload.availabilitySearchFlag,
      };
    }

    case CHECK_STOCKS_FOR_EANS: {
      return {
        ...state,
        loading: true,
        itemsAvailabilitiesPerStores: initialState.itemsAvailabilitiesPerStores,
      };
    }
    case CHECK_STOCKS_FOR_EANS_SUCCESS: {
      let itemsAvailabilitiesPerStores = {};
      action.payload.stocks.map((item) => {
        item.stores.forEach((store) => {
          if (!itemsAvailabilitiesPerStores[store.objectId]) {
            itemsAvailabilitiesPerStores[store.objectId] = {
              objectId: store.objectId,
              isClosed: store.isClosed,
              availableItems: [],
              orderableItems: [],
            };
          }
          if (store.availabilityCode === 100) {
            itemsAvailabilitiesPerStores[store.objectId].availableItems.push(item.ean);
          } else if (store.availabilityCode === 104) {
            itemsAvailabilitiesPerStores[store.objectId].orderableItems.push(item.ean);
          }
        });
      });

      return {
        ...state,
        loading: false,
        errors: [],
        itemsAvailabilitiesPerStores: Object.values(itemsAvailabilitiesPerStores),
      };
    }
    case CHECK_STOCKS_FOR_EANS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_RESERVATIONS_STORES_TO_SHOW: {
      return {
        ...state,
        reservationsStoresToShow: action.payload.stores,
      };
    }

    case CLEAR_ONE_CLICK_RELATED_INFOS: {
      return {
        ...state,
        favoriteSearchFlag: initialState.favoriteSearchFlag,
        availabilitySearchFlag: initialState.availabilitySearchFlag,
        reservationsStoresToShow: initialState.reservationsStoresToShow,
      };
    }

    default: {
      return state;
    }
  }
}

// loadStores ====================================================

export function storeStores(stores) {
  return {
    type: STORE_STORES,
    payload: { stores },
  };
}

// loadStores ====================================================

export function loadStores() {
  return {
    type: LOAD_STORES,
  };
}
export function loadStoresSuccess(stores) {
  return {
    type: LOAD_STORES_SUCCESS,
    payload: {
      stores,
    },
  };
}
export function loadStoresFail(error) {
  return {
    type: LOAD_STORES_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadStore ====================================================

export function loadStore(storeObjectId) {
  return {
    type: LOAD_STORE,
    payload: { storeObjectId },
  };
}
export function loadStoreSuccess(store) {
  return {
    type: LOAD_STORE_SUCCESS,
    payload: {
      store,
    },
  };
}
export function loadStoreFail(error) {
  return {
    type: LOAD_STORE_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// addStorePartnershipRequest ====================================================

export function createStorePartnershipRequest(storeObjectId, userObjectId) {
  return {
    type: CREATE_STORE_PARTNERSHIP_REQUEST,
    payload: { storeObjectId, userObjectId },
  };
}
export function createStorePartnershipRequestSuccess(clientToken, userObjectId, storeObjectId) {
  return {
    type: CREATE_STORE_PARTNERSHIP_REQUEST_SUCCESS,
    payload: { clientToken, userObjectId, storeObjectId },
  };
}
export function createStorePartnershipRequestError(error) {
  return {
    type: CREATE_STORE_PARTNERSHIP_REQUEST_ERROR,
    payload: new Error(error),
    error: true,
  };
}

// checkStocksForEans ====================================================

export function checkStocksForEans(eans, storeObjectId = null) {
  return {
    type: CHECK_STOCKS_FOR_EANS,
    payload: { eans, storeObjectId },
  };
}
export function checkStocksForEansSuccess(stocks) {
  return {
    type: CHECK_STOCKS_FOR_EANS_SUCCESS,
    payload: {
      stocks,
    },
  };
}
export function checkStocksForEansFail(error) {
  return {
    type: CHECK_STOCKS_FOR_EANS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// loadStoreBestSellers ====================================================

export function loadStoreBestSellers(storeObjectId) {
  return {
    type: LOAD_STORE_BEST_SELLERS,
    payload: { storeObjectId },
  };
}
export function loadStoreBestSellersSuccess(storeObjectId, bestSellingAlbumObjectIds) {
  return {
    type: LOAD_STORE_BEST_SELLERS_SUCCESS,
    payload: {
      storeObjectId,
      bestSellingAlbumObjectIds,
    },
  };
}
export function loadStoreBestSellersFail(error) {
  return {
    type: LOAD_STORE_BEST_SELLERS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// setReservationsStoresToShow ====================================================

export function setReservationsStoresToShow(stores) {
  return {
    type: SET_RESERVATIONS_STORES_TO_SHOW,
    payload: { stores },
  };
}

// setFavoriteSearchFlag ====================================================

export function setFavoriteSearchFlag(favoriteSearchFlag) {
  return {
    type: SET_FAVORITE_SEARCH_FLAG,
    payload: { favoriteSearchFlag },
  };
}

// setAvailabilitySearchFlag ====================================================

export function setAvailabilitySearchFlag(availabilitySearchFlag) {
  return {
    type: SET_AVAILABILITY_SEARCH_FLAG,
    payload: { availabilitySearchFlag },
  };
}

// clearOneClickRelatedInfos ====================================================

export function clearOneClickRelatedInfos() {
  return {
    type: CLEAR_ONE_CLICK_RELATED_INFOS,
  };
}

import { all, takeLatest } from 'redux-saga/effects';

import {
  LOGIN_GUEST_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_WITH_FACEBOOK_SUCCESS,
  LOGOUT,
  SIGNUP_SUCCESS,
} from 'bubble-reducers/src/reducers/auth';

import {
  createCookie,
  deleteCookie,
  deleteInLocalStorage,
  saveInLocalStorage,
} from 'bubble-utils/src/auth-utils';

function signupSuccessFlow(action) {
  try {
    const payload = action.payload.initialPayload;
    const authData = action.payload.authData;

    createCookie(payload.cookie.name, authData.token, payload.cookie.options);
    saveInLocalStorage(payload.localStorage.keyName, { user: authData.user });
  } catch (error) {
    console.log('saga middleware error (signupSuccessFlow web)', error);
  }
}

function loginSuccessFlow(action) {
  try {
    const payload = action.payload.initialPayload;
    const authData = action.payload.authData;

    createCookie(payload.cookie.name, authData.token, payload.cookie.options);
    saveInLocalStorage(payload.localStorage.keyName, { user: authData.user });
  } catch (error) {
    console.log('saga middleware error (loginSuccessFlow web)', error);
  }
}

function loginGuestSuccessFlow(action) {
  try {
    const payload = action.payload.initialPayload;
    const authData = action.payload.authData;

    createCookie(payload.cookie.name, authData.token, payload.cookie.options);
    saveInLocalStorage(payload.localStorage.keyName, { guest: authData.guest });
  } catch (error) {
    console.log('saga middleware error (loginSuccessFlow web)', error);
  }
}

function loginWithFacebookSuccessFlow(action) {
  try {
    const payload = action.payload.initialPayload;
    const authData = action.payload.authData;

    createCookie(payload.cookie.name, authData.token, payload.cookie.options);
    saveInLocalStorage(payload.localStorage.keyName, { user: authData.user });
  } catch (error) {
    console.log('saga middleware error (loginWithFacebookSuccessFlow web)', error);
  }
}

function logoutFlow(action) {
  try {
    const payload = action.payload;

    deleteCookie(payload.cookie.name, payload.cookie.options);
    deleteInLocalStorage(payload.localStorage.keyName);
  } catch (error) {
    console.log('saga middleware error (logoutFlow web)', error);
  }
}

export default function* cartWatcher() {
  yield all([
    takeLatest(SIGNUP_SUCCESS, signupSuccessFlow),
    takeLatest(LOGIN_SUCCESS, loginSuccessFlow),
    takeLatest(LOGIN_GUEST_SUCCESS, loginGuestSuccessFlow),
    takeLatest(LOGIN_WITH_FACEBOOK_SUCCESS, loginWithFacebookSuccessFlow),
    takeLatest(LOGOUT, logoutFlow),
  ]);
}

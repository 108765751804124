import React from 'react';

import config from '@/project-config';
import { connect } from 'react-redux';

import bubbleUtils from 'bubble-utils';

class BubbleIntercom extends React.Component {
  constructor(props) {
    super(props);

    this.lastUserSeen = props.user;
    if (!bubbleUtils.env.isServer) {
      setTimeout(() => {
        this.initialize();
      }, config.intercom.initializationDelay);
    }
  }

  initialize() {
    if (!config.intercom.appId) {
      console.error('[bubble-intercom] tracking IDs are required.');
      return;
    }

    window.intercomSettings = {
      app_id: config.intercom.appId,
    };

    // Placing the code within <head> tags reduces the chances of browsers or third-party
    // code blocking the execution.
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;

        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + config.intercom.appId;
        var head = d.getElementsByTagName('head')[0];
        head.appendChild(s);
      }
    })();

    // Initialize Intercon
    window.Intercom('boot', {
      app_id: config.intercom.appId,
    });

    this.updateIntercomUserWithLastUserSeen();
  }

  updateIntercomUserWithLastUserSeen() {
    if (!window.Intercom) {
      return;
    }

    const lastUserSeen = this.lastUserSeen;
    if (lastUserSeen) {
      window.Intercom('update', {
        user_id: lastUserSeen.objectId,
        user_hash: lastUserSeen.intercomHashWeb,
        email: lastUserSeen.email,
        name: lastUserSeen.email,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      this.lastUserSeen = this.props.user;
    }
    this.updateIntercomUserWithLastUserSeen();
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, null)(BubbleIntercom);

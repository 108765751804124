import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  activateInfinityCodeApi,
  addStoreToFavoriteApi,
  createAddressApi,
  deleteAddressApi,
  deleteUserApi,
  editAddressApi,
  editUserApi,
  editUserNewsletterFromEmailApi,
  loadOrderApi,
  loadUserAdditionsApi,
  loadUserAddressesApi,
  loadUserApi,
  loadUserCodesApi,
  loadUserFavoriteStoresApi,
  loadUserLastAddedAlbumsApi,
  loadUserLikesApi,
  loadUserOrdersApi,
  loadUserPointsApi,
  loadUserRevisionsApi,
  loadUserVerifiedPurchaseApi,
  loadUserVerifiedPurchasesApi,
  removeStoreFromFavoriteApi,
  uploadUserImageApi,
} from '../services/api';

import { storeStores } from '../reducers/stores';
import {
  ACTIVATE_INFINITY_CODE,
  ADD_STORE_TO_FAVORITE,
  CREATE_ADDRESS,
  DELETE_ADDRESS,
  DELETE_USER,
  EDIT_ADDRESS,
  EDIT_USER,
  EDIT_USER_NEWSLETTER_FROM_EMAIL,
  LOAD_USER,
  LOAD_USER_ADDITIONS,
  LOAD_USER_ADDRESSES,
  LOAD_USER_CODES,
  LOAD_USER_FAVORITE_STORES,
  LOAD_USER_LAST_ADDED_ALBUMS,
  LOAD_USER_LIKES,
  LOAD_USER_ORDER,
  LOAD_USER_ORDERS,
  LOAD_USER_POINTS,
  LOAD_USER_REVISIONS,
  LOAD_USER_VERIFIED_PURCHASE,
  LOAD_USER_VERIFIED_PURCHASES,
  REMOVE_STORE_FROM_FAVORITE,
  UPLOAD_USER_IMAGE,
} from '../reducers/user';
import {
  activateInfinityCodeFail,
  activateInfinityCodeSuccess,
  addStoreToFavoriteFail,
  addStoreToFavoriteSuccess,
  createAddressFail,
  createAddressSuccess,
  deleteAddressFail,
  deleteAddressSuccess,
  deleteUserFail,
  deleteUserSuccess,
  editAddressFail,
  editAddressSuccess,
  editUserFail,
  editUserNewsletterFromEmailFail,
  editUserNewsletterFromEmailSuccess,
  editUserSuccess,
  loadUserAdditionsFail,
  loadUserAdditionsSuccess,
  loadUserAddresses,
  loadUserAddressesFail,
  loadUserAddressesSuccess,
  loadUserCodesFail,
  loadUserCodesSuccess,
  loadUserFail,
  loadUserFavoriteStores,
  loadUserFavoriteStoresFail,
  loadUserFavoriteStoresSuccess,
  loadUserLastAddedAlbumsFail,
  loadUserLastAddedAlbumsSuccess,
  loadUserLikesFail,
  loadUserLikesSuccess,
  loadUserOrderFail,
  loadUserOrderSuccess,
  loadUserOrdersFail,
  loadUserOrdersSuccess,
  loadUserPointsFail,
  loadUserPointsSuccess,
  loadUserRevisionsFail,
  loadUserRevisionsSuccess,
  loadUserSuccess,
  loadUserVerifiedPurchaseFail,
  loadUserVerifiedPurchaseSuccess,
  loadUserVerifiedPurchasesFail,
  loadUserVerifiedPurchasesSuccess,
  removeStoreFromFavoriteFail,
  removeStoreFromFavoriteSuccess,
  uploadUserImageFail,
  uploadUserImageSuccess,
} from '../reducers/user';
import { getClientToken } from '../selectors';

function* loadUserOrdersFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadUserOrdersApi, clientToken, action.payload.userObjectId);
    yield put(loadUserOrdersSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadUserOrdersFlow)', error);
    yield put(loadUserOrdersFail(error));
  }
}
function* loadUserOrderFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadOrderApi, clientToken, action.payload.orderObjectId);
    yield put(loadUserOrderSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadUserOrderFlow)', error);
    yield put(loadUserOrderFail(error));
  }
}
function* loadUserFavoriteStoresFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      loadUserFavoriteStoresApi,
      clientToken,
      action.payload.userObjectId,
    );
    yield put(storeStores(response));
    yield put(loadUserFavoriteStoresSuccess(response.map((store) => store.objectId)));
  } catch (error) {
    yield put(loadUserFavoriteStoresFail(error));
  }
}
function* loadUserAddressesFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadUserAddressesApi, clientToken, action.payload.userObjectId);
    yield put(loadUserAddressesSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadUserAddressesFlow)', error);
    yield put(loadUserAddressesFail(error));
  }
}
export function* loadUserFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadUserApi, clientToken, action.payload.userObjectId);
    yield put(loadUserSuccess(response, action.payload.isGuest));
  } catch (error) {
    console.log('saga middleware error (loadUserFlow)', error);
    yield put(loadUserFail(error));
  }
}
function* loadUserLastAddedAlbumsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      loadUserLastAddedAlbumsApi,
      clientToken,
      action.payload.userObjectId,
      action.payload.limit,
    );
    yield put(loadUserLastAddedAlbumsSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadUserLastAddedAlbumsFlow)', error);
    yield put(loadUserLastAddedAlbumsFail(error));
  }
}
function* createAddressFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(createAddressApi, clientToken, action.payload.address);
    yield put(loadUserAddresses(action.payload.userObjectId));
    yield put(createAddressSuccess(response));
  } catch (error) {
    console.log('saga middleware error (createAddressFlow)', error);
    yield put(createAddressFail(error));
  }
}
function* addStoreToFavoriteFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      addStoreToFavoriteApi,
      clientToken,
      action.payload.userObjectId,
      action.payload.storeObjectId,
    );
    yield put(loadUserFavoriteStores(action.payload.userObjectId));
    yield put(addStoreToFavoriteSuccess(response));
  } catch (error) {
    console.log('saga middleware error (addStoreToFavoriteFlow)', error);
    yield put(addStoreToFavoriteFail(error));
  }
}

function* removeStoreFromFavoriteFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      removeStoreFromFavoriteApi,
      clientToken,
      action.payload.userObjectId,
      action.payload.storeObjectId,
    );
    yield put(loadUserFavoriteStores(action.payload.userObjectId));
    yield put(removeStoreFromFavoriteSuccess(response));
  } catch (error) {
    console.log('saga middleware error (removeStoreFromFavoriteFlow)', error);
    yield put(removeStoreFromFavoriteFail(error));
  }
}

function* loadUserRevisionsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadUserRevisionsApi, clientToken, action.payload.userObjectId);
    yield put(loadUserRevisionsSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadUserRevisionsFlow)', error);
    yield put(loadUserRevisionsFail(error));
  }
}

function* loadUserAdditionsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadUserAdditionsApi, clientToken, action.payload.userObjectId);
    yield put(loadUserAdditionsSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadUserAdditionsFlow)', error);
    yield put(loadUserAdditionsFail(error));
  }
}

function* loadUserCodesFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadUserCodesApi, clientToken, action.payload.userObjectId);
    yield put(loadUserCodesSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadUserCodesFlow)', error);
    yield put(loadUserCodesFail(error));
  }
}

export function* editUserFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      editUserApi,
      clientToken,
      action.payload.userObjectId,
      action.payload.user,
    );
    yield put(editUserSuccess(response));
  } catch (error) {
    console.log('saga middleware error (editUserFlow)', error);
    yield put(editUserFail(error));
  }
}

function* deleteUserFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    yield call(deleteUserApi, clientToken, action.payload.userObjectId);
    yield put(deleteUserSuccess());
  } catch (error) {
    console.log('saga middleware error (deleteUserFlow)', error);
    yield put(deleteUserFail(error));
  }
}

function* editAddressFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      editAddressApi,
      clientToken,
      action.payload.addressObjectId,
      action.payload.address,
    );
    yield put(loadUserAddresses(action.payload.userObjectId));
    yield put(editAddressSuccess(response));
  } catch (error) {
    console.log('saga middleware error (editAddressFlow)', error);
    yield put(editAddressFail(error));
  }
}

function* deleteAddressFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(deleteAddressApi, clientToken, action.payload.addressObjectId);
    yield put(loadUserAddresses(action.payload.userObjectId));
    yield put(deleteAddressSuccess(response));
  } catch (error) {
    console.log('saga middleware error (deleteAddressFlow)', error);
    yield put(deleteAddressFail(error));
  }
}

export function* uploadUserImageFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      uploadUserImageApi,
      clientToken,
      action.payload.userObjectId,
      action.payload.formData,
    );

    yield put(uploadUserImageSuccess(response));
    yield put(loadUserSuccess(response));
  } catch (error) {
    console.log('saga middleware error (uploadUserImageFlow)', error);
    yield put(uploadUserImageFail(error));
  }
}

function* activateInfinityCodeFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      activateInfinityCodeApi,
      clientToken,
      action.payload.userObjectId,
      action.payload.code,
    );

    yield call(loadUserFlow, { payload: { userObjectId: action.payload.userObjectId } });
    yield put(activateInfinityCodeSuccess(response));
  } catch (error) {
    console.log('saga middleware error activateInfinityCodeFlow)', error);
    yield put(activateInfinityCodeFail(error));
  }
}

export function* loadUserLikesFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadUserLikesApi, clientToken, action.payload.userObjectId);

    yield put(loadUserLikesSuccess(response));
  } catch (error) {
    console.log('saga middleware error loadUserLikesFlow)', error);
    yield put(loadUserLikesFail(error));
  }
}

export function* editUserNewsletterFromEmailFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    yield call(editUserNewsletterFromEmailApi, clientToken, action.payload.params);

    yield put(editUserNewsletterFromEmailSuccess());
  } catch (error) {
    console.log('saga middleware error editUserNewsletterFromEmailFlow)', error);
    yield put(editUserNewsletterFromEmailFail(error));
  }
}

function* loadUserPointsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(loadUserPointsApi, clientToken, action.payload.userObjectId);
    yield put(loadUserPointsSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadUserPointsFlow)', error);
    yield put(loadUserPointsFail(error));
  }
}

function* loadUserVerifiedPurchaseFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      loadUserVerifiedPurchaseApi,
      clientToken,
      action.payload.userObjectId,
    );
    yield put(loadUserVerifiedPurchaseSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadUserVerifiedPurchaseFlow)', error);
    yield put(loadUserVerifiedPurchaseFail(error));
  }
}

function* loadUserVerifiedPurchasesFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const response = yield call(
      loadUserVerifiedPurchasesApi,
      clientToken,
      action.payload.userObjectId,
    );
    yield put(loadUserVerifiedPurchasesSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadUserVerifiedPurchasesFlow)', error);
    yield put(loadUserVerifiedPurchasesFail(error));
  }
}

export default function* userWatcher() {
  yield all([
    takeEvery(LOAD_USER, loadUserFlow),

    takeLatest(LOAD_USER_ORDER, loadUserOrderFlow),
    takeLatest(LOAD_USER_ORDERS, loadUserOrdersFlow),
    takeLatest(LOAD_USER_FAVORITE_STORES, loadUserFavoriteStoresFlow),
    takeLatest(LOAD_USER_LAST_ADDED_ALBUMS, loadUserLastAddedAlbumsFlow),
    takeLatest(LOAD_USER_ADDRESSES, loadUserAddressesFlow),
    takeLatest(CREATE_ADDRESS, createAddressFlow),
    takeLatest(ADD_STORE_TO_FAVORITE, addStoreToFavoriteFlow),
    takeLatest(REMOVE_STORE_FROM_FAVORITE, removeStoreFromFavoriteFlow),
    takeLatest(LOAD_USER_REVISIONS, loadUserRevisionsFlow),
    takeLatest(LOAD_USER_ADDITIONS, loadUserAdditionsFlow),
    takeLatest(LOAD_USER_CODES, loadUserCodesFlow),
    takeLatest(EDIT_USER, editUserFlow),
    takeLatest(DELETE_USER, deleteUserFlow),
    takeLatest(EDIT_ADDRESS, editAddressFlow),
    takeLatest(DELETE_ADDRESS, deleteAddressFlow),
    takeLatest(UPLOAD_USER_IMAGE, uploadUserImageFlow),
    takeLatest(ACTIVATE_INFINITY_CODE, activateInfinityCodeFlow),
    takeLatest(LOAD_USER_LIKES, loadUserLikesFlow),
    takeLatest(EDIT_USER_NEWSLETTER_FROM_EMAIL, editUserNewsletterFromEmailFlow),
    takeLatest(LOAD_USER_POINTS, loadUserPointsFlow),
    takeLatest(LOAD_USER_VERIFIED_PURCHASE, loadUserVerifiedPurchaseFlow),
    takeLatest(LOAD_USER_VERIFIED_PURCHASES, loadUserVerifiedPurchasesFlow),
  ]);
}

export const filterSeries = (series, albums, filtersTmp, userProfile = {}) => {
  if (!userProfile) {
    return series;
  }
  const filters = Object.assign({}, filtersTmp);
  Object.keys(filters).forEach((key) => (filters[key] = Array.from(filters[key])));

  return (
    series
      .map((serieTmp) => {
        const serie = Object.assign({}, serieTmp);
        serie.albums = serie.albums.slice();
        let isIn = true;
        // category
        if (filters.category.length) {
          isIn = filters.category.some((c) => (serie.serie.category || '').toLowerCase() === c);
        }

        // genre
        if (isIn && filters.genre.length)
          isIn = filters.genre.some((g) => (serie.serie.genre || '').toLowerCase() === g);

        if (isIn && filters.started.length && filters.started.length !== 2) {
          const value = filters.started[0] === 1;
          isIn = value ? serie.albumsOwned !== 0 : serie.albumsOwned === 0;
        }

        // serie is ok, now checking each albums
        if (isIn) {
          let newAlbums = (serie.albums || [])
            .map((albumObjectId) => {
              const album = albums[albumObjectId];
              if (!album) return false;
              let isAlbumIn = true;

              // read
              if (filters.read.length && filters.read.length !== 2) {
                const value = filters.read[0] === 1;
                isAlbumIn = !!(userProfile.readAlbumsIdsMap || {})[album.objectId] === value;
              }

              // owned
              if (isAlbumIn && filters.owned.length && filters.owned.length !== 2) {
                const value = filters.owned[0] === 1;
                isAlbumIn = !!(userProfile.ownedAlbumsIdsMap || {})[album.objectId] === value;
              }

              // signed
              if (isAlbumIn && filters.signed.length) {
                // const value = filters.signed[0] === 1;
                isAlbumIn = !!(userProfile.signedAlbumsIdsMap || {})[album.objectId];
              }

              // originalEdition
              if (isAlbumIn && filters.oe.length) {
                // const value = filters.originalEdition[0] === 1;
                isAlbumIn = !!(userProfile.originalEditionsAlbumsIdsMap || {})[album.objectId];
              }

              // loaned
              if (isAlbumIn && filters.loaned.length) {
                // const value = filters.originalEdition[0] === 1;
                isAlbumIn = !!(userProfile.loanAlbumsIdsMap || {})[album.objectId];
              }

              if (isAlbumIn) return album;
              return null;
            })
            .filter((row) => row);

          // remap serie.albums to objectIds
          serie.albums = newAlbums.map((album) => album.objectId);
          // remove serie if no albums match
          if (serie.albums.length === 0) {
            return null;
          }
          return serie;
        }
        return null;
      })
      .filter((row) => row) || []
  );
};

import React, { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadArticles } from 'bubble-reducers/src/reducers/articles';

import ArticleCard from '@/components/ArticleCard/ArticleCard';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

export default function CategoryRelatedArticles({ title, category }) {
  const dispatch = useDispatch();
  let articles = useSelector((state) => state.articles.articlesMapByObjectId); // whole articles list for no reason
  const memoizedArticlesPlaceholder = useMemo(() => ({}), []);
  articles = articles || memoizedArticlesPlaceholder;

  const articlesComponents = useMemo(
    () =>
      Object.values(articles)
        .filter((article) => article.tags.includes(category?.toLowerCase()))
        .slice(0, 4)
        .map((article) => (
          <ArticleCard
            className="col-10 col-md-6 col-lg-3 pt-3"
            key={article.objectId}
            article={article}
          />
        )),
    [articles, category],
  );

  useEffect(() => {
    if (!articles.length) {
      dispatch(loadArticles());
    }
  }, [dispatch, articles.length]);

  return (
    <TitledListContainer
      noWrap
      title={
        <div className="d-block d-md-flex">
          <div>Suivez toute l'actualité de {title || 'cette série'} sur notre blog</div>
          <Link className="fw-normal bb-regular-text-size d-md-none py-2" to="/9emeart">
            Aller sur notre blog
          </Link>
        </div>
      }
      topRightLink={
        <Link className="d-none d-md-flex" to="/9emeart">
          Aller sur notre blog
        </Link>
      }
      list={articlesComponents}
    />
  );
}

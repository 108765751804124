import React from 'react';

import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { loadAlbums } from 'bubble-reducers/src/reducers/albums';
import { loadArticlesForResource } from 'bubble-reducers/src/reducers/articles';
import { loadAuthor, loadRelatedAuthors } from 'bubble-reducers/src/reducers/authors';
import { loadSerie } from 'bubble-reducers/src/reducers/series';

import { getSeoForAuthor } from '@/services/seo-utils';

import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import InfinityCard from '@/components/InfinityCard/InfinityCard';
import withRouter from '@/components/withRouter';

import AuthorHeader from './components/AuthorHeader/AuthorHeader';
import AuthorNavBar from './components/AuthorNavBar';
import AuthorAlbumsSection from './scenes/AuthorAlbumsSection';
import AuthorAllSection from './scenes/AuthorAllSection';
import AuthorAllSectionBottom from './scenes/AuthorAllSectionBottom';
import AuthorArticlesSection from './scenes/AuthorArticlesSection';
import AuthorRelatedAuthorsSection from './scenes/AuthorRelatedAuthorsSection';
import AuthorSeriesSection from './scenes/AuthorSeriesSection';

class Author extends React.Component {
  constructor(props) {
    super(props);
    this.loadInfos(this.props.router?.params?.authorObjectId);
  }

  loadInfos(authorObjectId) {
    const { loadAuthor, loadRelatedAuthors, loadArticlesForResource, loadAlbums } = this.props;

    loadAuthor(authorObjectId);
    loadRelatedAuthors(authorObjectId);
    loadAlbums({ author: authorObjectId });
    loadArticlesForResource(authorObjectId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.router?.params?.authorObjectId !== this.props.router?.params?.authorObjectId) {
      this.loadInfos(this.props.router?.params?.authorObjectId);
    }
  }

  render() {
    const { author } = this.props;
    const authorObjectId = this.props.router?.params?.authorObjectId;
    return (
      <>
        <BubbleHelmet seo={getSeoForAuthor(author)} />
        <AuthorHeader authorObjectId={authorObjectId} />
        <div className="bb-background-light-grey" id="ct1">
          <div className="container">
            <div className="row px-sm-3">
              <div className="col-xl-2 d-none d-xl-block">
                <InfinityCard />
              </div>
              <div className="col-xl-10">
                <AuthorNavBar authorObjectId={authorObjectId} />
                <Routes>
                  <Route
                    path="/albums"
                    element={<AuthorAlbumsSection authorObjectId={authorObjectId} />}
                  />
                  <Route
                    path="/series"
                    element={<AuthorSeriesSection authorObjectId={authorObjectId} />}
                  />
                  <Route
                    path="/articles"
                    element={<AuthorArticlesSection authorObjectId={authorObjectId} />}
                  />
                  <Route
                    path="/authors"
                    element={<AuthorRelatedAuthorsSection authorObjectId={authorObjectId} />}
                  />
                  <Route path="/" element={<AuthorAllSection authorObjectId={authorObjectId} />} />
                </Routes>
              </div>
            </div>
          </div>
          <Routes>
            <Route path="/" element={<AuthorAllSectionBottom authorObjectId={authorObjectId} />} />
          </Routes>
          <div className="py-4" />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  author: state.authors.authors[props.router?.params?.authorObjectId] || null,
});

export default withRouter(
  connect(mapStateToProps, {
    loadAuthor,
    loadRelatedAuthors,
    loadArticlesForResource,
    loadAlbums,
    loadSerie,
  })(Author),
);

import bubbleConfig from 'bubble-config';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { UPDATE_WISHLIST_PRINTS } from 'bubble-reducers/src/reducers/profiles';
import { removeFromCartFlow } from 'bubble-reducers/src/sagas/cart';
import { checkEanStatusFlow, createAlbumAdditionFlow } from 'bubble-reducers/src/sagas/catalogs';
import { updateLibraryFlow } from 'bubble-reducers/src/sagas/profiles';
import { editUserFlow, loadUserFlow } from 'bubble-reducers/src/sagas/user';
import { getUserOrGuest } from 'bubble-reducers/src/selectors';

import { createCookie, getCookie } from 'bubble-utils/src/auth-utils';
import { formatDateWithFormat } from 'bubble-utils/src/date-utils';

import Toaster from '@/components/DUPLICATE/Toaster/Toaster';

import {
  ACTIVATE_TRIAL_PERIOD,
  ADD_ALBUM_TO_ADDITION,
  MOVE_CART_ALBUM_TO_WISHLIST,
  REOPTIN_NEWSLETTER,
  addAlbumToAdditionFail,
  addAlbumToAdditionSuccess,
  reOptinNewsletterSuccess,
} from '../reducers/website-other';

// payload = {albumObjectId: string, printObjectId: string}
function* moveCartAlbumToWishlistFlow(action) {
  const { item } = action.payload;

  yield call(removeFromCartFlow, {
    payload: { prints: [{ ...item.extra, ...item, objectId: item.itemObjectId }] },
  });
  yield call(updateLibraryFlow, {
    payload: { prints: [{ printObjectId: item.itemObjectId, isWishlist: {} }] },
  });
  Toaster.addToast('Wishlist', '1 album déplacé vers ma wishlist');
}

// payload = {libraryObjectId, printIds, wishlistObject}
function updateWishlistAlbumsFlow(action) {
  const { libraryObjectId, printIds, wishlistObject } = action.payload;
  const wishlistBookingsCookie = getCookie(bubbleConfig.cookies.wishlistBookings.name) || {
    bookings: {},
  };

  const bookingsForRecipientSet = new Set(wishlistBookingsCookie.bookings[libraryObjectId] || []);
  if (wishlistObject.isGifted) printIds.forEach((id) => bookingsForRecipientSet.add(id));
  if (!wishlistObject.isGifted) printIds.forEach((id) => bookingsForRecipientSet.delete(id));

  wishlistBookingsCookie.bookings[libraryObjectId] = Array.from(bookingsForRecipientSet);

  createCookie(
    bubbleConfig.cookies.wishlistBookings.name,
    {
      bookings: wishlistBookingsCookie.bookings,
    },
    bubbleConfig.cookies.defaultOptions,
  );
}

// payload = {userObjectId}
function* activateTrialPeriodFlow(action) {
  const { userObjectId } = action.payload;
  try {
    yield call(editUserFlow, {
      payload: { userObjectId, user: { hasActivatedTrialPeriod: true } },
    });
    yield call(loadUserFlow, { payload: { userObjectId } });
    const newUser = yield select(getUserOrGuest);
    alert(
      `Votre version d'essai est activée jusqu'au ${formatDateWithFormat(
        newUser.infinityExpirationDate,
      )} 🎉`,
    );
  } catch (error) {
    console.log('saga middleware error (activateTrialPeriodFlow)', error);
  }
}

function* reOptinNewsletterFlow(action) {
  const { userObjectId, email } = action.payload;
  try {
    yield call(editUserFlow, {
      payload: {
        userObjectId,
        user: { hasAcceptedNewsletter: true, hasAcceptedNewsletterCommercial: true, email },
      },
    });
    yield call(loadUserFlow, { payload: { userObjectId } });
    yield put(reOptinNewsletterSuccess());
  } catch (error) {
    console.log('saga middleware error (activateTrialPeriodFlow)', error);
  }
}

// * 'missingEan': EAN is missing, please fill one
// * 'notFound': the EAN hasn't been found anywhere
// * 'foundInProduction': the EAN is already on our production database
// * 'dataFound': data has been found for the EAN in catalog
// * 'alreadyAdded': the EAN has already been added
function* addAlbumToAdditionFlow(action) {
  try {
    const { userObjectId, userEmail, addition } = action.payload;
    const eanStatus = yield call(checkEanStatusFlow, { payload: { ean: addition.ean } });
    console.log('eanStatus', eanStatus);
    if (eanStatus.status === 'alreadyAdded' || eanStatus.status === 'dataFound') {
      if (
        eanStatus.data.isRejected &&
        window.confirm(
          [
            "Nous avons déjà refusé cet album pour l'une des raisons suivantes :",
            "- C'est une réédition d'un album déjà présent.",
            "- C'est une édition non francophone.",
            "- Ce n'est pas une bande dessinée",
            '',
            'Rien de tout ça ? Clickez sur "OK" pour nous le resoumettre',
          ].join('\n'),
        )
      ) {
        addition.isResubmisison = true;
        yield call(createAlbumAdditionFlow, { payload: { userObjectId, userEmail, addition } });
        alert("C'est envoyé !\nMerci pour votre aide :)");
      } else if (eanStatus.data.isInProduction) {
        alert(
          "Cet album se trouve déjà dans notre catalogue, si vous ne l'avez pas trouvé dans la recherche, n'hésitez pas à nous faire signe sur le chat pour nous en informer.",
        );
      } else if (!eanStatus.data.isRejected && !eanStatus.data.isInProduction) {
        alert(
          "Cet album est déjà dans notre pile d'ajouts à traiter et nous faisons tout pour le faire au plus vite 🏎️ . N'hésitez pas à recommencer votre recherche dans quelques heures.\nMerci pour votre patience 🙏",
        );
      }
    } else if (eanStatus.status === 'foundInProduction') {
      alert(
        "Cet album se trouve déjà dans notre catalogue, si vous ne l'avez pas trouvé dans la recherche, n'hésitez pas à nous faire signe sur le chat pour nous en informer.",
      );
    } else {
      yield call(createAlbumAdditionFlow, { payload: { userObjectId, userEmail, addition } });
      alert("C'est envoyé !\nMerci pour votre aide :)");
    }
    yield put(addAlbumToAdditionSuccess());
  } catch (error) {
    console.log('saga middleware error (activateTrialPeriodFlow)', error);
    yield put(addAlbumToAdditionFail(error));
  }
}

export default function* otherWatcher() {
  yield all([
    takeEvery(MOVE_CART_ALBUM_TO_WISHLIST, moveCartAlbumToWishlistFlow),
    takeEvery(UPDATE_WISHLIST_PRINTS, updateWishlistAlbumsFlow),
    takeEvery(ACTIVATE_TRIAL_PERIOD, activateTrialPeriodFlow),
    takeLatest(REOPTIN_NEWSLETTER, reOptinNewsletterFlow),
    takeLatest(ADD_ALBUM_TO_ADDITION, addAlbumToAdditionFlow),
  ]);
}

import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';

import config from '@/project-config';
import FacebookLogin from 'react-facebook-login';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useLocation } from 'react-router-dom';

import { loginWithFacebook, signup } from 'bubble-reducers/src/reducers/auth';
import { getUserId } from 'bubble-reducers/src/selectors';

import { isValidEmail } from 'bubble-utils/src/validity-utils';

import useEffectOnce from '@/services/hooks/useEffectOnce';

import BubbleCheckbox from '@/components/BubbleCheckbox/BubbleCheckbox';
import { Icon } from '@/components/Icon/Icon';
import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';
import PhoneAndQuestions from '@/components/PhoneAndQuestions/PhoneAndQuestions';

import KeyValueCard from './components/KeyValueCard';

import './Login.scss';

const SignupInvite = () => {
  const location = useLocation();
  // query parameter _code_ should NOT be named "code", "code" interfers with facebook login somehow
  const urlParams = new URLSearchParams(location.search);
  const codeFromLocation = urlParams.get('rcode') || null;
  const userObjectId = useSelector(getUserId);
  const signupErrors = useSelector((state) => state.auth.signupErrors);
  const [signupOptions, setSignupOptions] = useState({ referralCode: codeFromLocation || '' });
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const dispatch = useDispatch();
  let signupInputRef = React.createRef();

  useEffectOnce(() => {
    if (signupInputRef) {
      signupInputRef.focus();
    }
  }, []);

  useEffect(() => {
    if (!shouldRedirect && userObjectId) {
      setShouldRedirect(true);
    }
  }, [shouldRedirect, userObjectId]);

  const onChangeField = useCallback(
    (field, value) => setSignupOptions((signupOptions) => ({ ...signupOptions, [field]: value })),
    [setSignupOptions],
  );

  const onClickSignup = () => {
    if (!signupOptions.email || !signupOptions.password || !signupOptions.isGdprCompliant) {
      return alert('Il manque des informations');
    }
    if (!isValidEmail(signupOptions.email)) {
      return alert('Votre e-mail semble incorrect');
    }
    if (signupOptions.password.length < 7) {
      return alert('Votre mot de passe doit contenir au moins 7 caractères');
    }

    dispatch(
      signup(
        signupOptions.email,
        signupOptions.password,
        signupOptions.hasAcceptedNewsletter,
        signupOptions.isGdprCompliant,
        null,
        {
          name: config.cookies.jwtToken.name,
          options: config.cookies.defaultOptions,
        },
        {
          keyName: config.localStorage.user.keyName,
        },
        signupOptions.referralCode,
        'website',
      ),
    );
  };

  const handleEnterSignup = (event) => {
    event.preventDefault();
    onClickSignup();
  };

  const onFacebookResponse = function (response, method) {
    dispatch(
      loginWithFacebook(
        // while debugging missing access token
        response.accessToken ? response.accessToken : JSON.stringify(response),
        response.data_access_expiration_time,
        config.platform,
        method,
        {
          name: config.cookies.jwtToken.name,
          options: config.cookies.defaultOptions,
        },
        {
          keyName: config.localStorage.user.keyName,
        },
        signupOptions.referralCode,
      ),
    );
  };

  let errorMessage = null;
  if (signupErrors.length) {
    errorMessage = signupErrors[0].message;
    if (errorMessage.includes('Vous êtes')) {
      const i = errorMessage.indexOf('Vous');
      errorMessage = errorMessage.substr(0, i - 1);
    }
  }

  return (
    <>
      {shouldRedirect && <Navigate to={signupOptions.referralCode ? '/invite-landing' : '/'} />}
      <div className="bb-background-light-grey py-3 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5 offset-md-1 col-lg-4 offset-lg-2">
              <div className="p-4 p-md-5 text-center bg-white shadow rounded">
                <h3 className="mb-3">1 an offert de Bubble Infinity après votre inscription</h3>

                <div className="text-start mb-3">
                  Créez votre compte avec votre code de parrainage pour recevoir 1 an de{' '}
                  <Link target="_blank" to="/infinity" className="fw-bold">
                    Bubble Infinity
                  </Link>
                </div>

                <FacebookLogin
                  appId={config.facebook.appId}
                  autoLoad={false}
                  version={config.facebook.apiVersion}
                  fields="name,email,picture"
                  callback={(response) => onFacebookResponse(response, 'signup')}
                  textButton="Inscription avec Facebook"
                  disableMobileRedirect={true}
                  icon={<Icon className="me-3" prefix="fab" name="facebook-f" />}
                  cssClass="btn bb-btn-fb btn-lg mb-2"
                />

                <div className="d-flex align-items-center my-2">
                  <div className="d-flex flex-fill h-separator reverse" />
                  <div className="mx-3">ou</div>
                  <div className="d-flex flex-fill  h-separator " />
                </div>

                <div className="text-start">
                  <form>
                    <LabeledTextInput
                      mandatory
                      inputRef={(ref) => (signupInputRef = ref)}
                      onEnterPressed={handleEnterSignup}
                      title="Adresse e-mail"
                      value={signupOptions.email || ''}
                      onChange={(e) => onChangeField('email', e.target.value)}
                      autoComplete="email"
                      type="text"
                    />

                    <LabeledTextInput
                      mandatory
                      rightComponent="7 caractères minimum"
                      onEnterPressed={handleEnterSignup}
                      title="Mot de passe"
                      value={signupOptions.password || ''}
                      onChange={(e) => onChangeField('password', e.target.value)}
                      autoComplete="new-password"
                      type="password"
                    />

                    <LabeledTextInput
                      disabled={!codeFromLocation}
                      onEnterPressed={handleEnterSignup}
                      title="Code parrain"
                      value={signupOptions.referralCode || ''}
                      onChange={(e) => onChangeField('referralCode', e.target.value)}
                      id="signuppasswordform"
                    />

                    <div className="d-flex align-items-center">
                      <BubbleCheckbox
                        checked={signupOptions.hasAcceptedNewsletter || false}
                        onChange={(e) =>
                          onChangeField(
                            'hasAcceptedNewsletter',
                            !signupOptions.hasAcceptedNewsletter,
                          )
                        }
                        id="newslettercheck"
                      />
                      <label className="text-muted mb-0 ms-2" htmlFor="newslettercheck">
                        S'inscrire à la newsletter
                      </label>
                    </div>
                    <div className="d-flex align-items-center pt-2">
                      <BubbleCheckbox
                        checked={signupOptions.isGdprCompliant || false}
                        onChange={(e) =>
                          onChangeField('isGdprCompliant', !signupOptions.isGdprCompliant)
                        }
                        id="cguvcheck"
                      />
                      <label className="text-muted mb-0 ms-2 mt-1" htmlFor="cguvcheck">
                        J'accepte les{' '}
                        <Link
                          target="_blank"
                          to="/conditions-generales-de-vente-et-d-utilisation-marketplace"
                        >
                          Conditions Générales d'Utilisation
                        </Link>
                      </label>
                    </div>
                  </form>
                </div>
                <div className="text-start mt-4">
                  <button
                    onClick={onClickSignup}
                    className="btn btn-lg px-5 text-white btn-secondary"
                  >
                    Créer mon compte
                  </button>
                </div>
                {errorMessage && <div className="text-danger mt-4 text-start">{errorMessage}</div>}
              </div>
            </div>

            <div className="col-12 col-md-5 col-lg-4">
              <KeyValueCard />
            </div>
          </div>

          <div className="row pt-md-5">
            <div className="col-12 col-md-5 offset-md-1 col-lg-3 offset-lg-2">
              <div className="d-block d-md-none text-center px-3 pt-4">
                <h2 className="font-wight-bold">
                  Commandez auprès de libraires indépendants. En livraison à domicile ou retrait sur
                  place.
                </h2>
              </div>
              <PhoneAndQuestions usePng />
            </div>
            <div className="col-12 col-md-5 col-lg-5 pt-md-5 text-center text-md-start px-5 px-md-0">
              <div className="d-none d-md-block">
                <h2 className="bb-l-text-size font-wight-bold">
                  Commandez auprès de libraires indépendants. En livraison à domicile ou retrait sur
                  place.
                </h2>
              </div>

              <div className="pt-md-3 d-flex flex-column flex-md-row align-items-center">
                <div className="d-flex rounded-circle align-items-center justify-content-center bg-bubble-color text-white circle-with-icon">
                  <Icon name="search" className="bb-xl-text-size" />
                </div>

                <p className="bb-medium-large-text-size ms-md-4 my-0 mt-2 mt-md-n1">
                  <span className="fw-bold bb-medium-text-size">DECOUVRIR</span> et être alerté de
                  vos futurs coups de coeur
                </p>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-center py-4">
                <div className="d-flex rounded-circle align-items-center justify-content-center bg-bubble-color text-white circle-with-icon">
                  <Icon name="comments" className="bb-xl-text-size" />
                </div>

                <p className="bb-medium-large-text-size ms-md-4 my-0 mt-2 mt-md-n1">
                  <span className="fw-bold bb-medium-text-size">REJOIGNEZ</span> la plus grande
                  communauté BD Comics Mangas, et consultez plus d'avis que sur Amazon
                </p>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-center">
                <div className="d-flex rounded-circle align-items-center justify-content-center bg-bubble-color text-white circle-with-icon">
                  <Icon name="server" className="bb-xl-text-size" />
                </div>

                <p className="bb-medium-large-text-size ms-md-4 my-0 mt-2 mt-md-n1 pb-5 pb-md-0">
                  <span className="fw-bold bb-medium-text-size">ORGANISER</span> votre collection
                  mieux que{' '}
                  <a
                    href="https://fr.wikipedia.org/wiki/John_Dewey"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    John Dewey
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupInvite;

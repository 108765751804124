import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loadCategoriesApi } from '../services/api';

import { getClientToken } from '../selectors';

const LOAD_CATEGORIES = 'categories/loadCategories';

const initialState = {
  categories: [],
  loading: false,
  errors: [],
};

export const loadCategories = createAsyncThunk(LOAD_CATEGORIES, async (arg, { getState }) => {
  const clientToken = getClientToken(getState());
  const categories = await loadCategoriesApi(clientToken);
  return { categories };
});

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadCategories.pending, (state, action) => {
        state.categories = initialState.categories;
        state.loading = true;
      })
      .addCase(loadCategories.fulfilled, (state, action) => {
        state.categories = action.payload.categories;
        state.loading = false;
      })
      .addCase(loadCategories.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default categoriesSlice.reducer;

export const UPDATE_FILTER_FOR_ITEM = 'UPDATE_FILTER_FOR_ITEM';

export const initialFilters = {
  category: new Set(),
  genre: new Set(),
  owned: new Set(),
  read: new Set(),
  signed: new Set(),
  loaned: new Set(),
  oe: new Set(),
  started: new Set(),
};

const initialState = {
  filters: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_FILTER_FOR_ITEM: {
      const { action: filterAction, itemId, filterName, value } = action.payload;

      let newFilters = { ...state.filters };

      if (!newFilters[itemId]) {
        newFilters[itemId] = { ...initialFilters };
      }
      if (newFilters[itemId][filterName]) {
        if (filterAction === 'add') newFilters[itemId][filterName].add(value);
        if (filterAction === 'del') newFilters[itemId][filterName].delete(value);
      } else {
        console.warn('no filters named', filterName);
      }

      return {
        ...state,
        filters: newFilters,
      };
    }

    default: {
      return state;
    }
  }
}

// updateFilterForItem ====================================================

export function updateFilterForItem(itemId, filterName, action, value) {
  return {
    type: UPDATE_FILTER_FOR_ITEM,
    payload: {
      itemId,
      filterName,
      action,
      value,
    },
  };
}

export const LOAD_CROWDFUNDING = 'LOAD_CROWDFUNDING';
export const LOAD_CROWDFUNDING_SUCCESS = 'LOAD_CROWDFUNDING_SUCCESS';
export const LOAD_CROWDFUNDING_FAIL = 'LOAD_CROWDFUNDING_FAIL';

export const STOP_CROWDFUNDING_LOADING = 'STOP_CROWDFUNDING_LOADING';

export const HIDE_CROWDFUNDING = 'HIDE_CROWDFUNDING';

export const RESET_CROWDFUNDING_REDUCER = 'RESET_CROWDFUNDING_REDUCER';

const initialState = {
  loading: false,
  crowdfundingsById: {},
  crowdfundingsHiddenByUser: [],
  errors: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_CROWDFUNDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_CROWDFUNDING_SUCCESS: {
      const previous = state.crowdfundingsById[action.payload.crowdfundingId] || {};
      const crowdfundingsById = { ...state.crowdfundingsById };
      crowdfundingsById[action.payload.crowdfundingId] = action.payload.crowdfunding;
      let crowdfundingsHiddenByUser = [...state.crowdfundingsHiddenByUser];
      if ((previous.revision || 0) < (action.payload.crowdfunding?.revision || 0)) {
        crowdfundingsHiddenByUser = crowdfundingsHiddenByUser.filter(
          (cfId) => cfId !== action.payload.crowdfundingId,
        );
      }

      return {
        ...state,
        crowdfundingsById,
        crowdfundingsHiddenByUser,
        loading: false,
        errors: [],
      };
    }
    case LOAD_CROWDFUNDING_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case HIDE_CROWDFUNDING: {
      const crowdfundingsHiddenByUser = [...state.crowdfundingsHiddenByUser];
      crowdfundingsHiddenByUser.push(action.payload.crowdfundingId);
      return {
        ...state,
        crowdfundingsHiddenByUser,
      };
    }

    case RESET_CROWDFUNDING_REDUCER: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}

// loadCrowdfunding ====================================================

export function loadCrowdfunding(crowdfundingId) {
  return {
    type: LOAD_CROWDFUNDING,
    payload: {
      crowdfundingId,
    },
  };
}
export function loadCrowdfundingSuccess(crowdfundingId, crowdfunding) {
  return {
    type: LOAD_CROWDFUNDING_SUCCESS,
    payload: {
      crowdfundingId,
      crowdfunding,
    },
  };
}
export function loadCrowdfundingFail(error) {
  return {
    type: LOAD_CROWDFUNDING_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// stopCrowdfundingLoading ====================================================

export function stopCrowdfundingLoading() {
  return {
    type: STOP_CROWDFUNDING_LOADING,
  };
}

// hideCrowdfunding ====================================================

export function hideCrowdfunding(crowdfundingId) {
  return {
    type: HIDE_CROWDFUNDING,
    payload: { crowdfundingId },
  };
}

// resetCrowdfundingReducer ====================================================

export function resetCrowdfundingReducer() {
  return {
    type: RESET_CROWDFUNDING_REDUCER,
  };
}

import React, { useEffect } from 'react';

import config from '@/project-config';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { loadAppOptions } from 'bubble-reducers/src/reducers/app-options';
import { loadUser } from 'bubble-reducers/src/reducers/user';

import { createCookie, getCookie } from 'bubble-utils/src/auth-utils';

import useEffectOnce from '@/services/hooks/useEffectOnce';
import { useScrollToTop } from '@/services/hooks/useScrollToTop';
import { getDefaultSeo } from '@/services/seo-utils';

import BdcatRedirectButton from '@/components/BdcatRedirectButton/BdcatRedirectButton';
import { useBubbleFacebookPixel } from '@/components/BubbleFacebookPixel/BubbleFacebookPixel';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import Toaster from '@/components/DUPLICATE/Toaster/Toaster';
import SvgGradients from '@/components/SvgGradients/SvgGradients';

import bubbleUtils from 'bubble-utils';

const Layout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userObjectId = useSelector((state) => state.user.user?.objectId);

  useEffectOnce(() => {
    if (!bubbleUtils.env.isServer) {
      TagManager.initialize({
        gtmId: config.google.gtm.id,
        auth: config.google.gtm.auth,
        preview: config.google.gtm.env,
        dataLayer: {
          userId: userObjectId,
        },
      });
    }
    const sessionCountCookie = getCookie(config.cookies.sessionCount.name) || {};
    createCookie(
      config.cookies.sessionCount.name,
      { count: (sessionCountCookie.count || 0) + 1, lastSession: new Date() },
      config.cookies.defaultOptions,
    );
    dispatch(loadAppOptions());
    if (userObjectId) {
      dispatch(loadUser(userObjectId));
    }
  });

  // should be placed above the hash-link part otherwise it will reset the `scrollTo` effect
  useScrollToTop();

  // handles hash link because react-router-hash-link is not comptatible any more woth react router v6
  useEffect(() => {
    const hashParts = window.location.hash.split('#');
    if (hashParts.length > 1) {
      const hash = hashParts.slice(-1)[0];
      if (hash !== '_=_' && document.querySelector(`#${hash}`)) {
        const element = document.querySelector(`#${hash}`);
        const yOffset = -80;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [location]);

  useBubbleFacebookPixel();

  return (
    <>
      <BubbleHelmet seo={getDefaultSeo()} />
      <Helmet>
        <html lang="fr" />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`${config.resources.domain}/favicon-192x192.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href={`${config.resources.domain}/favicon-96x96.png`}
        />
        <link rel="apple-touch-icon" href={`${config.resources.domain}/icons/icon-192.png`} />
        <link rel="android-touch-icon" href={`${config.resources.domain}/icons/icon-192.png`} />
        <meta
          name="apple-itunes-app"
          content={`app-id=${config.bubbleIos.appId}, affiliate-data=pt=${config.bubbleIos.providerToken}&ct=smart-banner`}
        />
        <meta name="google-play-app" content={`app-id=${config.bubbleAndroid.appId}`} />
      </Helmet>
      <Outlet />
      <Toaster />
      <SvgGradients />
      <BdcatRedirectButton />
    </>
  );
};

export default Layout;

export const LOAD_ARTICLE_COMMENTS = 'LOAD_ARTICLE_COMMENTS';
export const LOAD_ARTICLE_COMMENTS_SUCCESS = 'LOAD_ARTICLE_COMMENTS_SUCCESS ';
export const LOAD_ARTICLE_COMMENTS_FAIL = 'LOAD_ARTICLE_COMMENTS_FAIL';

export const STORE_COMMENT = 'STORE_COMMENT';
export const STORE_COMMENT_SUCCESS = 'STORE_COMMENT_SUCCESS';
export const STORE_COMMENT_FAIL = 'STORE_COMMENT_FAIL';

export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAIL = 'DELETE_COMMENT_FAIL';

export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_FAIL = 'UPDATE_COMMENT_FAIL';

export const EDITING_COMMENT = 'EDITING_COMMENT';
export const EDITING_COMMENT_ABORT = 'EDITING_COMMENT_ABORT';

export const FLAG_COMMENT = 'FLAG_COMMENT';
export const FLAG_COMMENT_SUCCESS = 'FLAG_COMMENT_SUCCESS';
export const FLAG_COMMENT_FAIL = 'FLAG_COMMENT_FAIL';

export const LIKE_COMMENT = 'LIKE_COMMENT';
export const UNLIKE_COMMENT = 'UNLIKE_COMMENT';

const initialState = {
  creatingComment: false,
  comments: [],
  commentsBeingMutated: {},
  commentsStatus: null,
};

function dbArrayToMapping(xs) {
  const mapping = {};
  xs.forEach((x) => (mapping[x.objectId] = x));
  return mapping;
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ARTICLE_COMMENTS: {
      return {
        ...state,
        commentsStatus: LOAD_ARTICLE_COMMENTS,
        loading: true,
        errors: [],
      };
    }
    case LOAD_ARTICLE_COMMENTS_SUCCESS: {
      const comments = dbArrayToMapping(action.payload.comments);
      return {
        ...state,
        commentsStatus: LOAD_ARTICLE_COMMENTS_SUCCESS,
        loading: false,
        errors: [],
        comments,
      };
    }
    case LOAD_ARTICLE_COMMENTS_FAIL: {
      return {
        ...state,
        commentsStatus: LOAD_ARTICLE_COMMENTS_FAIL,
        user: {},
        loading: false,
        errors: [],
      };
    }
    case STORE_COMMENT: {
      return {
        ...state,
        creatingComment: true,
        loading: true,
        errors: [],
      };
    }
    case STORE_COMMENT_SUCCESS: {
      const commentData = action.payload.newComment;
      const comment = { [commentData.objectId]: commentData };
      const comments = Object.assign({}, comment, state.comments);
      return {
        ...state,
        creatingComment: false,
        loading: false,
        commentsBeingMutated: {},
        error: [],
        comments,
      };
    }
    case STORE_COMMENT_FAIL: {
      return {
        ...state,
        creatingComment: false,
        loading: false,
        errors: [],
      };
    }
    case FLAG_COMMENT: {
      return {
        ...state,
        errors: [],
      };
    }
    case FLAG_COMMENT_SUCCESS: {
      const flagcount = action.payload.flagcount;
      const comments = Object.assign({}, state.comments);
      comments[flagcount.commentObjectId] = flagcount;
      return {
        ...state,
        comments,
        commentsBeingMutated: {},
        errors: [],
      };
    }
    case FLAG_COMMENT_FAIL: {
      return {
        ...state,
        errors: [],
      };
    }
    case DELETE_COMMENT: {
      const commentObjectId = action.payload.commentObjectId;
      const commentsBeingMutated = {
        [commentObjectId]: DELETE_COMMENT,
        ...state.commentsBeingMutated,
      };
      return {
        ...state,
        commentsBeingMutated,
        errors: [],
      };
    }
    case DELETE_COMMENT_SUCCESS: {
      const deletedComment = action.payload.deletedComment;
      const commentObjectId = deletedComment.objectId;
      const comments = Object.assign({}, state.comments);
      delete comments[commentObjectId];
      const commentsBeingMutated = Object.assign({}, state.commentsBeingMutated);
      delete commentsBeingMutated[deletedComment.objectId];
      return {
        ...state,
        comments,
        commentsBeingMutated,
        errors: [],
      };
    }
    case DELETE_COMMENT_FAIL: {
      return {
        ...state,
        errors: [],
      };
    }
    case UPDATE_COMMENT: {
      const commentObjectId = action.payload.commentObjectId;
      const commentsBeingMutated = {
        ...state.commentsBeingMutated,
        [commentObjectId]: 'UPDATE_COMMENT',
      };
      return {
        ...state,
        commentsBeingMutated,
        errors: [],
      };
    }
    case UPDATE_COMMENT_SUCCESS: {
      const commentData = action.payload.updatedComment;
      const comment = { [commentData.objectId]: commentData };
      const comments = Object.assign({}, state.comments, comment);
      return {
        ...state,
        comments,
        updating: false,
        errors: [],
      };
    }
    case UPDATE_COMMENT_FAIL: {
      return {
        ...state,
        updating: false,
        errors: [],
      };
    }
    case EDITING_COMMENT: {
      const commentsBeingMutated = Object.assign({}, state.commentsBeingMutated);
      const commentObjectId = action.payload.commentObjectId;
      commentsBeingMutated[commentObjectId] = EDITING_COMMENT;
      return {
        ...state,
        commentsBeingMutated,
        updating: false,
        errors: [],
      };
    }
    case EDITING_COMMENT_ABORT: {
      const commentsBeingMutated = Object.assign({}, state.commentsBeingMutated);
      const commentObjectId = action.payload.commentObjectId;
      delete commentsBeingMutated[commentObjectId];
      return {
        ...state,
        commentsBeingMutated,
        updating: false,
        errors: [],
      };
    }
    case LIKE_COMMENT: {
      const comments = Object.assign({}, state.comments);
      const { commentObjectId } = action.payload;
      comments[commentObjectId].likes++;
      return {
        ...state,
        comments,
      };
    }
    case UNLIKE_COMMENT: {
      const comments = Object.assign({}, state.comments);
      const { commentObjectId } = action.payload;
      comments[commentObjectId].likes--;
      return {
        ...state,
        comments,
      };
    }
    default:
      return { ...state };
  }
}

export function loadArticleComments(articleObjectId) {
  return {
    type: LOAD_ARTICLE_COMMENTS,
    payload: {
      articleObjectId,
    },
  };
}
export function loadArticleCommentsSuccess(comments) {
  return {
    type: LOAD_ARTICLE_COMMENTS_SUCCESS,
    payload: {
      comments,
    },
  };
}
export function loadArticleCommentsFail(error) {
  return {
    type: LOAD_ARTICLE_COMMENTS_FAIL,
    payload: new Error(error),
    error: true,
  };
}
export function storeComment(articleObjectId, content, userObjectId, user) {
  return {
    type: STORE_COMMENT,
    payload: {
      articleObjectId,
      content,
      userObjectId,
      user,
    },
  };
}
export function storeCommentSuccess(newComment) {
  return {
    type: STORE_COMMENT_SUCCESS,
    payload: {
      newComment,
    },
  };
}
export function storeCommentFail(error) {
  return {
    type: STORE_COMMENT_FAIL,
    payload: new Error(error),
    error: true,
  };
}
export function deleteComment(commentObjectId) {
  return {
    type: DELETE_COMMENT,
    payload: {
      commentObjectId,
    },
  };
}
export function deleteCommentSuccess(deletedComment) {
  return {
    type: DELETE_COMMENT_SUCCESS,
    payload: {
      deletedComment,
    },
  };
}
export function deleteCommentFail(error) {
  return {
    type: DELETE_COMMENT_FAIL,
    payload: new Error(error),
    error: true,
  };
}
export function updateComment(commentObjectId, content) {
  return {
    type: UPDATE_COMMENT,
    payload: {
      commentObjectId,
      content,
    },
  };
}
export function updateCommentSuccess(updatedComment) {
  return {
    type: UPDATE_COMMENT_SUCCESS,
    payload: {
      updatedComment,
    },
  };
}
export function updateCommentFail(error) {
  return {
    type: UPDATE_COMMENT_FAIL,
    payload: new Error(error),
    error: true,
  };
}
export function editComment(commentObjectId) {
  return {
    type: EDITING_COMMENT,
    payload: {
      commentObjectId,
    },
  };
}
export function abortEditComment(commentObjectId) {
  return {
    type: EDITING_COMMENT_ABORT,
    payload: {
      commentObjectId,
    },
  };
}
export function flagComment(commentObjectId) {
  return {
    type: FLAG_COMMENT,
    payload: {
      commentObjectId,
    },
  };
}
export function flagCommentSuccess(flagcount) {
  return {
    type: FLAG_COMMENT_SUCCESS,
    payload: {
      flagcount,
    },
  };
}
export function flagCommentFail(error) {
  return {
    type: FLAG_COMMENT_FAIL,
    payload: new Error(error),
    error: true,
  };
}
export function likeComment(commentObjectId) {
  return {
    type: LIKE_COMMENT,
    payload: {
      commentObjectId,
    },
  };
}
export function unlikeComment(commentObjectId) {
  return {
    type: UNLIKE_COMMENT,
    payload: {
      commentObjectId,
    },
  };
}

import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { loadCartApi, updateCartApi } from '../services/api';

import {
  ADD_SKUS_TO_CART,
  ADD_TO_CART,
  LOAD_CART,
  REMOVE_FROM_CART,
  REMOVE_SKUS_FROM_CART,
  UPDATE_CART,
} from '../reducers/cart';
import {
  itemsAddedToCart,
  loadCartFail,
  loadCartSuccess,
  updateCartFail,
  updateCartSuccess,
} from '../reducers/cart';
import { logEvent } from '../reducers/events';
import { getClientId, getClientToken } from '../selectors';

import { EVENT } from 'bubble-constants';
import bubbleUtils from 'bubble-utils';

function* loadCartFlow() {
  try {
    const clientToken = yield select(getClientToken);
    const clientId = yield select(getClientId);
    const response = yield call(loadCartApi, clientToken, clientId);
    yield put(loadCartSuccess(response));
  } catch (error) {
    console.log('saga middleware error (loadCartFlow)', error);
    yield put(loadCartFail(error));
  }
}

function* addToCartFlow(action) {
  try {
    const prints = Array.isArray(action.payload.prints)
      ? action.payload.prints
      : [action.payload.prints];
    const items = prints.map((print) => ({
      printObjectId: print.objectId,
      sku: print.ean || print.sku,
      quantity: print.quantity !== null && print.quantity !== undefined ? print.quantity : 1,
    }));
    const clientToken = yield select(getClientToken);
    const clientId = yield select(getClientId);
    const response = yield call(updateCartApi, clientToken, clientId, { items });
    yield put(updateCartSuccess(response));
    const addedItems = (response.items || []).filter((item) =>
      prints.find((print) => print.objectId === item.itemObjectId),
    );
    yield put(itemsAddedToCart(addedItems));
    if (addedItems.length) {
      yield put(
        logEvent(
          EVENT.ADD_TO_CART,
          bubbleUtils.event.getPropertiesForCart(addedItems, action.payload.isBatch),
          null,
          EVENT.ITEMS_IN_CART,
          addedItems.reduce((acc, curr) => acc + Number(curr.quantity || 1), 0),
        ),
      );
    }
  } catch (error) {
    console.log('saga middleware error (addToCartFlow)', error);
    yield put(updateCartFail(error));
  }
}

function* addSkusToCartFlow(action) {
  try {
    const skus = Array.isArray(action.payload.skus) ? action.payload.skus : [action.payload.skus];
    const items = skus.map((sku) => ({
      sku: sku.sku,
      quantity: sku.quantity !== null && sku.quantity !== undefined ? sku.quantity : 1,
    }));

    const clientToken = yield select(getClientToken);
    const clientId = yield select(getClientId);
    const response = yield call(updateCartApi, clientToken, clientId, { items });
    yield put(updateCartSuccess(response));
    const addedItems = (response.items || []).filter((item) =>
      skus.find((sku) => sku.sku === item.sku),
    );
    yield put(itemsAddedToCart(addedItems));
  } catch (error) {
    console.log('saga middleware error (addSkusToCartFlow)', error);
    yield put(updateCartFail(error));
  }
}

export function* removeFromCartFlow(action) {
  try {
    const prints = Array.isArray(action.payload.prints)
      ? action.payload.prints
      : [action.payload.prints];
    const items = prints.map((print) => ({
      printObjectId: print.objectId,
      sku: print.ean || print.sku,
      quantity: 0,
    }));

    const clientToken = yield select(getClientToken);
    const clientId = yield select(getClientId);
    const response = yield call(updateCartApi, clientToken, clientId, { items });
    yield put(updateCartSuccess(response));
    yield put(
      logEvent(
        EVENT.REMOVE_FROM_CART,
        bubbleUtils.event.getPropertiesForCart(prints, action.payload.isBatch),
        null,
        EVENT.ITEMS_IN_CART,
        prints.reduce((acc, curr) => acc + Number(curr.quantity || 1), 0) * -1,
      ),
    );
  } catch (error) {
    console.log('saga middleware error (removeFromCartFlow)', error);
    yield put(updateCartFail(error));
  }
}

function* removeSkusFromCartFlow(action) {
  try {
    const skus = Array.isArray(action.payload.skus) ? action.payload.skus : [action.payload.skus];
    const items = skus.map((sku) => ({ sku: sku.sku, quantity: 0 }));
    const clientToken = yield select(getClientToken);
    const clientId = yield select(getClientId);

    const response = yield call(updateCartApi, clientToken, clientId, { items });
    yield put(updateCartSuccess(response));
  } catch (error) {
    console.log('saga middleware error (removeSkusFromCartFlow)', error);
    yield put(updateCartFail(error));
  }
}

function* updateCartFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    //TODO: cart with no account ?
    const clientId = yield select(getClientId);

    const response = yield call(updateCartApi, clientToken, clientId, action.payload.options);
    yield put(updateCartSuccess(response));
  } catch (error) {
    console.log('saga middleware error (updateCartFlow)', error);
    yield put(updateCartFail(error));
  }
}

export default function* cartWatcher() {
  yield all([
    takeLatest(LOAD_CART, loadCartFlow),
    takeLatest(ADD_TO_CART, addToCartFlow),
    takeLatest(REMOVE_FROM_CART, removeFromCartFlow),
    takeLatest(ADD_SKUS_TO_CART, addSkusToCartFlow),
    takeLatest(REMOVE_SKUS_FROM_CART, removeSkusFromCartFlow),
    takeLatest(UPDATE_CART, updateCartFlow),
  ]);
}

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadIaps } from 'bubble-reducers/src/reducers/in-app-purchases';
import { getIapsForPlatform } from 'bubble-reducers/src/selectors';

import { useIsUserlogged } from '@/services/hooks/useUserUtils';
import imageUtils from '@/services/image-utils';

import InfinityBuyZone from '@/components/InfinityBuyZone/InfinityBuyZone';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import bubbleUtils from 'bubble-utils';

import './Modals.css';

const InfinityFunctionalityModal = (props) => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  const iaps = useSelector((state) => getIapsForPlatform(state, 'web'));
  const [selectedIap, setSelectedIap] = useState(null);

  useEffect(() => {
    dispatch(loadIaps());
  }, []);

  const dismiss = () => {
    setSelectedIap(null);
    props.callback();
  };

  let infinityFunctionality = props.infinityFunctionalityType;
  if (
    !infinityFunctionality ||
    (infinityFunctionality && Object.keys(infinityFunctionality).length === 0)
  ) {
    infinityFunctionality = InfinityFunctionalityModal.types.DEFAULT;
  }
  return (
    <Modal
      show={props.show}
      onHide={dismiss}
      size="xl"
      centered
      contentClassName="text-center text-white bb-background-light-grey modal-infinity-background"
    >
      <div className="modal-header header-no-separator">
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={dismiss}
        ></button>
      </div>
      <div className="modal-body mx-sm-3">
        <div className="text-center bb-xxl-text-size fw-bold">
          Avantage <span className="text-infinity">Bubble Infinity</span>{' '}
          <img
            style={{ width: 70 }}
            alt="infinity loop"
            src={imageUtils.getAssetImage('icon_infinity_yellow.svg')}
          />
        </div>
        <div className="pt-4">
          <div className="bb-l-text-size">
            {infinityFunctionality.title} Abonnez-vous à Infinity pour en profiter ainsi que{' '}
            <u>
              <Link to="/infinity" className="text-white">
                toutes les autres fonctionnalités
              </Link>
            </u>
          </div>
        </div>
        <div className="px-0 px-xl-5">
          <div className="row pt-4">
            <div className="col-12 pe-xl-5 pt-4 pt-xl-0 d-flex flex-column align-items-center">
              <div className="infinity-modal-separator bg-warning mb-4" />
              <div className="fw-bold bb-xl-text-size pb-3">
                Choisissez votre durée à la carte
                <span role="img" aria-label="victory">
                  ✌️
                </span>
              </div>
              <div className="d-flex justify-content-around text-center">
                {iaps.map((iap) => (
                  <div key={'modal_iap_' + iap.objectId}>
                    <WithClickHandler
                      onClick={() => {
                        if (isUserlogged()) {
                          setSelectedIap(iap);
                        }
                      }}
                    >
                      <div className="rounded border infinity-landing-starter-grey-border pt-2 px-4">
                        <div className="fw-bold text-infinity bb-l-text-size">{iap.title}</div>
                        <div className="fw-bold text-infinity bb-medium-large-text-size">
                          {bubbleUtils.currency.formatCurrency(iap.price)}
                        </div>
                        <div className="py-3 bb-medium-text-size">Souscrire</div>
                      </div>
                      soit {bubbleUtils.currency.formatCurrency(iap.price / iap.monthsAdded)}
                      /mois
                    </WithClickHandler>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {selectedIap && (
          <div className="pb-5 row mt-4 text-start">
            <div className="col-10 offset-1">
              <InfinityBuyZone
                iap={selectedIap}
                callback={() => {
                  setSelectedIap(null);
                  props.callback();
                }}
              />
            </div>
          </div>
        )}
        {!selectedIap && (
          <div className="py-5">
            <Link className="btn btn-lg bg-infinity no-decoration" to="/infinity">
              Découvrir tous les autres avantages
            </Link>
          </div>
        )}
      </div>
    </Modal>
  );
};

InfinityFunctionalityModal.propTypes = {
  infinityFunctionality: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
};

InfinityFunctionalityModal.types = {
  OWNED: {
    title: "L'ajout d'édition personnalisée n'est pas inclus dans la version gratuite.",
  },
  READ: {
    title: "La fonctionnalité lu/non-lu n'est pas incluse dans la version gratuite.",
  },
  OE: {
    title: "Le tag 'Edition Originale' n'est pas inclu dans la version gratuite.",
  },
  SIGNED: {
    title: "Le tag 'Dédicace' n'est pas inclu dans la version gratuite.",
  },
  LOAN: {
    title: "Le prêt n'est pas inclu dans la version gratuite.",
  },
  MEMO: {
    title: "La création de mémo n'est pas incluse dans la version gratuite.",
  },
  DEFAULT: {
    title: '',
    subtitle: 'pour profiter de cette fonctionnalité',
  },
};

export default InfinityFunctionalityModal;

import React from 'react';

import { Link } from 'react-router-dom';

import { formatDateWithFormat } from 'bubble-utils/src/date-utils';

import imageUtils from '@/services/image-utils';

import Cover from '@/components/Cover/Cover';

import './PrintSelectionCell.scss';

const PrintSelectionCell = (props) => {
  const print = props.print || {};
  const album = props.album || {};

  const date =
    formatDateWithFormat(print.publicationDate, {
      month: 'long',
      day: null,
    }) || '';

  return (
    <div
      className={`row rounded p-2 my-2 my-md-3 mx-1 mx-md-0 bb-s-text-size text-secondary ${
        props.selected ? 'print-cell-selected' : 'print-cell'
      }`}
    >
      <div className="col-12 col-md-10 d-flex ms-n2 bb-small-text-size">
        <Cover shadow imageUrl={print.images?.front?.smallFramed} />
        <div className="ps-3">
          <div className="print-cell-full-height-container d-flex flex-column justify-content-between py-1">
            <div>
              <span className="text-secondary">
                <Link
                  className="text-primary"
                  to={`/${album.serie?.permalink || 's'}/serie/${album.serie?.objectId}`}
                >
                  {album.serie?.title}
                </Link>
                {album.tome && ` - Tome ${album.tome}`}{' '}
              </span>
              <div className="fw-bold bb-medium-text-size text-black">
                {date && date.charAt(0).toUpperCase() + date.slice(1)}
              </div>
            </div>
            <div>
              <div>
                {[print.publisher, print.numberOfPages ? `${print.numberOfPages} pages` : null]
                  .filter((row) => row)
                  .join(' - ')}
              </div>
              <div>
                {[print.width && print.length ? `${print.width}x${print.length}` : null, print.ean]
                  .filter((row) => row)
                  .join(' - ')}
              </div>
            </div>
            <div className="d-flex d-md-none align-items-center">
              <img
                alt="edition"
                className="pe-2"
                src={imageUtils.getAssetImage(
                  `button_edition_add_${props.selected ? 'green' : 'grey'}.svg`,
                )}
              />
              <span className={props.selected ? 'text-success' : ''}>
                Choisir une autre édition
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-2 d-none d-md-flex flex-column align-items-center justify-content-center">
        <img
          alt="edition"
          className="ps-2"
          src={imageUtils.getAssetImage(
            `button_edition_add_${props.selected ? 'green' : 'grey'}.svg`,
          )}
        />
        <div className={`text-center ${props.selected ? 'text-success' : ''}`}>
          {props.selected ? 'Édition sélectionnée' : 'Choisir une autre édition'}
        </div>
      </div>
    </div>
  );
};

export default PrintSelectionCell;

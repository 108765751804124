import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loadCountriesApi, loadShippingFeesApi } from '../services/api';

import { getClientToken } from '../selectors';

const LOAD_COUNTRIES = 'countries/loadCountries';
const LOAD_SHIPPING_FEES = 'countries/loadShippingFees';

const initialState = {
  countries: {},
  shippingFees: [],
  loading: false,
  errors: [],
};

export const loadCountries = createAsyncThunk(LOAD_COUNTRIES, async (arg, { getState }) => {
  const clientToken = getClientToken(getState());
  const countries = await loadCountriesApi(clientToken);
  return { countries };
});

export const loadShippingFees = createAsyncThunk(LOAD_SHIPPING_FEES, async (arg, { getState }) => {
  const clientToken = getClientToken(getState());
  const shippingFees = await loadShippingFeesApi(clientToken);
  return { shippingFees };
});

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadCountries.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadCountries.fulfilled, (state, action) => {
        state.countries = action.payload.countries.reduce((acc, cur) => {
          acc[cur.objectId] = cur;
          return acc;
        }, {});
        state.loading = false;
      })
      .addCase(loadCountries.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(loadShippingFees.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadShippingFees.fulfilled, (state, action) => {
        state.shippingFees = action.payload?.shippingFees || [];
        state.loading = false;
      })
      .addCase(loadShippingFees.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default countriesSlice.reducer;
